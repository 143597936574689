import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { add_temp_session } from "../../../reducers/chat-session-slice";

import {
    Header as StyledHeader,
    Hamburger as StyledHamburger,
    Add as StyledAdd
} from "./styled-components.js";

import {ReactComponent as HamburgerIcon} from "../../../images/hamburger-32.svg";
import {ReactComponent as AddIcon} from "../../../images/add-20.svg";

function SecondaryHeader(props) {
    const dispatch = useDispatch();
    const creatingNewSession = useSelector(
        (state) => state.chat.creatingNewSession
    );

    function addSession() {
        dispatch(add_temp_session({}));
    }

    return <StyledHeader>
        <StyledHamburger onClick={props.onHamburgerClick}>
            <HamburgerIcon /> 
        </StyledHamburger>
        <StyledAdd onClick={creatingNewSession ? null : addSession}>
            <AddIcon /> 
        </StyledAdd>
    </StyledHeader>
}

export default SecondaryHeader;