import styled from "styled-components";
import { getStyleString } from "../../../../../utils/styleUtils";

interface containerTypes {
    $rowColumns: {
        rows: number;
        columns: number;
    };
}

interface cellTypes {
    $noTopBorder: boolean;
    $noLeftBorder: boolean;
}

export const Container = styled.div<containerTypes>`
    border-radius: 12px;
    border: 1px solid ${props => props.theme["border_02"]};
    background: ${props => props.theme["ui_05"]};
    display: grid;
    grid-template-columns: repeat(${props => props.$rowColumns.columns}, auto);
`;


export const Cell = styled.div<cellTypes>`
    padding: 16px 20px;
    border-top: ${props => props.$noTopBorder ? "none" : `1px solid ${props.theme["border_02"]}`};
    border-left: ${props => props.$noLeftBorder ? "none" : `1px solid ${props.theme["border_02"]}`};
`;

export const FillerCell = styled.div<cellTypes>`
    border-top: ${props => props.$noTopBorder ? "none" : `1px solid ${props.theme["border_02"]}`};
    border-left: ${props => props.$noLeftBorder ? "none" : `1px solid ${props.theme["border_02"]}`};
`;

export const CellLabel = styled.div`
    color: ${props => props.theme["text_03"].light};
    ${props => getStyleString(props.theme["eyebrow_text"])};
    text-transform: uppercase;
    margin-bottom: 8px;
`;

export const CellContent = styled.div`
    color: ${props => props.theme["text_01"].light};
    ${props => getStyleString(props.theme["text_02_med"])};
`;

export const Tags = styled.div`
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
`;

export const Tag = styled.div`
    padding: 6px 12px;
    border-radius: 8px;
    background: ${props => props.theme["ui_04"]};
`;