import React from "react";
import { useSelector } from "react-redux";

import { Icon as StyledIcon } from "./styled-components.js";

function ProfileIcon(props) {
  const userDetails = useSelector((state) => state.user.details);
  const firstInitial = userDetails.firstName
    ? userDetails.firstName.substring(0, 1).toUpperCase()
    : "";
  const lastInitial = userDetails.lastName
    ? userDetails.lastName.substring(0, 1).toUpperCase()
    : "";

  return (
    <StyledIcon
      theme={props.theme}
      size={props.size}
    >{`${firstInitial}${lastInitial}`}</StyledIcon>
  );
}

export default ProfileIcon;
