import React from "react";
import { useTheme } from 'styled-components';

import {
    Container as StyledContainer,
    Emoji as StyledEmoji,
    Text as StyledText
} from "./styled-components";

function ErrorCard(props) {
    const theme = useTheme();

    return <StyledContainer height={props.height}>
        <div>
            <StyledEmoji>🚫</StyledEmoji>
            <StyledText>Oops, we couldn’t load the details for you. Please try again later.</StyledText>
        </div>
    </StyledContainer>
}

export default ErrorCard;