import styled from "styled-components";
import { getStyleString } from "../../../../utils/styleUtils";

export const MainContainer = styled.div`
    width: 100%;
`;

export const Header = styled.div`
    margin: 24px 0px;
    text-align: center;
`;

export const OrBlock = styled.div`
    color: ${props => props.theme["text_04"].light};
    ${props => getStyleString(props.theme["text_01_med"])};
    display: flex;
    flex-direction: row;

    &:before, &:after{
        content: "";
        flex: 1 1;
        border-bottom: 1px solid ${props => props.theme["border_02"]};
        margin: auto;
    }
    &:before {
        margin-right: 10px
    }
    &:after {
        margin-left: 10px
    }
`;

export const Button = styled.button`
    width: 320px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    border-radius: 4px;

  ${({ theme, btnActive }) => `
    color: ${
        btnActive ? theme["text_05"].light : theme["text_04"].light
    };
    background: ${btnActive ? theme["ui_interactive"] : theme["ui_pressed"]};
    cursor: ${btnActive ? "pointer" : "not-allowed"};
    ${getStyleString(theme["text_01_med"])};
    &:hover {
        background: ${
            btnActive ? theme["ui_interactive_hover"] : theme["ui_pressed"]
        };
    }`}
`;

export const ErrorContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 12px;
    margin-bottom: 16px;
`;

export const FormField = styled.div`
    position: relative;
    text-align: left;

    ${props => props.isSubmit ? `
        margin-top: 16px;
        text-align: center;

        button {
            width: 100%;
            height:48px;
        }
    ` : ``}
`;

export const LinkContainer = styled.div`
    margin-top: 16px;
    text-align: center;

    button {
        display: inline-block!important;
    }
`;