import styled from 'styled-components';

export const VizDiv = styled.div`
    width: 100%;
    
    ${props => props.viewOnly ? 
        `overflow-x: auto;
        overflow-y: hidden;` : ``
    }
   
`;

export const LoaderDiv = styled.div`
    height: 50px;

    .loader-div {
        position: relative;
        top: 50%;
    }

    img {
        transform: translateY(-50%);
    }
`;