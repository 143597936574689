import styled from 'styled-components';
// import {getStyleString} from "../../utils/styleUtils.js";

export const VizSelector = styled.span`
    position: relative;
    ${props => props.customStyle || ""}
`;

export const Label = styled.span`
    cursor: ${props => props.disableLabel ? "auto" : "pointer"};
    position: relative;
    display: inline-block;

    .ans-card__viz-disable-tooltip {
        position: absolute;
        top: calc(100% + 8px);
        width: 180px;
        display: none;
        white-space: pre-wrap;
        z-index: 100;
    }
    
    &:hover .ans-card__viz-disable-tooltip {
        display: block;
    }
`;

export const Dropdown = styled.div`
    position: absolute;
    left: ${props => props.left || "0"}px;
    top: calc(100% + 4px);
    z-index: 110;
    ${props => props.searchable ? "width: 100%;" : ""}
`;

// export const ActionIcon = styled.span`
//     vertical-align: middle;
//     margin-left: 4px;
// color: ${props => props.textColor};
    // ${props => getStyleString(props.textStyle)}
// `;

export const VizLabel = styled.div`
    display: flex;
    align-items: center;
    cursor: ${props => props.disableLabel ? "auto" : "pointer"};
    border-radius: 8px;
    background: ${props => props.disableLabel ? "#F5F7FA" : "none"};
`;

export const VizLabelIcon = styled.div`
    padding: 6px;
    width: 20px;
    height: 20px;
`;

export const VizLabelArrow = styled.div`
    padding: 2px;
    width: 20px;
    height: 20px;
    pointer-events: none;
`;
