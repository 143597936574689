import { createSlice } from "@reduxjs/toolkit";

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    settings: [],
    currencyFormat: undefined,
    supportEmail: undefined,
    autosuggestSettings: undefined,
    enableAutoAnalysis: false
  },
  reducers: {
    set_settings: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const data = action.payload;

      state.settings = data.settings;
      state.currencyFormat = data.currencyFormat;
      state.supportEmail = data.supportEmail;
      state.autosuggestSettings = data.autosuggestSettings;
      state.enableAutoAnalysis = data.enableAutoAnalysis;
    },
  },
});

// Action creators are generated for each case reducer function
export const { set_settings } = settingsSlice.actions;

export default settingsSlice.reducer;