import styled from "styled-components";
import { getStyleString } from "../../../utils/styleUtils";

export const Container = styled.div`
    & div:nth-child(2) {
        margin-top: 8px;
    }
`;

export const Title = styled.div`
    color: ${props => props.theme["text_01"].light};
    ${props => getStyleString(props.theme["answer_title"])};
`;

export const SubTitle = styled.div`
    color: ${props => props.theme["text_03"].light};
    ${props => getStyleString(props.theme["text_01_med"])};
`;