// import { SET_CURRENT_PROJECT } from "../actions/Project.actions";
// import { REHYDRATE } from "redux-persist";

// const INITIAL_STATE = [];

// export const CurrProjReducer = (state = INITIAL_STATE, action) => {
//   switch (action.type) {
//     case REHYDRATE:
//       let payload = action.payload || {};
//       return {
//         ...state,
//         ...payload.currProject,
//       };

//     case SET_CURRENT_PROJECT:
//       return {
//         ...state,
//         currProject: action.payload,
//       };

//     default:
//       let newState = { ...state };
//       return newState;
//   }
// };

import { createSlice } from "@reduxjs/toolkit";

export const currentProjectSlice = createSlice({
  name: "currentProject",
  initialState: {
    currProject: [],
    projectInfo: undefined
  },
  reducers: {
    set_project: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.currProject = action.payload;
    },
    set_project_info: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.projectInfo = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { set_project, set_project_info } = currentProjectSlice.actions;

export default currentProjectSlice.reducer;
