import React, { useEffect } from "react";

export function useOutsideClick(ref, outsideClickHandler) {
    useEffect(() => {
        /**
         * Dev Note: Any click outside the question bubble will be considered cancel question
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                outsideClickHandler();
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}