// https://copilot-dev.cruxintelligence.com/agent-document/document/source?file_name=Narratives.pdf&source_location=copilot-document-data-dev

import { generateQueryString, getNodeServerURL, getAgentDocUrl } from "../utils/utilityFunctions";
import  newHttpRequestService from "../utils/newHTTPrequest";

class DocumentAgentService {
  constructor() {
    this.BASE_URL = `${getNodeServerURL()}agent-document/`;
  }

  //Get conversations of user
  getDocument = (query) => {
    // let url = this.BASE_URL + "document/source?file_name=Narratives.pdf&source_location=copilot-document-data-dev";
    let url = generateQueryString(this.BASE_URL + "document/source", query);
    const config = {responseType: 'blob'}     
    return newHttpRequestService.get(url, null, config);
  };
  
}

export default new DocumentAgentService();