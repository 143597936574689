import styled from "styled-components";

// interface ContainerProps {
// }

// export const Container = styled.div<ContainerProps>`
//     display: grid;
//     grid-template-rows: auto;
//     height: 100%;

//     @media (min-width: 1024px) {
//       grid-template-rows: 60px auto;
//     }
// `;

// export const Header = styled.div`
//     padding: 12px 20px;
//     box-sizing: border-box;
//     border-bottom: 1px solid ${props => props.theme["left_pane_border"].light};
//     display: none;
//     z-index: 1;
//     position: relative;
//     @media (min-width: 1024px) {
//       display: flex;
//       align-items: center;
//     }

//     .button-icon {
//       margin-left: auto;
//     } 
// `;

export const ProfileDropDownContainer = styled.div`
  position: relative;
`;

// export const NewConvo = styled.div`
//   padding: 20px 20px 16px 20px;

//   .button-content {
//     justify-content: left !important;
//   }
// `;

// export const SessionList = styled.div`
//   height: calc(100% - 72px);
//   overflow: auto;
// `;