import styled from "styled-components";
import { getStyleString } from "../../../../../utils/styleUtils";

interface ContainerProps {
  hideSend?: boolean;
}

interface InputWrapperProps {
  isDropdownOpen?: boolean;
  disabled?: boolean;
  customStyle?: "";
}

interface InputProps {
  inputPadding: number;
}

interface SuggestionProps {
  inputWidth: number;
  inputPadding: number;
}

interface CrossProps {
  show: boolean;
}

interface SendProps {
  isDisabled: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: ${props => props.hideSend ? "100%" : "auto 52px"};
`;

export const InputWrapper = styled.div<InputWrapperProps>`
  ${(props) => `
        display:flex;
        align-items: center;
        border-radius: ${props.isDropdownOpen ? "0px 0px 8px 8px" : "8px"};
        background-color: #FFFFFF;
        height: 52px;
        box-sizing: border-box;
        padding: 16px;

        ${props.disabled ? "pointer-events:none; opacity: 0.5;" : ""}

        ${props.customStyle || ""};
    `}
`;

export const Cross = styled.div<CrossProps>`
  ${(props) => `   
        margin-left: 16px;
        margin-right: 11px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 24px;
        height: 24px;
        visibility: ${props.show ? "visible" : "hidden"};
        
        svg {
          transform: scale(1.2);
          
          path {
              fill: ${props.theme["icon03"]}
          }
        }
    `}
`;

export const Send = styled.div<SendProps>`
  padding: 14px 8px 14px 12px;
  // margin-left: 4px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  cursor: pointer;
  // width: 24px;
  // height: 24px;
  border-radius: 0px 8px 8px 0px;
  border-left: 1px solid ${props => props.theme["border_02"]};
  background: ${props => props.theme[props.isDisabled ? "ui_04" : "ui_interactive"]};
  box-sizing: border-box;
  height: 52px;

  svg {
    path {
        fill: ${props => props.isDisabled ? props.theme["icon_disabled"] : props.theme["icon_04"].light}
    }
  }
`;

export const InputBox = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

export const Input = styled.input<InputProps>`
  ${(props) => `   
    outline: none;
    border: none;
    background-color: transparent;
    margin: 2px 0px;
    width: 100%;
    padding-right: ${props.inputPadding}px;
    box-sizing: border-box;
    ${getStyleString(props.theme["text_01_med"])};
    color: ${props.theme["text_01"].light};

    ::placeholder {
        ${getStyleString(props.theme["text_01_med"])};
        color: ${props.theme["text_04"].light};
    }
  `}
`;

export const Suggestion = styled.div<SuggestionProps>`
  position: absolute;
  top: 3px;
  left: ${(props) => props.inputWidth + 4}px;
  width: ${(props) => props.inputPadding - 4}px;
  pointer-events: none;
  ${(props) => getStyleString(props.theme["text_01_med"])};
  color: ${(props) => props.theme["text_04"].light};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const HiddenText = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: none;
  z-index: -1;
  visibility: hidden;
  white-space: nowrap;
  ${(props) => getStyleString(props.theme["text_01_med"])};
`;
