const POPUP_WIDTH = 400;
const POPUP_HEIGHT = 426;

const ID_TOKEN = "ID_TOKEN";

export class OAuthService {
    launchPopup(token_url, onWindowClose) {
        const left = window.screen.width / 2 - POPUP_WIDTH / 2, top = window.screen.height / 2 - POPUP_HEIGHT / 2;
        const win = window.open(token_url,
            "oauth-app", 
            "menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=" +
                POPUP_WIDTH +
                ", height=" +
                POPUP_HEIGHT +
                ", top=" +
                top +
                ", left=" +
                left
            );
        
        if(win) {
            win.opener = window;
            var winCloseChecker = setInterval(function() {
                if (win.closed) {
                    clearInterval(winCloseChecker);
                    onWindowClose(new Error('window closed!'));
                }
            }, 500);
        }
    }

    listenToMessageEvent(resolve, reject) {
        const windowEventHandler = (event) => {
            let hash = event.data;
            // eslint-disable-next-line no-console
            console.log(hash);
            console.log("[listenToMessageEvent] hash: ", hash);
            if (hash.type === ID_TOKEN) {
                const id_token = hash.id_token;
                const access_token = hash.access_token;
                resolve({
                    id_token,
                    access_token
                });
            } else if (hash.type == "error") {
                console.error(hash.message);
                reject(hash.message);
            }
            window.removeEventListener("message", windowEventHandler);
        };
        window.addEventListener("message", windowEventHandler, false);
    }

    findTokenInHash(hash) {
        const matchedResult = hash.match(/id_token=([^&]+)/);
        return matchedResult && matchedResult[1];
    }

    processToken(id_token, access_token, callbackFn) {
        if (!window.opener) {
            id_token && callbackFn(id_token, access_token);
            return;
        }
        if (!(id_token || access_token)) {
            window.opener.postMessage({
                type: "error",
                message: "No Token Found."
            }, window.location.origin);
            window.close();
            return;
        }
        window.opener.postMessage({
            type: ID_TOKEN,
            id_token: id_token,
            access_token: access_token
        }, window.location.origin);
        window.close();
    }
}
export default new OAuthService();