import React, { useEffect, useRef } from "react";

//styled-components
import {
  Container as StyledContainer,
  FixedText as StyledFixedText,
  ChangingText as StyledChangingText,
  // ChangingText2 as StyledChangingText2,
} from "./styled-components.js";

function AnimatedText(props) {
  const textRef1 = useRef();
  const textRef2 = useRef();

  useEffect(() => {
    const elts = {
      text1: textRef1.current,
      text2: textRef2.current,
    };

    const texts = [
      "explain your revenue numbers by region",
      "help you understand why your revenues are declining",
      "give you the best performing outlets on margins",
      "diagnose which retailer is pulling the margins down",
      "explain if your discounts are impacting sales",
      "give you all the sales representatives who have achieved < 90% on targets",
      "forecast when you will end up this month based on your past performance",
      "compare the performance between west and east regions",
      "explain the difference between Gross Sales and Net Sales",
      "help you understand how Average Price is computed",
    ];

    const morphTime = 1;
    const cooldownTime = 3;

    let textIndex = texts.length - 1;
    let time = new Date();
    let morph = 0;
    let cooldown = cooldownTime;

    elts.text1.textContent = texts[textIndex % texts.length];
    elts.text2.textContent = texts[(textIndex + 1) % texts.length];

    function doMorph() {
      morph -= cooldown;
      cooldown = 0;

      let fraction = morph / morphTime;

      if (fraction > 1) {
        cooldown = cooldownTime;
        fraction = 1;
      }

      setMorph(fraction);
    }

    function setMorph(fraction) {
      elts.text2.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
      elts.text2.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

      fraction = 1 - fraction;
      elts.text1.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
      elts.text1.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

      elts.text1.textContent = texts[textIndex % texts.length];
      elts.text2.textContent = texts[(textIndex + 1) % texts.length];
    }

    function doCooldown() {
      morph = 0;

      elts.text2.style.filter = "";
      elts.text2.style.opacity = "100%";

      elts.text1.style.filter = "";
      elts.text1.style.opacity = "0%";
    }

    function animate() {
      requestAnimationFrame(animate);

      let newTime = new Date();
      let shouldIncrementIndex = cooldown > 0;
      let dt = (newTime - time) / 1000;
      time = newTime;

      cooldown -= dt;

      if (cooldown <= 0) {
        if (shouldIncrementIndex) {
          textIndex++;
        }

        doMorph();
      } else {
        doCooldown();
      }
    }

    animate();
  }, []);

  return (
    <React.Fragment>
      <StyledContainer>
        <StyledFixedText theme={props.theme}>{props.envInfo?.name|| "Crux "} can</StyledFixedText>
        <span ref={textRef1}></span>
        <span ref={textRef2}></span>
        <StyledChangingText theme={props.theme}>
          <span>{props.envInfo?.name|| "Crux "} can </span>
          <span ref={textRef1}></span>
        </StyledChangingText>
        <StyledChangingText theme={props.theme}>
          <span>{props.envInfo?.name || "Crux "}   can </span>
          <span ref={textRef2}></span>
        </StyledChangingText>
      </StyledContainer>
      <svg id="filters">
        <defs>
          <filter id="threshold">
            <feColorMatrix
              in="SourceGraphic"
              type="matrix"
              values="1 0 0 0 0
									0 1 0 0 0
									0 0 1 0 0
									0 0 0 255 -140"
            />
          </filter>
        </defs>
      </svg>
    </React.Fragment>
  );
}

export default AnimatedText;
