import styled from "styled-components";
import { getStyleString } from "../../../../../utils/styleUtils";

interface ContainerProps {
    gridColumn: number;
}

export const Container = styled.div<ContainerProps>`
    display: grid;
    grid-template-columns: repeat(${props => props.gridColumn}, 1fr);
    grid-gap: 16px;
`;

export const FieldLabel = styled.div`
    margin-bottom: 8px;
    ${props => getStyleString(props.theme["text_01_med"])};
    color: ${props => props.theme["text_02"].light};
`;
