import React, { useEffect, useRef } from "react";

// import "./LoginButtons.scss";

// styled components
import {
  Buttons as StyledButtons,
  Button as StyledButton,
  ButtonIcon as StyledButtonIcon,
  ButtonText as StyledButtonText,
} from "./styled-components";

//images
import microsoftLogo from "../../../../images/microsoft-logo.png";
import organizationIcon from "../../../../images/organization.png";

function LoginButtons(props) {
  const googleButton = useRef();

  useEffect(() => {
    if (googleButton?.current) {
      props.onGoogleButtonRender(googleButton.current);
    }
  }, []);

  function clickHandler(event) {
    let type = event.currentTarget.getAttribute("data-type");
    props.onButtonClick(type);
  };

  let loginOptions = props.loginOptions || [];

  return (
    <StyledButtons>
      {loginOptions.indexOf("GOOGLE") > -1 ? (
        <div ref={googleButton}></div>
      ) : null}
      {loginOptions.indexOf("MICROSOFT") > -1 ? (
        <StyledButton
          data-type="MICROSOFT"
          onClick={clickHandler}
        >
          <StyledButtonIcon className="login-buttons__button-icon">
            <img src={microsoftLogo} alt="" />
          </StyledButtonIcon>
          <StyledButtonText className="login-buttons__button-text">
            Continue with Microsoft
          </StyledButtonText>
        </StyledButton>
      ) : null}
      {loginOptions.indexOf("ORG_AUTH") > -1 ? (
        <StyledButton
          className="login-buttons__button"
          theme={this.props.theme}
          data-type="ORG"
          onClick={this.clickHandler}
        >
          <StyledButtonIcon className="login-buttons__button-icon">
            <img src={organizationIcon} alt="" />
          </StyledButtonIcon>
          <StyledButtonText className="login-buttons__button-text">
            Continue with Organization account
          </StyledButtonText>
        </StyledButton>
      ) : null}
    </StyledButtons>
  );
}

export default LoginButtons;
