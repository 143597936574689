import React, { useRef, useState, useEffect } from "react";
import {SVGIcon, Button, ButtonType, ActionMenu} from '@cuddle-dev/web-components';
import { useTheme } from 'styled-components';

// Styled components
import {
    Container as StyledContainer,
    Label as StyledLabel,
    Dropdown as StyledDropdown
} from "./styled-components.js";

//images
import { ReactComponent as ChevronDownIcon } from "../../../../images/chevron_down_20.svg";
import { ReactComponent as ChevronUpIcon } from "../../../../images/chevron_up_20.svg";

const SELECT_ALL = {
    value: "all",
    label: "All Transactions"
}

function TransactionSelector({transactionList, selectedTransaction, onChange, selectAll}) {
    const theme = useTheme();
    const [showDropdown, setShowDropdown] = useState(false);
    const containerRef = useRef();

    useEffect(() => {
        const handleDocumentClick = function (event) {
          if (
            containerRef &&
            containerRef.current &&
            !containerRef.current.contains(event.target)
          ) {
            if (showDropdown) {
                setShowDropdown(false);
            }
          }
        };
    
        document.addEventListener("click", handleDocumentClick, false);
    
        return function cleanup() {
          document.removeEventListener("click", handleDocumentClick, false);
        };
    });

    function changeHandler(data) {
        onChange(data.selected.data);
    }

    function containerClickHandler() {
        setShowDropdown(!showDropdown);
    }

    const options = selectAll ? [SELECT_ALL] : transactionList.map(transaction => {
        return {
            value: transaction.name,
            label: transaction.displayName,
            data: transaction
        }
    });

    const selected = selectAll ? SELECT_ALL : {
        value: selectedTransaction.name,
        label: selectedTransaction.displayName
    };

    const customStyle=`
        background-color: ${theme["ui_04"]};
        color: ${theme["text_03"].light};
        &:hover{
            background-color: ${theme["ui_04"]};
            color: ${theme["text_03"].light};
        }
        &:hover  path{
            fill: ${theme["icon_02"].light};
        }
        &:active{
            background-color: ${theme["ui_04"]};
            color: ${theme["text_03"].light};
        }
        &:active path{
            fill: ${theme["icon_02"].light};
        }
    `;

    return <StyledContainer ref={containerRef} onClick={selectAll ? null : containerClickHandler}>
        <StyledLabel showDropdown={showDropdown} disabled={selectAll}>
            <Button
                endEnhancer={<SVGIcon Icon={showDropdown && !selectAll ? ChevronUpIcon : ChevronDownIcon} 
                    width={20} 
                    height={20} 
                    fill={selectAll ? theme["icon_disabled"] : theme["icon_02"].light}
                />}
                buttonType={ButtonType.tertiary}
                text={selected.label}
                style={{ width: "100%" }}
                disabled={selectAll}
                customStyling={customStyle}
            />
        </StyledLabel>
        {showDropdown && !selectAll ? 
            <StyledDropdown>
                <ActionMenu
                    selected={selected}
                    optionSections={[
                    {
                        options,
                    },
                    ]}
                    clickHandler={changeHandler}
                />
            </StyledDropdown>
            : null
        }
    </StyledContainer>
}

export default TransactionSelector;