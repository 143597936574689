import styled from "styled-components";
import { getStyleString } from "../../../../../../utils/styleUtils";

interface DropdownProps {
  dropdownOffset: number;
}

export const Container = styled.div`
  position: relative;

  .button-content {
    justify-content: left !important;
  }
`;

export const Label = styled.div`
  cursor: pointer;

  .button-content {
    justify-content: left !important;
  }

  svg {
    position: absolute;
    right: 8px;
    top: 8px;
  }
`;

export const Dropdown = styled.div<DropdownProps>`
  position: absolute;
  top: calc(100% + 4px);
  left: 0px;
  width: 100%;
  z-index: 100;
  transform: translateY(${props => props.dropdownOffset}px);
`;
