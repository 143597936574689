//services
import httpRequestService from "./httpRequest.js";
// import { getCookie } from "./utilityFunctions.js";
import SessionService from "../services/session.service";

let auth2;

function init(element, onSuccess, onError, onLoginStart) {
  // if(!auth2) {
  //     window.gapi.load('auth2', function () {
  //         // Retrieve the singleton for the GoogleAuth library and set up the client.
  //         auth2 = window.gapi.auth2.init({
  //             client_id: '494399145338-vg955vb0svp2b1npkp0g7icu0j8se95b.apps.googleusercontent.com',
  //             cookiepolicy: 'single_host_origin',
  //             // Request scopes in addition to 'profile' and 'email'
  //             //scope: 'additional_scope'
  //         });

  //         attachSignin(element, onSuccess, onError);
  //     });
  // } else {
  //     attachSignin(element, onSuccess, onError);
  // }
  window.google.accounts.id.initialize({
    client_id:
      "494399145338-vg955vb0svp2b1npkp0g7icu0j8se95b.apps.googleusercontent.com",
    callback: googleSignInHandler.bind(this, onSuccess, onError, onLoginStart),
  });

  window.google.accounts.id.renderButton(
    element,
    {
      theme: "outline",
      size: "large",
      type: "standard",
      shape: "rectangular",
      text: "continue_with",
      logo_alignment: "left",
      width: "320px",
    } // customization attributes
  );
}

function attachSignin(element, onSuccess, onError) {
  console.log("[attachSignin] Element: ", element);
  auth2.attachClickHandler(
    element,
    {},
    function (googleUser) {
      var profile = googleUser.getBasicProfile();
      var id_token = googleUser.getAuthResponse().id_token;
      console.log("[attachSignin] profile: ", profile);
      console.log("[attachSignin] id_token: ", id_token);
      cuddleLogin(id_token, onSuccess, onError);
    },
    function (error) {
      console.log("[attachSignin] Error: ", error);
      onError(error);
    }
  );
}

function cuddleLogin(id_token, onSuccess, onError) {
  console.log("[cuddleLogin] id_token: ", id_token);
  httpRequestService.getAuthToken(
    `/login/token`,
    {
      grant_type: "password",
      authType: "GOOGLE",
      id_token,
    },
    (response) => {
      console.log("[cuddleLogin] response: ", response);
      onSuccess(response, "GOOGLE");
    },
    (err) => {
      console.log("[cuddleLogin] Error: ", err);
      onError(err);
    }
  );
}

function googleSignInHandler(onSuccess, onError, onLoginStart, data) {
  console.log("[googleSignInHandler] onSuccess: ", onSuccess);
  console.log("[googleSignInHandler] onError: ", onError);
  console.log("[googleSignInHandler] data: ", data);
  // let userID = getCookie("userID");
  const currentProject = SessionService.getItem("currentProject");

  if (data && data.credential) {
    if (!currentProject) {
      if (onLoginStart) {
        onLoginStart();
      }
      cuddleLogin(data.credential, onSuccess, onError);
    } else {
      console.log("[googleSignInHandler] already logged in.");
    }
  } else {
    console.log("[googleSignInHandler] no response or credential.");
    onError({});
  }
}

export default {
  init,
};
