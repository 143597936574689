import styled from "styled-components";
import { getStyleString } from "../../../../../utils/styleUtils";

export const Container = styled.div`
    display: flex;
    align-items: center;
`;

export const Text = styled.div`
    text-transform: uppercase;
    color: ${props => props.theme["text_03"].light};
    ${props => getStyleString(props.theme["eyebrow_text"])};
`;

export const InfoTooltip = styled.div`
    position: absolute;
    left: 50%;
    top: calc(100% + 4px);
    transform: translateX(-50%);
    display: none;
    width: max-content;
    z-index: 1;
`;

export const Info = styled.div`
    position: relative;
    height: 20px;
    margin-left: 8px;

    &:hover ${InfoTooltip} {
        display: block;
    }
`;
