import styled from "styled-components";
import { getStyleString } from "../../../../utils/styleUtils";

export const Buttons = styled.div`
    width: 100%;
    position: relative;

`;

export const Button = styled.div`
  ${({ theme }) =>
    `   padding: 12px 16px;
        box-sizing: border-box;
        border-radius: 4px;
        text-align: center;
        margin-top: 16px;
        cursor: pointer;
        ${getStyleString(theme["text_01_med"])};
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;

        // copying style of Google button    
        color: ${theme['text_02'].light};
        // background: ${theme['ui_03']};
        border: 1px solid #dadce0;
        color: #3c4043;
        font-size: 14px;

        &:hover {
            box-sizing: border-box;

            // copying style of Google button    
            background: rgba(66,133,244,.04);
            border: 1px solid #d2e3fc;
        }

        &:first-child {
            margin-top: 0px;
        }
    `}
`;

export const ButtonIcon = styled.div`
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 16px;
    img {
        width: 20px;
        height: 20px;
    }
`;

export const ButtonText = styled.div`
    display: inline-block;
    vertical-align: middle;
    margin-left: 16px;

`;
