import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    details: undefined,
    persona: { value: "CONSULTANT", label: "Consultant" },
  },
  reducers: {
    add_details: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.details = action.payload;
    },
    change_persona: (state, action) => {
      state.persona = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { add_details, change_persona } = userSlice.actions;

export default userSlice.reducer;
