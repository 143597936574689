import React from 'react';
import PropTypes from 'prop-types';

// import component dependency
import "./AppLoader.css";

// import Loader from '../Loader';
// import Loader from "@cuddle-dev/test-common-web-utils/dist/loader";

const AppLoader = (props) => {
    if(props.show) {
        return ;
    }
   return (
       <div className="app-loader">
            <div className="app-loader__content">
                <div className="app-loader__message">{ props.message }</div>
                {/* <Loader /> */}  
            </div>
       </div>
   )
}

export default AppLoader;

AppLoader.propTypes = {
    message: PropTypes.string,
    show: PropTypes.bool
};