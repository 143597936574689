import styled from "styled-components";
import { getStyleString } from "../../../utils/styleUtils";

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 320px;

    @media screen and (min-width: 1024px) {
        justify-content: center;
    }
`;

export const LoginText = styled.div`
  ${({ theme }) =>
    `
            margin-bottom: 24px;
            ${getStyleString(theme["text_large_01_semi"])};
            color: ${theme["text_01"].light};

            @media screen and (max-width: 1024px) {
                text-align: center;
            }
        `}
`;

export const Retry = styled.div`
    color: #304FFE;
    cursor: pointer;
`;

export const ErrorContainer  = styled.div`
    margin-bottom: 16px;
`;