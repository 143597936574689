import styled from "styled-components";
// import { getStyleString } from "@cuddle-dev/test-web-ui-lib/dist/utils/styleUtils";

import { getStyleString } from "../../utils/styleUtils";


export const Icon = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  padding: 8px 0px;
  text-align: center;
  background: ${(props) => props.theme["data08"]};
  color: ${(props) => props.theme["text_05"].light};
  box-sizing: border-box;
  ${(props) => getStyleString(props.theme["text_02_med"])};
  ${props => props.size === "SMALL" ? `
    font-size: 12px;
    width: 32px;
    height: 32px;
    padding: 6px 0px;
  ` : ""}
`;
