import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

// import SearchBox from "@cuddle-dev/test-web-ui-lib/dist/search-box";
import TransactionSelector from "./transaction-selector/TransactionSelector.js";
import EntityLists from "./entity-lists/EntityLists.js";
// import analyticsModule from "@cuddle-dev/test-common-web-utils/dist/utils/analytics";
import {SVGIcon, SearchBox} from '@cuddle-dev/web-components';
import { useTheme } from 'styled-components';
import analyticsModule from "../../../utils/analytics.js";

// Styled components
import {
    Container as StyledContainer,
    Header as StyledHeader,
    Content as StyledContent,
    Filters as StyledFilters,
    HeaderTitle as StyledHeaderTitle,
    Cross as StyledCross,
    HeaderSubTitle as StyledHeaderSubTitle,
    HeaderDescription as StyledHeaderDescription,
    Lists as StyledLists
} from "./styled-components.js";

// services
import DataDiscoveryService from "../../../services/dataDiscovery.service";

//images
import { ReactComponent as CloseIcon } from "../../../images/close_20.svg";

const isMac = window.navigator.userAgent.indexOf("Mac OS") !== -1;

const fetchAttributeList = (transaction, userID) => {
    return new Promise((resolve, reject) => {
        DataDiscoveryService.fetchAttributesList({
            transactionName: transaction.name,
            userID
        })
        .promise
        .then((response) => {
            resolve(response.data);
        })
        .catch(error => {
            reject(error);
        });
    });
}

const fetchMeasureList = (transaction, userID) => {
    return new Promise((resolve, reject) => {
        DataDiscoveryService.fetchMeasuresList({
            transactionName: transaction.name,
            userID
        })
        .promise
        .then((response) => {
            resolve(response.data);
        })
        .catch(error => {
            reject(error);
        });
    });
}

function AvailableData({onClose, transactionData}) {
    const theme = useTheme();
    const headerRef = useRef();
    const filtersRef = useRef();
    const transactionList = transactionData; // && transactionData.slice(0, 3);
    const [searchText, setSearchText] = useState("");
    const [selectedTransaction, setSelectedTransaction] = useState(transactionList && transactionList.length ? transactionList[0] : "");
    const [attributeList, setAttributeList] = useState(undefined);
    const [measureList, setMeasureList] = useState(undefined);
    const [filteredData, setFilteredData] = useState(null);
    const [headerHeight, setHeaderHeight] = useState();
    const [filtersHeight, setFiltersHeight] = useState();
    const userID = useSelector((state) => state.user.details.userID);

    useEffect(() => {
        if(transactionList && transactionList.length) {
            fetchAttributesAndMeasures(transactionList[0]);   
        }
    }, []);

    useEffect(() => {
        const newHeaderHeight = (headerRef && headerRef.current && headerRef.current.offsetHeight) || 0;
        const newFiltersHeight = (filtersRef && filtersRef.current && filtersRef.current.offsetHeight) || 0;

        if(newHeaderHeight !== headerHeight) {
            setHeaderHeight(newHeaderHeight);
        }

        if(newFiltersHeight !== filtersHeight) {
            setFiltersHeight(newFiltersHeight);
        }
    });

    function fetchAttributesAndMeasures(transaction) {
        let promiseList = [fetchAttributeList(transaction, userID), fetchMeasureList(transaction, userID)];

        Promise.all(promiseList)
            .then(data => {
                console.log(data, "data");
                setAttributeList(data[0] || []);
                setMeasureList(data[1] || []);
            })
            .catch(error => {
                console.log(error, "error data");
            })
    }

    function searchChangeHandler(event) {
        setSearchText(event.target.value);
    }

    function searchKeyDownHandler(event) {
        const searchTerm = event.target.value;
        
        if(event.key === 'Enter' && searchTerm) {
            setSelectedTransaction(null);
            setAttributeList(null);
            setMeasureList(null);

            DataDiscoveryService.searchKnowledge({
                searchTerm,
                userID
            })
            .promise
            .then((response) => {
                let data = response.data;
                setFilteredData(data);
                let attributeList = [];
                let measureList = [];

                if(data && Object.keys(data)) {
                    Object.keys(data).forEach(item => {
                        if(data[item].attributeList) {
                            attributeList = attributeList.concat(data[item].attributeList);
                        }

                        if(data[item].measureList) {
                            measureList = measureList.concat(data[item].measureList);
                        }
                    });
                }

                setAttributeList(attributeList || []);
                setMeasureList(measureList || []);

                // console.log(attributeList, measureList, "searchKnowledge")
            })
            .catch((error) => {
            });

            analyticsModule.trackEvent("Available Data", {
                "Actions": "Search"
            });
        } else if(event.key === 'Enter' && !searchTerm) {
            clearSearch();
        }
    }

    function clearSearch() {
        setSearchText("");
        setSelectedTransaction(transactionList && transactionList.length ? transactionList[0] : "");
        setFilteredData(null);
    }

    function transactionChangeHandler(data) {
        setSelectedTransaction(data);
        setAttributeList(undefined);
        setMeasureList(undefined);
        
        // if(!searchText) {
            let promiseList = [fetchAttributeList(data, userID), fetchMeasureList(data, userID)];

            Promise.all(promiseList)
                .then(data => {
                    // console.log(data, "data");
                    setAttributeList(data[0] || []);
                    setMeasureList(data[1] || []);
                })
                .catch(error => {
                    console.log(error, "error data");
                })
        // } else {
        //     setAttributeList(filteredData[item.name] && filteredData[item.name].attributeList ? filteredData[item.name].attributeList : []);
        //     setMeasureList(filteredData[item.name]  && filteredData[item.name].measureList  ? filteredData[item.name].measureList : []);
        // }
    }

    // console.log("transactionList: ", transactionList);
    // console.log("selectedTransaction: ", selectedTransaction);

    const fixedHeight = filtersHeight + 20;
 
    return <StyledContainer>
        <StyledHeader ref={headerRef}>
            <StyledHeaderTitle>
                <span>Available data</span>
                <StyledCross onClick={onClose}>
                    <SVGIcon Icon={CloseIcon} width={20} height={20} fill={theme["icon_01"].light}/>
                </StyledCross>
            </StyledHeaderTitle>
            <StyledHeaderSubTitle>
                <span>{/*isMac ? "cmd" :*/ "ctrl"}</span>
                <span>Shift</span>
                <span>D</span>
                <span>to toggle this panel</span>
            </StyledHeaderSubTitle>
            <StyledHeaderDescription>All the measures and attributes you have permission to within the transactions in this project</StyledHeaderDescription>
        </StyledHeader>
        <StyledContent headerHeight={headerHeight}>
            <StyledFilters ref={filtersRef}>
                <SearchBox value={searchText} 
                    onKeyDown={searchKeyDownHandler} 
                    onChange={searchChangeHandler} 
                    clearSearch={clearSearch}
                />
                {selectedTransaction || !!filteredData ? <TransactionSelector 
                    transactionList={transactionList} 
                    selectedTransaction={selectedTransaction} 
                    onChange={transactionChangeHandler}
                    selectAll={!!filteredData}
                /> : null}
            </StyledFilters>
            {(selectedTransaction && attributeList && measureList) || filteredData ? 
                <StyledLists fixedHeight={fixedHeight}>
                    <EntityLists
                        transactionList={transactionList} 
                        selectedTransaction={selectedTransaction} 
                        selectedEntities={{
                            attributeList,
                            measureList
                        }}
                        filteredData={filteredData}
                    />
                </StyledLists>
                : null
            }
        </StyledContent>
    </StyledContainer>
}

export default AvailableData;