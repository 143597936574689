import { DONE } from "./readyState";
import { OK } from "./status";
import {VISUALISATION_LIB_TYPES} from "../../constants";

const widgetURL = "/widget/";
const copilotVizURL = "/copilot-viz/";
const copilotWidgetsURL = "/copilot-widgets/";
let widgets = {
  [VISUALISATION_LIB_TYPES.copilotViz]: {},
  [VISUALISATION_LIB_TYPES.copilotWidgets]: {}
};

function loadCSSFile(filename) {
  const fileref = document.createElement("link");

  fileref.setAttribute("rel", "stylesheet");
  fileref.setAttribute("type", "text/css");
  fileref.setAttribute("href", filename);

  document.getElementsByTagName("head")[0].appendChild(fileref);

  return fileref;
}

function loadWidgetCSS(CSSPath, widgetType, vizLibType) {
  return new Promise(function (resolve, reject) {
    let CSSFiles;

    if (window.CSSFiles) {
      CSSFiles = window.CSSFiles;
    } else {
      CSSFiles = window.CSSFiles = {
        [VISUALISATION_LIB_TYPES.copilotViz]: {},
        [VISUALISATION_LIB_TYPES.copilotWidgets]: {}
      };
    }

    if (!CSSFiles[vizLibType][widgetType]) {
      let CSSFile = loadCSSFile(CSSPath);
      CSSFiles[vizLibType][widgetType] = CSSFile;
      // return CSSFile;
      CSSFile.onload = function () {
        resolve();
      };
    } else {
      resolve();
    }
  });
}

function vizToWidgetType(vizType) {
  if (typeof vizType === "string") {
    vizType = vizType.toLowerCase();
  }

  switch (vizType) {
    case "trend":
    case "line":
    case "line_chart":
    case "timeseries":
    case "outlier":
    case "trend_outlier":
      return "trend";
    case "bar":
    case "bar_chart":
    case "column_chart":
      return "bar";
    case "column":
      return "column";
    case "number":
    case "singlevalue":
      return "value";
    case "list":
      return "list";
    case "table":
      return "table";
    case "targeted_against_bar":
      return "achievement-bar";
    case "targeted_against_trend":
      return "targeted-against-trend";
    case "growth":
      return "growth";
    case "drivers":
    case "attribute_drivers":
      return "driver";
    case "multi_measure_trend":
      return "multi-measure-trend";
    case "driver_table":
      return "driver-table";
    case "achievement_ring":
      return "achievement-ring";
    case "waterfall":
      return "waterfall";
    case "scatter":
      return "scatter";
    default:
      return vizType === "string" ? vizType : undefined;
  }
}

const defaultRequires = (name) => {
  throw new Error(
    `Could not require '${name}'. The 'requires' function was not provided.`
  );
};

const xmlHttpRequestGET = (url) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== DONE) return;
      xhr.status === OK
        ? resolve(xhr.responseText)
        : reject(`${xhr.status} ${xhr.statusText}`);
    };
    xhr.open("GET", url, true);
    xhr.send();
  });
};

function fetchWidget(widgetType, widgetLink, dependencies, vizLibType) {
  let requires;
  if (dependencies) {
    requires = createRequires(dependencies);
  }

  return new Promise(function (resolve, reject) {
    const _requires = requires || defaultRequires;

    xmlHttpRequestGET(widgetLink)
      .then(function (response) {
        const exports = {};
        const module = { exports };
        const func = new Function("require", "module", "exports", response);
        func(_requires, module, exports);

        widgets[vizLibType][widgetType] = module.exports;

        resolve(module.exports.default);
      })
      .catch(function (error) {
        console.log("[GET] error: ", error);
        reject(error);
      });
  });
}

const createRequires = (dependencies) => (name) => {
  const _dependencies = dependencies || {};
  if (!(name in _dependencies)) {
    throw new Error(
      `Could not require '${name}'. '${name}' does not exist in dependencies.`
    );
  }
  return _dependencies[name];
};

function fetchJS(jsLink, dependencies) {
  let requires;
  if (dependencies) {
    requires = createRequires(dependencies);
  }

  return new Promise(function (resolve, reject) {
    const _requires = requires || defaultRequires;

    xmlHttpRequestGET(jsLink)
      .then(function (response) {
        const exports = {};
        const module = { exports };
        const func = new Function("require", "module", "exports", response);
        func(_requires, module, exports);
        resolve(module.exports.default);
      })
      .catch(function (error) {
        console.log("[GET] error: ", error);
        reject(error);
      });
  });
}

function getWidget(vizType, urlOrigin, dependencies, vizLibType) {
  const widgetType = vizToWidgetType(vizType);
  const cssLink = `${
    urlOrigin || ""
  }${vizLibType === VISUALISATION_LIB_TYPES.copilotViz ? copilotVizURL : widgetURL}${widgetType}/static/css/main.css`;
  const widgetLink = `${
    urlOrigin || ""
  }${vizLibType === VISUALISATION_LIB_TYPES.copilotViz ? copilotVizURL : widgetURL}${widgetType}/static/js/main.js`;
  
  if (widgets[vizLibType || VISUALISATION_LIB_TYPES.copilotWidgets][widgetType]) {
    return new Promise(function (resolve, reject) {
      resolve(widgets[vizLibType || VISUALISATION_LIB_TYPES.copilotWidgets][widgetType].default);
    });
  } else {
    return new Promise(function (resolve, reject) {
      loadWidgetCSS(cssLink, widgetType, vizLibType || VISUALISATION_LIB_TYPES.copilotWidgets)
        .then(() => {
          fetchWidget(widgetType, widgetLink, dependencies, vizLibType || VISUALISATION_LIB_TYPES.copilotWidgets)
            .then((data) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
    // return fetchWidget(widgetType, widgetLink, dependencies);
  }
}

function loadOndemandComponents(urls, dependencies) {
  if (urls.cssURLOrigin) {
    loadCSSFile(urls.cssURLOrigin);
  }

  if (urls.jsURLOrigin) {
    return fetchJS(urls.jsURLOrigin, dependencies);
  }
}

export { getWidget, loadOndemandComponents };
