import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

//services
import chatSessionService from "../services/chat-session.service.js";
// import analyticsModule from "@cuddle-dev/test-common-web-utils/dist/utils/analytics.js";

export const TEMP_SESSION_NAME = "TEMP_SESSION";

// thunk
export const createNewSession = createAsyncThunk<
  any,
  Function,
  {
    state: any
  }
>(
  "session/createNewSession",
  async (completionCallback, thunkAPI) => {
    const { user, chat } = thunkAPI.getState();
    
    try {
      const response = await new Promise((resolve, reject) => {
        chatSessionService.createConversation(
          {
            userId: user.details.userID,
            description: "",
            name: chat.sessions[TEMP_SESSION_NAME].name
          },
          (data) => {
            console.log("[createConversation] data: ", data);
            completionCallback(true, data);
            resolve(data);
          },
          (error) => {
            console.log("[createConversation] error: ", error);
            completionCallback(false, error);
            reject(error);
          }
        );
      });

      return response;
    } catch (error) {
      completionCallback(false, error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const chatSessionSlice = createSlice({
  name: "chatSession",
  initialState: {
    activeSession: undefined,
    creatingNewSession: false,
    sessionGroups: {},
    sessions: {},
    dates: [],
  },
  reducers: {
    setActiveSession: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const conversationId = action.payload;
      const { sessions, sessionGroups, dates, activeSession } = state;
      
      if(activeSession === TEMP_SESSION_NAME) {
        const session = sessions[TEMP_SESSION_NAME];
        const date = new Date(session.created).setHours(0, 0, 0, 0);

        if (sessionGroups[date].length === 1) {
          delete sessionGroups[date];
          dates.splice(dates.indexOf(date), 1);
        } else {
          sessionGroups[date].splice(
            sessionGroups[date].indexOf(TEMP_SESSION_NAME),
            1
          );
        }
        delete sessions[TEMP_SESSION_NAME];

        state.sessionGroups = sessionGroups;
        state.sessions = sessions;
        state.dates = dates;
      }
      
      state.activeSession = conversationId;
    },
    setSessions: (state, action) => {
      const list = action.payload;
      const sessionGroups = {};
      const sessionMap = {};
      const dates = [];

      list.sort((a, b) => b.created - a.created);

      list.forEach((item) => {
        sessionMap[item.conversationId] = item;
        const updatedAt = new Date(item.created);
        const dateStamp = updatedAt.setHours(0, 0, 0, 0);
        if (sessionGroups[dateStamp]) {
          sessionGroups[dateStamp].push(item.conversationId);
        } else {
          sessionGroups[dateStamp] = [item.conversationId];
          dates.push(dateStamp);
        }
      });

      state.sessionGroups = sessionGroups;
      state.dates = dates;
      state.sessions = sessionMap;
      state.activeSession = sessionGroups[dates[0]][0];
      console.log(state, "state componentDidUpdate");
    },
    updateSession: (state, action) => {
      const { oldSession, newSession } = action.payload;
      const { sessions, sessionGroups, dates } = state;
      const oldDate = new Date(oldSession.created).setHours(0, 0, 0, 0);
      const newDate = new Date(newSession.created).setHours(0, 0, 0, 0);

      sessions[newSession.conversationId] = newSession;

      if (oldDate === newDate) {
        sessionGroups[newDate].sort(
          (a, b) => sessions[b].created - sessions[a].created
        );
      } else {
        const oldGroup = sessionGroups[oldDate];
        if (oldGroup.length === 1) {
          delete sessionGroups[oldDate];
          dates.splice(dates.indexOf(oldDate), 1);
        } else {
          sessionGroups[oldDate].splice(
            oldGroup.indexOf(oldSession.conversationId),
            1
          );
        }
        if (dates.indexOf(newDate) > -1) {
          sessionGroups[newDate].splice(0, 0, newSession.conversationId);
        } else {
          dates.splice(0, 0, newDate);
          sessionGroups[newDate] = [newSession.conversationId];
        }
      }
      state.sessionGroups = sessionGroups;
      state.sessions = sessions;
      state.dates = dates;
    },
    delete_session: (state, action) => {
      const session = action.payload;
      const { sessions, sessionGroups, dates } = state;
      const date = new Date(session.created).setHours(0, 0, 0, 0);

      if (sessionGroups[date].length === 1) {
        delete sessionGroups[date];
        dates.splice(dates.indexOf(date), 1);
      } else {
        sessionGroups[date].splice(
          sessionGroups[date].indexOf(session.conversationId),
          1
        );
      }
      delete sessions[session.conversationId];

      state.sessionGroups = sessionGroups;
      state.sessions = sessions;
      state.dates = dates;
      if (dates.length) {
        state.activeSession = sessionGroups[dates[0]][0];
      } else {
        state.activeSession = undefined;
      }
    },
    add_temp_session: (state, action) => {
      const currentTime = new Date().getTime();
      const session = {
        "name": "New Conversation",
        "description": "",
        "conversationId": TEMP_SESSION_NAME,
        "created": currentTime,
        "updated": currentTime
      }; //action.payload;
      const updatedAt = new Date(session.created);
      const dateStamp = updatedAt.setHours(0, 0, 0, 0);

      state.sessions[session.conversationId] = session;
      state.activeSession = session.conversationId;
      if (state.dates && state.dates.indexOf(dateStamp) > -1) {
        if(state.sessionGroups[dateStamp].indexOf(session.conversationId) === -1) {
          state.sessionGroups[dateStamp].splice(0, 0, session.conversationId);
        }
      } else {
        if (!state.dates) {
          state.dates = [];
        }
        state.dates.splice(0, 0, dateStamp);
        state.sessionGroups[dateStamp] = [session.conversationId];
      }
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(createNewSession.pending, (state, action) => {
      state.creatingNewSession = true;
    });
    builder.addCase(createNewSession.fulfilled, (state, action) => {
      console.log("action: ", action);
      state.creatingNewSession = false;
      const session = action.payload;
      const updatedAt = new Date(session.created);
      const dateStamp = updatedAt.setHours(0, 0, 0, 0);

      const tempSession = state.sessions[TEMP_SESSION_NAME];
      if(tempSession) {
        const tempDate = new Date(tempSession.created).setHours(0, 0, 0, 0);

        if (state.sessionGroups[tempDate].length === 1) {
          delete state.sessionGroups[tempDate];
          state.dates.splice(state.dates.indexOf(tempDate), 1);
        } else {
          state.sessionGroups[tempDate].splice(
            state.sessionGroups[tempDate].indexOf(TEMP_SESSION_NAME),
            1
          );
        }
        delete state.sessions[TEMP_SESSION_NAME];
      }

      state.sessions[session.conversationId] = session;
      state.activeSession = session.conversationId;
      if (state.dates && state.dates.indexOf(dateStamp) > -1) {
        state.sessionGroups[dateStamp].splice(0, 0, session.conversationId);
      } else {
        if (!state.dates) {
          state.dates = [];
        }
        state.dates.splice(0, 0, dateStamp);
        state.sessionGroups[dateStamp] = [session.conversationId];
      }
    });
    builder.addCase(createNewSession.rejected, (state, action) => {
      state.creatingNewSession = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const { 
  setActiveSession, 
  setSessions, 
  updateSession, 
  delete_session, 
  add_temp_session,
} = chatSessionSlice.actions;

export const deleteSession = (data) => async (dispatch, getState) => {
  const { user } = getState();

  if(data.conversationData.conversationId === TEMP_SESSION_NAME) {
    dispatch(delete_session(data.conversationData));

    const { chat } = getState();
  
    if (!chat.dates.length) {
      dispatch(add_temp_session({}));
    }
  } else {
    chatSessionService.deleteConversation(
      {
        conversation_id: data.conversationData.conversationId,
        "user_id": user.details.userID
      }
    ).promise.then((response) => {
      const responseData = response.data;
      console.log("[deleteChatSession] data: ", responseData);
      dispatch(delete_session(responseData));

      const { chat } = getState();

      if (!chat.dates.length) {
        dispatch(add_temp_session({}));
      }
      data.deleteComplete();
      // analyticsModule.trackEvent("Conversation Deleted", {
      //   "Conversation ID": data.chatSessionId,
      //   "Status": "Successful"
      // });

    })
    .catch((error) => {
      console.log("[deleteChatSession] error: ", error);
      data.deleteFailed();

      // analyticsModule.trackEvent("Conversation Deleted", {
      //   "Conversation ID": data.chatSessionId,
      //   "Status": "Unsuccessful"
      // });
    });
  }
};

export default chatSessionSlice.reducer;
