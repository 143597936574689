import styled from "styled-components";
// import { getStyleString } from "@cuddle-dev/web-components/dist/utils/styleUtils";
import { getStyleString } from "../../../../utils/styleUtils";

export const SelectorLabel = styled.div`
  align-items: center;
  display: flex;
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
`;

export const SelectorLabelText = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  ${(props) => getStyleString(props.theme["text_01_med"])};
  color: ${(props) =>  props.theme["text_02"][props.theme.backgroundMeta?.interface_background?.colorBrightness || "light"]};
`;

export const SelectorLabelIcon = styled.div`
  display: inline-block;
  vertical-align: middle;
  height: 20px;
  margin-left: 8px;

  svg path {
    fill: ${(props) =>  props.theme["icon_02"][props.theme.backgroundMeta?.interface_background?.colorBrightness || "light"]};
  }
`;

export const DropdownRoot = styled.div`
    width: max-content;
    margin-top: 4px;
    position: absolute;
    box-sizing: border-box;
`;

