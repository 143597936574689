import httpRequestService from '../utils/httpRequest.js';
import {
    generateQueryString,
    getNodeServerURL
} from '../utils/utilityFunctions.js';

class DocumentService {
    constructor() {
        this.BASE_URL = getNodeServerURL();
        this.DOC_URL = `${this.BASE_URL}doc`
    }

    getAnswerDoc = (queryParams, data, headers) => {
        let url = `${this.DOC_URL}/answer/${data.insightID}`;
        // let headers = headers || {};
        if(headers) {
            headers["x-cuddle-userid"] = headers.userId
        }
        
        if(queryParams) {
            url = generateQueryString(url, queryParams);
        }
        return httpRequestService.get(url, headers);
    }

    getAnswerDocV3 = (queryParams, data, headers) => {
        let url = `${this.DOC_URL}/answer/v3/${data.insightID}`;
        // let headers = headers || {};
        if(headers) {
            headers["x-cuddle-userid"] = headers.userId
        }
        
        if(queryParams) {
            url = generateQueryString(url, queryParams);
        }
        return httpRequestService.get(url, headers);
    }

    getOrgDoc = (queryParams, data, headers) => {
        let url = `${this.DOC_URL}/organisation/${data.orgId}`;
        if(queryParams) {
            url = generateQueryString(url, queryParams);
        }
        return httpRequestService.get(url, headers);
    }
}

export default new DocumentService;