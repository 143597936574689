// import React from "react";
// import { createStore, combineReducers } from "redux";
// import { AppReducer } from "../reducers/App.reducer";
// import { LoaderReducer } from "../reducers/loader.reducer";
// import { ProjectReducer } from "../reducers/Project.reducer";
// import { CurrProjReducer } from "../reducers/CurrProj.reducer";
// import sessionStorage from "redux-persist/lib/storage/session";
// import { persistStore, persistReducer } from "redux-persist";
// import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

// const persistConfig = {
//   key: "root",
//   storage: sessionStorage,
//   whitelist: ["userProjects", "currProject"],
//   // which reducer want to store
// };

// const rootReducer = combineReducers({
//   AppReducer,
//   LoaderReducer,
//   userProjects: ProjectReducer,
//   currProject: CurrProjReducer,
// });
// const pReducer = persistReducer(persistConfig, rootReducer);

// export const store = createStore(
//   pReducer,
//   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
// );

// export const persistor = persistStore(store);

import { configureStore, combineReducers } from "@reduxjs/toolkit";
import appReducer from "../reducers/App.reducer.js";
import loaderReducer from "../reducers/loader-slice";
import ProjectReducer from "../reducers/Project.reducer";
import CurrProjReducer from "../reducers/CurrProj.reducer";
import userReducer from "../reducers/user-slice.js";
import chatSessionReducer from "../reducers/chat-session-slice";
import settingsReducer from "../reducers/settings-slice";
import sessionStorage from "redux-persist/lib/storage/session";
import { 
  persistStore, 
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
// import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  storage: sessionStorage,
  whitelist: ["userProjects", "currProject"],
  // which reducer want to store
};

const applicationReducer = combineReducers({
  app: appReducer,
  loader: loaderReducer,
  user: userReducer,
  chat: chatSessionReducer,
  userProjects: ProjectReducer,
  currProject: CurrProjReducer,
  settings: settingsReducer
});

// reset redux state on user logout and delete persisted session data
const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    sessionStorage.removeItem('persist:root');
    return applicationReducer(undefined, action)
  }

  return applicationReducer(state, action)
}

// const rootReducer = combineReducers({
//   app: appReducer,
//   loader: loaderReducer,
//   user: userReducer,
//   chat: chatSessionReducer,
//   userProjects: ProjectReducer,
//   currProject: CurrProjReducer,
// });

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
