import styled from "styled-components";

interface ContainerProps {
    fixLeftPanel?: boolean;
    showAvailableData?: boolean;
    conversationWidth: number;
}

interface LeftPanelProps {
    fixLeftPanel?: boolean;
}

interface CollapsiblePanelProps {
    fixLeftPanel?: boolean;
    showLeftPanel?: boolean;
}

interface UtilityBarProps {
    isDesktop?: boolean;
    colorBrightness?: string;
}

export const Container = styled.div<ContainerProps>`
    display: grid;
    grid-template-rows: 96px calc(100vh - 96px);
    height: 100%;
    background: ${props => props.theme["interface_background"]};
    
    @media (min-width: 1024px) { 
        grid-template-rows: 100vh;
        grid-template-columns: ${props => props.fixLeftPanel ? "324" : "60"}px ${props => props.conversationWidth}px;
    }
`;

export const UtilityBar = styled.div<UtilityBarProps>`
    position: relative;
    z-index: 13;
    height: 52px;
    border-bottom: 1px solid ${props => props.theme["left_pane_border"].light};
    display: flex;
    padding: 10px 12px;
    box-sizing: border-box;

    @media (min-width: 1024px) {
        height: auto;
        border-bottom: none;
        box-sizing: border-box;
        border-right: 1px solid ${props => props.theme["left_pane_border"].light};
        background: ${props => props.theme["interface_background"]};
        padding: 12px 10px 24px 10px;
        flex-direction: column;
    }

    .display-bottom {
        margin-top: auto;
    }

    .display-right {
        margin-left: auto;
    }
`;

export const LeftPanel = styled.div<LeftPanelProps>`
    height: 100%;
    
    border-right: 1px solid ${props => props.theme["left_pane_border"][props.theme.backgroundMeta?.interface_background?.colorBrightness || "light"]};
    background: ${props => props.theme["interface_background"]};

    @media (min-width: 1024px) { 
        display: grid;
        grid-template-rows: 100%;
        grid-template-columns: ${props => props.fixLeftPanel ? "60px auto" : "60px"};
        background: none;
    }
`;

export const RightPanel = styled.div`
    
`;

export const SecondaryHeader = styled.div`
    @media (min-width: 1024px) {
        display: none;        
    }
`;

export const CollapsiblePanel = styled.div<CollapsiblePanelProps>`
    width: 300px;
    height: calc(100vh - 52px);
    position: absolute;
    z-index: 12;
    top: 52px;
    left: 0px;
    left: ${props => props.showLeftPanel ? "0" : "-100"}%;
    transition: left 0.5s;
    background: ${props => props.theme["interface_background"]};

    @media (min-width: 768px) and (max-width: 1024px) {
        width: 564px;
    }

    @media (min-width: 1024px) {
        width: 263px;
        height: 100%;
        top: 0px;
        left: 0px;

        ${props => props.fixLeftPanel ? `
            position: relative;
        ` : `
            position: absolute;
            left: calc(60px + ${props.showLeftPanel ? "0" : "-100"}%);
            transition: left 0.5s;
            background: ${props.theme["interface_background"]};
        `
        }
    }
`;

export const PanelBack = styled.div`
    background: ${props => props.theme["ui_overlay"]};
    position: absolute;
    width: 100%;
    z-index: 11;
    height: calc(100vh - 52px);

    @media (min-width: 1024px) {
        display: none;
    }
`;

export const RightDrawer = styled.div`
    width: 440px;
    height: calc(100% - 16px);
    position: fixed;
    top: 8px;
    right: 0px;
    z-index: 999;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 608px 170px 0px rgba(0, 0, 0, 0.00), 0px 389px 156px 0px rgba(0, 0, 0, 0.01), 0px 219px 131px 0px rgba(0, 0, 0, 0.05), 0px 97px 97px 0px rgba(0, 0, 0, 0.09), 0px 24px 53px 0px rgba(0, 0, 0, 0.10);
`;

export const HelpTooltip = styled.div`
    position: absolute;
    right: calc(100% + 8px);
    top: 50%;
    transform: translateY(-50%);
    display: none;
    width: max-content;
    z-index: 1;
`;

export const HelpIcon = styled.div`
    border-radius: 8px;
    background: ${props => props.theme["ui_01"]};
    box-shadow: ${props => props.theme["boxShadow"].shadow_high};
    padding: 6px;
    position: fixed;
    right: 20px;
    bottom: 112px;
    cursor: pointer;
    z-index: 900;

    &:hover ${HelpTooltip} {
        display: block;
    }
`;