import React, { useState } from "react";

// import "./PrivacyPolicy.scss";
// import Modal from "../../../../components/Modal.js";
import { Portal } from "@cuddle-dev/web-components";
import Loader from "../../../../components/loader/Loader.js";

// styled components
import {
    Background as StyledBackground,
    Content as StyledContent,
    Header as StyledHeader,
    Body as StyledBody,
    Footer as StyledFooter,
    Accept as StyledAccept,
    Reject as StyledReject,
} from "./styled-components";

//images
import crossIcon from "../../../../images/admin-left-cross@2x.png";

//services
import OrganizationUserService from "../../../../services/organisation.users.service";

function PrivacyPolicy(props) {
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    function setPrivacyPolicyFlag() {
        setIsLoading(true);
        
        OrganizationUserService.acceptPrivacyPolicy(
            { userID: props.userID }, 
            undefined, 
            (success) => {
                setIsLoading(false);
                setIsError(false);
                
                props.onAccept();
            },
            (error) => {
                console.log("[setPrivacyPolicyFlag] error: ", error);
                setIsLoading(false);
                setIsError(true);
            }
        );
    }

    return (
        <Portal parentId="modal-root">
            <StyledBackground>
                <StyledContent>
                    <StyledHeader>
                        <span className="privacy-policy__title">{isError ? "An error occured" : "Privacy Policy"}</span>
                        <img onClick={isLoading ? null : props.onCancel} src={crossIcon} alt="" />
                    </StyledHeader>
                    <StyledBody>
                        {isError ?
                            "Couldn’t agree to privacy policy and terms of use. Please try again."
                            : <span>To continue, please agree to the <a href="https://cruxintelligence.com/privacy-policy/" target="_blank">privacy policy</a> and <a href="https://cruxintelligence.com/terms-of-use/" target="_blank">terms of use</a>.</span>
                        }
                    </StyledBody>
                    <StyledFooter>
                        <StyledAccept className="privacy-policy__accept" onClick={isLoading ? null : setPrivacyPolicyFlag}>
                            {isLoading ? 
                                <Loader type="circularMonoColor" />
                                : (isError ? "Try again" : "Agree")
                            }
                        </StyledAccept>
                        <StyledReject onClick={isLoading ? null : props.onCancel}>
                            {isError ? "Cancel" : "Disagree"}
                        </StyledReject>
                        <div className="clearfix"></div>
                    </StyledFooter>
                </StyledContent>
            </StyledBackground>
        </Portal>
    );
}

export default PrivacyPolicy;