import React from "react";
import ReactDOM from 'react-dom/client';
import "./index.css";
import App from "./App";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import analyticsModule from "./utils/analytics";
import { Provider } from "react-redux";
import { persistor, store } from "./stores/index";
import { PersistGate } from "redux-persist/integration/react";

analyticsModule.initMixpanel("c039420858abdf4de72a3272022b32da");

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
]);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </PersistGate>
  </Provider>
);