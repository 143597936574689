import { createSlice } from "@reduxjs/toolkit";

const MESSAGE_TYPE = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

const constructMessage = ({
  index,
  type = MESSAGE_TYPE.SUCCESS,
  message,
  link,
  doNotClose,
  icon,
}) => {
  let currTS = new Date().getTime();
  let validTill = currTS + 30 * 1000;

  return {
    index,
    type,
    message,
    validTill,
    link,
    doNotClose,
    icon,
  };
};


export const appSlice = createSlice({
  name: "app",
  initialState: {
    messages: [],
    appTimezone: undefined,
    isFullScreenAnsEnabled: false,
    showAvailableData: false,
    rightDrawerContent: ""
  },
  reducers: {
    set_timezone: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.appTimezone = action.payload[0];
    },
    set_message: (state, action) => {
      let messages = state.messages ? [...state.messages] : [];
      let message;

      if (messages.length === 5) {
        let newMessages = [];
        for (let i = 1; i < messages.length; i++) {
          let message = { ...messages[i] };
          message.index = i;
          newMessages.push(message);
        }
        messages = newMessages;
      }

      let index = messages.length;

      index++;
      let { payload } = action;

      message = constructMessage({
        index,
        message: payload.message,
        type: payload.type,
        link: payload.link,
        doNotClose: payload.doNotClose,
        icon: payload.icon,
      });
      messages.push(message);
      console.log(payload, "payload");

      state.messages = messages;
    },
    remove_message: (state, action) => {
      let message = action.payload;
      let messages = state.messages.filter(
        (item) => item.index !== message.index
      );

      state.messages = messages;
    },
    setFullScreenAnsVisibility: (state, action) => {
      state.isFullScreenAnsEnabled = action.payload;
    },
    setShowAvailableData: (state, action) => {
      state.showAvailableData = action.payload;
    },
    setRightDrawerContent: (state, action) => {
      if(state.rightDrawerContent === action.payload) {
        state.rightDrawerContent = "";  
      } else {
        state.rightDrawerContent = action.payload;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { 
  set_timezone, 
  set_message, 
  remove_message, 
  setFullScreenAnsVisibility, 
  setShowAvailableData, 
  setRightDrawerContent 
} = appSlice.actions;

export default appSlice.reducer;
