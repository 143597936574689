const PERIOD_CROSSOVER_TYPES = {
  ANSWER: "answer",
  PILLS: "pills",
  TRACKBOARD: "trackboard",
  TRACKBOARD_ADD: "trackboard-add",
  MORE_INFO: "more-info",
};
const ENTITY_TYPES_FOR_TITLE = ['BUSINESS_OBJECT', 'PERIOD', 'ATTRIBUTE'];

const RECENT_QUESTION_SESSION_STORAGE_KEY = "recentQuestions";

const FEEDBACK_THUMBS_INDEX = {
  UP: 1,
  DOWN: 2,
};

const EXPLORE_MATRIX = {
  CARD_DD_SINGLE: "card_dd_single",
  CARD_SINGLE: "card_single",
  CARD_CAROUSEL: "card_carousel",
  CARD_GRID: "card_grid",
  CARD_NUDGE_CAROUSEL: "card_nudge_carousel",
  CARD_PILLS: "card_pills",
  CARD_LIST: "card_list",
};

const ANSWER_TYPES = {
  PERIOD_AGO: "PERIOD_AGO",
  TREND: "TREND",
  DRIVER: "DRIVER",
  RELATED_MEASURES: "RELATED_MEASURES",
  SEGMENT_ATTRIBUTES: "SEGMENT_ATTRIBUTES",
  TOP_BUSINESS_OBJECTS: "TOP_BUSINESS_OBJECTS",
  TARGET_MEASURES: "TARGET_MEASURES",
  RECOMMENDED_QUESTIONS: "RECOMMENDED_QUESTIONS",
  DIAGNOSTIC_ALERT: "DIAGNOSTIC_ALERT",
  RELATED_BUSINESS_OBJECTS: "RELATED_BUSINESS_OBJECTS",
  TOP_BOTTOM_BUSINESS_OBJECTS: "TOP_BOTTOM_BUSINESS_OBJECTS",
  TOP_MEASURE: "TOP_MEASURE",
};

const ALL_PROJECT_SESSION_KEY = "userProjects";
const APP_USER_CONFIG_SESSION_KEY = "userConfig";
const CURRENT_PROJECT_SESSION_KEY = "currentProject";
const CURRENT_ORGANIZATION_SESSION_KEY = "currentOrganization";
const USER_LOGIN_RESPONSE = "userLoginResponse";

const USER_CONFIG_COOKIE_NAME = "userConfig";

const APP_BASE_ROUTE = {
  ADMIN: "/admin/",
  ORGANIZATIONS: "/organizations/",
  HOME_PAGE: "/user/boards",
  ADMIN_PAGE: "/admin/transaction/list",
  LOGIN: "/",
};

const ORGANIZATION_LINKS = {
  USERS: "/organizations/users",
  PROJECTS: "/organizations/projects",
  PROJECTS_LIST: "/organizations/projects/list",
  USERS_LIST: "/organizations/users/list",
  ADD_PROJECT: "/organizations/projects/add",
  UPDATE_PROJECT: "/organizations/projects/",
  UPDATE_PROJECT_ROUTE: "/organizations/projects/:id",
  ADD_USER: "/organizations/users/add",
  EDIT_USER: "/organizations/users/:id",
};

const USER_CONFIRM_DIALOG_TYPE = {
  DELETE: "delete",
  CONFIRM: "confirm",
};

const SYSTEM_SETTING_TYPES = {
  TIMEZONE: "TIMEZONE",
  SUPPORT_EMAIL: "SUPPORT_EMAIL",
};

const USER_CONFIG_ROLE = {
  USER: "user",
  ADMIN: "admin",
};

const PROJECT_ID_COOKIE = "projectID";
const MULTITENANT_PROJECT_ALERT_MESSAGE_SESSION_KEY =
  "multitenantProjectAlertMesssage";

const APP_LEVEL_MESSAGES = {
  ADD_USER_SUCCESS: "User created successfully.",
  ADD_USER_ERROR: "Couldn't create the user. Try again.",
  UPDATE_USER_SUCCESS: "User updated successfully.",
  UPDATE_USER_ERROR: "Couldn’t update the user. Try again.",
  USER_ADDED_TO_PROJECT_SUCCESS: "User added to the project successfully.",
  USER_ADDED_TO_PROJECT_ERROR: "Couldn't add the user to project. Try again.",
  USER_REMOVE_TO_PROJECT_SUCCESS: "User removed from the project successfully.",
  USER_REMOVE_TO_PROJECT_ERROR:
    "Couldn't remove the user to project. Try again.",
  ADD_PROJECT_SUCCESS: "Project created successfully.",
  ADD_PROJECT_ERROR: "Couldn't create the project. Try again.",
  UPDATE_PROJECT_SUCCESS: "Project updated successfully.",
  UPDATE_PROJECT_ERROR: "Couldn’t update project. Try again.",
  ADD_MEASURE_RELATION_SUCCESS: "Measure relation created successfully",
  ADD_MEASURE_RELATION_ERROR: "Couldn't create measure relation. Try again.",
  UPDATE_MEASURE_RELATION_SUCCESS: "Measure relation updated",
  UPDATE_MEASURE_RELATION_ERROR: "Couldn't update measure relation. Try again.",
  DELETE_MEASURE_RELATION_SUCCESS: "Measure relation deleted",
  DELETE_MEASURE_RELATION_ERROR: "Couldn't delete measure relation. Try again.",
  DELETE_MULTIPLE_MEASURE_RELATION_SUCCESS: "Measure relations deleted",
  DELETE_MULTIPLE_MEASURE_RELATION_ERROR:
    "Couldn't delete measure relations. Try again.",
  ALREADY_EXISTING_MEASURE_RELATION: "This measure relation already exists",
  ADD_MEASURE_ALGORITHM_SUCCESS: "Nudge configured successfully",
  ADD_MEASURE_ALGORITHM_ERROR:
    "Couldn't configure a measure algorithm. Try again.",
  UPDATE_MEASURE_ALGORITHM_SUCCESS: "Configuration updated successfully",
  UPDATE_MEASURE_ALGORITHM_ERROR:
    "Couldn't update a measure algorithm. Try again.",
  DELETE_MEASURE_ALGORITHM_SUCCESS: "Configuration deleted successfully",
  DELETE_MEASURE_ALGORITHM_ERROR:
    "Couldn't delete a measure algorithm. Try again.",
  BULK_DELETE_MEASURE_ALGORITHM_SUCCESS: "Configurations deleted successfully",
  LOAD_NUDGES_ERROR: "Could not load Nudges. Please try again.",
  DELETE_NUDGE_SUCCESS: "Nudge deleted successfully",
  DELETE_NUDGE_ERROR: "Could not delete Nudge. Please try again.",
  DELETE_NUDGES_DATE_SUCCESS: "Nudges for <date> deleted",
  DELETE_NUDGES_DATE_ERROR: "Could not delete Nudges. Please try again.",
  EDIT_ANSWER_SUCCESS: "Answer changes saved.",
  ADD_DATASOURCE_SUCCESS: "Data source configured successfully",
  UPDATE_DATASOURCE_SUCCESS: "Data source updated successfully",
  DELETE_DATASOURCE_SUCCESS: "Data source deleted successfully",
  DELETE_DATASOURCE_ERROR: "Error deleting data source",
  BULK_DELETE_DATASOURCE_SUCCESS: "Data sources deleted successfully",
  CREATE_DRAFT_TRANSACTION_SUCCESS: "Draft transaction created successfully",
  CREATE_DRAFT_TRANSACTION_ERROR: "Error creating draft transaction",
  DATASET_FORMAT_SUCCESS: "Dataset formated successfully",
  DATASET_FORMAT_ERROR: "Error formating dataset",
  REMOVE_DATASET_FORMAT_SUCCESS: "Format removed successfully",
  REMOVE_DATASET_FORMAT_ERROR: "Error removing format",
  CACHE_SETTINGS_UPDATE_SUCCESS: "Cache settings updated sucessfully",
  CACHE_SETTINGS_UPDATE_ERROR: "Error updating Cache settings",
  REFRESH_NOW_SUCCESS: "All dependent reflections will be refreshed.",
  TRACK_REFRESH_FAILURE: "Couldn’t refresh answers. Please try again.",
  TRACK_REFRESH_PARTIAL_FAILURE:
    "Couldn’t refresh <errored>/<total> answers. Please try again.",
  QUESTION_ANNOTATION_SUCCESS: "Question annotated",
  QUESTION_ANNOTATION_ERROR: "Couldn’t annotate question. Please try again.",
  ADD_FORECAST_SUCCESS: "Forecast enabled successfully",
  ADD_FORECAST_ERROR: "Configuration failed",
  UPDATE_FORECAST_SUCCESS: "Configuration updated successfully",
  UPDATE_FORECAST_ERROR: "Configuration updated failed",
  DELETE_FORECAST_SUCCESS: "Configuration deleted successfully",
  DELETE_FORECAST_ERROR: "Couldn't delete configuration",
  TRIGGER_ANSWER_CACHE_SUCCESS: "Answer cache triggered successfully",
  TRIGGER_ANSWER_CACHE_ERROR: "Error while triggering answer cache",
  TRIGGER_BUSINESS_OBJECT_REFRESH_SUCCESS: "Business object refresh triggered successfully",
  TRIGGER_BUSINESS_OBJECT_REFRESH_ERROR: "Error while triggering business object refresh",
  TRIGGER_NUDGE_SUCCESS: "Nudges triggered successfully",
  TRIGGER_NUDGE_ERROR: "Error while triggering nudges",
  TRIGGER_BO_CLEANUP_SUCCESS: "Business object cleanup triggered successfully",
  TRIGGER_BO_CLEANUP_ERROR: "Error while triggering business object cleanup",
};

const THRESHOLD_ALERTS_INTERNAL_NAME = "THRESHOLD-ALERTS";

const PERSONAS = [
  {
    value: "CONSULTANT",
    label: "Consultant",
  },
  {
    value: "ANALYST",
    label: "Analyst",
  },
];

const DEVICE_TYPES_BY_WIDTH = {
  desktop: "desktop",
  mobile: "mobile",
  tablet: "tablet"
};

const SOURCES = {
  data: "ENTERPRISE",
  document: "DOCUMENT"
};

const ANALYSIS_GROUPS = {
  change: "change",
  drivers: "drivers",
  contribution: "contribution",
  trend: "trend",
  correlation: "correlation"
};

const RESPONSE_TYPES = {
  interpretation: "INTERPRETATION",
  insights: "INSIGHTS",
  summary: "SUMMARY",
  document: "DOCUMENT",
  assistance: "ASSISTANCE",
  terminate: "TERMINATE",
};

const CAN_TYPES = {
  insight: "INSIGHT",
  insightV2: "INSIGHT_V2",
  summary: "SUMMARY"
};

const RESPONSE_DISPLAY_TYPES = {
  availableData: "AVAILABLE_DATA"
};

const VISUALISATION_LIB_TYPES = {
  copilotWidgets: "COPILOT_WIDGETS",
  copilotViz: "COPILOT_VIZ"
};

const RIGHT_DRAWER_CONTENT = {
  availableData: "AVAILABLE_DATA",
  questionBank: "QUESTION_BANK"
};

export {
  PERIOD_CROSSOVER_TYPES,
  RECENT_QUESTION_SESSION_STORAGE_KEY,
  FEEDBACK_THUMBS_INDEX,
  EXPLORE_MATRIX,
  ANSWER_TYPES,
  ALL_PROJECT_SESSION_KEY,
  CURRENT_PROJECT_SESSION_KEY,
  APP_BASE_ROUTE,
  ORGANIZATION_LINKS,
  USER_CONFIRM_DIALOG_TYPE,
  CURRENT_ORGANIZATION_SESSION_KEY,
  SYSTEM_SETTING_TYPES,
  APP_USER_CONFIG_SESSION_KEY,
  USER_CONFIG_ROLE,
  PROJECT_ID_COOKIE,
  MULTITENANT_PROJECT_ALERT_MESSAGE_SESSION_KEY,
  USER_LOGIN_RESPONSE,
  APP_LEVEL_MESSAGES,
  USER_CONFIG_COOKIE_NAME,
  THRESHOLD_ALERTS_INTERNAL_NAME,
  PERSONAS,
  DEVICE_TYPES_BY_WIDTH,
  ENTITY_TYPES_FOR_TITLE,
  SOURCES,
  ANALYSIS_GROUPS,
  RESPONSE_TYPES,
  CAN_TYPES,
  RESPONSE_DISPLAY_TYPES,
  VISUALISATION_LIB_TYPES,
  RIGHT_DRAWER_CONTENT
};
