import styled from 'styled-components';
import { getStyleString } from "../../../../../../utils/styleUtils";

export const Option = styled.div`
    margin-top: 8px;

    &:first-child {
        margin-top: 0px;
    }

    padding: 6px 8px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        background-color: ${props => props.theme["ui_hover"]};
    }

    ${props => props.highlight ? `
        background-color: ${props.theme["ui_hover"]};
    ` : ""}
}`;

export const Label = styled.span`
    color: ${props => props.theme["text_01"].light};
    ${(props) => getStyleString(props.theme["text_02_reg"])};

    ${Option}:hover & {
        ${(props) => getStyleString(props.theme["text_01_med"])};
    }

    ${props => props.highlight ? `
        ${getStyleString(props.theme["text_01_med"])};
    ` : ""}
}`;

export const SubLabel = styled.span`
    color: ${props => props.theme["text_04"].light};
    ${(props) => getStyleString(props.theme["text_02_reg"])};
    font-style: italic;

    ${Option}:hover & {
        ${(props) => getStyleString(props.theme["text_01_med"])};
    }

    ${props => props.highlight ? `
        ${getStyleString(props.theme["text_01_med"])};
    ` : ""}
}`;