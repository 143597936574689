import styled from 'styled-components';
import {getStyleString} from "../../../utils/styleUtils.js";

export const subtitleText = styled.span`
    color: ${props => props.theme["text_03"].light};
    ${props => getStyleString(props.theme["text_01_med"])};
    
    span:nth-child(2)::before {
        padding-left: 8px;
        padding-right: 8px;
        content: "\\2022";
        color: #808E9B;
    }
`;