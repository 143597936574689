import styled from "styled-components";

interface AskProps {
  hideSource: boolean;
}

interface DropdownProps {
  bottomDropdown?: boolean;
  isdisabled?:boolean;
}

export const Container = styled.div`
  position: relative;
  margin: 20px 24px;
`;

export const AskContainer = styled.div<AskProps>`
  display: grid;
  grid-template-columns: ${props => props.hideSource ? "100%" : "72px auto"};
  border-radius: 8px;
  border: 1px solid ${props => props.theme["border_02"]};
  background: #FFF;
`;

export const SourceTooltip = styled.div`
    position: absolute;
    left: calc(100% - 16px);
    bottom: calc(100% - 4px);
    display: none;
    width: max-content;
    z-index: 1;
`;

export const SourceSelector = styled.div`
  padding: 13px 12px;
  border-radius: 8px 0px 0px 8px;
  border-right: 1px solid ${props => props.theme["border_02"]};
  background: ${props => props.theme["ui_04"]};
  display: grid;
  grid-template-columns: 24px 20px;
  grid-gap: 4px;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;

  &:hover ${SourceTooltip} {
    display: block;
  }
`;

export const Dropdown = styled.div<DropdownProps>`
  width: 100%;
  position: absolute;
  z-index: 100;
  left: 0px;

  ${props => props.bottomDropdown ? `
    top: 100%;
  ` : `
    bottom: 100%;
  `}
`;


export const CTAs = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

export const Cancel = styled.div`
`;

export const Submit = styled.div`
  margin-left: 16px;
`;

export const SourceDropdown = styled.div<DropdownProps>`
  width: 100%;
  position: absolute;
  z-index: 100;
  left: 0px;
  bottom: 100%;
  pointer-events: ${props => props.isdisabled ? "none" : "all"};
`;