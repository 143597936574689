import SessionService from "../services/session.service";

import {
    CURRENT_PROJECT_SESSION_KEY,
    ALL_PROJECT_SESSION_KEY
} from "../constants/";

const mixpanel = require('mixpanel-browser');

let getProjectMetaData = () => {
    
    let currProject = SessionService.getItem(CURRENT_PROJECT_SESSION_KEY);
    currProject = currProject ? JSON.parse(currProject) : {};

    let allProjects = SessionService.getItem(ALL_PROJECT_SESSION_KEY);
    allProjects = allProjects ? JSON.parse(allProjects) : [];
    
    let allProjectIDs = allProjects.map(item => item.id);
    allProjectIDs = allProjectIDs.filter(item => item);

    let data = {}

    if(Object.keys(currProject).length) {
        data.organization = currProject.organizationName;
        data.projectID = currProject.id;
    }

    if(allProjectIDs.length) {
        data.projects = allProjectIDs.join(", ");
        if(!data.organization) {
            data.organization = allProjects[0].organizationName;
        }
    }
    return data;
}

function initMixpanel(token) {
    mixpanel.init(token || "c039420858abdf4de72a3272022b32da");
};

function setUserProps(userDetails) {
    console.log("userDetails: ", userDetails);
    let userData = {
        "name": userDetails.lastName ? `${userDetails.firstName} ${userDetails.lastName}` : userDetails.firstName,
        "username": userDetails.email,
        "$email": userDetails.email,
        "Cuddle URL": window.location.origin
    };

    let data = getProjectMetaData();
    if(Object.keys(data).length > 0) {
        userData.ProjectID = data.projects;
        userData.organization = data.organization;
    }
    // console.log("userData: ", userData);
    mixpanel.people.set(userData);
    mixpanel.identify(userDetails.userID);
    trackEvent("App Viewed");
    incrementPeopleProps({"# of App Viewed": 1});
}

function trackEvent(eventName, segmentation = {}) {
    if(eventName) {
        let data = getProjectMetaData();
        let trackingData;

        if(Object.keys(segmentation).length > 0) {
            trackingData = segmentation;
        }

        if(Object.keys(data).length > 0) {
            trackingData = trackingData || {};
            trackingData.organization = data.organization;
            trackingData.projectID = data.projectID;
        }
        
        if(trackingData) {
            mixpanel.track(eventName, trackingData);
        } else {
            mixpanel.track(eventName);
        }
    } else {
        console.log("[trackEvent] missing event name.");
    }
}

function resetTracking() {
    mixpanel.reset();
}

function incrementPeopleProps(props) {
    mixpanel.people.increment(props);
}

function setPeopleProps(props) {
    mixpanel.people.set(props);
}

function setPeoplePropsOnce(props) {
    mixpanel.people.set_once(props);
}

let analyticsModule = {
    initMixpanel,
    setUserProps,
    trackEvent,
    resetTracking,
    incrementPeopleProps,
    setPeopleProps,
    setPeoplePropsOnce
};

export default analyticsModule;