import styled from "styled-components";
import { getStyleString } from "../../../utils/styleUtils";

export const Container = styled.div`
  color: ${props => props.theme["text_02"].light};
  font-size: 14px;
  font-family: Inter;
  line-height: 24px;
  ${props => getStyleString(props.theme["answer_summary"])};
`;
