import styled from "styled-components";
import { getStyleString } from "../../../../utils/styleUtils";

export const Container = styled.div`
  position: relative;
  margin-top: 16px;

  .button-content {
    justify-content: left !important;
  }
`;

export const Label = styled.div`
  ${props => props.disabled ? "" : "cursor: pointer;"}

  .button-content {
    justify-content: left !important;
  }

  svg {
    position: absolute;
    right: 8px;
    top: 8px;
    // transform: rotate(${props => props.showDropdown ? "-90" : "90" }deg);
  }
`;

export const Dropdown = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  left: 0px;
  width: 100%;
  z-index: 100;
`;