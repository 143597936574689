import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from '../../../../stores/redux-hooks';
import SessionList from "./session-list/SessionList";
import { setSessions } from "../../../../reducers/chat-session-slice";

import { Container as StyledContainer } from "./styled-components.js";

import ChatSessionService from "../../../../services/chat-session.service.js";

function Sessions(props) {
  const userID = useAppSelector((state) => state.user.details.userID);
  const sessionGroups = useAppSelector((state) => state.chat.sessionGroups);
  const sessions = useAppSelector((state) => state.chat.sessions);
  const sessionDates = useAppSelector((state) => state.chat.dates);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // if (!activeSession) {
    ChatSessionService.getConversations(
      { userID },
      (data) => {
        console.log("[getConversations] success: ", data);
        if (data && data.length) {
          dispatch(setSessions(data));
        } else {
          props.addSession();
        }
      },
      (error) => {
        console.log("[getConversations] error: ", error);
      }
    );
    // }
  }, []);
  
  return (
    <StyledContainer>
      {sessionDates
        ? sessionDates.map((sessionDate) => (
            <SessionList
              key={sessionDate}
              period={sessionDate}
              data={sessionGroups[sessionDate].map((id) => sessions[id])}
            />
          ))
        : null}
    </StyledContainer>
  );
}

export default Sessions;
