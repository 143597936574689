import React, { useEffect, useState } from "react";
import MarkdownReader from "../markdown-reader";
import Loader from "../../components/loader/Loader";
import { ContextualMessageBar } from "@cuddle-dev/web-components";
import { renderMessages } from "../chat/Chat";

//styled components
import {
    Container as StyledContainer,
    Message as StyledMessage
} from "./styled-components";

// services
import ChatSessionService from "../../services/chat-session.service.js";

function Summary({data, promptId}) {
    const [summaryData, setSummaryData] = useState(data);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [messages, setMessages] = useState();

    useEffect(() => {
        if(promptId) {
            setIsLoading(true);
            setIsError(false);
            ChatSessionService.executePrompt({id: promptId}, {})
                .promise.then((response) => {
                    // console.log("[Summary] response: ", response);
                    const content = response?.data?.content;
                    if(content?.type === "SUMMARY" && content?.value) {
                        setSummaryData(content.value);
                    }
                    setMessages(response?.data?.messages);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log("Error while fetching summary data. ", error);
                    setIsLoading(false);
                    setIsError(true);
                });
        }
    }, []);

    return <StyledContainer>
        {summaryData ? (
            <MarkdownReader markdownText={summaryData} />
        ) : null}
        {isLoading ? <Loader type="shimmer"/> : null}
        {isError ?
            <ContextualMessageBar 
                type={"error"}
                feedback={"There was an issue in processing the information."}
                width="full"
                card={true}
            ></ContextualMessageBar>
            : null
        }
        {messages ? <StyledMessage>{renderMessages(messages)}</StyledMessage> : null}
    </StyledContainer>
}

export default Summary;