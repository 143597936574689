import styled from "styled-components";
import { getStyleString } from "../../../../utils/styleUtils";

export const Container = styled.div`
    border-radius: 16px;
    background: ${props => props.theme["ui_05"]};
    width: 100%;
    max-height: calc(100vh - 60px - 152px);
    overflow-y: auto;

    @media (min-width: 768px) and (max-width: 1024px) {
        width: 600px;
    }
    
    @media (min-width: 1024px) {
        width: 100%;
    }
`;

export const Header = styled.div`
    padding: 24px;
    border-bottom: 1px solid ${props => props.theme["border_02"]};
    position: relative;
`;

export const HeaderText = styled.div`
    text-transform: uppercase;
    text-align: center;
    color: ${props => props.theme["text_02"].light};
    ${props => getStyleString(props.theme["transperancy_box_heading"])};
`;

export const BackButton = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    left: 24px;
    top: 24px;
    cursor: pointer;
`;

export const BackIcon = styled.div`
    width: 20px;
    height: 20px;
`;

export const BackText = styled.div`
    margin-left: 4px;
    color: ${props => props.theme["text_interactive"]};
    ${props => getStyleString(props.theme["web_button"])};
`;

export const CloseIcon = styled.div`
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    right: 24px;
    top: 22px;
`;

export const Section = styled.div`
    padding: 24px;
    border-bottom: 1px solid ${props => props.theme["border_03"]};

    &:last-child {
        border-bottom: none;
    }
`;

export const Tabs = styled.div`
    margin-top: 24px;
    text-align: center;
`;