import React, {useState, useEffect, useRef, MutableRefObject} from "react";
import { useTheme } from 'styled-components';
import {SVGIcon, Button, ButtonType, ActionMenu} from '@cuddle-dev/web-components';

//styled-components
import {
  Container as StyledContainer,
  Label as StyledLabel,
  Dropdown as StyledDropdown,
} from "./styled-components";

//images
import { ReactComponent as ChevronDownIcon } from "../../../../../../images/chevron_down_20.svg";
import { ReactComponent as ChevronUpIcon } from "../../../../../../images/chevron_up_20.svg";

function AssistanceDropdown(props) {
    const theme = useTheme();
    const [showDropdown, setShowDropdown] = useState(false);
    const [dropdownOffset, setDropdownOffset] = useState(0);
    const containerRef: MutableRefObject<HTMLDivElement> = useRef();
    const dropdownRef: MutableRefObject<HTMLDivElement> = useRef();

    useEffect(() => {
        const handleDocumentClick = function (event) {
          if (
            containerRef &&
            containerRef.current &&
            !containerRef.current.contains(event.target)
          ) {
            if (showDropdown) {
                setShowDropdown(false);
            }
          }
        };
    
        document.addEventListener("click", handleDocumentClick, false);
    
        return function cleanup() {
          document.removeEventListener("click", handleDocumentClick, false);
        };
    });

    useEffect(() => {
        if(showDropdown) {
            const bounds = dropdownRef.current.getBoundingClientRect();
            const totalHeight = window.innerHeight;
            let offset = 0;

            if((bounds.top + bounds.height) > (totalHeight - 20)) {
                offset = (totalHeight - 20) - (bounds.top + bounds.height);
            }

            if(offset !== dropdownOffset) {
                setDropdownOffset(offset);
            }
        }
    }, [showDropdown]);

    function changeHandler(data) {
        console.log("AssistanceDropdown changeHandler: ", data);
        props.changeHandler(props.dataKey, data.selected);
    }

    function containerClickHandler() {
        setShowDropdown(!showDropdown);
    }

    const customStyle=`
        background-color: ${theme["ui_04"]};
        color: ${theme["text_03"].light};
        &:hover{
            background-color: ${theme["ui_04"]};
            color: ${theme["text_03"].light};
        }
        &:hover  path{
            fill: ${theme["icon_02"].light};
        }
        &:active{
            background-color: ${theme["ui_04"]};
            color: ${theme["text_03"].light};
        }
        &:active path{
            fill: ${theme["icon_02"].light};
        }
    `;

    // console.log("AssistanceDropdown data: ", props.data);

    return <StyledContainer ref={containerRef} onClick={containerClickHandler}>
        <StyledLabel>
            <Button
                endEnhancer={<SVGIcon Icon={showDropdown ? ChevronUpIcon : ChevronDownIcon} 
                    width={20} 
                    height={20} 
                    fill={theme["icon_02"].light}
                />}
                buttonType={ButtonType.tertiary}
                text={props.selected.label}
                style={{ width: "100%" }}
                customStyling={customStyle}
            />
        </StyledLabel>
        {showDropdown ? 
            <StyledDropdown ref={dropdownRef} dropdownOffset={dropdownOffset}>
                <ActionMenu
                    selected={props.selected}
                    optionSections={[
                    {
                        options: props.data,
                    },
                    ]}
                    clickHandler={changeHandler}
                />
            </StyledDropdown>
            : null
        }
    </StyledContainer>
}

export default AssistanceDropdown;
