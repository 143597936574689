import React from 'react';

//styled-components
import {
    FootnoteText as StyledFootnoteText
} from './styled-components.js';

const Footnote = React.forwardRef((props, ref) => {
    return (
        <StyledFootnoteText theme={props.theme} ref={ref}>
            {props.text}
        </StyledFootnoteText>
    );
});

export default Footnote;