import React from "react";

import { Visualization } from "../../../../components/visualization/";
import { generateUUID } from "../../../../utils/utilityFunctions.js";
import {ANALYSIS_GROUPS} from "../../../../constants";
import VizSelector from "../../../answer-card/viz-selector/VizSelector";
import AnswerFooter from "../../../chat/answer-footer/AnswerFooter";
import AnswerTitle from "../../../chat/answer-title/AnswerTitle";
import { useAppSelector } from '../../../../stores/redux-hooks';

import {
  Container as StyledContainer,
  Title as StyledTitle,
  Viz as StyledViz,
  VizTitle as StyledVizTitle,
  VizSection as StyledVizSection,
  Error as StyledError,
  SubHeader as StyledSubHeader,
  Header as StyledHeader,
} from "./styled-components";

function getVizTitle(query) {
  const { attributes } = query;

  if (attributes) {
    let labels = [];

    attributes.forEach((attribute) => {
      if (attribute.filterValues) {
        labels.push(attribute.filterValues[0].label);
      }
    });
    return labels.join(", ");
  }
  return "";
};

function ChangeViz(props) {
  const data = props.data;
  const showAvailableData = useAppSelector((state) => state.app.showAvailableData);

  if (!data) {
    return null;
  }

  const algoGroup = data[0].additionalInfo?.group;
  const noViewOnly =
    algoGroup &&
    (algoGroup.toLowerCase() === ANALYSIS_GROUPS.drivers ||
      algoGroup.toLowerCase() === ANALYSIS_GROUPS.contribution ||
      algoGroup.toLowerCase() === ANALYSIS_GROUPS.correlation);
      
  // const errors = data.errors || [];
  // const warnings = data.warnings || [];
  const isChange = algoGroup && algoGroup.toLowerCase() === ANALYSIS_GROUPS.change;
  let gridColumns = 1;
  
  if(isChange && props.layout && props.layout.blocks && props.layout.blocks[0].type === "GRID") {
    gridColumns = props.layout.layoutMeta && props.layout.layoutMeta.column;
  }

  return (
    <StyledContainer>
      {/* errors.map(error => 
        <StyledError>
          <ContextualMessageBar
            type="error"
            feedback={error.displayMessage}
            width={"full"}
            theme={props.theme}
          />
        </StyledError>
      ) */}
      {/* warnings.map(warning => 
        <StyledError>
          <ContextualMessageBar
            type="warning"
            feedback={warning.displayMessage}
            width={"full"}
            theme={props.theme}
          />
        </StyledError>
      ) */}
      {data ?
        <React.Fragment>
            {
              !props.hideTitle ?
                <StyledHeader>
                  <AnswerTitle title={data[0]?.additionalInfo?.title?.label} subTitle={data[0].answer?.periodDisplayTitle || ""} />
                </StyledHeader>
              : null
            }
            <StyledSubHeader>
                <VizSelector data={data[0]?.answer?.visualisation}
                    selected={data[0]?.answer?.visualisation?.preferredType}
                    onChange={() => {}}
                />
            </StyledSubHeader>
            <StyledVizSection isChange={isChange} gridColumns={gridColumns}>
            {data
                .filter((analysisData) => !analysisData.errors)
                .map((answerJSON) => {
                return (
                  <StyledViz key={answerJSON.id + showAvailableData}>
                    {isChange ? 
                        <StyledVizTitle theme={props.theme}>
                        {getVizTitle(answerJSON.query)}
                        </StyledVizTitle> 
                    : null}
                    <Visualization
                        data={answerJSON.answer.result}
                        id={`viz-${generateUUID()}`}
                        options={answerJSON.answer.visualisation.preferredType}
                        metaData={
                        answerJSON.answer.visualisation.preferredType.metadata
                        }
                        viewOnly={!noViewOnly}
                        configurations={{
                        load: () => {}, //this.vizLoadHandler,
                        // style: vizStyle,
                        curatedAnalysisClickHandler: null,
                        // click: answerJSON.query && (answerJSON.query.rank || answerJSON.query.order) ? undefined : this.drilldownHandler,
                        currencyFormat: props.currencyFormat,
                        // vizState: (this.props.vizState && this.state.visualisation && this.props.vizState[this.state.visualisation.type]) ? this.props.vizState : undefined,
                        resultMetadata: answerJSON.answer.resultMetadata,
                        // onStateChange: this.vizStateChangeHandler,
                        disableSorting: true, //answerJSON.query && (answerJSON.query.rank || answerJSON.query.order),
                        disableClickThrough: true,
                        answerGroup:
                            answerJSON.answer && answerJSON.answer.answerGroup,
                        theme: props.theme,
                        // viewOnly: viewOnly,
                        legendPosition: "bottom",
                        // autoScaleYAxis: this.props.autoScaleYAxis,
                        enableZoom: true,
                        // zoomHandlers: {
                        //     onZoom: this.chartZoomHandler,
                        //     onPan: this.chartPanHandler,
                        //     onResetZoom: this.resetZoomHandler
                        // },
                        // recordAnalyticsEvent: this.recordAnalyticsEvent,
                        symbolInHeader: false,
                        // disableSorting: this.props.disableSorting,
                        hideUniqueBOsInSummary: true,
                        hideSearch: true,
                        hideColumnOptions: true,
                        }}
                        type={answerJSON.answer.visualisation.preferredType.type}
                        supplementaryData={answerJSON.answer.supplementaryInfo}
                    />
                    </StyledViz>
                );
                })}
            </StyledVizSection>
            {!props.hideFooter && data[0]?.additionalInfo?.transparency ?
              <AnswerFooter hideRemoveAnswer={true} transparencyData={data[0]?.additionalInfo?.transparency}/>
              : null
            }
        </React.Fragment>
        : null
      }
    </StyledContainer>
  );
}

export default ChangeViz;
