import React, {Component} from 'react';
// import PropTypes from 'prop-types'; //To be installed
// import {createTheme} from "../Themes";
import {getWidget} from "./utils.js";
// import ErrorBoundary from "../error-boundary";
import { VISUALISATION_LIB_TYPES } from "../../constants";

import {
    VizDiv as StyledVizDiv,
    LoaderDiv as StyledLoaderDiv
} from './styled-components.js';
import Loader from "../loader/Loader.js";

class Visualization extends Component {
    state = {
        widget: undefined,
        isErrored: false
    };

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ isErrored: true });
        // You can also log the error to an error reporting service
        console.log("[VISUALISATION ERROR]", error, "Data: ", this.props);
      }
    

    componentDidMount() {
        const type = this.props.vizLibType === VISUALISATION_LIB_TYPES.copilotViz ?
                        this.props.data?.visualisation?.preferredType :
                        this.props.type;

        getWidget(type, this.props.urlOrigin, { react: require("react") }, this.props.vizLibType)
            .then(widget => {
                this.setState({
                    widget
                }); 
            })
            .catch(error => {
                console.log(`[Visualization] Error fetching widget ${this.props.type}: `, error, this.props);
                // this.setState({
                //     isErrored: true
                // });
            });
    }

    render() {
        let Widget = this.state.widget;
        let configurations = {...this.props.configurations};

        //set default value of isBrowser
        if(typeof configurations.isBrowser !== "boolean") {
            configurations.isBrowser = true;
        }

        return (
            <StyledVizDiv viewOnly={this.props.viewOnly}>
                {this.state.isErrored ? 
                    <div>Couldn't process your request.</div> : (
                        Widget ? (
                            this.props.vizLibType === VISUALISATION_LIB_TYPES.copilotViz ? (
                                <Widget data={this.props.data}
                                    id={this.props.id}
                                />
                            ) : (
                                <Widget data={this.props.data}
                                    outlier={this.props.outlier}
                                    id={this.props.id}
                                    options={JSON.parse(JSON.stringify(this.props.options))}
                                    configurations={configurations}
                                    metaData={this.props.metaData}
                                    supplementaryData={this.props.supplementaryData}
                                />
                            )
                        )
                        : (
                            <StyledLoaderDiv>
                                <Loader />
                            </StyledLoaderDiv>
                        )
                    )
                }
            </StyledVizDiv>
        );
    }
}

export default Visualization;

// Visualization.propTypes = {
//     data: PropTypes.object,
//     id: PropTypes.string,
//     outlier: PropTypes.object,
//     options: PropTypes.object,
//     configurations: PropTypes.object,
//     metaData: PropTypes.object
// };