import React, {useState, useEffect, useRef, MutableRefObject} from "react";
import { useTheme } from 'styled-components';
// import {SVGIcon, Button, ButtonType, ActionMenu} from '@cuddle-dev/web-components';

//styled-components
import {
  Container as StyledContainer,
  TextBox as StyledTextBox
} from "./styled-components";

function AssistanceTextbox(props) {
    const theme = useTheme();

    function  changeHandler(event) {
        const value = event.target.value;

        props.textboxChangeHandler(props.dataKey, value);
    }
    // console.log("AssistanceTextbox data: ", props.data);
    return <StyledContainer>
        <StyledTextBox value={props.value} placeholder="Type here" onChange={changeHandler} />
    </StyledContainer>
}

export default AssistanceTextbox;
