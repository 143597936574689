import React, { useEffect, useState } from "react";
import { useTheme } from 'styled-components';
import Loader from "../../../../../components/loader/Loader";
import { Button, ButtonType, SVGIcon } from "@cuddle-dev/web-components";
import ErrorCard from "../error-card/ErrorCard";

// styled components
import {
    Container as StyledContainer,
    Loader as StyledLoader,
    Title as StyledTitle,
    SQL as StyledSQL,
    Nav as StyledNav,
    LeftArrow as StyledLeftArrow,
    RightArrow as StyledRightArrow,
    Dots as StyledDots,
    Dot as StyledDot,
    CopyButton as StyledCopyButton,
    CopyIcon as StyledCopyIcon,
    CopyText as StyledCopyText,
} from "./styled-components";

// services
import ChatSessionService from "../../../../../services/chat-session.service.js";

// images
import { ReactComponent as ChevronLeftIcon } from "../../../../../images/chevron_left_20.svg";
import { ReactComponent as ChevronRightIcon } from "../../../../../images/chevron_right_20.svg";
import { ReactComponent as CopyIcon } from "../../../../../images/copy-24.svg";
import { ReactComponent as TickIcon } from "../../../../../images/tick_20.svg";

function SQLSection(props) {
    const theme = useTheme();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [sqlData, setSqlData] = useState<any>();
    const [sqlError, setSqlError] = useState(false);
    const [copiedSQL, setCopiedSQL] = useState(false);

    useEffect(() => {
        const sqlIdData = props.sqlIds[currentIndex];

        if(sqlIdData.sqlId) {
            if(props.sqlData[sqlIdData.sqlId]) {
                setSqlData(props.sqlData[sqlIdData.sqlId]);
            } else {
                ChatSessionService.getSQL({sqlId: sqlIdData.sqlId})
                    .promise.then((response) => {
                        // console.log("response: ", response);
                        props.handleSQLFetch(response.data);
                        setSqlData(response.data);
                    })
                    .catch((error) => {
                        console.log("Error while fetching sql data. ", error);
                        setSqlError(true);
                    });
            }
        }
    }, [currentIndex]);

    function handleLeftClick() {
        if(currentIndex > 0) {
            setSqlData(undefined);
            setSqlError(undefined);
            setCopiedSQL(undefined);
            setCurrentIndex(currentIndex - 1);
        }
    }

    function handleRightClick() {
        if(currentIndex < props.sqlIds.length) {
            setSqlData(undefined);
            setSqlError(undefined);
            setCopiedSQL(undefined);
            setCurrentIndex(currentIndex + 1);
        }
    }

    function handleCopyClick() {
        navigator.clipboard.writeText(sqlData.query.sqlQueries[0].sql);
        setCopiedSQL(true);
    }

    function getDots(totalIds, currentIndex) {
        const dots = [];
        for(let i = 0; i < totalIds; i++) {
            dots.push(<StyledDot key={i} selected={i === currentIndex} />);
        }
        return <StyledDots>{dots}</StyledDots>;
    }
    
    function getTitle(titleObj) {
        if(!titleObj.format) {
            return <span>{titleObj.label}</span>;
        }
        const sortedFormat = titleObj.format.sort((a, b) => a.startIndex - b.startIndex);
        let start = 0;
        const spans = [];
        let key = 0;
        sortedFormat.forEach((obj, index) => {
            if(start !== obj.startIndex) {
                const str = titleObj.label.slice(start, obj.startIndex);
                spans.push(<span key={key}>{str}</span>);
                key++;
            }
            const str = titleObj.label.slice(obj.startIndex, obj.lastIndex + 1);
            start = obj.lastIndex + 1;
            const classes = obj.format_type === "BOLD" ? "title-bold" : "";
            spans.push(<span key={key} className={classes}>{str}</span>);
            key++;

            if(index === sortedFormat.length - 1 && obj.lastIndex !== titleObj.label.length) {
                const str = titleObj.label.slice(obj.lastIndex + 1);
                spans.push(<span key={key}>{str}</span>);
            }
        });
        return spans;
    }

    const maxContainerHeight = window.innerHeight - 60 - 152;
    const padding = 48;
    const contentHeight = maxContainerHeight - props.headerHeight - padding;
    const titleLoaderHeight = 20 + 26;
    const sqlIdData = props.sqlIds[currentIndex];
    const totalIds = props.sqlIds.length;
    const sqlSectionHeight = contentHeight - 22 - 12 - (totalIds > 1 ? 48 : 0);
    const firstIndex = currentIndex === 0;
    const lastIndex = currentIndex === totalIds - 1;
    
    return <StyledContainer>
        {sqlData ?
            <>
                <StyledTitle>{getTitle(sqlIdData.title)}</StyledTitle>
                <StyledSQL maxHeight={sqlSectionHeight}>
                    <StyledCopyButton clickable={!copiedSQL} onClick={copiedSQL ? null : handleCopyClick}>
                        <StyledCopyIcon>
                            <SVGIcon Icon={copiedSQL ? TickIcon : CopyIcon} width={20} height={20} fill={theme["icon_02"].light}/>
                        </StyledCopyIcon>
                        <StyledCopyText>{copiedSQL ? "Copied" : "Copy"}</StyledCopyText>
                    </StyledCopyButton>
                    <div>{sqlData.query.sqlQueries[0].sql}</div>
                </StyledSQL>
                {totalIds > 1 ?
                    <StyledNav>
                        <StyledLeftArrow disabled={firstIndex} onClick={firstIndex ? null : handleLeftClick}>
                            <Button
                                startEnhancer={<SVGIcon Icon={ChevronLeftIcon} width={24} height={24} fill={theme[firstIndex ? "icon_disabled" : "icon_02"].light}/>}
                                buttonType={ButtonType.buttonIcon}
                                // onClick={handleLeftClick}
                                disabled={firstIndex}
                            ></Button>
                        </StyledLeftArrow>
                        {getDots(totalIds, currentIndex)}
                        <StyledRightArrow disabled={lastIndex} onClick={lastIndex ? null : handleRightClick}>
                            <Button
                                startEnhancer={<SVGIcon Icon={ChevronRightIcon} width={24} height={24} fill={lastIndex ? "icon_disabled" : theme["icon_02"].light}/>}
                                buttonType={ButtonType.buttonIcon}
                                // onClick={handleRightClick}
                                disabled={lastIndex}
                            ></Button>
                        </StyledRightArrow>
                    </StyledNav>
                    : null
                }
            </>
            : sqlError ?
                <ErrorCard height={contentHeight}/>
                : 
                <StyledLoader>
                    <Loader type="shimmer" height={20} width={380}/>
                    <Loader type="shimmer" height={contentHeight - titleLoaderHeight} />
                </StyledLoader>
        }
    </StyledContainer>
}

export default SQLSection;