import React from "react";
import { format } from 'sql-formatter';
import { useTheme } from 'styled-components';

// styled components
import {
    Container as StyledContainer,
    Title as StyledTitle,
    Text as StyledText
} from "./styled-components";

// constants
const DIALECTS = ["sql", "bigquery", "db2", "db2i", "hive", "mariadb", "mysql", "tidb", "n1ql", "plsql", "postgresql", "redshift", "singlestoredb", "snowflake", "spark", "sqlite", "transactsql", "tsql", "trino"];

function getDialect(dialect) {
    if(dialect && DIALECTS.indexOf(dialect.toLowerCase()) > -1) {
        return dialect.toLowerCase();
    }
}

function SQLQuery(props) {
    const theme = useTheme();
    let formattedString;
    let options:any = {};
    let dialect = getDialect(props.data.dialect);

    if(dialect) {
        options.language = dialect;
    }

    try {
        formattedString = format(props.data.query, options);
    } catch(error) {
        console.log("Error formatting sql ", error);
    }

    return <StyledContainer>
        <StyledTitle>SQL</StyledTitle>
        <StyledText>{formattedString || "Something went wrong"}</StyledText>
    </StyledContainer>
}

export default SQLQuery;