import styled from "styled-components";
import { getStyleString } from "../../../../../../utils/styleUtils";

export const Container = styled.div`
   
`;

export const TextBox = styled.textarea`
    background: ${props => props.theme["ui_04"]};
    color: ${props => props.theme["text_03"].light};
    border-radius: 8px;
    padding: 8px 8px 8px 12px;
    width: 100%;
    resize: none;
    border: none;
    box-sizing: border-box;
    height: 60px;

    ${props => getStyleString(props.theme["text_01_med"])};

    &::placeholder {
        color: ${props => props.theme["text_04"].light};
        ${props => getStyleString(props.theme["text_01_med"])};
    }

    &:focus {
        outline: none;
    }
`;
