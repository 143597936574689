import styled from "styled-components";
import { getStyleString } from "../../../utils/styleUtils";

export const Container = styled.div`
  height: calc(100% - 16px);
  border-radius: 12px;
  background: #FFF;
  margin: 8px 8px 8px 0px;
`;

export const Header = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${(props) => props.theme["border_03"]};
`;

export const Content = styled.div`
  padding: 20px 0px;
  height: calc(100% - ${props => props.headerHeight}px);
  overflow: hidden;
  box-sizing: border-box;
`;

export const Filters = styled.div`
  margin-bottom: 20px;
  padding: 0px 20px;
`;

export const HeaderTitle = styled.div`
  ${props => getStyleString(props.theme["text_02_med"])};
  color: ${props => props.theme["text_02"].light};
`;

export const Cross = styled.span`
  width: 20px;
  height: 20px;
  float: right;
  cursor: pointer;
`;

export const HeaderSubTitle = styled.div`
  margin-top: 16px;
  ${props => getStyleString(props.theme["text_00_reg"])};
  color: ${props => props.theme["text_04"].light};

  span:nth-child(1), span:nth-child(2), span:nth-child(3) {
    ${props => getStyleString(props.theme["shortcut_text_tag"])};
    padding: 2px 4px;
    border-radius: 3px;
    border-bottom: 1px solid ${(props) => props.theme["border_02"]};
    background: ${props => props.theme["ui_03"]};
    color: ${props => props.theme["text_03"].light};
    margin-right: 4px;
  }
`;

export const HeaderDescription = styled.div`
  ${props => getStyleString(props.theme["text_01_reg"])};
  color: ${props => props.theme["text_03"].light};
  margin-top: 16px;
`;

export const Lists = styled.div`
  height: calc(100% - ${props => props.fixedHeight}px);
  overflow: auto;
  box-sizing: border-box;
  padding: 0px 44px 0px 20px;
`;