import React, { Component } from 'react';
import "./Loader.css";
import circularLoader from "../../images/circular-loader.gif";
import pageLoader from "../../images/page-loader.gif";

import {
    Shimmer as StyledShimmer
} from "./styled-components";

function Loader(props) {
    function getLoader(type) {
        switch(type) {
            case "circular":
                return (
                    <div className="circular-loader">
                        <img alt="" src={circularLoader} />
                    </div>
                );
            case "circularMonoColor":
                let loaderStyle = {};

                if(props.color) {
                    loaderStyle = {
                        borderTop: `1px solid ${props.color}`,
                        borderRight: `1px solid ${props.color}`,
                        borderBottom: `1px solid ${props.color}`
                    };
                }
                return (
                    <div style={loaderStyle} className="circular-mono-color"></div>
                );
            case "tick-spinner":
                return (
                    <div className="tick-spinner">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                );
            case "blue-dots":
                return (
                    <div className="blue-dots-loader">
                        <img alt="" src={pageLoader} />
                    </div>
                );
            case "shimmer":
                return (
                    <StyledShimmer height={props.height || 160} width={props.width}>
                        <div className="shimmer-back"></div>
                        <div className="shimmer-top"></div>
                    </StyledShimmer>
                );
            default:
                return <div className="loader"></div>
        }
    }

    const loaderClasses = "loader-div" + (props.isModal ? " modal" : "");

    return (
        <div className={loaderClasses}>
            <div className="loader-content">
                {getLoader(props.type)}
                {((props.text !== undefined) && (props.text.length !== 0)) ?
                    <div className="loader-text">
                        {props.text}
                    </div> : null
                }
            </div>
        </div>
    );
}

export default Loader;
