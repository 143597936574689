import styled from 'styled-components'
import {getStyleString} from '../../../../../utils/styleUtils'

export const ShowHidePassword = styled.span`
    ${({theme, disabled}) => (
        `
            color: ${theme['text_04'].light};
            ${getStyleString(theme['text_01_med'])};
            position: absolute;
            right: 16px;
            cursor:pointer;

            ${disabled ? `
                color: ${theme['text_04'].light} !important;
                pointer-events: none;
            ` : ""}

            &:hover {
                color: ${disabled ? theme[ 'text_04'].light : theme['ui_interactive']};
            }
        `
    )}
`

export const InputContainer = styled.div`
    ${({theme, kind, error}) => (
        `   
            background-color: white;
            border-radius: 4px;
            height: 48px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left:20px;
            border: 1px solid ${error ? theme['ui_interactive_negative'] : theme['border_02']};
            color: ${theme['text_01'].light};

            &:focus-within {
                border: 1px solid ${theme['ui_focus']}!important;
            };

            &:focus-within ${ShowHidePassword} {
                color: ${theme['ui_interactive']};
            }

            input {
                width: calc(100% - 32px);
                border-radius: 2px;
                background-color: white;
                font-family: 'Inter', sans-serif;
                font-size: 16px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 24px;
                letter-spacing: normal;
                color: #636e72;
                outline: none;
                border: none;
                padding: 0px;
                
                ${getStyleString(theme['text_01_med'])};
                &::placeholder{
                    color: ${theme['text_04'].light};
                    ${getStyleString(theme['text_01_med'])};
                };
                &::-webkit-input-placeholder {
                    color: ${theme['text_04'].light};
                    ${getStyleString(theme['text_01_med'])};
                };
                color: ${theme['text_01'].light}!important;
            }

            input:hover {
                outline: none!important;
                border: none!important;
            }

            ${kind === 'password' ? `input {
                width: calc(100% - 70px);
            }`: ''}
        `
    )
    }
`


export const ShowErrorIcon = styled.span`
    ${({theme}) => (
        `
            path{
                fill: ${theme['icon_interactive_negative']}
            }
            position: absolute;
            right: 16px;
            height: 20px;
        `
    )}
`
