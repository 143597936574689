import React, { useState, useEffect } from "react";
import { useTheme } from 'styled-components';

// styled components
import {
    Container as StyledContainer,
    Title as StyledTitle,
    SubTitle as StyledSubTitle,
} from "./styled-components";


function AnswerTitle(props) {
    return <StyledContainer>
        {props.title ?
            <StyledTitle>{props.title}</StyledTitle>
            : null
        }
        {props.subTitle ?
            <StyledSubTitle>{props.subTitle}</StyledSubTitle>
            : null
        }
    </StyledContainer>
    
}

export default AnswerTitle;