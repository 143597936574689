import axios from 'axios'; 
import {getCookie, setCookie, deleteAllCookies} from  "./utilityFunctions.js";
import SessionService from  "../services/session.service.js";

let clientID = "WEB_APP",
    clientSecret = "VzagO+ufWljKCWHfY1a+0uR8Vek=";

class httpRequestService {
    constructor() {
        axios.defaults.timeout  = 1000 * 60 * 10;
        axios.defaults.headers["X-CUDDLE-OriginGroup"] = "USER";
    }

    promiseErrorHandler = (error, reject, funcRef) => {
        let timeoutCallback = this.timeoutCallback;
        if(error && error.response && (error.response.status === 401 || error.response.statusText === "Unauthorized")) {
            // this.getNewToken(() => {
            //     funcRef();
            // });
        } else {
            if((error.code === 0 || (error.message && error.message.indexOf("timeout") > -1) || error.code === 'ECONNABORTED') && timeoutCallback) {
                console.log(error.code, "error.code === 'ECONNABORTED'");
                timeoutCallback();
            }
            
            reject(error && error.response ? error.response : error);
        }
    }

    serailize = (obj) => {
        let str = [];
        for (let p in obj)
          if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
        return str.join("&");
    }

    getToken = (type) => {
        let token = getCookie(type);
        if(!token) {
            this.logout("system_init_sto");
        }
        return token;
    }

    getProjectID = () => {
        let currentProject = SessionService.getItem("currentProject");
        let projectID;
        if(currentProject) {
            projectID = JSON.parse(currentProject).id;
        } 

        if(!projectID) {
            projectID = getCookie("projectID");
        }

        return projectID;
        // return projectID || "cuddle_bauer";
    }

    getNewToken = (successHandler, errorHandler) => {
        let projectID = this.getProjectID();
        let axiosConfig = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    "X-ProjectID": projectID,
                    "x-cuddle-userid":  getCookie("userID")
                },
                auth: {
                    username: clientID,
                    password: clientSecret
                }
            },
            data = {
                grant_type: "refresh_token",
                refresh_token: getCookie("refreshtoken")
            },
            url = "/login/token";
    
        axios.post(url, this.serailize(data), axiosConfig)
            .then((response) => {
                setCookie({
                    "accesstoken": response.data.accesstoken,
                    "refreshtoken": response.data.refreshtoken 
                });
                if(getCookie("accesstoken") === response.data.accesstoken) {
                    console.log("access token updated successfully");
                }
                if(getCookie("refreshtoken") === response.data.refreshtoken) {
                    console.log("refresh token updated successfully");
                }
                successHandler();
            })
            .catch((error) => {
                this.logout("system_init_sto");
                console.log("[get] Error during get new token request", error);
            });
    }

    getAuthToken = (url, data, successHandler, errorHandler) => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            auth: {
                username: clientID,
                password: clientSecret
            }
        };
    
        axios.post(url, this.serailize(data), axiosConfig)
            .then((response) => {
                successHandler(response.data);
            })
            .catch((error) => {
                errorHandler(error.response);
                console.log("[get] Error during get request", error);
            });
    }

    setTimeoutCallback = (callbackFunction) => {
        this.timeoutCallback = callbackFunction;
    }

    logout = () => {
        // let userConfigAPI = new dataAPI(),
        // tracksAPI = new dataAPI(),
        // notificationsAPI = new dataAPI();
        // deleteAllCookies();
        // sessionStorage.clear();
        // Promise.all([userConfigAPI.destroyByName(new pouch(), "user-config", () => {
        //     }), tracksAPI.destroyByName(new pouch(), "tracks", () => {
        //     }), notificationsAPI.destroyByName(new pouch(), "notifications", () => {
        // })])
        // .then(values => { 
        //     window.location.reload();
        // });
    }

    getWithoutToken = (url, successHandler, errorHandler) => {
        axios.get(url, { 
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                } 
            })
            .then(response => {
                successHandler(response.data);
            })
            .catch((error) => {
                errorHandler(error);
                console.log("[getWithoutToken] Error during get request: ", error);
            });
    }

    getWithoutToken = (url) => {
        return axios.get(url, { 
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                } 
            });
    }

    getR = (url, resolve, reject, customHeaders, config={}) => {
        const CancelToken = axios.CancelToken;
        const accesstoken = this.getToken("accesstoken");
        const projectID = this.getProjectID();
        let cancel;
        let that = this;
        let configs = { 
            ...config,
            headers: { 
                "Authorization": `bearer ${accesstoken}`,
                "X-ProjectID": projectID,
                "x-cuddle-userid":  getCookie("userID")
            },
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c;
            })
        };


        console.log(configs, "configs")
        if(customHeaders) {
            configs.headers = Object.assign({}, configs.headers, customHeaders);
        }

        axios.get(url, configs)
            .then(function(response) {
                resolve(response);
            })
            .catch(function(thrown) {
                if(axios.isCancel(thrown)) {
                    console.log('[Get] Request canceled', thrown.message);
                    reject({
                        isCancelled: true,
                        message: thrown.message
                    });
                } else {
                    console.log("[Get] Error during get request", thrown);
                    that.promiseErrorHandler(thrown, reject, that.getR.bind(that, url, resolve, reject));
                }
            });

        return cancel;
    }

    get = (url, customHeaders, config) => {
        let requestPromise,
            cancel,
            that = this;

        requestPromise = new Promise(function(resolve, reject) {
            cancel = that.getR(url, resolve, reject, customHeaders, config);
        });

        return {
            promise: requestPromise,
            cancel
        };
    }

    postR = (url, data, textData, resolve, reject, customHeaders, responseType) => {
        const CancelToken = axios.CancelToken;
        const projectID = this.getProjectID();
        const accesstoken = this.getToken("accesstoken");
        console.log("postR", projectID);
        let cancel;
        let that = this;
        let configs = {
            headers: {
                "X-ProjectID": projectID,
                "Authorization": `bearer ${accesstoken}`,
                "x-cuddle-userid":  getCookie("userID")
            },
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c;
            }),
            responseType
        };

        if(textData) {
            configs.headers['Content-Type'] = "text/plain";
        }

        if(customHeaders) {
            configs.headers = Object.assign({}, configs.headers, customHeaders);
        }
        
        axios.post(url, data || {}, configs)
            .then(function(response) {
                resolve(response);
            })
            .catch(function(thrown) {
                if(axios.isCancel(thrown)) {
                    console.log('[Post] Request canceled', thrown.message);
                    reject({
                        isCancelled: true,
                        message: thrown.message
                    });
                } else {
                    console.log("[Post] Error during post request", thrown);
                    if(thrown && thrown.response && thrown.response.data && thrown.response.data.reason === "Invalid login") {
                        reject(thrown.response);
                    } else {
                        // this.errorHandlerFunc(error, errorHandler, this.post.bind(this, url, data, successHandler, errorHandler, textData));
                        that.promiseErrorHandler(thrown, reject, that.postR.bind(that, url, data, textData, resolve, reject));
                    }
                }
            });

        return cancel;
    }

    post = (url, data, textData, customHeaders, responseType) => {
        console.log(customHeaders, "customHeaders");
        let requestPromise,
            cancel,
            that = this;

       
        requestPromise = new Promise(function(resolve, reject) {
            cancel = that.postR(url, data, textData, resolve, reject, customHeaders, responseType);
        });

        return {
            promise: requestPromise,
            cancel
        };
    }

    putR = (url, data, textData, resolve, reject) => {
        const CancelToken = axios.CancelToken;
        const projectID = this.getProjectID();
        let cancel;
        let that = this;
        let configs = {
            headers: {
                "X-ProjectID": projectID,
                "Authorization": `bearer ${this.getToken("accesstoken")}`,
                "Content-Type": "application/json",
                "x-cuddle-userid":  getCookie("userID")
            },
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c;
            })
        };

        if(textData) {
            configs.headers['Content-Type'] = "text/plain";
        }

        axios.put(url, data, configs)
            .then(function(response) {
                resolve(response);
            })
            .catch(function(thrown) {
                if(axios.isCancel(thrown)) {
                    console.log('[Put] Request canceled', thrown.message);
                    reject({
                        isCancelled: true,
                        message: thrown.message
                    });
                } else {
                    console.log("[Put] Error during put request", thrown);
                    that.promiseErrorHandler(thrown, reject, that.putR.bind(that, url, data, textData, resolve, reject));
                }
            });

        return cancel;
    }

    put = (url, data, textData) => {
        let requestPromise,
            cancel,
            that = this;

        requestPromise = new Promise(function(resolve, reject) {
            cancel = that.putR(url, data, textData, resolve, reject);
        });

        return {
            promise: requestPromise,
            cancel
        };
    }

    deleteR = (url, data, textData, resolve, reject) => {
        const CancelToken = axios.CancelToken;
        let that = this;
        const projectID = this.getProjectID();
        const accesstoken = this.getToken("accesstoken");
        let cancel;
        let configs = {
            headers: {
                "X-ProjectID": projectID,
                "Authorization": `bearer ${accesstoken}`,
                "x-cuddle-userid":  getCookie("userID")
            },
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c;
            })
        };

        if(textData) {
            configs.headers['Content-Type'] = "text/plain";
        }

        // data is not accepted
        if(data) {
            configs.data = data;
        }

        axios.delete(url, configs)
            .then(function(response) {
                resolve(response);
            })
            .catch(function(thrown) {
                if(axios.isCancel(thrown)) {
                    console.log('[Delete] Request canceled', thrown.message);
                    reject({
                        isCancelled: true,
                        message: thrown.message
                    });
                } else {
                    console.log("[Delete] Error during delete request", thrown);
                    that.promiseErrorHandler(thrown, reject, that.deleteR.bind(that, url, data, textData, resolve, reject));
                }
            });

        return cancel;
    }

    delete = (url, data, textData) => {
        let requestPromise,
            cancel,
            that = this;

        requestPromise = new Promise(function(resolve, reject) {
            cancel = that.deleteR(url, data, textData, resolve, reject);
        });

        return {
            promise: requestPromise,
            cancel
        };
    }

    postFormData = (url, data, resolve, reject) => {
        const CancelToken = axios.CancelToken;
        let that = this;
        const projectID = this.getProjectID();
        let cancel;

        let configs = {
            headers: {
                "X-ProjectID": projectID,
                "Authorization": `bearer ${this.getToken("accesstoken")}`,
                "x-cuddle-userid":  getCookie("userID")
            },
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c;
            })
        };

        axios.post(url, data || {}, configs)
            .then(function(response) {
                resolve(response);
            })
            .catch(function(thrown) {
                if(axios.isCancel(thrown)) {
                    console.log('[postFormData] Request canceled', thrown.message);
                    reject({
                        isCancelled: true,
                        message: thrown.message
                    });
                } else {
                    console.log("[postFormData] Error during postFormData request", thrown);
                    that.promiseErrorHandler(thrown, reject, that.postFormData.bind(that, url, data, resolve, reject));
                }
            });

        return cancel;
    }

    getRequestWithoutToken = (url, successHandler, errorHandler) => {
        axios.get(url, { 
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                } 
            })
            .then(response => {
                successHandler(response.data);
            })
            .catch((error) => {
                errorHandler(error);
                console.log("[getRequestWithoutToken] Error during get request: ", error);
            });
    }
};

export default new httpRequestService();