import styled from "styled-components";
import { getStyleString } from "../../../../../utils/styleUtils";

interface containerProps {
    height: number;
}

export const Container = styled.div<containerProps>`
    border-radius: 8px;
    height: ${props => props.height}px;
    background: ${props => props.theme["support_error_highlight"]};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Emoji = styled.div`
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const Text = styled.div`
    margin-top: 20px;
    color: ${props => props.theme["text_02"].light};
    ${props => getStyleString(props.theme["text_02_med"])};
`;