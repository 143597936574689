import React from "react";
import { useAppSelector } from '../../../../../stores/redux-hooks';
import SessionItem from "./session-item/SessionItem";
import { TEMP_SESSION_NAME } from "../../../../../reducers/chat-session-slice";

import {
  Container as StyledContainer,
  Period as StyledPeriod,
  List as StyledList,
  ListItem as StyledListItem
} from "./styled-components.js";

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function SessionList(props) {
  const activeSession = useAppSelector((state) => state.chat.activeSession);
  let periodText;

  if (props.period) {
    const periodObj = new Date(props.period);
    periodText = `${periodObj.getDate()} ${
      MONTHS[periodObj.getMonth()]
    } ${periodObj.getFullYear()}`;
  }
  
  return (
    <StyledContainer>
      {periodText ? (
        <StyledPeriod>{periodText}</StyledPeriod>
      ) : null}
      <StyledList>
        {props.data.map((datum, index) => {
          if(datum) {
            return (
              <StyledListItem key={datum.conversationId}>
                <SessionItem key={datum.created}
                  data={datum}
                  isactive={activeSession === datum.conversationId}
                  fromComponent={"Side Panel"}
                  hideDelete={datum.conversationId === TEMP_SESSION_NAME}
                />
              </StyledListItem>
            );
          }
          return null;
        })}
      </StyledList>
    </StyledContainer>
  );
}

export default SessionList;
