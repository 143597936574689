import React, { useState } from "react";
import {Button, ButtonType} from '@cuddle-dev/web-components';
import AssistanceSection from "./assistance-section/AssistanceSection";
import {RESPONSE_TYPES} from "../../../../constants";

//styled-components
import {
  Container as StyledContainer,
  Header as StyledHeader,
  HeaderTitle as StyledHeaderTitle,
  Body as StyledBody,
  Footer as StyledFooter,
  FooterActions as StyledFooterActions,
  FooterMessage as StyledFooterMessage
} from "./styled-components";

function Assistance(props) {
  const [sectionData, setSectionData] = useState([]);

  function submitClickHandler() {
    console.log("[Assistance] submitClickHandler");
    const data = props.data;
    const assistanceData = data.data[RESPONSE_TYPES.assistance];
    const newFields = assistanceData.assistParameters.parameters.fields.map((field, index) => {
      const keys = Object.keys(field);
      const newFieldObj = {};
      const selectedData = sectionData[index];
      
      keys.forEach(key => {
        if(field[key].type === "BUSINESS_OBJECT" || field[key].type === "MEASURE") {
          newFieldObj[key] = {
            ...field[key],
            values: [selectedData[key].value.originalData]
          };
        } else if(field[key].type === "TEXT") {
          newFieldObj[key] = {
            ...field[key],
            values: [{
              output: selectedData[key].value
            }]
          };
        } else {
          // newFieldObj[key] = field[key];
        }
      });

      return newFieldObj;
    });

    const assistanceRequest = {
      "messageId": data.messageId,
      "chatId": data.chatId,
      "conversationId": data.conversationId,
      "userInfo": data.userInfo,
      "type": "REQUEST",
      "searchSpace": "ENTERPRISE",
      "requestType": "ASSISTED",
      "data": {
        "ASSISTANCE": {
          "passThrough": assistanceData.passThrough,
          "assistParameters": {
            "parameters": {
              "fields": newFields
            }
          }
        }
      },
      "visible": data.visible,
      "additionalInfo": data.additionalInfo,
      "messages": data.messages,
      "created": data.created,
      "updated": data.updated,
      "projectId": data.projectId
    }
    props.assistanceSubmitHandler(assistanceRequest);
  }

  function cancelClickHandler() {
    console.log("[Assistance] cancelClickHandler");
    const data = props.data;

    const assistanceRequest = {
      "parentMessageId": data.messageId,
      "version": "1",
      "chatId": data.chatId,
      "conversationId": data.conversationId,
      "userInfo": data.userInfo,
      "type": "REQUEST",
      "requestType":"CONVERSATION",
      "userAction":"CANCELED",
      "messages": {
        "error": [
          {
            "code": "499",
            "message": "Assistance canceled by user",
            "displayMessage": "There was an error in rendering this response as we did not get all the required inputs from you."
          }
        ]
      }
    };
    
    props.assistanceCancelHandler(assistanceRequest);
  }

  // console.log("Assistance data: ", props.data);
  const assistanceData = props.data.data[RESPONSE_TYPES.assistance];
  const fields = assistanceData.assistParameters.parameters.fields;

  function updateSectionData(index, data) {
    const newData = [...sectionData];
    newData[index] = data;
    setSectionData(newData);
  }

  let enableSubmit = true;

  fields.forEach((field, index) => {
    if(!sectionData[index]) {
      enableSubmit = false;
    }
  });

  return <StyledContainer>
    <StyledHeader>
      <StyledHeaderTitle>⏳ Need your input to proceed further...</StyledHeaderTitle>
    </StyledHeader>
    <StyledBody>
      {fields.map((field, index) => {
        // return null;
        return <AssistanceSection key={index} data={field} sectionIndex={index} updateSectionData={updateSectionData} />
      })}
    </StyledBody>
    <StyledFooter>
      <StyledFooterActions> 
        <Button text={"Submit"}
          buttonType={ButtonType.primary}
          onClick={submitClickHandler}
          style={{ width: "248px" }}
          disabled={!enableSubmit}
        />
        <Button text={"Cancel"}
          buttonType={ButtonType.tertiary}
          onClick={cancelClickHandler}
          style={{ width: "248px", marginLeft: "16px" }}
        />
      </StyledFooterActions>
      <StyledFooterMessage>
        The inputs you provide will help us in enhancing the experience & providing accurate results to you.
      </StyledFooterMessage>
    </StyledFooter>
  </StyledContainer>
}

export default Assistance;
