import React, { useState } from 'react';
// import { ChatError as StyledChatError } from "./styled-components";
import {ContextualMessageBar} from "@cuddle-dev/web-components";

function ChatError(props) {
    // Declare a new state variable, which we'll call "count"
    // const [text, setText] = useState(props.text);
  // console.log(props.type, "type");
    return (
      <ContextualMessageBar 
        type={props.type || "error"}
        feedback={props.text}
        width="full">
      </ContextualMessageBar>
    );
}

export default ChatError;