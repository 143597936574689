import styled from "styled-components";
import { getStyleString } from "../../../../../utils/styleUtils";

export const Container = styled.div`
`;

export const Description = styled.div`
    margin-top: 12px;
    color: ${props => props.theme["text_02"].light};
    ${props => getStyleString(props.theme["text_02_med"])};
`;

export const SQL = styled.div`
    margin-top: 16px;
`;