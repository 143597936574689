import React from "react";

import {
    Container as StyledContainer
} from "./styled-components.js";

function AvailableDataAns({transactionData, theme}) {
    return <StyledContainer theme={theme}>
        {transactionData && transactionData.length ? 
            <React.Fragment>
                <div>You've access to the following transactions</div>
                {transactionData.map(t => <div key={t.name}>{t.displayName}</div>)}
                <br />
            </React.Fragment>
            : null
        }
        <div>Cmd + / or Ctrl / to view more details on the transaction</div>
    </StyledContainer>
}

export default AvailableDataAns;