import React, { useState, useEffect } from "react";
import { useTheme } from 'styled-components';
import { SVGIcon, Modal } from "@cuddle-dev/web-components";
import Transparency from "./transparency/Transparency";

// styled components
import {
    Container as StyledContainer,
    Transparency as StyledTransparency,
    TransparencyText as StyledTransparencyText,
    Remove as StyledRemove,
    RemoveIcon as StyledRemoveIcon,
    RemoveText as StyledRemoveText,
    Date as StyledDate,
    Separator as StyledSeparator
} from "./styled-components";

// images
import { ReactComponent as QuestionIcon } from "../../../images/question_20.svg";
import { ReactComponent as TrashIcon } from "../../../images/trash_20.svg";
import { func } from "prop-types";

function AnswerFooter(props) {
    const theme = useTheme();
    const [showTranparencyModal, setShowTranparencyModal] = useState(false);

    function transparencyClickHandler(event) {
        setShowTranparencyModal(true);
    }

    function hideTransparencyModal() {
        setShowTranparencyModal(false);
    }

    function testClick() {
        console.log("testClick");
    }

    return <StyledContainer className="answer-footer">
        {props.chatDate ? <StyledDate>{props.chatDate}</StyledDate> : null}
        {!props.hideTransparency ?
            <>
                {props.chatDate ? <StyledSeparator>|</StyledSeparator> : null}
                <StyledTransparency onClick={transparencyClickHandler}>
                    <SVGIcon Icon={QuestionIcon} width={24} height={24} fill={theme["icon_02"].light}/>
                    <StyledTransparencyText>How did we arrive at this?</StyledTransparencyText>
                </StyledTransparency> 
            </>
            : null 
        }
        {!props.hideRemoveAnswer ? 
            <StyledRemove className="remove-answer" onClick={props.removeClickHandler}>
                <StyledRemoveIcon>
                    <SVGIcon Icon={TrashIcon} width={24} height={24} fill={theme["icon_interactive_error"]}/>
                </StyledRemoveIcon>
                <StyledRemoveText onClick={props.onDelete}>Remove Answer</StyledRemoveText>
            </StyledRemove> 
            : null
        }
        {showTranparencyModal && props.transparencyData ?
            <Modal parentId="conversation_pane_content"
                content={<Transparency data={props.transparencyData} closeModal={hideTransparencyModal} />}
                closeModal={hideTransparencyModal}
                className="transparency-modal"
            />
            : null
        }
    </StyledContainer>
}

export default AnswerFooter;