import React from "react";
import { useTheme } from 'styled-components';
import SectionTitle from "../section-title/SectionTitle";
import MarkdownReader from "../../../../markdown-reader";
import { getStyleString } from "../../../../../utils/styleUtils";
import SQLQuery from "./sql-query/SQLQuery";

import {
    Container as StyledContainer,
    Description as StyledDescription,
    SQL as StyledSQL
} from "./styled-components";

function BasicSection(props) {
    const theme = useTheme();

    return <StyledContainer>
        <SectionTitle title={props.data.label} info={props.data.info}/>
        {/* <StyledDescription>{props.data.description}</StyledDescription> */}
        <MarkdownReader markdownText={props.data.description} customStyle={`
            color: ${theme["text_02"].light};
            ${getStyleString(theme["text_02_med"])};
        `} />
        {props.sqlData?.query ?
            <StyledSQL>
                <SQLQuery data={props.sqlData} />
            </StyledSQL>
            : null
        }
    </StyledContainer>
}

export default BasicSection;