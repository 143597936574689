import React, { useEffect, useRef, useState, MutableRefObject } from "react";
import { useTheme } from 'styled-components';
import Loader from "../../../../../components/loader/Loader";
import { SVGIcon, Tooltip } from "@cuddle-dev/web-components";
import ErrorCard from "../error-card/ErrorCard";
import {capitalizeFirstLetter} from "../../../../../utils/utilityFunctions";

// styled components
import {
    Container as StyledContainer,
    Loader as StyledLoader,
    Details as StyledDetails,
    Prompts as StyledPrompts,
    Model as StyledModel,
    Temperature as StyledTemperature,
    DetailLabel as StyledDetailLabel,
    DetailLabelText as StyledDetailLabelText,
    DetailValue as StyledDetailValue,
    Title as StyledTitle,
    Prompt as StyledPrompt,
    CopyButton as StyledCopyButton,
    CopyIcon as StyledCopyIcon,
    CopyText as StyledCopyText,
    Info as StyledInfo,
    InfoTooltip as StyledInfoTooltip,
    Error as StyledError
} from "./styled-components";

// services
import ChatSessionService from "../../../../../services/chat-session.service.js";

// images
import { ReactComponent as CopyIcon } from "../../../../../images/copy-24.svg";
import { ReactComponent as TickIcon } from "../../../../../images/tick_20.svg";
import { ReactComponent as InfoIcon } from "../../../../../images/info_20.svg";

// constants
const PROMPT_TITLE = {
    system: "System Message",
    human: "Human Message",
};
const MODEL_TOOLTIP = "This specifies the LLM model and the version used to generate this output.";
const TEMP_TOOLTIP = "Temperature controls the language of the generated summary. A lower value would result in a predictable, more focused summary while the higher value would generate unique summaries but at the cost of accuracy.";

function PromptSection(props) {
    const theme = useTheme();
    const [promptsData, setPromptsData] = useState<any>();
    const [promptError, setPromptError] = useState(false);
    const [copiedPrompt, setCopiedPrompt] = useState({});

    useEffect(() => {
        const promptIdData = props.prompts[0];

        if(promptIdData.promptId) {
            if(props.propmptsData[promptIdData.promptId]) {
                setPromptsData(props.propmptsData[promptIdData.promptId]);
            } else {
                ChatSessionService.getPrompt({id: promptIdData.promptId})
                    .promise.then((response) => {
                        console.log("response: ", response);
                        props.handlePromptFetch(response.data, promptIdData.promptId);
                        setPromptsData(response.data);
                    })
                    .catch((error) => {
                        console.log("Error while fetching prompt data. ", error);
                        setPromptError(true);
                    });
            }
        }
    }, []);

    function handleCopyClick(promptType) {
        const copiedData = promptsData.prompts.filter(x => x.type === promptType)[0];
        navigator.clipboard.writeText(copiedData.content);
        setCopiedPrompt(promptType);
    }

    function getTitle(promptType) {
        return PROMPT_TITLE[promptType] || capitalizeFirstLetter(promptType);
    }

    const maxContainerHeight = window.innerHeight - 60 - 152;
    const padding = 48;
    const contentHeight = maxContainerHeight - props.headerHeight - padding;
    const titleLoaderHeight = 20 + 26;
    const promptSectionHeight = contentHeight - 22 - 12;
    
    return <StyledContainer maxHeight={promptSectionHeight}>
        {promptsData ?
            <>
                <StyledDetails>
                    <StyledModel>
                        <StyledDetailLabel>
                            <StyledDetailLabelText>model used</StyledDetailLabelText>
                            <StyledInfo>
                                <SVGIcon Icon={InfoIcon} width={20} height={20} fill={theme["icon_03"].light}/>
                                <StyledInfoTooltip>
                                    <Tooltip style={{position: "relative"}} 
                                        text={MODEL_TOOLTIP}
                                    />
                                </StyledInfoTooltip>
                            </StyledInfo>
                        </StyledDetailLabel>
                        <StyledDetailValue>{promptsData.config.model_name}</StyledDetailValue>
                    </StyledModel>
                    <StyledTemperature>
                        <StyledDetailLabel>
                            <StyledDetailLabelText>temperature</StyledDetailLabelText>
                            <StyledInfo>
                                <SVGIcon Icon={InfoIcon} width={20} height={20} fill={theme["icon_03"].light}/>
                                <StyledInfoTooltip>
                                    <Tooltip style={{position: "relative"}} 
                                        text={TEMP_TOOLTIP}
                                    />
                                </StyledInfoTooltip>
                            </StyledInfo>
                        </StyledDetailLabel>
                        <StyledDetailValue>{promptsData.config.temperature}</StyledDetailValue>
                    </StyledTemperature>
                </StyledDetails>
                <StyledPrompts>
                    {promptsData.prompts.map(promptDatum => {
                        const promptType = promptDatum.type;
                        return (
                            <div key={promptType}>
                                <StyledTitle>{getTitle(promptType)}</StyledTitle>
                                <StyledPrompt>
                                    <StyledCopyButton clickable={!copiedPrompt}
                                        onClick={copiedPrompt === promptType ? null : handleCopyClick.bind(this, promptType)}
                                    >
                                        <StyledCopyIcon>
                                            <SVGIcon Icon={copiedPrompt === promptType ? TickIcon : CopyIcon} width={20} height={20} fill={theme["icon_02"].light}/>
                                        </StyledCopyIcon>
                                        <StyledCopyText>{copiedPrompt === promptType ? "Copied" : "Copy"}</StyledCopyText>
                                    </StyledCopyButton>
                                    <div>{promptDatum.content}</div>
                                </StyledPrompt>
                            </div>
                        );
                    })}
                </StyledPrompts>
            </>
            : promptError ?
                <StyledError>
                    <ErrorCard height={promptSectionHeight - 48}/>
                </StyledError>
                : <StyledLoader>
                    <Loader type="shimmer" height={20} width={380}/>
                    <Loader type="shimmer" height={contentHeight - titleLoaderHeight} />
                </StyledLoader>
        }
    </StyledContainer>
}

export default PromptSection;