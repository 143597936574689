import React, { useRef, useState, useEffect } from "react";

// import Icons, { IconWrapper } from "@cuddle-dev/test-web-ui-lib/dist/icons";
// import Tooltip from "@cuddle-dev/test-web-ui-lib/dist/tooltip";
import {Tooltip, SVGIcon} from '@cuddle-dev/web-components';
// import analyticsModule from "@cuddle-dev/test-common-web-utils/dist/utils/analytics";
import { useTheme } from 'styled-components';
import analyticsModule from "../../../../utils/analytics.js";

// Styled components
import {
    Container as StyledContainer,
    Section as StyledSection,
    SectionTitle as StyledSectionTitle,
    Transaction as StyledTransaction,
    TransactionTitle as StyledTransactionTitle,
    List as StyledList,
    More as StyledMore,
    ListItem as StyledListItem,
    ItemText as StyledItemText,
    Actions as StyledActions,
    InfoIcon as StyledInfoIcon,
    InfoTooltip as StyledInfoTooltip,
    PasteIcon as StyledPasteIcon
} from "./styled-components.js";

// images
import { ReactComponent as PasteIcon } from "../../../../images/paste.svg";
import { ReactComponent as TickIcon } from "../../../../images/tick_20.svg";
import { ReactComponent as InfoIcon } from "../../../../images/info_20.svg";

function ListItem({data, listRef}) {
    const theme = useTheme();
    const itemRef = useRef();
    const tooltipRef = useRef();
    const [copied, setCopied] = useState(false);
    const [showActions, setShowActions] = useState(false);
    const [willOverflow, setWillOverflow] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState();

    useEffect(() => {
        const itemBounds = itemRef && itemRef.current.getBoundingClientRect();
        const listBounds = listRef && listRef.current.getBoundingClientRect();
        const itemRight = itemBounds.left + itemBounds.width;
        const listRight = listBounds.left + listBounds.width;
        const comparisonValue = data.description ? 44 : 24;

        if(itemRight + comparisonValue >= listRight) {
            setWillOverflow(true);
        }
    }, []);

    useEffect(() => {
        if(tooltipRef && tooltipRef.current && !tooltipPosition) {
            const tooltipBounds = tooltipRef && tooltipRef.current.getBoundingClientRect();
            const listBounds = listRef && listRef.current.getBoundingClientRect();
            const tooltipRight = tooltipBounds.left + tooltipBounds.width;
            const listRight = listBounds.left + listBounds.width;
            let left = 0;

            if(tooltipRight > listRight) {
                left = tooltipRight - listRight;
            } else if(tooltipBounds.left < listBounds.left) {
                left = tooltipBounds.left - listBounds.left;
            }

            setTooltipPosition({
                left
            });
        }
    });

    function copyText() {
        // analyticsModule.trackEvent("Available Data", {
        //     "Actions": "Copy"
        // });
        navigator.clipboard.writeText(data.displayName);
        setCopied(true);
        analyticsModule.trackEvent("Available Data", {
            "Actions": "Copy"
        });
    }

    function onItemMouseOut(event) {
        if(itemRef &&
            itemRef.current &&
            !itemRef.current.contains(event.relatedTarget)
        ) {
            if(copied) {
                setCopied(false);
            }
            setShowActions(false);
        }
    }

    function onItemMouseOver() {
        if(!showActions) {
            setShowActions(true);
        }
    }
    
    return (
        <StyledListItem ref={itemRef} 
            key={data.name} 
            theme={theme} 
            onMouseOut={onItemMouseOut}
            onMouseOver={onItemMouseOver}
        >
            <StyledItemText theme={theme} >{data.displayName}</StyledItemText>
            {showActions ?
                <StyledActions className="ad__item-actions" 
                    theme={theme} 
                    floating={willOverflow} 
                    withInfo={!!data.description}
                >
                    {data.description ? 
                        <StyledInfoIcon theme={theme}>
                            <SVGIcon Icon={InfoIcon} width={20} height={20} fill={theme["icon_03"].light}/>
                            <StyledInfoTooltip ref={tooltipRef} tooltipPosition={tooltipPosition} className="ad__entity-info-tooltip">
                                <Tooltip
                                    style={{ position: "relative" }}
                                    text={data.description}
                                />
                            </StyledInfoTooltip>
                        </StyledInfoIcon> 
                        : null
                    }
                    <StyledPasteIcon theme={theme} onClick={copyText}>
                        <SVGIcon Icon={copied ? TickIcon : PasteIcon} width={20} height={20} fill={theme["icon_02"].light}/>
                    </StyledPasteIcon>
                </StyledActions>
                : null
            }
        </StyledListItem>
    );
}

function Transaction({theme, entities, name}) {
    const listRef = useRef();
    const [showHiddenEntities, setShowHiddenEntities] = useState(false);
    let visibleEntities;
    let hiddenEntities;

    if(entities.length > 10 && !showHiddenEntities) {
        visibleEntities = entities.slice(0, 10);
        hiddenEntities = entities.slice(10);
    } else {
        visibleEntities = [...entities];
    }

    function moreClickHandler() {
        setShowHiddenEntities(true);
    }

    return <StyledTransaction ref={listRef}>
        {name ? <StyledTransactionTitle theme={theme}>{name}</StyledTransactionTitle> : null}
        <StyledList>
            {visibleEntities.map(entity => <ListItem key={entity.name} theme={theme} data={entity} listRef={listRef}/>)}
        </StyledList>
        {hiddenEntities && !showHiddenEntities ?
            <StyledMore onClick={moreClickHandler} theme={theme}>{`+ ${hiddenEntities.length} more`}</StyledMore>
            : null
        }
    </StyledTransaction>;
}

function EntityLists({
    theme, 
    transactionList, 
    selectedTransaction, 
    selectedEntities, 
    filteredData
}) {
    return <StyledContainer>
        <StyledSection>
            <StyledSectionTitle theme={theme}>Attributes</StyledSectionTitle>
            {filteredData ?
                transactionList.map(t => {
                    if(!filteredData[t.name] || !(filteredData[t.name].attributeList && filteredData[t.name].attributeList.length)) {
                        return null;
                    }

                    return <Transaction key={t.name}
                        theme={theme} 
                        entities={filteredData[t.name].attributeList} 
                        name={t.displayName} 
                    />;
                }) :
                <Transaction theme={theme} entities={selectedEntities.attributeList} /> 
            }
        </StyledSection>
        <StyledSection>
            <StyledSectionTitle theme={theme}>Measures</StyledSectionTitle>
            {filteredData ?
                transactionList.map(t => {
                    if(!filteredData[t.name] || !(filteredData[t.name].measureList && filteredData[t.name].measureList.length)) {
                        return null;
                    }

                    return <Transaction key={t.name}
                        theme={theme} 
                        entities={filteredData[t.name].measureList} 
                        name={t.displayName} 
                    />;
                }) :
                <Transaction theme={theme} entities={selectedEntities.measureList} /> 
            }
        </StyledSection>
    </StyledContainer>;
}

export default EntityLists;