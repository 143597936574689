import styled from "styled-components";
import { getStyleString } from "../../utils/styleUtils";

export const Container = styled.div`
  color: ${(props) => props.theme["text02"]};
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;
