import React, { useEffect, useRef, useState, MutableRefObject } from "react";
import { useAppSelector, useAppDispatch } from '../../../../../../stores/redux-hooks';
import { useTheme } from 'styled-components';
import {SVGIcon, ActionMenu, Dialogbox, Modal, ButtonType} from '@cuddle-dev/web-components';
import analyticsModule from "../../../../../../utils/analytics";
// import Icons, { IconWrapper } from "@cuddle-dev/test-web-ui-lib/dist/icons";

//styled-components
import {
  Session as StyledSession,
  Title as StyledTitle,
  IconContainer as StyledIconContainer,
  Icon as StyledIcon,
  Input as StyledInput,
  MenuIcon as StyledMenuIcon,
  Dropdown as StyledDropdown,
  DialogBoxWrapper as StyledDialogBoxWrapper
} from "./styled-components";

//actions
import {
  setActiveSession,
  deleteSession,
  updateSession,
  TEMP_SESSION_NAME,
} from "../../../../../../reducers/chat-session-slice";
import { set_message as setAppMessage } from "../../../../../../reducers/App.reducer";

//services
import chatSessionService from "../../../../../../services/chat-session.service";

//images
import { ReactComponent as TickIcon } from "../../../../../../images/tick_20.svg";
import { ReactComponent as CloseIcon } from "../../../../../../images/close_20.svg";
import { ReactComponent as PencilIcon } from "../../../../../../images/pencil_20.svg";
import { ReactComponent as TrashIcon } from "../../../../../../images/trash_20.svg";
import { ReactComponent as OverflowIcon } from "../../../../../../images/overflow_20.svg";

function SessionItem(props) {
  const theme = useTheme();
  const { isactive } = props;
  const [isEditable, setIsEditable] = useState(false);
  const [isDeletable, setIsDeletable] = useState(props.isDeletable || false);
  const [inputValue, setInputValue] = useState(props.data?.name);
  const [showDropdown, setShowDropdown] = useState(false);
  const userID = useAppSelector((state) => state.user.details.userID);
  // const selectedPersona = useAppSelector((state) => state.user.persona);
  const activeSession = useAppSelector((state) => state.chat.activeSession);
  const dispatch = useAppDispatch();
  const sessionRef: MutableRefObject<HTMLDivElement> = useRef();
  const backgroundbrightness = theme.backgroundMeta?.interface_background?.colorBrightness || "light";
  const isbackgroundlight = backgroundbrightness === "light";

  useEffect(() => {
    const handleDocumentClick = function (event) {
      console.log("event.target: ", event.target);
      console.log("event.target: ", sessionRef.current, sessionRef.current.contains(event.target));
      if (
        sessionRef &&
        sessionRef.current &&
        !sessionRef.current.contains(event.target)
      ) {
        if (isEditable) {
          setInputValue(props.data.name);
          setIsEditable(false);
        }
      }
    };

    if (isEditable) {
      document.addEventListener("click", handleDocumentClick, false);
    }

    return function cleanup() {
      document.removeEventListener("click", handleDocumentClick, false);
    };
  }, [isEditable]);

  useEffect(() => {
    const handleDocumentClick = function (event) {
      if (
        sessionRef &&
        sessionRef.current &&
        !sessionRef.current.contains(event.target)
      ) {
        if (showDropdown) {
          setShowDropdown(false);
        }
      }
    };

    if (showDropdown) {
      document.addEventListener("click", handleDocumentClick, false);
    }

    return function cleanup() {
      document.removeEventListener("click", handleDocumentClick, false);
    };
  }, [showDropdown]);

  useEffect(() => {
    if(props.data && inputValue !== props.data.name) {
      setInputValue(props.data.name.trim());
    }
  }, [props.data?.name]);

  useEffect(() => {
    if (props.data.conversationId !== activeSession) {
      if(isEditable) {
        setInputValue(props.data.name);
        removeEditable();
      }

      if(isDeletable) {
        setIsDeletable(false);
      }
    }
  }, [activeSession]);

  function removeEditable() {
    setIsEditable(false);
  }

  function clickHandler() {
    if (props.data.conversationId !== activeSession) {
      dispatch(setActiveSession(props.data.conversationId));
      analyticsModule.trackEvent("Conversation Selected", {
        "Conversation ID": props.data.conversationId,
      });
    }

    if (isEditable) {
      setInputValue(props.data.name);
      removeEditable();
    }
  }

  function inputChangeHandler(event) {
    setInputValue(event.target.value);
  }

  function inputKeyDownHandler(event) {
    if (event.key === "Enter" && inputValue) {
      editSession();
    }
  }

  function inputClickHandler(event) {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  }

  function onDelete() {
    dispatch(setAppMessage({
      type: "system",
      message: "Conversation successfully deleted."
    }));
    logDeleteAnalyticsEvent("Successful");
  }

  function onDeleteFailure() {
    logDeleteAnalyticsEvent("Unsuccessful");
  }

  function deleteChatSession() {
    dispatch(deleteSession({
      deleteComplete: onDelete,
      deleteFailed: onDeleteFailure,
      conversationData: props.data
    }));
  }

  function logDeleteAnalyticsEvent(status) {
    analyticsModule.trackEvent("Conversation Deleted", {
      "Conversation ID": props.data.conversationId,
      "Conversation Name": props.data?.name,
      "Status": status
    });
  }

  function editSession() {
    if (!inputValue || !inputValue.trim()) {
      setInputValue(props.data.name);
      removeEditable();
      return;
    }

    if (inputValue !== props.data.name) {
      if(activeSession === TEMP_SESSION_NAME) {
        dispatch(
          updateSession({
            oldSession: { ...props.data },
            newSession: { ...props.data, name: inputValue },
          })
        );
        removeEditable();
      } else {
        chatSessionService.updateConversation(
          {
            conversation_id: props.data.conversationId,
          },
          {
            userId: userID,
            name: inputValue,
            description: props.data.description,
          },
          (data) => {
            console.log("[updateConversation] data: ", data);
            dispatch(
              updateSession({
                oldSession: { ...props.data },
                newSession: data,
              })
            );
            removeEditable();
            analyticsModule.trackEvent("Conversation Renamed", {
              "Conversation ID": props.data.conversationId,
              "Status": "Successful",
              "From": props.fromComponent
            });
          },
          (error) => {
            console.log("[updateConversation] error: ", error);
            removeEditable();
            analyticsModule.trackEvent("Conversation Renamed", {
              "Conversation ID": props.data.conversationId,
              "Status": "Unsuccessful",
              "From": props.fromComponent
            });
          }
        );
      }
    } else {
      removeEditable();
    }
  }


  function crossClickHandler(event) {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    if (isEditable) {
      setInputValue(props.data.name);
      removeEditable();
    } 
    // else if (isDeletable) {
    //   setIsDeletable(false);
    // }
  }

  function tickClickHandler(event) {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    if (isEditable) {
      editSession();
    } 
    // else if (isDeletable) {
    //   deleteChatSession();
    // }
  }

  function menuClickHandler(event) {
    // console.log("menuClickHandler");
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    sessionRef.current.click(); // done to send click to document from outside menu, but inside sessionRef

    setShowDropdown(!showDropdown);

    if(isDeletable) {
      setIsDeletable(false);
    }
  }

  function menuOptionClickHandler(data) {
    const value = data?.selected?.value;

    if(value === "edit") {
      setIsEditable(true);
    } else if(value === "delete") {
      setIsDeletable(true);
    }
  }

  function editIconClickHandler(event) {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    sessionRef.current.click(); // done to send click to document from outside icon, but inside sessionRef
    
    setIsEditable(true);
  }

  function closeModal() {
    setIsDeletable(false);
    logDeleteAnalyticsEvent("Aborted");
  }

  const notFullWidth = props.fullWidth === false;

  return (
    <StyledSession
      isactive={isactive}
      onClick={clickHandler}
      ref={sessionRef}
      isSmall={isEditable}
      // isbackgroundlight={isbackgroundlight}
      className={"session-item-container" + (isactive ? " is-active": "") + (isEditable ? " is-in-edit-state" : "")}
      notFullWidth={notFullWidth}
    >
      <StyledTitle
        isEditable={isEditable}
        className="session-item__title"
      >
        {isEditable ? (
          <StyledInput
            className="session-name-input-box"
            type="text"
            autoFocus
            onChange={inputChangeHandler}
            onClick={inputClickHandler}
            onKeyDown={inputKeyDownHandler}
            value={inputValue}
            notFullWidth={notFullWidth}
            // isbackgroundlight={isbackgroundlight}
          />
        ) : (
          props.data?.name
        )}
      </StyledTitle>
      <StyledIconContainer bigIcon={props.hideDelete}>
        {isactive ? (
          isEditable ?
          <React.Fragment>
            <StyledIcon
              isbackgroundlight={isbackgroundlight}
              onClick={tickClickHandler}>
                <SVGIcon Icon={isEditable ? TickIcon : TrashIcon} width={20} height={20} fill={theme["icon_02"][backgroundbrightness]}/>
            </StyledIcon>
            <StyledIcon
              isbackgroundlight={isbackgroundlight}
              onClick={crossClickHandler}
            >
              <SVGIcon Icon={CloseIcon} width={20} height={20} fill={theme["icon_02"][backgroundbrightness]}/>
            </StyledIcon>
          </React.Fragment> :  
          <StyledMenuIcon onClick={props.hideDelete ? editIconClickHandler : menuClickHandler}>
            <StyledIcon bigIcon={props.hideDelete}
              isbackgroundlight={isbackgroundlight}
            >
              <SVGIcon Icon={props.hideDelete ? PencilIcon : OverflowIcon} 
                width={props.hideDelete ? 24: 20} 
                height={props.hideDelete ? 24: 20} 
                fill={theme["icon_02"][backgroundbrightness]}
              />
            </StyledIcon>
            {showDropdown ? 
              <StyledDropdown>
                <ActionMenu optionSections={[{
                      options: [
                        {
                            label: "Edit",
                            value: "edit",
                            icon: PencilIcon,
                        },
                        ...(props.hideDelete ? [] : [{
                          label: "Delete",
                          value: "delete",
                          icon: TrashIcon,
                      }])
                      ]
                    }]}
                  clickHandler={menuOptionClickHandler}
                />
              </StyledDropdown>
              : null
            }
          </StyledMenuIcon>
        ) : null} 
      </StyledIconContainer>
      {
        isDeletable ? (
          <Modal parentId="conversation_pane_content"
            content={<StyledDialogBoxWrapper>
              <Dialogbox 
                onClose={closeModal}
                header={{
                  title: "Delete Conversation?"
                }}
                body="Are you sure you want to delete the conversation?."
                type="negative"
                footer={ {
                  rightActionButtons: [
                    {
                      buttonType: ButtonType.tertiary,
                      text: "Discard",
                      onClick: closeModal,
                    },
                    {
                      buttonType: ButtonType.negative,
                      text: "Submit",
                      onClick: deleteChatSession,
                    },
                  ],
                }}
              />
            </StyledDialogBoxWrapper>
          }
          closeModal={closeModal}
        />

        ) : null
      } 
    </StyledSession>
  );
}

export default SessionItem;
