import styled from "styled-components";
import { getStyleString } from "../../../../utils/styleUtils";

export const Container = styled.div`
  
`;

export const Section = styled.div`
  margin-top: 20px;

  &:first-child {
    margin-top: 0px;
  }
`;

export const SectionTitle = styled.div`
  color: ${props => props.theme["text_01"].light};
  /* $viewerbox-small-header */
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
  line-height: 24px;
`;

export const Transaction = styled.div`
  margin-top: 12px;
`;

export const TransactionTitle = styled.div`
  color: ${props => props.theme["text_04"].light};
  ${props => getStyleString(props.theme["text_01_med"])};
  margin-bottom: 8px;
`;

export const List = styled.span`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 12px;
  align-self: stretch;
  flex-wrap: wrap;
`;

export const More = styled.div`
  color: ${props => props.theme["text_interactive"]};
  ${props => getStyleString(props.theme["text_01_med"])};
  margin-top: 16px;
  cursor: pointer;
`;

export const ListItem = styled.span`
  position: relative;
  padding: 6px 8px;
  border-radius: 8px;
  background: ${props => props.theme["ui_04"]};
  color: ${props => props.theme["text_01"].light};
`;

export const ItemText = styled.span`
  display: inline-block;
  // vertical-align: middle;
  ${props => getStyleString(props.theme["text_01_reg"])};
`;

export const Actions = styled.span`
  // vertical-align: middle;
  margin-left: 4px;
  height: 20px;

  ${props => props.floating ? `
    position: absolute;
    padding: 6px 8px 6px 0px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    background: ${props.theme["ui_04"]};
    left: calc(100% - 8px);
    top: 0px;
    width: ${props.withInfo ? "40" : "20"}px;
  ` : ""}
`;

export const InfoIcon = styled.span`
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 20px;
  height: 20px;

  &:hover .ad__entity-info-tooltip {
    visibility: visible;
  }
`;

export const InfoTooltip = styled.div`
  position: absolute;
  max-width: 180px;
  top: 100%;
  left: calc(50% - ${props => (props.tooltipPosition && props.tooltipPosition.left) || 0}px);
  transform: translateX(-50%);
  visibility: hidden;
  pointer-events: none;
  z-index: 100;
`;

export const PasteIcon = styled.span`
  display: inline-block;
  vertical-align: top;
  width: 20px;
  height: 20px;
  cursor: pointer;
`; 