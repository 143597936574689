import React from "react";

import {ContextualMessageBar} from "@cuddle-dev/web-components";

//styled-components
import {
    ErrorDiv as StyledErrorDiv
} from './styled-components.js';

const default_error_text = "Couldn’t process your request, please try again.";

function getText(type) {
    switch(type) {
        case "DATA_UNAVAILABLE":
            return "Data Unavailable.";
        case "NON_AGGREGATABLE":
            return "Sorry, information cannot be aggregated at the requested level.";
        case "NOT_AUTHORISED":
            return "Sorry, you’re not authorised to view this information.";
        case "NO_ANSWER_FOUND":
            return "No answers found";
        case "POOR_NETWORK": 
            return "Something went wrong, could not retrieve your answer. Please try again."
    }
}

function ErrorCard(props) {
    const text = getText(props.type) || props.text || default_error_text;
    const type = (props.type === "NOT_AUTHORISED") ? "default" : "warning";
    const message = props.message;

    return (
        <StyledErrorDiv>
            <ContextualMessageBar 
                type={type}
                feedback= {message || text}
                theme={props.theme}
                width={"full"}
                classes={"error-card"}
                card={true}
                height={props.height}
            />
        </StyledErrorDiv>
    );
}

export default ErrorCard;