import styled from "styled-components";

export const Background = styled.div`
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9999;
`;

export const Content = styled.div`
    width: 488px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    border-radius: 4px;
`;

export const Header = styled.div`
    padding: 16px 24px;
    border-bottom: 2px solid #EDF0F5;

    img {
        width: 24px;
        height: 24px;
        float: right;
        cursor: pointer;
    }
`;

export const Body = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #151B24;
    padding: 24px;

    a {
        color: #4E68FD;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;

export const Footer = styled.div`
    padding: 0px 24px 24px 24px;

    span {
        width: 110px;
        padding: 10px 0px;
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        float: right;
        cursor: pointer;
    }
`;

export const Accept = styled.span`
    background: #4E68FD;
    color: #FFFFFF;
    margin-left: 16px;
`;

export const Reject = styled.span`
    background: #EDF0F5;
    color: #5D697A;
`;