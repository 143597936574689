import styled from "styled-components";
// import { getStyleString } from "@cuddle-dev/test-web-ui-lib/dist/utils/styleUtils";

export const Container = styled.div`
  filter: url(#threshold);
`;

export const FixedText = styled.div`
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 300;
  font-size: 72px;
  line-height: 92px;
  color: ${(props) => props.theme["text_03"][props.theme.backgroundMeta?.interface_background?.colorBrightness || "light"]}};
`;

export const ChangingText = styled.div`
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 72px;
  line-height: 92px;
  
  color: ${(props) =>  props.theme["text_02"][props.theme.backgroundMeta?.interface_background?.colorBrightness || "light"]};
  position: absolute;
  top: 0px;

  span:first-child {
    visibility: hidden;
  }
`;
