import React, { useState,useEffect } from 'react';
import { useAppSelector } from '../../../stores/redux-hooks';
import { useTheme } from 'styled-components';
import { Document as PDFDocument, Page, pdfjs } from 'react-pdf';
import { Modal, SVGIcon, Button, ButtonType } from "@cuddle-dev/web-components";
import AgentDocumentService from '../../../services/agent.document.service';
// styled components
import { 
  Container as StyledContainer,
  Title as StyledTitle,
  Content as StyledContent,
  Page as StyledPage,
  PageContent as StyledPageContent,
  PDFContainer as StyledPDFContainer,
  PDFContainerBody as StyledPDFContainerBody,
  PDFPage as StyledPDFPage,
  Header as StyledHeader,
  Icons as StyledIcons,
  HeaderTitle as StyledHeaderTitle
} from "./styled-components";


import { ReactComponent as downloadIcon } from "../../../images/download.svg";
import { ReactComponent as closeIcon } from "../../../images/close-12.svg";
import {ReactComponent as topRight} from "../../../images/top_right_20.svg";

// import topRightIcon from "../../../images/top_right_20.svg";

const PDFViewer = ({pdfResponse, data, theme, onClose, onDownload, openDocument, onLoadSuccess}) => {
  return  <StyledPDFContainer theme={theme}>
      <StyledHeader>
        <StyledHeaderTitle>
          <div className='title'>{data.metadata.source}</div>
          <Button
            style={{
              marginLeft: "-12px"
            }}
            onClick={openDocument}
            startEnhancer={<SVGIcon Icon={topRight} width={20} height={20} fill={theme["icon_interactive"]}/>}
            buttonType={ButtonType.iconsPrimary}
            theme={theme}
            text={"Open Document"}
          ></Button>
        </StyledHeaderTitle>
        <StyledIcons>
          <span className='icon' onClick={onDownload}>
            <SVGIcon Icon={downloadIcon} width={20} height={20} fill={theme["icon_02"].light}/>
          </span>
          <span className='icon' onClick={onClose}>
            <SVGIcon Icon={closeIcon} width={20} height={20} fill={theme["icon_02"].light}/>
          </span>
        </StyledIcons>
      </StyledHeader>
    <StyledPDFContainerBody>
      <StyledPDFPage>
        {
          pdfResponse ? <PDFDocument file={pdfResponse} onLoadSuccess={onLoadSuccess}>
            <Page pageNumber={data.metadata.page || 1}  renderTextLayer={false} renderAnnotationLayer={false}></Page>
          </PDFDocument> : "Loading PDF..."
        }
   
      </StyledPDFPage>
    </StyledPDFContainerBody>
  </StyledPDFContainer>
}

function Document(props) {
    const [data, setData] = useState(props.data);
    const [pageNumber, setPageNumber] = useState(1);
    const [pdfResponse, setPdfResponse] = useState();
    const [showPDFModal, setShowPDFModal] = useState(false);
    const theme = useTheme();
   
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const fetchPDF = () => {
      console.log("fetchPDF closeModal")
      setShowPDFModal(true);
      AgentDocumentService.getDocument({
        file_name: data.metadata.source,
        source_location: data.metadata.bucket_name
      })
      .promise
      .then((response) => {
        setPdfResponse(response.data);
      });
    }

    const onDocumentLoadSuccess = () => {
      setPageNumber(data.metadata.page);
    }

    const closeModal = () => {
      console.log("closeModal");
      setShowPDFModal(false);
    }

    const onDownload = () => {
      const fileURL = window.URL.createObjectURL(pdfResponse);
      let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = data.metadata.source;
      alink.click();
    }

    const openDocument = () => {
      window.open(window.URL.createObjectURL(pdfResponse));
    }
    
    return (
      <>
        <StyledContainer onClick={fetchPDF}>
          <StyledTitle>
              {data.metadata.source}
          </StyledTitle>
          <StyledContent>
              <StyledPage>Page no {data.metadata.page}</StyledPage>
              <StyledPageContent>{data.page_content}</StyledPageContent>
          </StyledContent>
        </StyledContainer>
        {
          showPDFModal ? (
            <Modal parentId="conversation_pane_content"
              content={ 
              <PDFViewer 
                pdfResponse={pdfResponse} 
                data={data} 
                theme={theme}
                onClose={closeModal} 
                onDownload={onDownload}
                openDocument={openDocument}
                onLoadSuccess={onDocumentLoadSuccess}
              > 
              </PDFViewer>
            }
            closeModal={closeModal}
          />

          ) : null
      } 
      </>
      
      
     
    );
}

export default Document;