import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../stores/redux-hooks';

// styled components
import { 
  Container as StyledContainer,
  GreetingEmoji as StyledGreetingEmoji,
  Greeting as StyledGreeting,
  CopilotInfo as StyledCopilotInfo,
  OnboardingDiv as StyledOnboardingDiv,
  QuestionGroup as StyledQuestionGroup,
  Question as StyledQuestion,
  GroupTitle as StyledGroupTitle,
  QuestionText as StyledQuestionText
} from "./styled-components";

function shuffleArray(arr) {
  for (let i = arr.length - 1; i > 0; i--) {
    // Pick a random index from 0 to i
    const j = Math.floor(Math.random() * (i + 1));

    // Swap elements at i and j
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;  // Return the shuffled array
}

function getColorToken(index) {
  const num = (index % 9) + 1;
  // To skip data03 token
  if(num === 9) {
    return "data10";
  } else if(num < 3) {
    return "data0" + num;
  } else {
    return "data0" + (num + 1);
  }
}

function pickRandomQuestions(data, newData, maxQsPerGroup) {
  let groupsWithExtraQs = [];
  const questionCountByGroup = {};
  const totalQuestions = 8;

  while(newData.length < totalQuestions && data.length) {
    const randomNum = Math.floor(Math.random() * data.length);
    let group = data[randomNum];
    
    if(!questionCountByGroup[group.id]) {
      group.onBoardingQuestions = shuffleArray([...group.onBoardingQuestions]);
      questionCountByGroup[group.id] = 0;
    }

    if(group.onBoardingQuestions?.length) {
      let question = group.onBoardingQuestions.pop();
      newData.push({
        groupName: group.name,
        groupId: group.id,
        question,
        colorToken: group.colorToken
      });
      questionCountByGroup[group.id] += 1;

      if(!group.onBoardingQuestions.length || questionCountByGroup[group.id] >= maxQsPerGroup) {
        const datum = data.splice(randomNum, 1);
        if(questionCountByGroup[group.id] >= maxQsPerGroup && group.onBoardingQuestions.length) {
          groupsWithExtraQs.push(datum[0]);
        }
      }
    } else {
      data.splice(randomNum, 1);
    }
  }

  if(newData.length < totalQuestions && groupsWithExtraQs.length) {
    maxQsPerGroup = Math.floor((totalQuestions - newData.length) / groupsWithExtraQs.length);
    return pickRandomQuestions(groupsWithExtraQs, newData, maxQsPerGroup || 1);
  }

  return newData;
}

function getData(data) {
  // randomising questions
  const newData = [];

  if(data && data.length) {
    data = data.map((datum, index) => {
      return {
        ...datum,
        colorToken: getColorToken(index)
      }
    });

    return pickRandomQuestions(data, newData, 2);  
  }

  return newData;
}

function Onboarding(props) {
    // const [onBoardingQuestions, setOnBoardingQuestions] = useState(props.data);
    const [data, setData] = useState(getData(props.data));
    const userFirstName = useAppSelector((state) => state.user.details.firstName);
    const projectInfo = useAppSelector((state) => state.currProject.projectInfo);
    

    useEffect(() => {
      setData(getData(props.data));
    }, [props.data]);

    const onQuestionClick = (question, topic) => {
      if(props.onAsk) {
        props.onAsk(question, {
          from: "Onboarding",
          topic,
        });
      }
    }
    
    return (
      <StyledContainer>
          <StyledGreetingEmoji>👋</StyledGreetingEmoji>
          <StyledGreeting>{`Hello ${userFirstName}`}</StyledGreeting>
          <StyledCopilotInfo>{`I’m ${projectInfo?.name || "Crux" }, your analytics copilot. I can analyze data from various data sources available and answer your questions in plain English. Here are a few examples to get started:`}</StyledCopilotInfo>
          <StyledOnboardingDiv className='ask-onboarding'>
            {data.map(datum => {
              return (
                <StyledQuestion key={`${datum.groupId}-${datum.question.id}`} 
                  onClick={onQuestionClick.bind(this, datum.question?.question, datum.groupName)}
                >
                  <StyledGroupTitle $colorToken={datum.colorToken}>{datum.groupName.toUpperCase()}</StyledGroupTitle>
                  <StyledQuestionText className='question' title={datum.question.question}>
                    {datum.question.question}
                  </StyledQuestionText>
                </StyledQuestion>
              )
            })}
          </StyledOnboardingDiv>
      </StyledContainer>
      
    );
}

export default Onboarding;