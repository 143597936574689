import React, { Component } from "react";

import { Visualization } from "../../components/visualization";
import {Button, ButtonType, ContextualMessageBar, Modal, Tooltip, Blip, SVGIcon} from "@cuddle-dev/web-components";
// import PeriodSelector from "./period-selector";
// import PeriodicitySelector from "./periodicity-selector";
// import AnswerMenu from "./answer-menu";
import ErrorCard from "./error-card";
import VizSelector from "./viz-selector";
import PeriodSubtitle from "./period-subtitle";
import Footnote from "./footnote";
import LatestPeriod from "./latest-period";
// import Tooltip from "@cuddle-dev/test-web-ui-lib/dist/tooltip";
import { getTitle } from "../../utils/answerTitle";
// import analyticsModule from "../../utils/analytics.js";
import { CAN_TYPES, VISUALISATION_LIB_TYPES } from "../../constants";
import {
  generateUUID,
  getWindowType,
  downloadCSV,
} from "../../utils/utilityFunctions.js";
// import Icons, { IconWrapper } from "@cuddle-dev/test-web-ui-lib/dist/icons";
// import FooterMobileButton from "./footer-mobile-button";


// import ModalPositioner from "@cuddle-dev/test-web-ui-lib/dist/modal/ModalWrapper.js";
// import DropdownWithInput from "@cuddle-dev/test-web-ui-lib/dist/dropdownWithInput";
// import ViewerBox from "@cuddle-dev/test-web-ui-lib/dist/viewer-box/ViewerBox";
// import CardSelectorOverlay from "./card-selector-overlay";
// import AnswerCardNLG from "./answer-card-nlg";
// import { ShimmerEffect } from "../utils/cssUtils";
// import autoAnalysisOnboarding1 from "../images/auto-analysis-onboarding-1.png";
// import autoAnalysisOnboarding2 from "../images/auto-analysis-onboarding-2.png";
// import Snackbar from "@cuddle-dev/test-web-ui-lib/dist/snackbar";
// import SingleSelectButton from "@cuddle-dev/test-web-ui-lib/dist/single-select-button";
// import AnswerSettings from "./answer-settings";
// import SettingsOnboarding from "./settings-onboarding";
// import FullScreenView from "./full-screen-view/FullScreenView";
import { withTheme } from 'styled-components';
import AnswerFooter from "../chat/answer-footer/AnswerFooter";
import AnswerTitle from "../chat/answer-title/AnswerTitle";

//styled-components
import {
  Card as StyledCard,
  ZoomOnboarding as StyledZoomOnboarding,
  AutoAnalysisOnboarding as StyledAutoAnalysisOnboarding,
  ZoomTooltip as StyledZoomTooltip,
  ExploreEntitiesOnboarding as StyledExploreEntitiesOnboarding,
  ExploreEntitiesOnboardingTooltip as StyledExploreEntitiesOnboardingTooltip,
  ViewerBoxContent as StyledViewerBoxContent,
  FooterLoader,
  SettingsButton as StyledSettingsButton,
  ExpandIcon as StyledExpandIcon,
  Header as StyledHeader,
  HeaderCloseIcon as StyledHeaderCloseIcon,
  SubTitle as StyledSubTitle,
  HeaderActions as StyledHeaderActions,
  FullScreenViz as StyledFullScreenViz,
  LatestPeriodMsg as StyledLatestPeriodMsg,
  AnsBody as StyledAnsBody,
  AnsOverlay as StyledAnsOverlay,
  SubHeader as StyledSubHeader
} from "./styled-components.js";

//images
// const Analyse = <IconWrapper url={Icons.analyse} />;
// const Globe = <IconWrapper url={Icons.globe} />;
// const expandIcon = require('../images/expand-24.svg');
// const autoAnalysisOnboarding1 = require('../images/auto-analysis-onboarding-1.png');
// const autoAnalysisOnboarding2 = require('../images/auto-analysis-onboarding-2.png');
import { ReactComponent as QuestionIcon } from "../../images/question_20.svg";

//services
import UserConfigService from "../../services/userConfig.service.js";
// import DriverAnalysisService from "../services/driver.analysis";

// constants
const ZOOM_ONBOARDING_KEY = "zoomOnChart";
const PAN_ONBOARDING_KEY = "panOnChart";
const VIZ_SETTINGS_ONBOARDING_KEY = "vizSettings";

class AnswerCard extends Component {
  constructor(props) {
    super(props);

    this.cardRef = React.createRef();
    this.headerRef = React.createRef();
    this.footnoteRef = React.createRef();
    this.footerRef = React.createRef();
    this.infoRef = React.createRef();
    this.errorsRef = React.createRef();
    this.state = {
      visualisation: this.getDefaultViz(props.result, props.userPreferredViz),
      currentVizState: undefined,
      isAnswerExplorable: false,
      vizHeight: undefined,
      showZoomOnboarding: this.getZoomOnboardingValue(),
      showPanOnboarding: this.getPanOnboardingValue(),
      showVizSettingsOnboarding: this.getVizSettingsOnboardingValue(),
      chartZoomed: false,
      hasVizLoaded: false,
      disableAnalyseButton: false,
      nestedTableHeaders: undefined,
      fullScreenConfigs: undefined,
    };
    this.activeFeedbackMessages = [];
    this.activeFeedbackTypes = {};

    this.deviceType = getWindowType();
    this.isMobileOrTablet =
      this.deviceType === "mobile" || this.deviceType === "tablet";
    this.vizID = `viz-${generateUUID()}`;
    this.fullVizID = `viz-full-${generateUUID()}`;
  }

  async componentDidMount() {
    try {
      const answerJSON =
        this.props.result &&
        (this.props.result.latestAnswer || this.props.result);

      // try {
      //   await this.checkIfDriverAnalyisApplicable(answerJSON.query);
      // } catch (error) {
      //   console.log("[AnswerCard checkIfDriverAnalyisApplicable]: ", error);
      // }


      if (this.props.dimensions) {
        this.setState({
          vizHeight: this.getVizHeight(),
        });
      }
    } catch (error) {
      console.log("[AnswerCard]: ", error);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let cardHeight =
      (this.cardRef &&
        this.cardRef.current &&
        this.cardRef.current.clientHeight) ||
      0;
    let hasTitleChanged =
      prevProps.hideTitle !== this.props.hideTitle ||
      prevProps.title !== this.props.title;
    if (
      cardHeight &&
      ((this.props.dimensions && !this.state.vizHeight) || hasTitleChanged)
    ) {
      this.setState({
        vizHeight: this.getVizHeight(),
      });
    }

    const answerJSON =
      this.props.result &&
      (this.props.result.latestAnswer || this.props.result);
    const prevAnswerJSON =
      prevProps.result && (prevProps.result.latestAnswer || prevProps.result);
    if (
      !prevState.visualisation &&
      answerJSON &&
      answerJSON.answer &&
      answerJSON.answer.visualisation &&
      !(
        prevAnswerJSON &&
        prevAnswerJSON.answer &&
        prevAnswerJSON.answer.visualisation
      )
    ) {
      this.setState({
        visualisation: this.getDefaultViz(
          this.props.result,
          this.props.userPreferredViz
        ),
      });
    }

    if (
      (prevProps.userPreferredViz &&
        prevProps.userPreferredViz !== this.props.userPreferredViz) ||
      prevProps.vizState !== this.props.vizState
    ) {
      this.setState(
        {
          visualisation: undefined,
          currentVizState: undefined,
          hasVizLoaded: false,
          nestedTableHeaders: undefined,
        },
        () => {
          this.setState({
            visualisation: this.getDefaultViz(
              this.props.result,
              this.props.userPreferredViz
            ),
          });
        }
      );
    }
  }

  static getDerivedStateFromProps(props, state) {
    const answerJSON = props.result.latestAnswer || props.result;

    if (
      state.visualisation &&
      !(answerJSON && answerJSON.answer && answerJSON.answer.visualisation)
    ) {
      return {
        visualisation: undefined,
        currentVizState: undefined,
        hasVizLoaded: false,
        nestedTableHeaders: undefined,
      };
    }

    return null;
  }

  getZoomOnboardingValue = () => {
    let val = true;
    if (
      this.props.cardType === "BASIC" ||
      this.props.cardType === "BOARD" ||
      (this.props.onBoardingStates &&
        this.props.onBoardingStates[ZOOM_ONBOARDING_KEY] &&
        this.props.onBoardingStates[ZOOM_ONBOARDING_KEY].web)
    ) {
      val = false;
    }
    return val;
  };

  getPanOnboardingValue = () => {
    let val = true;
    if (
      this.props.cardType === "BASIC" ||
      this.props.cardType === "BOARD" ||
      (this.props.onBoardingStates &&
        this.props.onBoardingStates[PAN_ONBOARDING_KEY] &&
        this.props.onBoardingStates[PAN_ONBOARDING_KEY].web)
    ) {
      val = false;
    }
    return val;
  };

  getVizSettingsOnboardingValue = () => {
    let val = true;
    if (
      this.props.cardType === "BASIC" ||
      this.props.cardType === "BOARD" ||
      (this.props.onBoardingStates &&
        this.props.onBoardingStates[VIZ_SETTINGS_ONBOARDING_KEY] &&
        this.props.onBoardingStates[VIZ_SETTINGS_ONBOARDING_KEY].web) ||
      this.isMobileOrTablet
    ) {
      val = false;
    }
    return val;
  };

  getVizHeight = () => {
    const cardHeight =
      (this.cardRef &&
        this.cardRef.current &&
        this.cardRef.current.clientHeight) ||
      0;
    const headerHeight =
      (this.headerRef &&
        this.headerRef.current &&
        this.headerRef.current.clientHeight) ||
      0;
    const footnoteHeight =
      (this.footnoteRef &&
        this.footnoteRef.current &&
        this.footnoteRef.current.clientHeight) ||
      0;
    const footerHeight =
      (this.footerRef &&
        this.footerRef.current &&
        this.footerRef.current.clientHeight) ||
      0;
    const infoHeight =
      (this.infoRef &&
        this.infoRef.current &&
        this.infoRef.current.clientHeight) ||
      0;
    const errorsHeight =
      (this.errorsRef &&
        this.errorsRef.current &&
        this.errorsRef.current.clientHeight) ||
      0;
    // console.log("[getVizHeight] cardHeight: ", cardHeight);
    // console.log("[getVizHeight] headerHeight: ", headerHeight);
    // console.log("[getVizHeight] footnoteHeight: ", footnoteHeight, this.footnoteRef);
    // console.log("[getVizHeight] footerHeight: ", footerHeight);
    return (
      cardHeight -
      (headerHeight + 1) -
      (footnoteHeight && footnoteHeight + 12) -
      footerHeight -
      (infoHeight && infoHeight + 12) -
      (errorsHeight && errorsHeight + 12)
    );
  };

  getDefaultViz = (result, userPreferredViz) => {
    const answerJSON = result.latestAnswer || result;
    const visualisation = answerJSON.answer && answerJSON.answer.visualisation;

    if (visualisation) {
      if (userPreferredViz) {
        if (visualisation.preferredType === userPreferredViz) {
          return visualisation.preferredType;
        }
        let suggestedTypes = visualisation.suggestedTypes;
        if (suggestedTypes && suggestedTypes.length) {
          for (let i = 0; i < suggestedTypes.length; i++) {
            if (suggestedTypes[i].type === userPreferredViz) {
              return suggestedTypes[i];
            }
          }
        }
      }
      return visualisation.preferredType;
    }
  };

  // handleAutoAnalyseClick = () => {
  //   this.props.onExploreClick(this.props.result);
  // };

  // handleAnalyseDriversClick = () => {
  //   if (this.props.onDriverAnalysisClickHandler) {
  //     const result = this.props.result;
  //     const answerJSON = result.latestAnswer || result;
  //     this.props.onDriverAnalysisClickHandler(answerJSON.query);
  //   }
  // };

  // handlePeriodChange = (type, change) => {
  //   // console.log("handlePeriodChange: ", change);
  //   let selectedPeriod =
  //     type === "periodicity"
  //       ? change
  //       : change.selected.option
  //       ? change.selected.option
  //       : change.selected;
  //   let result = this.props.result;
  //   // let newState = {};
  //   let typeValue;
  //   let analysisValue;
  //   let periodData = {
  //     query: result.latestAnswer ? result.latestAnswer.query : result.query,
  //   };

  //   // selectedPeriod.context = {
  //   //     "parentInsightId": result.answer.insightID
  //   // };
  //   // this.currentId = "PILLS_dropdown_" + new Date().getTime();

  //   // newState = {
  //   //     widget: undefined,
  //   //     pills: undefined
  //   // }

  //   if (type === "period") {
  //     typeValue = "Result - Date Range";
  //     analysisValue = "Date Range";
  //     periodData.selectedPeriod = selectedPeriod;
  //     // newState.periodAnswerLoading = true;
  //   } else if (type === "periodicity") {
  //     // newState.selectedTrendPill = selectedPeriod;
  //     periodData.periodicity = selectedPeriod.value.toUpperCase();
  //     typeValue = "Result - Interval";
  //     analysisValue = "Interval";
  //   }

  //   // this.setState(newState);

  //   // if (this.props.analyticsModule) {
  //   //   this.props.analyticsModule.trackEvent("Answer Detail", {
  //   //     From: typeValue,
  //   //   });

  //   //   this.props.analyticsModule.trackEvent("Analysis Done", {
  //   //     Type: analysisValue,
  //   //   });
  //   // }

  //   console.log("[handlePeriodChange] periodData: ", periodData);
  //   this.props.onPeriodChange(periodData);
  // };

  // cueChangeHandler = (data) => {
  //   this.props.onCueChange(data);
  // };

  vizChangeHandler = (data, oldVizType) => {
    const fullScreenConfigs = this.state.fullScreenConfigs;
    const toReset = {
      // visualisation: undefined,
      currentVizState: undefined,
      hasVizLoaded: false,
      nestedTableHeaders: undefined,
    };

    if(fullScreenConfigs) {
      this.setState(
        {
          fullScreenConfigs: {
            ...fullScreenConfigs,
            ...toReset,
            vizState: this.props.vizState,
            nestedTableHeaders: this.state.nestedTableHeaders,
            visualisation: data.config,
          }
        }, 
        () => {
          // this.setState({
          //   fullScreenConfigs: {
          //     ...this.state.fullScreenConfigs,
          //     visualisation: data.config,
          //   }
          // })
        }
      );
    } else {
      this.setState(
        {
          ...toReset,
          visualisation: data.config,
        },
        () => {
          this.storeUserPreferences();
          // this.setState({
          //   visualisation: data.config,
          // });
        }
      );
    }

    this.props.vizChangeHandler(data.value, oldVizType);
  };

  // drilldownHandler = (data) => {
  //   // if (this.props.analyticsModule) {
  //   //   this.props.analyticsModule.trackEvent("Answer Detail", {
  //   //     From: `CT - ${this.state.visualisation.type}`,
  //   //   });
  //   //   this.props.analyticsModule.trackEvent("Analysis Done", {
  //   //     Type: "CT",
  //   //   });
  //   // }
  //   if (this.props.onDrillDown) {
  //     const query = this.props.result.query;
  //     const id = "chart_drilldown_" + new Date().getTime();
  //     this.props.onDrillDown(query, data, id);
  //   }
  // };

  // curatedAnalysisClickHandler = (data) => {
  //   if (this.props.curatedAnalysisClickHandler) {
  //     const result = this.props.result;
  //     const answerJSON = result.latestAnswer || result;
  //     const query = answerJSON.query;
  //     const id = "chart_curated_analysis_" + new Date().getTime();
  //     const filterValue = data.filterValue;
  //     let attributeValue = "";
  //     let exloreQuery;
  //     query.attributes.forEach((attribute) => {
  //       if (attribute.label === data.label) {
  //         attributeValue = attribute.type;
  //       }
  //     });

  //     let requestJSON = {
  //       attribute: {
  //         type: attributeValue,
  //         groupBy: false,
  //         label: data.label,
  //       },
  //       userID: this.props.userID,
  //     };

  //     if (filterValue) {
  //       requestJSON.attribute["filterValues"] = [
  //         {
  //           filterValue: filterValue.value,
  //           label: filterValue.label,
  //         },
  //       ];

  //       exloreQuery = {
  //         category: "BUSINESS_OBJECT_DATA_RETRIEVAL",
  //         userID: this.props.userID,
  //         label: filterValue.label,
  //         attributes: [requestJSON.attribute],
  //         period: query.period,
  //         calendarBasis: query.calendarBasis,
  //         latestPeriod: true,
  //       };
  //     } else {
  //       exloreQuery = {
  //         category: "BUSINESS_OBJECT_DATA_RETRIEVAL",
  //         userID: this.props.userID,
  //         label: data.label,
  //         attributes: [requestJSON.attribute],
  //         period: query.period,
  //         calendarBasis: query.calendarBasis,
  //         latestPeriod: true,
  //       };
  //     }

  //     this.props.curatedAnalysisClickHandler(
  //       query,
  //       {
  //         exloreQuery,
  //         curatedAnalysisQuery: requestJSON,
  //       },
  //       id
  //     );

  //     // this.trackCuratedAnalysisVizEvent(filterValue ? "ALL_BO_OF_ATTRIBUTE" : "ATTRIBUTE");
  //   }
  // };

  // trackCuratedAnalysisEvent = (eventFrom, type) => {
  //     if(this.props.analyticsModule) {
  //         this.props.analyticsModule.trackEvent("Curated Analysis", {
  //             "From": eventFrom,
  //             "Type": type
  //         });
  //     }
  // }

  // trackCuratedAnalysisVizEvent = (type) => {
  //     this.trackCuratedAnalysisEvent("Answer - Visual", type);
  // }

  // trackCuratedAnalysisDropdownEvent = (type) => {
  //     this.trackCuratedAnalysisEvent("Answer - Explore", type);
  // }

  vizStateChangeHandler = (newState) => {
    const fullScreenConfigs = this.state.fullScreenConfigs;
    
    if(fullScreenConfigs) {
      this.setState({
        fullScreenConfigs: {
          ...fullScreenConfigs,
          currentVizState: newState,
        }
      });
    } else {
      this.setState({
        currentVizState: newState,
      }, () => {
        this.storeUserPreferences();
      });
    }
  };

  storeUserPreferences = () => {
    if(this.props.onUserPreferenceUpdate) {
      let data;

      if(this.state.visualisation) {
        data = {};
        data.viz = this.state.visualisation.type;
      }
      if(this.state.currentVizState) {
        data = data || {};
        data.vizState = this.state.currentVizState;
      }
      console.log("[storeUserPreferences] data: ", data);
      if(data) {
        this.props.onUserPreferenceUpdate(data);
      }
    }
  }

  cardClickHandler = () => {
    if (this.props.onCardClick) {
      this.props.onCardClick(this.props.result, {
        title: this.props.title,
        hideTitle: this.props.hideTitle,
        userPreferredViz: this.props.userPreferredViz,
        vizState: this.props.vizState,
        lastRefreshedAt: this.props.lastRefreshedAt,
        autoScaleYAxis: this.props.autoScaleYAxis,
        customTitle: this.props.customTitle,
      });
    }
  };

  cardMouseOverHandler = () => {
    this.setState((prevState) => {
      if (!prevState.cardHover) {
        return {
          cardHover: true,
        };
      }
    });
  };

  cardMouseLeaveHandler = () => {
    this.setState((prevState) => {
      if (prevState.cardHover) {
        return {
          cardHover: false,
        };
      }
    });
  };

  feedbackClickHandler = (type) => {
    console.log("[feedbackClickHandler] type: ", type);

    if (this.props.setAppMessage) {
      const message =
        type === "UP"
          ? "You’ll see this answer more frequently."
          : "You’ll see this answer less frequently.";
      const id = generateUUID();
      let feedbackTimer;
      const feedbackUndoHandler = () => {
        console.log("[feedbackUndoHandler] id: ", id);
        clearTimeout(feedbackTimer);
        // this.feedbackTimer = undefined;
        let index = this.activeFeedbackMessages.indexOf(id);
        this.activeFeedbackTypes[id] = null;
        if (index > -1) {
          this.activeFeedbackMessages.splice(index, 1);
        }
        let lastID = this.activeFeedbackMessages.length
          ? this.activeFeedbackMessages[this.activeFeedbackMessages.length - 1]
          : undefined;
        this.setState({
          selectedFeedback: lastID
            ? this.activeFeedbackTypes[lastID]
            : undefined,
        });
      };

      this.props.setAppMessage({
        message,
        type: "system",
        link: {
          text: "Undo",
          onClick: feedbackUndoHandler,
        },
      });

      console.log("[feedbackClickHandler] id: ", id);
      this.activeFeedbackMessages.push(id);
      this.activeFeedbackTypes[id] = type;

      this.setState({
        selectedFeedback: type,
      });

      feedbackTimer = setTimeout(() => {
        // this.feedbackTimer = undefined;
        console.log("[feedbackTimer] Feedback time out. ", id);
        const result = this.props.result;
        let index = this.activeFeedbackMessages.indexOf(id);
        this.activeFeedbackTypes[id] = null;
        if (index > -1) {
          this.activeFeedbackMessages.splice(index, 1);
        }
        this.props.onFeedback(type, result.relation);
      }, 10000);
    }
  };

  processedDataHandler = (options) => {
    // console.log("[processedDataHandler] options: ", options);
    this.setState({
      processedData: options,
    });
  };

  getDataTooLargeMessage = (answerJSON) => {
    // console.log(answerJSON.answer.resultMetadata, "getDataTooLargeMessage");
    return (
      <div
        className={
          "data-large-warning" +
          (this.state.downloadInProgress ? " in-progress" : "")
        }
      >
        <ContextualMessageBar
          type="warning"
          feedback={
            this.isMobileOrTablet
              ? `Answer exceeds our data limit.`
              : (this.props.hideExport ? `Data Limit Exceeded: Displaying ${answerJSON.answer.result.length} out of ${answerJSON.answer.resultMetadata.length} records.` : `Showing and sorting within ${answerJSON.answer.result.length} records out of the total ${answerJSON.answer.resultMetadata.length} records as the answer exceeds our data limit. To view complete answer,`)
          }
          link={!this.props.hideExport ? {
            text: this.isMobileOrTablet ? "Know more" : "Export as CSV",
            onClick: this.isMobileOrTablet
              ? this.renderLongAnswerMobileMessage
              : this.downloadAsCSV.bind(this, "Answer UI"),
          } : null}
          width={"full"}
          theme={this.props.theme}
        ></ContextualMessageBar>
      </div>
    );
  };

  vizLoadHandler = () => {
    // console.log("vizLoadHandler");
    this.setState({
      hasVizLoaded: true,
    });
  };

  recordAnalyticsEvent = (data) => {
    if (this.props.analyticsModule) {
      // this.props.analyticsModule.trackEvent(data.eventName, data.segmentation);
    }
  };

  getVisualization = (answerJSON, fullScreenConfigs, vizHeight, isCardBasic) => {
    const visualisation = fullScreenConfigs ? fullScreenConfigs.visualisation : this.state.visualisation;
    let vizStyle = (this.props.dimensions || fullScreenConfigs)
        ? { height: vizHeight - 40 }
        : undefined
    const viewOnly =
        this.props.cardType === "BOARD" ||
        this.props.cardType === "BASIC" ||
        this.props.viewOnly;
    const dataTooLarge =
      answerJSON.answer &&
      answerJSON.answer.resultMetadata &&
      answerJSON.answer.resultMetadata.dataTooLarge
        ? answerJSON.answer.resultMetadata.dataTooLarge
        : false;
    const vizState = visualisation && (fullScreenConfigs ? fullScreenConfigs.vizState : (this.state.updatedVizState || this.props.vizState));
    const vizID = fullScreenConfigs ? this.fullVizID : this.vizID;

    if (visualisation.type === "ACHIEVEMENT_RING") {
      vizStyle = vizStyle || {};
      vizStyle.height = 176;
    }

    return (
      <>
        {dataTooLarge ? this.getDataTooLargeMessage(answerJSON) : null}
        <Visualization
          key={vizID + visualisation.type + this.props.showAvailableData}
          data={answerJSON.answer.result}
          id={vizID}
          options={visualisation}
          metaData={answerJSON.answer.visualisation.metadata}
          viewOnly={viewOnly}
          configurations={{
            load: this.vizLoadHandler,
            style: vizStyle,
            curatedAnalysisClickHandler: null,
            // click: this.drilldownHandler,
            currencyFormat: this.props.currencyFormat,
            vizState: vizState && (vizState[visualisation.type] ? vizState : undefined),
            resultMetadata: answerJSON.answer.resultMetadata,
            onStateChange: this.props.isAnalysis ? null : this.vizStateChangeHandler,
            disableSorting: this.props.isAnalysis || (answerJSON.query &&
              (answerJSON.query.rank || answerJSON.query.order)),
            disableClickThrough: this.props.disableClickThrough || false,
            answerGroup:
              !isCardBasic &&
              answerJSON.answer &&
              answerJSON.answer.answerGroup,
            // theme: this.props.theme,
            viewOnly: viewOnly,
            legendPosition:
              this.props.cardType === "BOARD" || this.props.isAnalysis ? "bottom" : undefined,
            autoScaleYAxis: this.props.autoScaleYAxis,
            enableZoom: !(
              this.props.cardType === "BASIC" ||
              this.props.cardType === "BOARD"
            ),
            zoomHandlers: {
              onZoom: this.chartZoomHandler,
              onPan: this.chartPanHandler,
              onResetZoom: this.resetZoomHandler,
            },
            recordAnalyticsEvent: this.recordAnalyticsEvent,
            symbolInHeader: this.props.symbolInHeader,
            // disableSorting: this.props.disableSorting,
            dataTooLarge: dataTooLarge,
            orientation: "horizontal",
            onDataProcess: this.processedDataHandler,
            nestedHeaders: fullScreenConfigs ? fullScreenConfigs.nestedTableHeaders : this.state.nestedTableHeaders,
            updateNestedHeaders: this.updatePivotOptions,
            hideUniqueBOsInSummary: this.props.isAnalysis,
            hideSearch: this.props.isAnalysis,
            hideColumnOptions: this.props.isAnalysis,
          }}
          type={visualisation.type}
          supplementaryData={answerJSON.answer.supplementaryInfo}
        />
        {!this.props.disableOnboarding &&
        this.state.hasVizLoaded &&
        (this.state.showZoomOnboarding ||
          (this.state.showPanOnboarding && this.state.chartZoomed)) &&
        ["TREND", "TARGETED_AGAINST_TREND", "MULTI_MEASURE_TREND"].indexOf(
          visualisation.type
        ) > -1 ? (
          <StyledZoomOnboarding>
            <Blip theme={this.props.theme} />
            <StyledZoomTooltip>
              <Tooltip
                customStyle={`position: relative; max-width: 180px;`}
                heading={
                  this.state.showZoomOnboarding
                    ? "Zoom in on line charts"
                    : "Pan across line chart"
                }
                text={
                  this.state.showZoomOnboarding
                    ? "Drag & select an area on the line chart for zooming in."
                    : "Press and hold shift key for panning across the line chart."
                }
                actions={[
                  {
                    type: "button",
                    text: "Done",
                    onClick: this.state.showZoomOnboarding
                      ? this.zoomOnboardingClickHandler
                      : this.panOnboardingClickHandler,
                  },
                ]}
              />
            </StyledZoomTooltip>
          </StyledZoomOnboarding>
        ) : null}
      </>
    );
  }

  getCopilotVisualization = (answerJSON, fullScreenConfigs) => {
    const visualisation = fullScreenConfigs ? fullScreenConfigs.visualisation : this.state.visualisation;
    const vizID = fullScreenConfigs ? this.fullVizID : this.vizID;

    return (
      <Visualization
        key={vizID + visualisation.type + this.props.showAvailableData}
        data={answerJSON.answer}
        id={vizID}
        vizLibType={VISUALISATION_LIB_TYPES.copilotViz}
      />
    );
  }

  getVisualizationSection = (
    notAuthorized,
    noDataFound,
    nonAggregatable,
    answerJSON,
    isCardBasic,
    isCardBoard,
    longAnswerError,
    fullScreenConfigs,
  ) => {
    // console.log("[getVisualizationSection] answerJSON: ", answerJSON);
    if (this.props.dimensions && !(this.state.vizHeight > 0)) {
      return null;
    }

    let errorCardHeight = this.props.dimensions && this.state.vizHeight - 40;

    if (notAuthorized) {
      return <ErrorCard type="NOT_AUTHORISED" height={errorCardHeight} />;
    }

    if (noDataFound) {
      return <ErrorCard type="DATA_UNAVAILABLE" height={errorCardHeight} />;
    }

    if (nonAggregatable) {
      return <ErrorCard type="NON_AGGREGATABLE" height={errorCardHeight} />;
    }

    if (longAnswerError) {
      return (
        <ErrorCard
          type="LONG_ANSWER"
          height={errorCardHeight}
          message={answerJSON.answer ? answerJSON.answer.message : null}
        />
      );
    }


    if ((!fullScreenConfigs && this.state.visualisation) || (fullScreenConfigs && fullScreenConfigs.visualisation)) {
      const vizHeight = fullScreenConfigs ? fullScreenConfigs.vizHeight : this.state.vizHeight;
      let containerStyle = (this.props.dimensions || fullScreenConfigs)
        ? { height: `${vizHeight - 40}px`, overflowY: "auto" }
        : {};
      let classes = ["viz-container"];

      if (isCardBoard) {
        classes.push("viz-container--board");
      }

      return (
        <div className={classes.join(" ")} style={containerStyle}>
          {answerJSON?.type === CAN_TYPES.insightV2 ? 
            this.getCopilotVisualization(answerJSON, fullScreenConfigs)
            : this.getVisualization(answerJSON, fullScreenConfigs, vizHeight, isCardBasic)
          }
        </div>
      );
    } else {
      return (
        <ErrorCard
          height={errorCardHeight}
          text={
            answerJSON && answerJSON.error && answerJSON.error.displayMessage
          }
        />
      );
    }
  };

  chartZoomHandler = () => {
    let newState;
    if (this.state.showPanOnboarding) {
      newState = {
        chartZoomed: true,
      };
    }
    if (this.state.showZoomOnboarding) {
      newState = newState || {};
      newState.showZoomOnboarding = false;
      this.setZoomOnboardingFlag("zoom");
    }

    if (newState) {
      this.setState(newState);
    }
  };

  chartPanHandler = () => {
    if (this.state.showPanOnboarding) {
      this.setZoomOnboardingFlag("pan");
      this.setState({
        showPanOnboarding: false,
      });
    }
  };

  resetZoomHandler = () => {
    if (this.state.chartZoomed) {
      this.setState({
        chartZoomed: false,
      });
    }
  };

  zoomOnboardingClickHandler = () => {
    console.log("zoomOnboardingClickHandler");
    this.setZoomOnboardingFlag("zoom");
    this.setState({
      showZoomOnboarding: false,
    });
  };

  panOnboardingClickHandler = () => {
    console.log("panOnboardingClickHandler");
    this.setZoomOnboardingFlag("pan");
    this.setState({
      showPanOnboarding: false,
    });
  };

  setVizSettingsOnboarding = () => {
    console.log("setVizSettingsOnboarding");
    let body = {
      userID: this.props.userID,
      type: VIZ_SETTINGS_ONBOARDING_KEY,
    };
    UserConfigService.setOnboardingDiadnosticsFlag(body);
    // if (this.props.analyticsModule) {
    //   this.props.analyticsModule.trackEvent("Auto Analysis Onboarding viewed");
    // }
    this.setState({
      showVizSettingsOnboarding: false,
    });
    if (window.vizSettingsOnboarding === this.vizID) {
      window.vizSettingsOnboarding = undefined;
    }
  };

  closeVizSettingsOnboarding = () => {
    this.setState({
      showVizSettingsOnboarding: false,
    });
    if (window.vizSettingsOnboarding === this.vizID) {
      window.vizSettingsOnboarding = undefined;
    }
  };

  setZoomOnboardingFlag = (type) => {
    let body = {
      userID: this.props.userID,
      type: type === "zoom" ? ZOOM_ONBOARDING_KEY : PAN_ONBOARDING_KEY,
    };
    UserConfigService.setOnboardingDiadnosticsFlag(body);
  };

  // downloadAsCSV = (clickedFrom) => {
  //   if (!this.state.downloadInProgress) {
  //     let columnList = [];
  //     let widgetAnswer = this.props.result.latestAnswer || this.props.result;

  //     if (
  //       widgetAnswer &&
  //       widgetAnswer.answer &&
  //       widgetAnswer.answer.visualisation
  //     ) {
  //       if (
  //         widgetAnswer.answer.visualisation.preferredType &&
  //         widgetAnswer.answer.visualisation.preferredType.type === "TABLE"
  //       ) {
  //         columnList =
  //           widgetAnswer.answer.visualisation.preferredType.configuration.columns.map(
  //             (eachCol) => eachCol.name
  //           );
  //       }
  //       if (widgetAnswer.answer.visualisation.suggestedTypes) {
  //         widgetAnswer.answer.visualisation.suggestedTypes.forEach((each) => {
  //           if (each.type === "TABLE") {
  //             columnList = each.configuration.columns.map(
  //               (eachCol) => eachCol.name
  //             );
  //           }
  //         });
  //       }
  //     }

  //     if (
  //       widgetAnswer.answer &&
  //       widgetAnswer.answer.resultMetadata &&
  //       !widgetAnswer.answer.resultMetadata.dataTooLarge
  //     ) {
  //       let downloadStartTime = new Date().getTime();
  //       const processedData = this.state.processedData;
  //       downloadCSV({
  //         data:
  //           (processedData && processedData.data) || widgetAnswer.answer.result,
  //         measures: widgetAnswer.query.measures,
  //         columnList:
  //           (processedData && processedData.columns) ||
  //           (columnList && columnList.length > 0 ? columnList : null),
  //       });
  //       this.closeDownloadCSVLoader();
  //       let downloadEndTime = new Date().getTime();
  //       this.props.analyticsModule.trackEvent("Download", {
  //         "Long Answer": "FALSE",
  //         "Download Type": "CSV",
  //         From: this.props.fromComponent,
  //         "Wait Duration": (downloadEndTime - downloadStartTime) / 1000,
  //         "Clicked From": clickedFrom,
  //       });
  //     } else {
  //       this.setState({
  //         showDownloadCSVLoader: true,
  //         downloadInProgress: true,
  //       });

  //       this.props.setAppMessage({
  //         message: "Preparing to download " + getTitle(widgetAnswer.query),
  //         type: "system",
  //         icon: Icons.circularLoader,
  //       });

  //       let downloadStartTime = new Date().getTime();

  //       DataService.downloadDataCSV(
  //         {
  //           interpretationId: widgetAnswer.answer.interpretationId,
  //           insightID: widgetAnswer.answer.insightID,
  //         },
  //         widgetAnswer.query
  //       )
  //         .promise.then((response) => {
  //           let downloadEndTime = new Date().getTime();
  //           const url = window.URL.createObjectURL(new Blob([response.data]));
  //           const link = document.createElement("a");
  //           link.href = url;
  //           link.setAttribute("download", "data.zip"); //or any other extension
  //           document.body.appendChild(link);
  //           link.click();
  //           this.closeDownloadCSVProgressLoader();

  //           if (this.props.analyticsModule) {
  //             this.props.analyticsModule.trackEvent("Download", {
  //               "Long Answer": "TRUE",
  //               "Download Type": "CSV",
  //               From: this.props.fromComponent,
  //               "Wait Duration": (downloadEndTime - downloadStartTime) / 1000,
  //               "Clicked From": clickedFrom,
  //             });
  //           }
  //         })
  //         .catch((error) => {
  //           console.log("[downloadAsCSV]: " + error);
  //           this.closeDownloadCSVProgressLoader();
  //         });
  //     }

  //     setTimeout(() => {
  //       this.closeDownloadCSVLoader();
  //     }, 5000);
  //   } else {
  //     this.props.setAppMessage({
  //       message: "Export in progress",
  //       type: "system",
  //       icon: Icons.circularLoader,
  //     });
  //     this.setState({
  //       showDownloadProgressLoader: true,
  //       showDownloadCSVLoader: false,
  //     });

  //     setTimeout(() => {
  //       this.closeDownloadCSVProgressLoader();
  //     }, 5000);
  //   }
  // };

  renderLongAnswerMobileMessage = () => {
    this.setState({
      showDataTooLargeMobileMessage: true,
    });
  };

  closeLongAnswerMobileMessage = () => {
    this.setState({
      showDataTooLargeMobileMessage: false,
    });
  };

  closeDownloadCSVLoader = () => {
    this.setState({
      showDownloadCSVLoader: false,
      showDownloadProgressLoader: false,
      downloadInProgress: false,
    });
  };

  closeDownloadCSVProgressLoader = () => {
    this.setState({
      showDownloadProgressLoader: false,
      showDownloadCSVLoader: false,
    });
  };

  showPivotSettings = () => {
    this.setState({
      showPivotSettings: true,
    });
  };

  hidePivotSettings = () => {
    console.log("hidePivotSettings");
    this.setState({
      showPivotSettings: false,
    });
  };

  updatePivotOptions = (data) => {
    console.log("[updatePivotOptions] data: ", data);
    const fullScreenConfigs = this.state.fullScreenConfigs;

    if(fullScreenConfigs) {
      this.setState({
        fullScreenConfigs: {
          ...fullScreenConfigs,
          nestedTableHeaders: data,
        }
      });
    } else {
      this.setState({
        nestedTableHeaders: data,
      });
    }
  };

  pivotClickHandler = () => {
    this.setFullScreenConfigs(true);
  }

  setFullScreenConfigs = (openPivotSettings) => {
    this.setState({
      fullScreenConfigs: {
        visualisation: {...this.state.visualisation},
        vizHeight: window.innerHeight - 90 - 60 + 16,
        vizState: this.state.currentVizState || this.props.vizState,
        nestedTableHeaders: this.state.nestedTableHeaders,
        openPivotSettings
      }
    });

    if(this.props.fullScreenViewChange) {
      this.props.fullScreenViewChange(true);
    }
  }

  expandClickHandler = () => {
    this.setFullScreenConfigs(false);
  }

  headerCloseClickHandler = () => {
    this.setState({
      showFullScreenConfirmation: true
    });
    // this.setState({
    //   fullScreenConfigs: undefined
    // });
  }

  closeFullScreenConfirmation = (saveChanges) => {
    const newState = {
      showFullScreenConfirmation: false
    };

    if(saveChanges === true) {
      if(this.state.fullScreenConfigs.visualisation.type !== this.state.visualisation.type) {
        newState.visualisation = this.state.fullScreenConfigs.visualisation;
      }

      newState.currentVizState = this.state.fullScreenConfigs.currentVizState;
      newState.updatedVizState = this.state.fullScreenConfigs.currentVizState;
      newState.nestedTableHeaders = this.state.fullScreenConfigs.nestedTableHeaders;
      newState.fullScreenConfigs = undefined;
      this.vizID = `viz-${generateUUID()}`;
      if(this.props.fullScreenViewChange) {
        this.props.fullScreenViewChange(false);
      }
    } else if(saveChanges === false) {
      newState.fullScreenConfigs = undefined;
      if(this.props.fullScreenViewChange) {
        this.props.fullScreenViewChange(false);
      }
    }

    if(saveChanges === true) {
      this.setState(newState, () => {
        this.storeUserPreferences();
      });
    } else {
      this.setState(newState);
    }
  }

  getSelectedViz = (answerJSON) => {
    if(this.state.fullScreenConfigs) {
      return this.state.fullScreenConfigs.visualisation;
    }
    
    if(answerJSON?.type === CAN_TYPES.insightV2) {
      return {
        type: this.state.visualisation
      };
    }

    return this.state.visualisation;
  }

  getDataTooLargeMobileMessage = (answerJSON) => {
    return null;
    // return {
    //   modalType: "information",
    //   component: ViewerBox,
    //   componentProps: {
    //     onClose: this.closeLongAnswerMobileMessage,
    //     title: "Answer exceeds our data limit",
    //     theme: this.props.theme,
    //     disableScroll: true,
    //     content: (
    //       <StyledViewerBoxContent theme={this.props.theme} className="">
    //         Showing and sorting within {answerJSON.answer.result.length} records
    //         out of the total {answerJSON.answer.resultMetadata.length} records
    //         as the answer exceeds our data limit.
    //         <br />
    //         <br />
    //         To view the complete answer, use the web app in your desktop
    //         browser.
    //       </StyledViewerBoxContent>
    //     ),
    //   },
    //   closeModal: this.closeLongAnswerMobileMessage,
    // };
  };

  getAnswerHeader = (result, info, notAuthorized, dataTooLarge, fullScreenConfigs) => {
    const theme = this.props.theme;
    const isCardBasic = this.props.cardType === "BASIC";
    const isCardBoard = this.props.cardType === "BOARD";
    const showLatestPeriodMessage = this.props.showLatestPeriodMessage;
    const answerJSON = result.latestAnswer && result.latestAnswer.answer ? result.latestAnswer : result;
    const visualisation = fullScreenConfigs ? fullScreenConfigs.visualisation : this.state.visualisation;
    // console.log(answerJSON.answer, "answerJSON.answer")
    return (
      <StyledHeader
        className={
          "ans-card__header" +
          (isCardBoard ? " ans-card__header-board" : "")
        }
        ref={this.headerRef}
      >
        {/* (showLatestPeriodMessage || (!isCardBasic && !isCardBoard)) &&
        !info &&
        result.latestAnswer &&
        result.query ? (
          <StyledLatestPeriodMsg className="ans-card__latest-period-msg">
            <LatestPeriod
              latestAnswer={result.latestAnswer}
              query={result.query}
              type={"default"}
            />
          </StyledLatestPeriodMsg>
        ) : null */}
        {
          !this.props.hideTitle ?
          <AnswerTitle title={this.props.title || answerJSON?.additionalInfo?.title?.label} subTitle={answerJSON.answer?.periodDisplayTitle || ""} />
          : null
        }
      </StyledHeader>
    );
  }

  getAnswerBody = (answerJSON, info, notAuthorized, nonAggregatable, noDataFound) => {
    const theme = this.props.theme;
    const isCardBasic = this.props.cardType === "BASIC";
    const isCardBoard = this.props.cardType === "BOARD";
    const errors = answerJSON.answer &&
      answerJSON.answer.errors &&
      answerJSON.answer.errors.length
        ? answerJSON.answer.errors
        : null;
    const longAnswerError =
      answerJSON && answerJSON.answer && answerJSON.answer.code === 205
        ? true
        : false;

    return (
      <StyledAnsBody className="ans-card__body">
        {answerJSON &&
        answerJSON.answer &&
        answerJSON.answer.result &&
        answerJSON.answer.result.length &&
        info
          ? info.map((text) => {
              return (
                <div
                  className="ans-card__info"
                  key={text}
                  ref={this.infoRef}
                >
                  <ContextualMessageBar
                    type="warning"
                    feedback={text}
                    theme={theme}
                  />
                </div>
              );
            })
          : null}
        {errors
          ? errors.map((error) => {
              return (
                <div className="ans-card__errors" ref={this.errorsRef}>
                  <ContextualMessageBar
                    type="warning"
                    feedback={error.message}
                    theme={theme}
                  />
                </div>
              );
            })
          : null}
        {this.getVisualizationSection(
          notAuthorized,
          noDataFound,
          nonAggregatable,
          answerJSON,
          isCardBasic,
          isCardBoard,
          longAnswerError
        )}
        {this.props.footnote ? (
          <Footnote
            text={this.props.footnote}
            theme={theme}
            ref={this.footnoteRef}
          />
        ) : null}
      </StyledAnsBody>
    );
  }

  render() {
    const theme = this.props.theme;
    const result = this.props.result;
    const answerInfo =
      result.answer && result.answer.info ? result.answer.info : null;
    const latestAnswerInfo =
      result.latestAnswer &&
      result.latestAnswer.answer &&
      result.latestAnswer.answer.info
        ? result.latestAnswer.answer.info
        : null;
    let info = [];

    if (answerInfo) {
      info.push(answerInfo);
    }

    if (latestAnswerInfo && info.indexOf(latestAnswerInfo) === -1) {
      info.push(latestAnswerInfo);
    }

    if(this.props.metadata) {
      info.push(this.props.metadata.message);
    }
    // let info = answerJSON.answer && answerJSON.answer.info ? answerJSON.answer.info : null;
    // console.log(info, "info");
    const answerJSON =
      result.latestAnswer && result.latestAnswer.answer
        ? result.latestAnswer
        : result;

    // const info = answerJSON.answer && answerJSON.answer.info ? answerJSON.answer.info : null;
    const errors =
      answerJSON.answer &&
      answerJSON.answer.errors &&
      answerJSON.answer.errors.length
        ? answerJSON.answer.errors
        : null;

    const longAnswerError =
      answerJSON && answerJSON.answer && answerJSON.answer.code === 205
        ? true
        : false;

    const notAuthorized =
      (result.error && result.error.code === 403) ||
      (answerJSON.answer && answerJSON.answer.code === 403);
    const nonAggregatable =
      result.code === 400 ||
      (result.error && result.error.code === 400) ||
      (answerJSON.answer &&
        answerJSON.answer.error &&
        answerJSON.answer.error.code === 400 &&
        answerJSON.answer.error.type === "NON_AGGREGATION");
    let noDataFound = (answerJSON?.answer?.code === 204) || (answerJSON?.answer?.result?.length === 0);
    const cues = this.props.cues;
    const areCuesPresent =
      cues &&
      ((cues.others && cues.others.length) ||
        (cues.suggested && cues.suggested.length));
    const attributeList = [];
    const boList =
      answerJSON.answer &&
      answerJSON.answer.resultMetadata &&
      answerJSON.answer.resultMetadata.attributes &&
      answerJSON.answer.resultMetadata.attributes.length
        ? answerJSON.answer.resultMetadata.attributes.map((attribute) => {
            const businessObjects = attribute.businessObjects
              ? attribute.businessObjects.map((businessObject) => {
                  return {
                    ...businessObject,
                    attributeValue: attribute.type,
                    attributeLabel: attribute.label,
                  };
                })
              : [];

            attributeList.push({
              label: attribute.label,
              value: attribute.type,
              type: "ATTRIBUTE",
            });

            return {
              title: "Explore entities in " + attribute.label + "s",
              options: businessObjects,
            };
          })
        : null;

        
    const cardStyle = this.props.dimensions
      ? { height: `${this.props.dimensions.height}px` }
      : {};
    const headerWarning = this.props.headerWarning;
    const showLatestPeriodMessage = this.props.showLatestPeriodMessage;
    const dataTooLarge =
      answerJSON.answer &&
      answerJSON.answer.resultMetadata &&
      answerJSON.answer.resultMetadata.dataTooLarge
        ? answerJSON.answer.resultMetadata.dataTooLarge
        : false;
    
    if(answerJSON?.type === CAN_TYPES.insightV2 && 
      (answerJSON?.answer && (!answerJSON.answer.columns || answerJSON.answer.columns.length === 0))
    ) {
      noDataFound = true;
    }

    return (
      <StyledCard
        className={
          this.props.cardType
            ? "ans-card__" + this.props.cardType.toLowerCase()
            : ""
        }
        style={cardStyle}
        theme={theme}
        onClick={this.cardClickHandler}
        isAnsRefreshing={this.props.isAnsRefreshing}
        ref={this.cardRef}
      >
        {this.props.blockActions ? (
          <StyledAnsOverlay />
        ) : null}

        {/* {answerJSON.query && !this.props.hideTitle ? ( */}
        {!this.props.hideTitle ? (
          this.getAnswerHeader(result, info, notAuthorized, dataTooLarge)
        ) : null}
        {answerJSON?.answer?.visualisation ?
          <StyledSubHeader>
            <VizSelector data={answerJSON.answer && answerJSON.answer.visualisation}
              selected={this.getSelectedViz(answerJSON)}
              onChange={this.vizChangeHandler}
              dataTooLarge={dataTooLarge}
            />
          </StyledSubHeader>
          : null
        }
        {this.getAnswerBody(answerJSON, info, notAuthorized, nonAggregatable, noDataFound)}
        {!this.props.hideFooter && answerJSON?.additionalInfo?.transparency ?
          <AnswerFooter hideRemoveAnswer={true} transparencyData={answerJSON?.additionalInfo?.transparency}/>
          : null
        }
        {this.state.showDataTooLargeMobileMessage ? (
          <Modal
            className="ans-menu__modal"
            {...this.getDataTooLargeMobileMessage(answerJSON)}
            theme={this.props.theme}
          />
        ) : null}
        {/* {this.state.fullScreenConfigs ? (
          <ModalPositioner parentId="modal-root">
            <FullScreenView theme={this.props.theme} 
              showFullScreenConfirmation={this.state.showFullScreenConfirmation}
              closeFullScreenConfirmation={this.closeFullScreenConfirmation}
              fullScreenHeaderTitle={this.props.fullScreenHeaderTitle}
              escKeyHandler={this.headerCloseClickHandler}
            >
              {answerJSON.query ? (
                this.getAnswerHeader(result, info, notAuthorized, dataTooLarge, this.state.fullScreenConfigs)
              ) : null}
              <StyledFullScreenViz>
                {this.getVisualizationSection(
                  notAuthorized,
                  noDataFound,
                  nonAggregatable,
                  answerJSON,
                  isCardBasic,
                  isCardBoard,
                  longAnswerError,
                  this.state.fullScreenConfigs,
                )}
              </StyledFullScreenViz>
            </FullScreenView>
          </ModalPositioner>
        ) : null} */}
      </StyledCard>
    );
  }
}

export default withTheme(AnswerCard);
