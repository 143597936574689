import React, { useState, useEffect, useRef, MutableRefObject } from "react";
import { useTheme } from 'styled-components';
import {SVGIcon} from '@cuddle-dev/web-components';

//styled components
import {
  Container as StyledContainer,
  InputWrapper as StyledInputWrapper,
  InputBox as StyledInputBox,
  Input as StyledInput,
  Suggestion as StyledSuggestion,
  HiddenText as StyledHiddenText,
  Send as StyledSend,
  Cross as StyledCross
} from "./styled-components";

//images
import { ReactComponent as SendIcon } from "../../../../../images/send_24.svg";

function AskBar({
  onChange,
  suggestionText,
  value,
  onAsk,
  clearAskInput,
  placeholder,
  customStyle,
  disabled,
  isDropdownOpen,
  hideSend,
  ...otherProps
}) {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState("");
  // const [suggestionWidth, setSuggestionWidth] = useState(0);
  // const [hiddenTextWidth, setHiddenTextWidth] = useState(0);
  const [inputPadding, setInputPadding] = useState(0);
  const [inputWidth, setInputWidth] = useState(0);
  const suggestionRef:MutableRefObject<HTMLDivElement> = useRef();
  const hiddenTextRef:MutableRefObject<HTMLDivElement> = useRef();
  const inputRef:MutableRefObject<HTMLInputElement> = useRef();

  const changeHandler = (event) => {
    setInputValue(event.target.value);
  };

  // console.log(onAsk, otherProps, "AskBar");

  const onAskClick = () => {
    // console.log(value, inputValue, "onAskClick", otherProps, onAsk);
    if (onAsk) {
      onAsk(value || inputValue);
    }
  };

  const onAskClear = () => {
    console.log(value, inputValue, "onAskClear", otherProps, clearAskInput);
    setInputValue("");
    if (clearAskInput) {
      clearAskInput();
    }
  };

  const calculateInputWidth = () => {
    if (suggestionText && (value || inputValue)) {
      const inputBoxWidth = inputRef.current.offsetWidth;
      const inputValueWidth = hiddenTextRef.current.offsetWidth + 2; // 2 to comensate for left padding in input element
      const suggestionWidth = suggestionRef.current.offsetWidth + 2; // 2 for spacing before the suggestion text; same is added in CSS
      const inputDiff = inputBoxWidth - inputValueWidth;

      if (inputDiff < suggestionWidth) {
        setInputPadding(Math.max(inputBoxWidth - inputValueWidth, 0));
        setInputWidth(inputValueWidth);
      } else {
        setInputPadding(inputDiff);
        setInputWidth(inputBoxWidth - inputDiff);
      }
    } else {
      setInputPadding(0);
      setInputWidth(0);
    }
  };

  useEffect(() => {
    // if(suggestionText) {
    //     // setSuggestionWidth(suggestionRef.current.offsetWidth);

    // }
    calculateInputWidth();
  }, [suggestionText]);

  useEffect(() => {
    if (suggestionText) {
      // setHiddenTextWidth(hiddenTextRef.current.offsetWidth);
      calculateInputWidth();
    }
  }, [value, inputValue]);

  const isSendDisabled = disabled || !(value || inputValue);
  return (
    <StyledContainer hideSend={hideSend}>
      <StyledInputWrapper
        customStyle={customStyle}
        disabled={disabled}
        isDropdownOpen={isDropdownOpen}
      >
        <StyledInputBox>
          <StyledInput
            placeholder={placeholder || "Ask a question"}
            {...otherProps}
            value={value || inputValue}
            onChange={!disabled ? (onChange || changeHandler) : null}
            // suggestionWidth={suggestionWidth}
            // hiddenTextWidth={hiddenTextWidth}
            inputPadding={inputPadding}
            ref={inputRef}
          />
          <StyledHiddenText ref={hiddenTextRef}>
            {value || inputValue}
          </StyledHiddenText>
          {suggestionText && (value || inputValue) ? (
            <StyledSuggestion
              ref={suggestionRef}
              inputWidth={inputWidth}
              inputPadding={inputPadding}
            >
              {suggestionText}
            </StyledSuggestion>
          ) : null}
        </StyledInputBox>
      </StyledInputWrapper>
      {/* <StyledCross onClick={(value || inputValue) ? onAskClear : null} show={value || inputValue} >
        <IconWrapper url={Icons.close} />
      </StyledCross> */}
      {!hideSend ?
        <StyledSend onClick={!disabled ? onAskClick : null} isDisabled={isSendDisabled}>
          <SVGIcon Icon={SendIcon} 
            width={24} 
            height={24} 
            fill={isSendDisabled ? theme["icon_disabled"] : theme["icon_04"].light}
          />
          {/* <IconWrapper url={Icons.send} /> */}
        </StyledSend>
        : null
      }
    </StyledContainer>
  );
}

export default AskBar;
