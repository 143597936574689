import styled from "styled-components";
import { getStyleString } from "../../../../../utils/styleUtils";

interface sqlProps {
    maxHeight?: number;
}

interface arrowProps {
    disabled?: boolean;
}

interface dotProps {
    selected?: boolean;
}

interface copyButtonProps {
    clickable?: boolean;
}

export const Container = styled.div`
    padding: 24px;
`;

export const Loader = styled.div`
    & .loader-div:last-child {
        margin-top: 26px;
    }
`;

export const Title = styled.div`
    color: ${props => props.theme["text_02"].light};
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
    .title-bold {
        font-weight: 600;
    }
`;
// ${props => getStyleString(props.theme["text_02_med"])};

export const CopyButton = styled.span<copyButtonProps>`
    position: absolute;
    top: 12px;
    right: 12px;
    padding: 8px;
    border-radius: 8px;
    background: ${props => props.theme["ui_05"]};
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.09);
    display: none;
    ${props => props.clickable ? "cursor: pointer;" : ""}
`;

export const SQL = styled.div<sqlProps>`
    position: relative;
    margin-top: 12px;
    border-radius: 8px;
    padding: 16px;
    background: ${props => props.theme["ui_04"]};
    color: ${props => props.theme["text_01"].light};
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    word-break: break-all;
    overflow: auto;
    box-sizing: border-box;
    ${props => props.maxHeight ? `max-height: ${props.maxHeight}px;` : ""}

    &:hover ${CopyButton} {
        display: block;
    }
`;

export const Nav = styled.div`
    position: relative;
    margin-top: 20px;
    height: 28px;
    text-align: center;
`;

export const LeftArrow = styled.div<arrowProps>`
    position: absolute;
    width: 28px;
    height: 28px;
    left: 0px;
    top: 0px;
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
`;

export const RightArrow = styled.div<arrowProps>`
    position: absolute;
    width: 28px;
    height: 28px;
    right: 0px;
    top: 0px;
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
`;

export const Dots = styled.span<dotProps>`
    display: inline-block;
    height: 28px;
    padding: 10px 0px;
    box-sizing: border-box;
`;

export const Dot = styled.span<dotProps>`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${props => props.theme[props.selected ? "ui_interactive" : "ui_01"]};
    display: inline-block;
    margin-left: 10px;
    vertical-align: top;

    &:fisrt-child {
        margin-left: 0px;
    }
`;

export const CopyIcon = styled.span<dotProps>`
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
`;

export const CopyText = styled.span<dotProps>`
    margin-left: 4px;
    color: ${(props) =>  props.theme["text_03"].light};
    ${(props) => getStyleString(props.theme["web_ui"])};
    display: inline-block;
    vertical-align: middle;
`;