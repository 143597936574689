import styled, { keyframes } from "styled-components";
import { getStyleString } from "../../utils/styleUtils.js";

export const Card = styled.div`
  border: 1px solid ${(props) => props.theme["border_03"]};
  border-radius: 12px;
  background: #FFF;
  position: relative;

  .viz-container {
    position: relative;
  }

  .viz-container .ring-chart {
    margin-left: 32px;
  }

  .ans-card__feedback {
    display: none;
    position: absolute;
    right: 12px;
    top: 16px;
  }

  &:hover {
    .ans-card__feedback {
      display: block;
    }

    .ans-card__header-board .ans-menu {
      display: block;
    }
  }

  .data-large-warning {
    &.in-progress .link {
      color: ${(props) => props.theme["textDisabled"]}!important;
    }
  }

  .ans-card__board, .ans-card__basic {
    .ans-card__body, .ans-card__header {
        padding: 12px 20px!important;
    }

    .ans-card__header {
        border-bottom: none!important;
    }
  }

  .answer-footer {
    margin-top: 0px;
    padding: 16px 20px;
    border-top: 1px solid ${(props) => props.theme["border_03"]};
  }
`;

export const ZoomOnboarding = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 200;
`;

export const AutoAnalysisOnboarding = styled.div`
  position: absolute;
  top: 0%;
  left: 0%;
  transform: translate(calc(50% + -8px), calc(-100% + 32px));
  z-index: 200;
`;

export const ZoomTooltip = styled.div`
  margin-top: 16px;
  text-align: left;
`;

export const ExploreEntitiesOnboarding = styled.div`
  vertical-align: bottom;
  position: relative;
  margin-left: 5px;
  display: inline-block;
  margin-top: 24px;
`;

export const ExploreEntitiesOnboardingTooltip = styled.div`
  text-align: left;
  position: absolute;
  top: calc(100% + 8px);
  left: 8px;
  z-index: 200;
  width: 240px;
  box-sizing: border-box;
`;

export const FooterLoader = styled.div`
  .card-loader {
    margin-right: 12px;
    border-radius: 8px;
    height: 36px;
    width: 160px;
  }
`;

export const ViewerBoxContent = styled.div`
  ${(props) => `
    color: ${props.theme["text01"]};
    ${getStyleString(props.theme.uiRegular)}
`}
`;

export const SettingsButton = styled.span`
  margin-left: 20px;
  position: relative;
`;

export const ExpandIcon = styled.span`
  width: 32px;
  height: 32px;
  cursor: pointer;
  padding: 4px;
  box-sizing: border-box;
`;

export const Header = styled.div`
  border-bottom: 1px solid ${(props) => props.theme["border_03"]};
  padding: 12px 20px 20px 20px;
  position: relative;

  .ans-card__header-warning {
      margin-top: 20px;
  }

  .nlg-div {
      margin-top: 24px;
  }

  .ans-card__header-board {
    border-bottom: none;
    .ans-menu {
        display: none;
    }
  }
`;

export const SubHeader = styled.div`
  padding: 10px 14px;
  border-bottom: 1px solid ${(props) => props.theme["border_03"]};
`;

export const HeaderCloseIcon = styled.span`
  width: 32px;
  height: 32px;
  cursor: pointer;
  padding: 6px;
  box-sizing: border-box;
  margin-left: 8px;
`;

export const SubTitle = styled.div`
  display: flex;
  align-items: center;
`;  

export const HeaderActions = styled.div`
  position: absolute;
  right: 20px;
  top: 15px;
  display: flex;
  align-items: center;
`;

export const FullScreenViz = styled.div`
  margin: 4px 20px;
`;

export const TransparencyText = styled.div`
  color: ${props => props.theme["text_03"].light};
  ${props => getStyleString(props.theme["text_01_med"])};
  cursor: pointer;
  margin-top: 24px;
  display: flex;
  align-items: center;

  span:last-child {
    margin-left: 8px;
  }
`;

export const LatestPeriodMsg = styled.div`
  margin-bottom: 12px;
  width: calc(100% - 36px);
`;

export const AnsBody = styled.div`
  padding: 12px 8px;

  .data-large-warning {
      margin-bottom: 12px;
  }

  .ans-card__info,  .ans-card__errors {
      margin-bottom: 12px;
  }
`;

export const AnsOverlay = styled.div`
  width: 100%;
  background-color: #FFF;
  opacity: 0.6;
  position: absolute;
  left: 0px;
  bottom: 0px;
  top: 0px;
  z-index: 300;
  border-radius: 16px;
`;