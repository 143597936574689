import React, {useState, useEffect, useRef} from "react";

//CSS
// import "./EmailLogin.scss";

//services
import httpRequestService from "../../../../utils/httpRequest.js";
import LoginInput from "./loginInput";
import {ContextualMessageBar} from "@cuddle-dev/web-components";
import {Button} from "@cuddle-dev/web-components";

// styled components
import {
  MainContainer as StyledMainContainer,
  Header as StyledHeader,
  Button as StyledButton,
  OrBlock as StyledOrBlock,
  BackLinkDiv as StyledBackLinkDiv,
  LinkSpan as StyledLinkSpan,
  ErrorContainer as StyledErrorContainer,
  FormField as StyledFormField,
  LinkContainer as StyledLinkContainer
} from "./styled-components";

const EMAIL_FILTER = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

function EmailLogin(props) {
  const googleButton = useRef();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [internalError, setInternalError] = useState("");
  const [credentialsError, setCredentialsError] = useState(false);
  const [googleButtonPresent, setgoogleButtonPresent] = useState();
  const loginInProgress = useRef(false);
  const forgotPasswordLink = useRef(`https://docs.google.com/forms/d/e/1FAIpQLScZB_vIieDqKNEha8xPQBReYJDyQabggfNcixLe3kI6A8zJ_Q/viewform?entry.1498715930=${
    window.location.hostname.split(".")[0]
  }`);

  useEffect(() => {
    if(googleButton?.current) {
      props.onGoogleButtonRender(googleButton.current);
    } else {
      setgoogleButtonPresent(false);
    }
  }, []);

  useEffect(() => {
    if(googleButtonPresent && googleButton?.current) {
      props.onGoogleButtonRender(googleButton.current);
      setgoogleButtonPresent(true);
    }
  }, [googleButtonPresent]);

  function emailChangeHandler(event) {
    setEmail(event.target.value);
    setErrorMsg("");
    setInternalError("");
    setCredentialsError(false);
  };

  function passwordChangeHandler(event) {
    setPassword(event.target.value);
    setErrorMsg("");
    setCredentialsError(false);
  };

  function emailBlurChangeHandler() {
    if (email && !EMAIL_FILTER.test(email)) {
      setInternalError("Incorrect email format");
    }
  };

  function submitHandler(event) {
    if (!email) {
      setCredentialsError(true);
      setErrorMsg("Please enter an email ID");
    } else if (!password) {
      setCredentialsError(true);
      setErrorMsg("Please enter a password");
    } else {
      loginInProgress.current = true;
      httpRequestService.getAuthToken(
        "login/token",
        {
          username: email,
          password: password,
          grant_type: "password",
        },
        (response) => {
          loginInProgress.current = false;
          if (response.status === "OK") {
            props.onLoginSuccess(response);
          } else if (
            response.status === "UNKNOWN" ||
            response.status === "INCORRECT_PASSWORD"
          ) {
            setCredentialsError(true);
            setErrorMsg("Incorrect email or password.");
          }
        },
        (err) => {
          loginInProgress.current = false;
          if (
            err &&
            err.message &&
            err.message.indexOf("authType") > -1 &&
            err.message.indexOf("not allowed") > -1
          ) {
            props.onLoginSuccess(err);
          } else if (err) {
            setCredentialsError(true);
            setErrorMsg("Incorrect email or password.");
          }
          console.log("Error during authentication call", err);
        }
      );
    }

    event.preventDefault();
  };

  return (
    // <div className={"email-login" + (this.state.credentialsError ? " email-login--errored" : "")}>
    <StyledMainContainer>
      <form onSubmit={loginInProgress.current ? null : submitHandler}>
        {props.showOtherLoginOptions ? (
          <StyledHeader>
            <StyledOrBlock>
              Or
            </StyledOrBlock>
          </StyledHeader>
        ) : null}

        <StyledFormField>
          <LoginInput
            className="input-box"
            // ref={(inp) => { this.emailInputElement = inp; }}
            type="text"
            style={{ borderRadius: "4px 4px 0px 0px" }}
            value={email}
            theme={props.theme}
            onChange={emailChangeHandler}
            onBlur={emailBlurChangeHandler}
            placeholder={"Email"}
            error={internalError}
            autoFocus={true}
            required
          />
        </StyledFormField>
        <StyledFormField>
          <LoginInput
            style={{ borderRadius: "0px 0px 4px 4px", borderTop: "none" }}
            className="input-box"
            type="password"
            value={password}
            theme={props.theme}
            onChange={passwordChangeHandler}
            placeholder={"Password"}
            kind="password"
            required
          />
        </StyledFormField>
        {credentialsError && (
          <StyledErrorContainer>
            <ContextualMessageBar
              type="error"
              feedback={errorMsg}
              theme={props.theme}
              width={"full"}
              style={{ width: "100%" }}
            />
          </StyledErrorContainer>
        )}
        <StyledFormField isSubmit>
          <StyledButton
            btnActive={
              email &&
              password &&
              !internalError
            }
            theme={props.theme}
            type="submit"
            buttonType={"primary"}
          >
            Log in
          </StyledButton>
        </StyledFormField>
      </form>
      <StyledLinkContainer>
        <a href={forgotPasswordLink.current} target="_blank">
          <Button buttonType="link">
            Forgot Password?
          </Button>
        </a>
      </StyledLinkContainer>
    </StyledMainContainer>
  );
}

export default EmailLogin;
