import React, { useEffect, useState } from "react";
import { useTheme } from 'styled-components';
// import {Button, ButtonType} from '@cuddle-dev/web-components';
import AssistanceDropdown from "./assistance-dropdown/AssistanceDropdown";
import AssistanceTextbox from "./assistance-textbox/AssistanceTextbox";

//styled-components
import {
  Container as StyledContainer,
  FieldLabel as StyledFieldLabel
} from "./styled-components";

function processData(data) {
  const keys = Object.keys(data);
  const processedData = {};
  keys.map(key => {
    const fieldData = data[key];

    if(fieldData.type === "BUSINESS_OBJECT" || fieldData.type === "MEASURE") {
      const dropdownData = fieldData.values.map(d => {
        return {
          value: fieldData.type === "BUSINESS_OBJECT" ? `${d.entity}_${d.attributeType}` : d.entity,
          label: fieldData.type === "BUSINESS_OBJECT" ? `${d.label} in ${d.attributeTypeLabel}` : d.label,
          originalData: d
        }
      });

      processedData[key] = {
        data: dropdownData,
        value: dropdownData[0],
        label: fieldData.info.token
      };
    } else if(fieldData.type === "TEXT") {
      processedData[key] = {
        value: "",
        label: fieldData.info.question
      };
    } else {
      processedData[key] = {
        value: ""
      }
    }
  });

  return processedData;
}

function AssistanceSection(props) {
  const theme = useTheme();
  const [processedData, setProcessedData] = useState(processData(props.data));

  useEffect(() => {
    checkCompleteness(processedData);
  }, []);

  function dropdownChangeHandler(key, data) {
    const newProcessedData = {...processedData};
    newProcessedData[key].value = data;
    setProcessedData(newProcessedData);
    checkCompleteness(newProcessedData);
  }

  function textboxChangeHandler(key, data) {
    const newProcessedData = {...processedData};
    newProcessedData[key].value = data;
    setProcessedData(newProcessedData);
    checkCompleteness(newProcessedData);
  }

  function getField(fieldKey, fieldData) {
    const type = fieldData.type;

    switch(type) {
      case "BUSINESS_OBJECT":
      case "MEASURE":
        return <AssistanceDropdown data={processedData[fieldKey].data} 
          selected={processedData[fieldKey].value}
          dataKey={fieldKey}
          changeHandler={dropdownChangeHandler}
        />;
      case "TEXT":
        return <AssistanceTextbox value={processedData[fieldKey].value} 
          dataKey={fieldKey} 
          textboxChangeHandler={textboxChangeHandler} 
        />;
      default:
        return null;
    }
  }

  function checkCompleteness(data) {
    const keys = Object.keys(data);
    for(let i = 0; i < keys.length; i++) {
      if(!data[keys[i]].value) {
        props.updateSectionData(props.sectionIndex, null);
        return false;
      }
    }

    props.updateSectionData(props.sectionIndex, data);
    return true;
  }

  const keys = Object.keys(props.data);

  // console.log("AssistanceSection data: ", props.data);
  // console.log("AssistanceSection processedData: ", processedData);

  return <StyledContainer gridColumn={keys.length > 1 ? 2 : 1}>
    {keys.map(key => {
      return (
        <div key={key}>
          {processedData[key].label ? <StyledFieldLabel>{processedData[key].label}</StyledFieldLabel> : null}
          {getField(key, props.data[key])}
        </div>
      );
    })}
  </StyledContainer>
}

export default AssistanceSection;
