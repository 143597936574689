import React, { useState, useEffect, useRef } from 'react';
// import './loginInput.scss';
import {Tooltip, SVGIcon} from '@cuddle-dev/web-components';
import { useTheme } from 'styled-components';

// styled components
import {
    ShowHidePassword as StyledShowHidePassword,
    InputContainer as StyledInputContainer,
    ShowErrorIcon as StyledShowErrorIcon,
} from "./styled-components";

// images
import { ReactComponent as WarningIcon} from "../../../../../images/warning-filled-20.svg";

function LoginInput(props) {
    const theme = useTheme();
    const [isShown, setIsShown] = useState(false);
    const [inputType, setInputType] = useState(null);
    const [showTooltip, setShowTooltip] = useState(true);
    const nameInput = useRef();

    useEffect(() => {
        if(props.autoFocus) {
            nameInput.current.focus();
        }
    }, []);

    function handleClick() {
        setInputType(isShown ? 'password' : 'text');
        setIsShown(!isShown);
        
        // this.setState((state) => ({
        //     isShown: !state.isShown,
        //     type: state.isShown ? 'password' : 'text'
        // }))
    }

    function handleMouseOver() {
        setShowTooltip(true);
        // this.setState({
        //     showTooltip: true
        // })
        console.log("enter")
    }

    function handleMouseLeave() {
        setShowTooltip(false);
        // this.setState({
        //     showTooltip: false
        // })
    }

    const {type, ...otherProps} = props;
    
    return(
        <StyledInputContainer  style={props.style} error={props.error}>
            <input ref={nameInput} 
                type={inputType || type}
                {...otherProps}
            />
            {!props.error && props.kind === 'password' && 
                <StyledShowHidePassword show={isShown} disabled={!props.value} onClick={handleClick}>
                    {isShown ? 'Hide' : 'Show'}
                </StyledShowHidePassword>
            }
            {props.error && 
                <StyledShowErrorIcon onMouseEnter={handleMouseOver} onMouseLeave={handleMouseLeave}>
                        <SVGIcon Icon={WarningIcon} width={20} height={20} fill={theme["icon_interactive_negative"]}/>
                        {props.error && showTooltip && 
                            // <div className="tooltip-box">
                                <Tooltip text={props.error}
                                    style={{
                                        zIndex: 1,
                                        'width': 'max-content',
                                        left: '-76px'}
                                    }
                                />
                            // </div>
                        }
                </StyledShowErrorIcon>
            }
            
        </StyledInputContainer>
    );
}

export default LoginInput