import styled from "styled-components";
import { getStyleString } from "../../../../../../utils/styleUtils";

export const Container = styled.div`
    padding: 20px;
    border-radius: 8px;
    background: ${props => props.theme["ui_04"]};
`;

export const Title = styled.div`
    color: ${props => props.theme["text_02"].light};
    ${props => getStyleString(props.theme["text_02_med"])};
    margin-bottom: 12px;
`;

export const Text = styled.pre`
    font-family: "IBM Plex Mono";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 0px;
`;