import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Define a type for the slice state
interface LoaderState {
  show: boolean;
  message: null | string;
}

// Define the initial state using that type
const initialState: LoaderState = {
  show: false,
  message: null,
};

export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    show: (state, action: PayloadAction<string>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.show = true;
      state.message = action.payload;
    },
    hide: (state) => {
      state.show = false;
      state.message = null;
    },
    trigger_state: (state, action) => {
      state.show = false;
      state.message = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { show, hide, trigger_state } = loaderSlice.actions;

export default loaderSlice.reducer;
