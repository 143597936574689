import React from "react";
import { useTheme } from 'styled-components';
import SectionTitle from "../section-title/SectionTitle";
import {SVGIcon, Tooltip} from '@cuddle-dev/web-components';

// styled components
import {
    Container as StyledContainer,
    AgentBox as StyledAgentBox,
    AgentTitle as StyledAgentTitle,
    AgentDescription as StyledAgentDescription,
    EntitiesTable as StyledEntitiesTable,
    EntityRow as StyledEntityRow,
    EntityName as StyledEntityName,
    EntityValues as StyledEntityValues,
    EntityValue as StyledEntityValue,
    ValueTitle as StyledValueTitle,
    ValueName as StyledValueName,
    ValueDescription as StyledValueDescription,
    EntityNameText as StyledEntityNameText,
    Info as StyledInfo,
    InfoTooltip as StyledInfoTooltip,
    EntityValueText as StyledEntityValueText,
    EntityValueNameText as StyledEntityValueNameText,
    EntityLink as StyledEntityLink
} from "./styled-components";

// images
import { ReactComponent as InfoIcon } from "../../../../../images/info_20.svg";

function EntitiesSection(props) {
    const theme = useTheme();

    const agentData = props.data.data;
    // console.log("props.sqlData: ", props.sqlData);

    return <StyledContainer>
        <SectionTitle title={props.data.label} info={props.data.info}/>
        <StyledAgentBox>
            <StyledAgentTitle>{agentData.label}</StyledAgentTitle>
            <StyledAgentDescription>{agentData.description}</StyledAgentDescription>
            {agentData.entities ?
                <StyledEntitiesTable>
                    {agentData.entities.map((entity, index) => {
                        return <StyledEntityRow key={index}>
                            <StyledEntityName>
                                <StyledEntityNameText>{entity.label}</StyledEntityNameText>
                                {entity.info ?
                                    <StyledInfo>
                                        <SVGIcon Icon={InfoIcon} width={20} height={20} fill={theme["icon_03"].light}/>
                                        <StyledInfoTooltip>
                                            <Tooltip style={{position: "relative"}} 
                                                text={entity.info}
                                            />
                                        </StyledInfoTooltip>
                                    </StyledInfo>
                                    : null
                                }
                            </StyledEntityName>
                            <StyledEntityValues>
                                {entity.values.map((entityValue, index) => {
                                    return <StyledEntityValue key={index}>
                                        {entityValue.title ? 
                                            <StyledValueTitle>
                                                <StyledEntityValueText>{entityValue.title.label}</StyledEntityValueText>
                                                {entityValue.title.info ?
                                                    <StyledInfo leftMargin={8}>
                                                        <SVGIcon Icon={InfoIcon} width={20} height={20} fill={theme["icon_03"].light}/>
                                                        <StyledInfoTooltip position="bottom">
                                                            <Tooltip style={{position: "relative"}} 
                                                                text={entityValue.title.info}
                                                            />
                                                        </StyledInfoTooltip>
                                                    </StyledInfo>
                                                    : null
                                                }
                                            </StyledValueTitle>
                                            : null
                                        }
                                        {entityValue.name ? 
                                            <StyledValueName>
                                                <StyledEntityValueNameText>{entityValue.name.label}</StyledEntityValueNameText>
                                                {entityValue.name.info ?
                                                    <StyledInfo leftMargin={8}>
                                                        <SVGIcon Icon={InfoIcon} width={20} height={20} fill={theme["icon_03"].light}/>
                                                        <StyledInfoTooltip position="bottom">
                                                            <Tooltip style={{position: "relative"}} 
                                                                text={entityValue.name.info}
                                                            />
                                                        </StyledInfoTooltip>
                                                    </StyledInfo>
                                                    : null
                                                }
                                            </StyledValueName>
                                            : null
                                        }
                                        {entityValue.description ?
                                            <StyledValueDescription>{entityValue.description}</StyledValueDescription>
                                            : null
                                        }
                                    </StyledEntityValue>
                                })}
                            </StyledEntityValues>
                        </StyledEntityRow>
                    })}
                    {props.sqlData || props.promptData ?
                        <StyledEntityRow key={"sql-row"}>
                            <StyledEntityName>
                                <StyledEntityNameText>{"Advanced details"}</StyledEntityNameText>
                            </StyledEntityName>
                            <StyledEntityValues>
                                {props.sqlData && <StyledEntityValue>
                                    <StyledEntityValueText>{"SQL details"}</StyledEntityValueText>
                                    <StyledEntityLink onClick={props.handleSQLDetailsClick}>{"SQL Details ↗"}</StyledEntityLink>
                                </StyledEntityValue>}
                                {props.promptData && <StyledEntityValue>
                                    <StyledEntityValueText>{"prompt details"}</StyledEntityValueText>
                                    <StyledEntityLink onClick={props.handlePromptDetailsClick}>{"Prompt Details ↗"}</StyledEntityLink>
                                </StyledEntityValue>}
                            </StyledEntityValues>
                        </StyledEntityRow>
                        : null
                    }
                </StyledEntitiesTable>
                : null
            }
        </StyledAgentBox>
    </StyledContainer>
}

export default EntitiesSection;