import httpRequestService from '../utils/httpRequest';
import {
    getNodeServerURL
} from '../utils/utilityFunctions';

class UserConfigService {
    constructor() {
        this.BASE_URL = getNodeServerURL();
    }

    setOnboardingDiadnosticsFlag = (body) => {
        let url = `${this.BASE_URL}onboarding-state`;
        httpRequestService.postRequest(url, body, 
            (resp)=> {
                console.log('[setOnboardingDiadnosticsFlag] Onboarding state updation successful');
            }, 
            (error)=> {
                console.log('[setOnboardingDiadnosticsFlag] Error while updating onboarding state. Error: ', error);
            }
        );
    }

    updateTrackDetails = (data, successCb, errorCb) => {
        let url = `${this.BASE_URL}trackboard/track-details`;

        httpRequestService.postRequest(url, data, successCb, errorCb);
    }
}



export default new UserConfigService();
