class SessionService {
    setItem = (key, value) => {
        sessionStorage.setItem(key, value);
    }

    getItem = (key) => {
        return sessionStorage.getItem(key);
    }
}

export default new SessionService();