import styled from "styled-components";

export const MainContainer = styled.div`
    height: 100%;
    position: relative;
    width: 100%;
    height: 100%;
`;

export const LoginContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #ffffff;
    margin-bottom: 92px;

    @media screen and (min-width: 1024px) {
        flex-direction: row;
        height: 100vh;
    }
`;

export const LeftSection = styled.div`
    width: 100%;
    height: 56px;
    position: relative;
    background: ${props => props.theme["interface_background"]};

    @media screen and (min-width: 1024px) {
        width: 60%;
        height: 100%;
    }
`;

export const LoginHeader = styled.div`
    padding: 8px 11px;
    align-items: center;
    display: flex;

    @media screen and (min-width: 1024px) {
        padding: 51px 66px;
    }
`;

export const HeaderLogo = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    img {
        height: 40px;
    }

    @media screen and (min-width: 1024px) {
        img {
            height: 60px;
        }
    }
`;

export const HeaderSeparator = styled.div`
    display: inline-block;
    vertical-align: middle;
    width: 2px;
    height: 18px;
    margin-left: 8px;
    margin-right: 8px;
    background-color: ${props => props.theme["text_03"][props.backgroundbrightness]};

    @media screen and (min-width: 1024px) {
        height: 27px;
        margin-left: 32px;
        margin-right: 32px;
    }
`;

export const HeaderText = styled.div`
    width: 82px;
    display: inline-block;
    vertical-align: middle;
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: ${props => props.theme["text_02"][props.backgroundbrightness]};

    @media screen and (min-width: 1024px) {
        width: 137px;
        font-size: 20px;
    }
`;

export const LoginContentText = styled.div`
    display: none;
    max-width: 700px;
    position: absolute;
    width: 80%;
    height: 368px;
    padding-left: 80px;
    top: 50%;
    transform: translate(0%, -50%);

    @media screen and (min-width: 1024px) {
        display: block;
    }
`;

export const LoginOptionsContainer = styled.div`
    width: 100%;
    height: calc(100vh - 56px);
    padding-top: 32px;
    box-sizing: border-box;

    @media screen and (min-width: 1024px) {
        width: 40%;
        min-width: 400px;
        height: 100%;
        padding-top: 0px;
    }
`;

export const LoginHolder = styled.div`
    background-color: #ffffff;
    position: relative;
    height: 100% ;
    padding: 0 40px;
    display: flex;
    justify-content: center;

    @media screen and (min-width: 1024px) {
        align-items: center;
    }
`;