import httpRequestService from '../utils/httpRequest';

 import {
    generateQueryString,
    getOrganizationURL
 } from '../utils/utilityFunctions';

 class OrganizationUserService {
    constructor() {    
        this.BASE_URL = '/nodeserver/organisation/user';
        this.ORGAINISATION_BASE_URL = getOrganizationURL() + "user";
    }

    addUser = (project, successCb, errorCb) => {
        // project = addProjectMetaData(project);
        httpRequestService.postRequest(this.BASE_URL, project, successCb, errorCb);
    }
    
    getUsers = (query, successCb, errorCb) => {
        let url =  generateQueryString(`${ this.BASE_URL}`, query);
        httpRequestService.getRequest(url, successCb, errorCb);
    }

    getUser = (query, successCb, errorCb) => {
        let url = `${ this.BASE_URL}/${query.id}`;
        httpRequestService.getRequest(url, successCb, errorCb);
    }

    updateUserDetails = (user, successCb, errorCb) => {
        let url = `${ this.BASE_URL}`;
        // project = addProjectMetaData(project);
        httpRequestService.putRequest(url, user, successCb, errorCb);
    }

    deleteUser = (query, successCb, errorCb) => {
        let url = `${ this.BASE_URL}/${query.userID}`;
        httpRequestService.deleteRequest(url, null, successCb, errorCb);
    }

    deleteMultipleUser = (userList,  successCb, errorCb) => {
        let url = `${ this.BASE_URL}`;
        httpRequestService.deleteRequest(url, userList, successCb, errorCb);
    }
    
    changePassword = (data, successCb, errorCb) => {
        console.log("pass", data)
        let url = `${ this.ORGAINISATION_BASE_URL}/changepassword`;
        httpRequestService.putRequest(url, data, successCb, errorCb);
    }

    generatePassword = (data, successCb, errorCb) => {
        let url = `${this.BASE_URL}/generatepassword`;
        httpRequestService.getRequest(url, successCb, errorCb);
    }

    resetPassword = (query, data, successCb, errorCb) => {
        let url = `${ this.BASE_URL }/resetPassword`;
        httpRequestService.putRequest(url, data, successCb, errorCb);        
    }

    addProjectsToUser = (query, data, successCb, errorCb) => {
        let url = `${this.BASE_URL}/${query.userID}/project`;
        httpRequestService.postRequest(url, data, successCb, errorCb);
    }

    removeProjectsFromUser = (query, data, successCb, errorCb) => {
        let url = `${this.BASE_URL}/${query.userID}/project`;
        httpRequestService.deleteRequest(url, data, successCb, errorCb);
    }
    
    getUserTimezone = (query, successCb, errorCb) => {
        let url = `${ this.BASE_URL }/${query.userID}/timezone`;
        httpRequestService.getRequest(url, successCb, errorCb);        
    }

    updateUserTimezone = (query, data, successCb, errorCb) => {
        let url = `${ this.BASE_URL }/${query.userID}/timezone`;
        delete query.userID;
        httpRequestService.putRequest(generateQueryString(url, query), null, successCb, errorCb);        
    }

    bulkUpdateUserTimezone = (query, data, successCb, errorCb) => {
        let url = `${ this.BASE_URL }/timezone`;
        httpRequestService.putRequest(generateQueryString(url, query), data, successCb, errorCb);    
    }

    acceptPrivacyPolicy = (query, data, successCb, errorCb) => {
        let url = `${ this.BASE_URL }/${query.userID}/acceptprivacypolicy`;
        httpRequestService.putRequest(url, data, successCb, errorCb);    
    }

    checkCCAccess = (query, successCb, errorCb) => {
        let url = `${ this.BASE_URL }/${query.userID}/${query.projectId}/checkCCAccess`;
        httpRequestService.getRequest(url, successCb, errorCb);        
    }

    checkOrgAccess = (query, data, successCb, errorCb) => {
        let url = `${ this.BASE_URL }/${query.userID}/${query.projectId}/checkOrgAccess`;
        httpRequestService.getRequest(url, successCb, errorCb);        
    }
};

export default new OrganizationUserService;