import React from "react";
import { useTheme } from 'styled-components';

// styled components
import {
    Container as StyledContainer,
    Cell as StyledCell,
    FillerCell as StyledFillerCell,
    CellLabel as StyledCellLabel,
    CellContent as StyledCellContent,
    Tags as StyledTags,
    Tag as StyledTag
} from "./styled-components";

function getRowsAndColumnsCount(data) {
    const rows = data.length;
    let columns = 0;

    data.forEach(row => {
        columns = Math.max(columns, row.length);
    });

    return {
        rows,
        columns
    };
}

function getContent(cellData) {
    switch(cellData.type) {
        case "TEXT":
            return cellData.data;
        case "LIST":
            return (
                <StyledTags>
                    {cellData.data.map(datum => <StyledTag key={datum}>{datum}</StyledTag>)}
                </StyledTags>
            );
    }
}

function TableSection(props) {
    // const theme = useTheme();
    const sectionData = props.data.data;
    const rowColumns = getRowsAndColumnsCount(sectionData);

    return <StyledContainer $rowColumns={rowColumns}>
        {sectionData.map((row, rowIndex) => {
            const cells = [];

            row.forEach((cell, cellIndex) => {
                cells.push(
                    <StyledCell key={`${rowIndex}_${cellIndex}`} $noTopBorder={!rowIndex} $noLeftBorder={!cellIndex}>
                        <StyledCellLabel>{cell.label}</StyledCellLabel>
                        <StyledCellContent>{getContent(cell)}</StyledCellContent>
                    </StyledCell>
                )
            });

            for(let i = row.length; i < rowColumns.columns; i++) {
                cells.push(<StyledFillerCell key={`${rowIndex}_${i}`} $noTopBorder={!rowIndex} $noLeftBorder={!i} />);
            }

            return cells;
        })}
    </StyledContainer>
}

export default TableSection;