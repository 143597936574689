import React, { useState, useEffect } from "react";
import {Button, SVGIcon} from "@cuddle-dev/web-components";
import { useTheme } from 'styled-components';
import { useAppSelector, useAppDispatch } from '../../../stores/redux-hooks';
import { createNewSession, add_temp_session, TEMP_SESSION_NAME } from "../../../reducers/chat-session-slice";
import Sessions from "./sessions/Sessions";
import ProjectDropdown from "../../main/NavBar/project-dropdown";

// styled components
import {
    Container as StyledContainer,
    Header as StyledHeader,
    Content as StyledContent,
    NewConvo as StyledNewConvo,
    SessionList as StyledSessionList,
    Loader as StyledLoader,
    LoadingBar as StyledLoadingBar
} from "./styled-components";

//images
import { ReactComponent as NavIcon} from "../../../images/nav-toggle-20.svg"; 
import { ReactComponent as AddIcon} from "../../../images/add_20.svg"; 

function SidePane(props) {
    const theme = useTheme();
    const creatingNewSession = useAppSelector(
        (state) => state.chat.creatingNewSession
    );
    const activeSession = useAppSelector(
        (state) => state.chat.activeSession
    );
    const sessionDates = useAppSelector((state) => state.chat.dates);
    const dispatch = useAppDispatch();
    // const history = useHistory();

    function newConvoHandler() {
        dispatch(add_temp_session({}));
    }

    const conversationsLoaded = sessionDates?.length;
    
    return <StyledContainer>
        <StyledHeader>
            <ProjectDropdown />
            <Button
                startEnhancer={<SVGIcon Icon={NavIcon} width={24} height={24} fill={theme["icon_02"].light}/>}
                buttonType="buttonIcon"
                onClick={props.togglePanel}
            />
        </StyledHeader>
        <StyledContent>
            <StyledNewConvo disabled={creatingNewSession || (activeSession === TEMP_SESSION_NAME)}>
                {conversationsLoaded ?
                    <Button
                        startEnhancer={<SVGIcon Icon={AddIcon} width={24} height={24} fill={theme["icon_04"].light}/>}
                        buttonType={"primary"}
                        text={"New conversation"}
                        style={{ width: "100%" }}
                        onClick={creatingNewSession || (activeSession === TEMP_SESSION_NAME) ? null : newConvoHandler}
                    />
                    : null
                }
            </StyledNewConvo>
            {!conversationsLoaded ?
                <StyledLoader>
                    <StyledLoadingBar><div className="shimmer-bar"></div></StyledLoadingBar>
                    <StyledLoadingBar><div className="shimmer-bar"></div></StyledLoadingBar>
                    <StyledLoadingBar><div className="shimmer-bar"></div></StyledLoadingBar>
                    <StyledLoadingBar><div className="shimmer-bar"></div></StyledLoadingBar>
                </StyledLoader>
                : null
            }
            <StyledSessionList>
                <Sessions addSession={newConvoHandler}/>
            </StyledSessionList>
        </StyledContent>
    </StyledContainer>
}

export default SidePane;