import styled from "styled-components";

// import { getStyleString } from "../../utils/styleUtils";

export const Shimmer = styled.div`
    height: ${props => props.height}px;
    position: relative;
    overflow: hidden;
    width: ${props => props.width ? `${props.width}px` : "100%" };
    border-radius: 12px;

    .shimmer-back {
        border-radius: 3px;
        background: linear-gradient(90deg, rgba(217, 217, 217, 0.00) -0.95%, #C7CDD7 20.33%, rgba(217, 217, 217, 0.00) 98.67%);
        height: ${props => props.height}px;
        width: 100%;
    }

    .shimmer-top {
        width: 73%;
        height: 150%;
        flex-shrink: 0;
        background: linear-gradient(110deg, rgba(255, 255, 255, 0.00) 32.24%, rgba(255, 255, 255, 0.70) 45.05%, rgba(255, 255, 255, 0.70) 56.09%, rgba(255, 255, 255, 0.00) 71.59%);
        mix-blend-mode: overlay;
        filter: blur(12.5px);
        position: absolute;
        top: 0px;
        animation-name: shimmerTop;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    @keyframes shimmerTop {
        0% {left: -40%;}
        70% {left: 55%}
        100% {left: -40%;}
    }
`;