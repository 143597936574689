import styled from "styled-components";
import { getStyleString } from "../../utils/styleUtils";

export const Container = styled.div`
    overflow-x: auto;
}`;

export const Message = styled.div`
    margin-top: 12px;
`;

// export const Title = styled.div`
//     margin-bottom: 12px;
//     ${(props) => `
//         color: ${props.theme["text02"]};
//         ${getStyleString(props.theme["ui"])};
//     `}
// }`;