const syncGatewayURL = `${window.location.origin}/syncgateway/`;
const authenticationURL = "/nodeserver/auth/";
const organizationURL = "/organization/";
const widgetURL = "/widget/";
const adminURL = "/admin/";
const configurationapiURL = "/configurationapi/";
const nodeServerURL = "/nodeserver/";
const kapiURL = "/kapi/";
const suggestQuestionsURL = "/question-recommendation/";
const dremioServerURL = "/datahub";
const assetURL = `/asset/file`;
// const websocketURL = `wss://${window.location.host}/${process.env.REACT_APP_COPILOT_ENV === "POC" ? "copilot-poc" : "nova"}/`;
const autoSuggestWebsocketURL = `wss://${window.location.host}/nodeserver/`;
const websocketURL = `wss://${window.location.host}/copilot-orchestrator`;
const copilotServerURL = process.env.REACT_APP_COPILOT_ENV === "POC" ? "/copilot-poc/" : "/nova/";
const copilotOrchestratorURL = "/copilot-orchestrator";
// console.log("process.env: ", process.env);
const agentDocUrl = '/agent-document/'

export default {
    syncGatewayURL,
    authenticationURL,
    organizationURL,
    widgetURL,
    adminURL,
    configurationapiURL,
    nodeServerURL,
    agentDocUrl,
    kapiURL,
    suggestQuestionsURL,
    dremioServerURL,
    assetURL,
    websocketURL,
    copilotServerURL,
    copilotOrchestratorURL,
    autoSuggestWebsocketURL
};