import React from "react";

import websocketClient from "./websocket-client.js";
import {getCookie, generateUUID, getAutoSuggestWebsocketURL} from "./utilityFunctions.js";
import { debounce } from 'underscore';

//services
import SessionService from "../services/session.service.js";

//constants
const SUGGESTION_CATEGORIES = {
    "MEASURE": {
        text: "Measure",
        abbr: "M",
        color: "#7E5BEF",
        backgroundColor: "#F2EFFD"
    },
    "ATTRIBUTE": {
        text: "Attribute",
        abbr: "A",
        color: "#F19153",
        backgroundColor: "#FFF6F0"
    },
    "BUSINESS_OBJECT": {
        text: "Business Object",
        abbr: "BO",
        color: "#F76394",
        backgroundColor: "#FFF2F6"
    }
};

function helper() {
    let startCursor = 0;
    let endCursor = 0;
    let prevString = "";
    let resolvedEntities = [];
    let activeWebsocketClient;
    let activeAutoSuggestRequest;
    let debouncedSendWSMessage = debounce(sendWSMessage, 300);
    let temporaryData = undefined;

    function startWebsocketConnection(data) {
        closeWebsocketConnection();

        let currentProject = SessionService.getItem("currentProject");
        let queryParams = {};

        // if(currentProject) {
        //     queryParams["projectid"] = JSON.parse(currentProject).id;
        // }

        websocketClient(
            {
                queryParams,
                onMessage: (message) => {
                    console.log("[websocketMessageHandler - onMessage] message: ", message);
            
                    if(message && activeAutoSuggestRequest === message.correlationID) {
                        data.websocketMessageHandler(message);
                    }
                },
                onDisconnect: data.onDisconnect,
                path: getAutoSuggestWebsocketURL() + "auto-suggest"
            },
            (client) => {
                activeWebsocketClient = client;
                data.onConnect();
            },
            getCookie("accesstoken"),
            (currentProject && JSON.parse(currentProject).id),
            getCookie("userID") || data.userID
        );
    }

    function closeWebsocketConnection() {
        if(activeWebsocketClient && activeWebsocketClient.client) {
            activeWebsocketClient.client.close();
        }
    }

    function sendWSMessage(questionText, activeWords) {
        if(questionText && activeWebsocketClient && activeWebsocketClient.client) {
            // console.log("[sendWSMessage] questionText: ", questionText, this.activeWord, this.activeSpan);
            // this.setState({
            //     suggestions: []
            // }, () => {
                // console.log("suggestions cleared.");
                // console.log("[sendWSMessage] activeWords: ", activeWords);
                activeAutoSuggestRequest = generateUUID();
                activeWebsocketClient.send({
                    "active": activeWords.activeWord || "",
                    "question": questionText,
                    "span": activeWords.activeWord || "", //(this.activeSpan && this.activeSpan.text) || this.activeWord,
                    "correlationID": activeAutoSuggestRequest,
                    "offset": startCursor,
                    "data": resolvedEntities.map(re => re.originalData)
                });
            // });
        } else {
            console.log("[sendWSMessage] websocket was disconnected.");
        }
    }

    function updateWebsocketCaller(autosuggestSettings) {
        debouncedSendWSMessage = debounce(sendWSMessage, (autosuggestSettings && autosuggestSettings.interval) || 300);
    }

    function sendWebsocketRequest(inputQuestion, activeWords) {
        debouncedSendWSMessage(inputQuestion, activeWords);
    }

    function getStartCursor() {
        return startCursor;
    }

    function setStartCursor(index) {
        if(index !== startCursor) {
            startCursor = index;
        }
    }

    function getEndCursor() {
        return endCursor;
    }

    function setEndCursor(index) {
        if(index !== endCursor) {
            endCursor = index;
        }
    }

    function getResolvedEntities() {
        return resolvedEntities;
    }

    function setResolvedEntities(data) {
        resolvedEntities = data;
    }

    function getTemporarySelection() {
        return temporaryData;
    }

    function reset() {
        resolvedEntities = [];
        startCursor = 0;
        endCursor = 0;
        prevString = "";
        temporaryData = undefined;
    }

    function clearTemporarySelection(reset) {
        if(temporaryData) {
            if(reset) {
                temporaryData = undefined;
            } else {
                const questionText = temporaryData.inputQuestion;

                startCursor = temporaryData.startCursor;
                endCursor = temporaryData.endCursor;
                resolvedEntities = temporaryData.resolvedEntities;
                prevString = temporaryData.prevString;
                temporaryData = undefined;
                
                return questionText;
            }
        }
    }

    function cursorChanged(element) {
        let currentString = element.value;
        let activeWords;
    
        // console.log("$$$$$ cursorChanged: ", element.selectionStart, element.selectionEnd, currentString, prevString);
        if(prevString !== currentString) {
            let prevLength = prevString.length;
            let currentLength = currentString.length;
            let addition = "";
    
            if(currentLength > prevLength) {
                //added
                if(startCursor !== endCursor) {
                    addition = currentString.substring(startCursor, element.selectionStart);
                    // console.log("$$$$$ Added: ", addition);
                    // console.log("$$$$$ Deleted: ", prevString.substring(startCursor, endCursor));
                } else {
                    addition = currentString.substring(startCursor, element.selectionStart);
                    // console.log("$$$$$ Added: ", addition);
                }
            } else if(currentLength < prevLength) {
                //deleted
                if(startCursor !== endCursor) {
                    addition = currentString.substring(startCursor, element.selectionStart);
                    // console.log("$$$$$ Deleted: ", prevString.substring(element.selectionStart, endCursor));
                    // console.log("$$$$$ Added: ", addition);
                } else {
                    // console.log("$$$$$ Deleted: ", prevString.substring(element.selectionStart, endCursor));
                }
            } else {
                //replaced
                addition = currentString.substring(startCursor, element.selectionStart);
                // console.log("$$$$$ Replaced: ", addition);
            }
    
            activeWords = getActiveWords(prevString, currentString, startCursor, endCursor, element.selectionStart, addition);
    
            startCursor = element.selectionStart;
            endCursor = element.selectionEnd;
            prevString = currentString;
            //character deletion
            //character addition
            //selection deletion
            //selection replace
            //paste text
            return activeWords;
        } else {
            startCursor = element.selectionStart;
            endCursor = element.selectionEnd;
        }
    
        return activeWords || {
            activeWord: undefined, // this.activeWord,
            activeSpan: undefined // this.activeSpan
        };
    }
    
    function getActiveWords(prevString, currentString, prevStart, prevEnd, currentPos, textAdded) {
        // console.log("getActiveWords prevStart ", prevStart);
        // console.log("getActiveWords prevEnd ", prevEnd);
        // console.log("getActiveWords currentPos ", currentPos);
        let newResolvedEntities = [...resolvedEntities];
        let activeWordStart = 0;
        // let activeSpan = this.activeSpan && this.activeSpan.editedEntity ? {...this.activeSpan} : undefined; 
        let editedEntityIndexes = [];
        let characterDiff = currentString.length - prevString.length;
    
        // if(activeSpan) {
        //     if(prevStart > activeSpan.start && (prevStart <= activeSpan.end || (prevStart === (activeSpan.end + 1) && characterDiff < 0))) {
        //         activeSpan.end = (activeSpan.end + characterDiff);
        //         if(activeSpan.start === activeSpan.end) {
        //             activeSpan = undefined;
        //         }
        //     } else {
        //         activeSpan = undefined;
        //     }
        // }
    
        for(let i = 0; i < newResolvedEntities.length; i++) {
            let entity = newResolvedEntities[i];
    
            // if(entity.start < prevStart && (entity.end >= prevStart || (prevStart === (entity.end + 1) && characterDiff < 0))) {
            if(((prevStart < entity.start || (prevStart === 0 && entity.start === 0)) && prevEnd > entity.end) || 
                (prevStart > entity.start && prevStart <= entity.end) ||
                (prevEnd > entity.start && prevEnd <= entity.end) ||
                (prevStart === (entity.end + 1) && textAdded !== " ")
            ) {
                //entity has been edited
                //update entity end
                //set update span
                // console.log("$$$$$ edit in between entity: ", entity);
                // activeWordStart = entity.start;
                editedEntityIndexes.push(i);
                // activeSpan = {
                //     start: entity.start,
                //     end: (entity.end + characterDiff),
                //     editedEntity: true
                // };
                entity.end = entity.end + characterDiff;
                // if(activeSpan.start === activeSpan.end) {
                //     activeSpan = undefined;
                // }
            } else if(entity.start > prevEnd) {
                //update entity extremes
                // console.log("$$$$$ edit before entity: ", entity);
                entity.start = entity.start + characterDiff;
                entity.end = entity.end + characterDiff;
            } else if(entity.end < prevStart) {
                // console.log("$$$$$ edit after entity: ", entity);
                activeWordStart = Math.max(activeWordStart, (entity.end + 1));
            }
        }
    
        if(editedEntityIndexes.length) {
            newResolvedEntities = newResolvedEntities.filter((entity, i) => editedEntityIndexes.indexOf(i) === -1);
        }
    
        let activeWord = currentString.substring(activeWordStart, currentPos).replace(/^\s+/, '');;
    
        // activeSpan = activeSpan || {
        //     start: activeWordStart,
        //     end: currentPos,
        //     editedEntity: false
        // };
        // activeSpan.text = currentString.substring(activeSpan.start, activeSpan.end);
    
        // this.activeWord = activeWord;
        // this.activeSpan = activeSpan;
        resolvedEntities = newResolvedEntities;
    
        // console.log("$$$$$ this.activeWord: ", this.activeWord);
        // console.log("$$$$$ this.activeSpan: ", this.activeSpan);
        // console.log("$$$$$ this.resolvedEntities: ", this.resolvedEntities);
    
        return {
            activeWord,
            // activeSpan
        };
    }

    function resolveSuggestion(suggestionData, inputQuestion, isTemporary) {
        let originalData = suggestionData.originalData;
        let questionText = inputQuestion;
        let offset = originalData.offset;
        let spanEnd = offset + originalData.span.length;
        let label = originalData.label;

        console.log("temporaryData: ", temporaryData);

        if(temporaryData) {
            startCursor = temporaryData.startCursor;
            endCursor = temporaryData.endCursor;
            resolvedEntities = temporaryData.resolvedEntities;
            prevString = temporaryData.prevString;
            questionText = temporaryData.inputQuestion;
        }

        console.log("startCursor: ", startCursor);
        console.log("endCursor: ", endCursor);
        console.log("resolvedEntities: ", resolvedEntities);
        console.log("prevString: ", prevString);
        console.log("questionText: ", questionText);

        if(isTemporary) {
            temporaryData = {
                startCursor: startCursor,
                endCursor: endCursor,
                inputQuestion: questionText,
                resolvedEntities: resolvedEntities,
                prevString: prevString,
                suggestionData
            };
        }

        if(originalData.category === "CURATED_ANALYSIS") {
            label = originalData.text;   
        }

        let newCursorPos = offset + label.length;

        startCursor = newCursorPos + 1;
        endCursor = newCursorPos + 1;

        let newText = questionText.substring(0, offset) + label + (questionText[spanEnd] !== " " ? " " : "") + questionText.substring(spanEnd);
        let newResolvedEntities = [...resolvedEntities];
        let insertionIndex = -1;
        // console.log("resolvedEntities: ", resolvedEntities);
        
        newResolvedEntities.forEach((entity, index) => {
            if(offset > entity.end) {
                insertionIndex = index;
            } else {
                entity.start =  entity.start + (newText.length - prevString.length);
                entity.end =  entity.end + (newText.length - prevString.length);
            }
        });

        newResolvedEntities.splice((insertionIndex + 1), 0, {
            start: offset,
            end: newCursorPos - 1,
            originalData
        });

        // console.log("newText: ", newText);
        resolvedEntities = newResolvedEntities;
        prevString = newText;

        return newText;
    }

    function getSuggestions(data) {
        // console.log("[getSuggestions] data: ", data);
        let measureLabels = [];
        let measureDuplicates = [];
        let boLabels = [];
        let boLabelIndexes = [];
        let boLabelDuplicates = [];
        let boSynonyms = [];
        let boSynonymDuplicates = [];
        let suggestionMatches = [];

        let suggestions = data.map((datum, index) => {
            let subTitle = "";
            let label = datum.label;
            let wordToMatch = datum.span;
            let synonyms;
            // console.log("[getSuggestions] label: ", label);
            // console.log("[getSuggestions] wordToMatch: ", wordToMatch);
            if(datum.category === "CURATED_ANALYSIS") {
                label = getBoldLabel(datum.text, wordToMatch);
            } else {
                let lowerCasedWordToMatch = wordToMatch.toLowerCase();
                if(label.toLowerCase().indexOf(lowerCasedWordToMatch) > -1) {   
                    // console.log("[getSuggestions] Label match, label: ", label);
                    label = getBoldLabel(label, wordToMatch);
                    suggestionMatches[index] = "LABEL";
                } else if(datum.value && datum.category === "BUSINESS_OBJECT" && datum.value.toLowerCase().indexOf(lowerCasedWordToMatch) > -1) {
                    // console.log("[getSuggestions] Value match, label: ", label);
                    label = getBoldLabel(`${label} - ${datum.value}`, wordToMatch);
                    suggestionMatches[index] = "VALUE";
                } else if(datum.matchedTokens && datum.matchedTokens.length && datum.matchedTokens[0].toLowerCase().indexOf(lowerCasedWordToMatch) > -1) {
                    // console.log("[getSuggestions] Synonym match, label: ", label);
                    synonyms = getSynonymns(datum.matchedTokens, wordToMatch);
                    suggestionMatches[index] = "SYNONYM";
                }

                if(datum.category === "BUSINESS_OBJECT") {
                    subTitle = datum.typeLabel ? `in ${datum.typeLabel}` : "";

                    let matchedLabelIndex = boLabels.indexOf(datum.label);

                    if(matchedLabelIndex > -1) {
                        boLabelDuplicates.push(boLabelIndexes[matchedLabelIndex]);
                        boLabelDuplicates.push(index);
                    }

                    boLabels.push(datum.label);
                    boLabelIndexes.push(index);

                    if(datum.matchedTokens && datum.matchedTokens.length) {
                        let matchedSynonymIndex = boSynonyms.indexOf(datum.matchedTokens[0]);

                        if(matchedSynonymIndex > -1) {
                            boSynonymDuplicates.push(matchedSynonymIndex);
                            boSynonymDuplicates.push(index);
                        }

                        boSynonyms.push(datum.matchedTokens[0]);
                    }
                } else if(datum.category === "MEASURE") {
                    let matchedIndex = measureLabels.indexOf(datum.label);

                    if(matchedIndex > -1) {
                        measureDuplicates.push(matchedIndex);
                        measureDuplicates.push(index);
                    }

                    measureLabels.push(datum.label);
                }
            }
            
            return {
                label,
                value: datum.typeId + index, //datum.transactionName
                category: SUGGESTION_CATEGORIES[datum.category],
                originalData: datum,
                synonyms,
                subTitle
            };
        });
        // console.log("[getSuggestions] measureDuplicates: ", measureDuplicates);
        // console.log("[getSuggestions] boLabelDuplicates: ", boLabelDuplicates);
        measureDuplicates.forEach(dupIndex => {
            suggestions[dupIndex].subTitle = `in ${suggestions[dupIndex].originalData.transactionLabel}`;
        });

        boLabelDuplicates.forEach(dupIndex => {
            if(suggestionMatches[dupIndex] === "LABEL") {
                suggestions[dupIndex].label = getBoldLabel(`${data[dupIndex].label} - ${data[dupIndex].value}`, data[dupIndex].span);
            } else if(suggestionMatches[dupIndex] === "SYNONYM" && boSynonymDuplicates.indexOf(dupIndex) > -1) {
                suggestions[dupIndex].label = getBoldLabel(`${data[dupIndex].label} - ${data[dupIndex].value}`, data[dupIndex].span);
            }
        });
        
        return suggestions;
    }

    function getBoldLabel(text, boldText, isSelectionBold) {
        let index = text.toLowerCase().indexOf(boldText.toLowerCase());

        if(index > -1) {
            let beforeSelection = text.substring(0, index);
            let selection = text.substring(index, index + boldText.length);
            let afterSelection = text.substring(index + boldText.length);

            return (
                <span>
                    <span>{beforeSelection}</span>
                    <b>{selection}</b>
                    <span>{afterSelection}</span>
                </span>
            );
        }
        return text;
    }

    function getSynonymns(matchedTokens, activeWord) {
        return [getBoldLabel(matchedTokens[0], activeWord, true), ...matchedTokens.slice(1)];
    }

    return {
        startWebsocketConnection,
        closeWebsocketConnection,
        updateWebsocketCaller,
        sendWebsocketRequest,
        cursorChanged,
        getStartCursor,
        setStartCursor,
        getEndCursor,
        setEndCursor,
        getResolvedEntities,
        setResolvedEntities,
        reset,
        resolveSuggestion,
        getSuggestions,
        clearTemporarySelection,
        getTemporarySelection
    }
}

export default helper;