import styled from "styled-components";

export const Header = styled.div`
  background: ${props => props.theme["ui02"]};
  // box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.06);
  padding: 6px 12px;
  position: absolute;
  // top: 44px;
  width: 100%;
  height: 44px;
  box-sizing: border-box;
  z-index: 10;
`;

export const Hamburger = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: inline-block;

  svg path {
    fill: ${props => props.theme["icon02"]};
  }
`;

export const Add = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;
  float: right;

  svg {
    width: 32px;
    height: 32px;
  }

  svg path {
    fill: ${props => props.theme["icon02"]};
  }
`;