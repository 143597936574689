import styled from "styled-components";

export const Container = styled.div``;

export const Text = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #5D697A;
`;