import {generateQueryString} from "./utilityFunctions.js";

const websocketClient = (options = {}, onConnect = null) => {
    let url = options.path || "";
    console.log("websocketClient url: ", url);
    // console.log("websocketClient accesstoken: ", accesstoken);
    // console.log("websocketClient projectID: ", projectID);
    // console.log("websocketClient userID: ", userID);
    if (options.queryParams) {
        url = generateQueryString(url, options.queryParams);
        // console.log("[websockets] url: ", url);
    }

    let client = new WebSocket(url);

    client.addEventListener("open", () => {
        console.log(`[websockets] Connected to ${url}`);
        if(onConnect) {
            onConnect(connection);
        };
    });

    client.addEventListener("close", () => {
        console.log(`[websockets] Disconnected from ${url}`);
        client = null;
    });

    client.addEventListener("message", (event) => {
        console.log(`[websockets] message`, event, new Date());
        if(event && event.data && options.onMessage) {
            try {
                let data = JSON.parse(event.data);
                options.onMessage(data, connection);
            } catch(error) {
                console.log(`[websockets] message not a JSON: `, event && event.data);
            }
        }
    });

    client.addEventListener("error", (event) => {
        if(options.onError) {
            options.onError(event, connection);
        }
        console.log("WebSocket error: ", event);
    });

    const connection = {
        id: options.id,
        client,
        send: (message = {}) => {
            console.log("[websockets] send message: ", message, new Date());
            // if(options.queryParams) {
            //     message = { ...message };
            // }

            if(client && client.send) {
                return client.send(JSON.stringify(message));
            }

            console.log("[websockets] send message - No client found");
        },
    };

    return connection;
};

export default websocketClient;