import React from "react";
import ReactMarkdown from "react-markdown";
// import rehypeMathjax from "rehype-mathjax";
import remarkGfm from "remark-gfm";
// import remarkMath from "remark-math";

import { Container as StyledContainer } from "./styled-components.js";

function MarkdownReader(props) {
  return (
    <StyledContainer>
      <ReactMarkdown
        children={props.markdownText}
        remarkPlugins={[remarkGfm]}
        //remarkPlugins={[remarkGfm, remarkMath]}
        //rehypePlugins={[rehypeMathjax]}
      />
    </StyledContainer>
  );
}

export default MarkdownReader;
