import React from "react";
import { useTheme } from 'styled-components';
import { SVGIcon } from "@cuddle-dev/web-components";

// styled components
import {
    Container as StyledContainer,
    SectionLabel as StyledSectionLabel,
    SectionText as StyledSectionText,
    Arrow as StyledArrow,
    Filler as StyledFiller
} from "./styled-components";

// images
import { ReactComponent as ArrowIcon } from "../../../../../images/arrow_right_20.svg";

function getGridTemplateValues(length) {
    let template = "";

    for(let i = 0; i < length; i++) {
        if(i) {
            template += "32px ";
        }
        template += "1fr ";
    }
    return template.trim();
}

function Section(props) {
    const labelOrder = props.dataIndex * 2 + 1;
    const textOrder = props.totalSections * 2 + props.dataIndex * 2;

    return <>
        <StyledSectionLabel order={labelOrder}>{props.data.label}</StyledSectionLabel>
        <StyledSectionText order={textOrder}>{props.data.data}</StyledSectionText>
    </>
}

function TransformSection(props) {
    const theme = useTheme();
    const sectionData = props.data.data;
    
    // The elements inside container are all arranged using order property.
    // This is done so that the height of the labels in the first row and 
    // the height of the grey boxes for text in the secon row
    // are synced with each other.
    
    return <StyledContainer $gridtemplate={getGridTemplateValues(sectionData.length)}>
        {sectionData.map((datum, index) => {
            const extraDivOrder = index * 2;
            const arrowOrder = sectionData.length * 2 + index * 2 - 1;

            return <>
                {!!index && 
                    <>
                        <StyledFiller order={extraDivOrder} key={"filler" + extraDivOrder}></StyledFiller>
                        <StyledArrow order={arrowOrder} key={"arrow" + arrowOrder}>
                            <SVGIcon Icon={ArrowIcon} 
                                width={32} 
                                height={32} 
                                fill={theme["icon_02"].light}
                            />
                        </StyledArrow>
                    </>
                }
                <Section data={datum} key={"section" + index} dataIndex={index} totalSections={sectionData.length} />
            </>
        })}
    </StyledContainer>
}

export default TransformSection;