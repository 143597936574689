import httpRequestService from '../utils/httpRequest';
import {
    getConfigurationapiURL, getNodeServerURL, generateQueryString
 } from '../utils/utilityFunctions';

// import newHttpRequestService from "../utils/httpRequest";

import newHttpRequestService from '../utils/newHTTPrequest';

 class ConfigurationAPIService {
    constructor() {
        // 'https://dev.cuddle.ai/kapi/businessobject/list'
        this.BASE_URL = getConfigurationapiURL();
        this.GUIDE_URL = `${getNodeServerURL()}configurationapi/guide`;
        this.NODE_BASE_URL = `${getNodeServerURL()}`
    }

    getSpecificSetting = (query, successCb, errorCb) => {
        let url = `${this.BASE_URL}/settings?filter=${query.filterValue}`;
        httpRequestService.getRequest(url, successCb, errorCb);
    }

    updateBO = (updateDeleteRequest, successCb, errorCb) => {
        let url = this.BASE_URL;
        console.log(url);
        httpRequestService.putRequest(url + "/", updateDeleteRequest, successCb, errorCb);
    }

    getCuddleconfig = (query, successCb, errorCb) => {
        let url = "cuddleconfig/settings";
        if(query && query.filterValue) {
            url += `?filter=${query.filterValue}`
        }
        httpRequestService.getRequest(url, successCb, errorCb);
    }

    // updateBOSynonyms= (updateDeleteRequest, successCb, errorCb) => {
    //     let url = this.BASE_URL;
    //     httpRequestService.putRequest(url, updateDeleteRequest, successCb, errorCb);
    // }

    getOnboardingQuestions = (query, successCb, errorCb) => {
        let url = `${this.BASE_URL}onboarding/questions?userID=${query.userID}`;
        httpRequestService.getRequest(url, successCb, errorCb);
    } 
    
    getAllGuides = (query) => {
        let url = `${this.GUIDE_URL}?userID=${query.userID}`;
        return httpRequestService.getRequestPromise(url);
    } 

    getResourcetype = (query) => {
        let url = `${this.GUIDE_URL}/resourcetype?userID=${query.userID}`;
        return httpRequestService.getRequestPromise(url);
    } 

    deleteGuide = (query, data) => {
        let url = `${this.GUIDE_URL}/${data.id}?userID=${query.userID}`;
        return newHttpRequestService.delete(url);
    }

    createGuides = (query, data) => {
        let url = `${this.GUIDE_URL}/batch?userID=${query.userID}`;
        return newHttpRequestService.post(url, data);
    }

    updateGuides = (query, data) => {
        let url = `${this.GUIDE_URL}/batch?userID=${query.userID}`;
        return newHttpRequestService.put(url, data);
    }

    getProjectInfo = (query) =>  {
        const url = generateQueryString(`${this.BASE_URL}info`, query);
        return newHttpRequestService.get(url);
    };

 }

 export default new ConfigurationAPIService;