import React, { useState, useEffect } from "react";

// import "./LoginOptions.scss";

import EmailLogin from "./email-login/EmailLogin";
import LoginButtons from "./login-buttons/LoginButtons";
import LoginLoader from "./login-loader/LoginLoader";
import PrivacyPolicy from "./privacy-policy/PrivacyPolicy";
import googleHelper from "../../../utils/googleSignIn.js";
import microsoftHelper from "../../../utils/microsoftSignIn.js";
import oauthHelper from "../../../utils/oauth.js";
import {
  setCookie,
  deleteAllCookies,
} from "../../../utils/utilityFunctions";
import { SYSTEM_SETTING_TYPES } from "../../../constants/";
import {ContextualMessageBar} from "@cuddle-dev/web-components";

// styled components
import {
  LoginText as StyledLoginText,
  MainContainer as StyledMainContainer,
  Retry as StyledRetry,
  ErrorContainer as StyledErrorContainer
} from "./styled-components";

//services
import LoginService from "../../../services/login.service.js";
import ConfigurationAPIService from "../../../services/configurationapi.service";


function LoginOptions(props) {
  const [showEmailLogin, setShowEmailLogin] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showSupportInfo, setShowSupportInfo] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [showLoaderText, setShowLoaderText] = useState(false);
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
  const [loginResponse, setLoginResponse] = useState(); //used to store response temporarily till privacy policy is accepted
  const [loginMethodUsed, setLoginMethodUsed] = useState(); //used to store login method temporarily till privacy policy is accepted
  const [loginOptions, setLoginOptions] = useState();

  useEffect(() => {
    fetchLoginOptions();
  }, []);

  useEffect(() => {
    if(props.loginWarningProjectAlert) {
      setShowError(true);
      setErrorMsg(props.projectAlert);
      setShowSupportInfo(true);
    }
  }, [props.loginWarningProjectAlert]);

  // componentDidUpdate = (prevProps, prevState) => {
  //   if (
  //     !prevProps.loginWarningProjectAlert &&
  //     this.props.loginWarningProjectAlert
  //   ) {
  //     this.setState({
  //       showError: true,
  //       errorMsg: this.props.projectAlert,
  //       showSupportInfo: true,
  //     });
  //   }
  // };

  function fetchLoginOptions() {
    LoginService.getLoginOptions(
      {},
      (result) => {
        console.log("[fetchLoginOptions] result: ", result);

        const directEmailLogin = result.value.length === 1 && result.value[0] === "CUDDLE";
        setShowLoader(false);
        setLoginOptions(result.value);
        setShowError(false);
        setErrorMsg("");
        setShowEmailLogin(directEmailLogin);
      },
      (error) => {
        console.log("[fetchLoginOptions] Error: ", error);
        setShowLoader(false);
        setShowError(true);
        setErrorMsg(
          <div>
            <div>Sorry, couldn’t fetch login details.</div>
            <StyledRetry onClick={retryFetchingOptions}>
              Click here to try again.
            </StyledRetry>
          </div>
        );
        setShowSupportInfo(false);
      }
    );
  };

  function getSuppportEmail() {
    ConfigurationAPIService.getCuddleconfig(
      {
        filterValue: SYSTEM_SETTING_TYPES.SUPPORT_EMAIL,
      },
      (resp) => {
        console.log("[getSuppportEmail] resp: ", resp);
        // let supportEmailInfo = resp.settings[0]
        // this.setState({
        //     supportEmailInfo
        // });
      },
      (error) => {
        console.error("[getSuppportEmail] error: ", error);
      }
    );
  };

  function retryFetchingOptions() {
    setShowLoader(true);
    fetchLoginOptions();
  };

  function onOptionSelect(type) {
    console.log("[onOptionSelect] type: ", type);

    // if(type === "CUDDLE") {
    //     this.setState({
    //         showEmailLogin: true
    //     });
    // } else
    if (type === "MICROSOFT") {
      microsoftHelper.signIn(onGoogleMSLogin, onGoogleMSError);
      setShowLoader(true);
      setShowLoaderText(true);
    } else if (type === "GOOGLE") {
      setShowLoader(true);
      setShowLoaderText(true);
    } else if (type === "ORG") {
      getOrgAuthDetails();
      setShowLoader(true);
      setShowLoaderText(true);
    }
  };

  function getOrgAuthDetails() {
    LoginService.getOrgAuthDetails(
      {},
      (response) => {
        console.log("[getOrgAuthDetails] response: ", response);
        oauthHelper.signIn(
          response,
          onGoogleMSLogin,
          onGoogleMSError
        );
      },
      (error) => {
        console.log("[getOrgAuthDetails] response: ", error);
      }
    );
  };

  function setAccessTokenCookie(loginResponse) {
    const cookieExpiry = new Date();
    cookieExpiry.setTime(cookieExpiry.getTime() + 20 * 24 * 60 * 60 * 1000);

    const userConfig = {
      accesstoken: loginResponse.accesstoken,
      refreshtoken: loginResponse.refreshtoken,
      expires: cookieExpiry.toUTCString(),
    };

    setCookie(userConfig);
  };

  function checkForProjects(response, loginMethod) {
    const { projects } = response;
    // console.log("checkForProjects", response, projects);
    if (
      (!projects || (projects && projects.length === 0)) 
    ) {
      setShowError(true);
      setErrorMsg("Unable to login as you are not assigned to any project.");
      setShowSupportInfo(true);
    } else {
      if (response.hasAcceptedPrivacyPolicy) {
        console.log(props.onLoginSuccess, " props.onLoginSuccess");
        props.onLoginSuccess(response, loginMethod);
      } else {
        setShowPrivacyPolicyModal(true);
        setLoginResponse(response);
        setLoginMethodUsed(loginMethod);
        
        setAccessTokenCookie(response);
      }
    }
  };

  function onEmailLogin(response) {
    if (
      response.message &&
      response.message.indexOf("authType") > -1 &&
      response.message.indexOf("not allowed") > -1
    ) {
      setShowError(true);
      setErrorMsg("This authentication is not allowed for your organization.");
      setShowSupportInfo(true);
      setShowLoader(false);
      setShowLoaderText(false);
    } else {
      checkForProjects(response, "Email");
    }
  };

  function onGoogleMSLogin(response, loginMethod) {
    console.log("[onGoogleMSLogin] response: ", response);
    setShowLoader(false);
    setShowLoaderText(false);
    
    checkForProjects(response, loginMethod);
  };

  function onGoogleMSError(error) {
    console.log("[onGoogleMSError] error: ", error);
    if (
      error.message &&
      error.message.indexOf("username") > -1 &&
      error.message.indexOf("doesn't exist") > -1
    ) {
      setShowError(true);
      setErrorMsg("Looks like this user is not registered with Crux Intelligence.");
      setShowSupportInfo(true);
      setShowLoader(false);
      setShowLoaderText(false);
    } else if (
      error.message &&
      error.message.indexOf("authType") > -1 &&
      error.message.indexOf("not allowed") > -1
    ) {
      setShowError(true);
      setErrorMsg("This authentication is not allowed for your organization.");
      setShowSupportInfo(true);
      setShowLoader(false);
      setShowLoaderText(false);
    } else {
      console.log("[onGoogleMSError]", error);
      setShowError(true);
      setErrorMsg("Error occured while logging in.");
      setShowSupportInfo(false);
      setShowLoader(false);
      setShowLoaderText(false);
    }
  };

  function onGoogleButtonRender(element) {
    console.log(element, "onGoogleButtonRender");
    googleHelper.init(element, onGoogleMSLogin, onGoogleMSError);
  };

  function onErrorBack() {
    setShowError(false);
    setErrorMsg("");
    setShowSupportInfo(false);
  };

  function onPrivacyPolicyAccept() {
    const newLoginResponse = { ...loginResponse };
    const loginMethod = loginMethodUsed;

    setShowPrivacyPolicyModal(false);
    setLoginResponse(undefined);
    setLoginMethodUsed(undefined);
    
    props.onLoginSuccess(newLoginResponse, loginMethod);
  };

  function onPrivacyPolicyCancel() {
    deleteAllCookies();

    setShowPrivacyPolicyModal(false);
    setLoginResponse(undefined);
    setLoginMethodUsed(undefined);
  };
  
  let view;

  if (showLoader) {
    view = (
      <div>
        <LoginLoader showText={showLoaderText} />
      </div>
    );
  } else {
    // if(this.state.showEmailLogin) {
    view = (
      <React.Fragment>
        <StyledLoginText>Log in</StyledLoginText>
        {showError && (
          <StyledErrorContainer className="error-container">
            <ContextualMessageBar
              type="error"
              feedback={errorMsg}
              theme={props.theme}
              width={"full"}
              style={{ width: "100%" }}
            />
          </StyledErrorContainer>
        )}
        <LoginButtons
          onButtonClick={onOptionSelect}
          onGoogleButtonRender={onGoogleButtonRender}
          loginOptions={loginOptions}
          theme={props.theme}
        />
        <EmailLogin
          theme={props.theme}
          projectAlert={props.projectAlert}
          showError={showError}
          errorMsg={errorMsg}
          onLoginSuccess={onEmailLogin}
          loginOptions={loginOptions}
          onLoginOptionClick={onOptionSelect}
          onGoogleButtonRender={onGoogleButtonRender}
          showOtherLoginOptions={
            loginOptions &&
            (loginOptions.indexOf("MICROSOFT") !== -1 ||
              loginOptions.indexOf("GOOGLE") !== -1 ||
              loginOptions.indexOf("ORG_AUTH") !== -1)
          }
        />
      </React.Fragment>
    );
    // }
  }

  return (
    <StyledMainContainer>
      {view}
      {showPrivacyPolicyModal ? (
        <PrivacyPolicy
          userID={loginResponse.userID}
          onAccept={onPrivacyPolicyAccept}
          onCancel={onPrivacyPolicyCancel}
        />
      ) : null}
    </StyledMainContainer>
  );
}

export default LoginOptions;
