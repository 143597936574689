import React, {useState, useEffect, useRef} from "react";

// import SingleSelectMenu from "@cuddle-dev/test-web-ui-lib/dist/single-select-menu";
// import SingleSelectInput from "@cuddle-dev/test-web-ui-lib/dist/singleSelectInput";
import {capitalizeFirstLetter} from "../../../utils/utilityFunctions";
import {SVGIcon, Tooltip, ActionMenu} from '@cuddle-dev/web-components';
import {useTheme} from "styled-components";

import {
    VIZ_ICONS,
    VIZ_LABELS,
    getLabelIcon
} from "./viz-utils";

//styled-components
import {
    VizSelector as StyledVizSelector,
    Dropdown as StyledDropdown,
    Label as StyledLabel,
    VizLabel as StyledVizLabel,
    VizLabelIcon as StyledVizLabelIcon,
    VizLabelArrow as StyledVizLabelArrow
} from './styled-components.js';

//images
import { ReactComponent as ChevronUpIcon } from "../../../images/chevron_up_20.svg";
import { ReactComponent as ChevronDownIcon } from "../../../images/chevron_down_20.svg";

const TOOLTIP_DISABLE_MSG = "Only table visualisation is supported for answers that exceed our data limit.";

function getLabel(type) {
    return VIZ_LABELS[type] || capitalizeFirstLetter(type);
}

function getAnOption(config) {
    const {type} = config;
    return {
        label: getLabel(type),
        value: type,
        icon: VIZ_ICONS[type] || VIZ_ICONS["DEFAULT"],
        config
    };
}

function getMenuOptions({preferredType, suggestedTypes=[]}) {
    // console.log("preferredType: ", preferredType);
    // console.log("suggestedTypes: ", suggestedTypes);
    let menuOptions = [];

    menuOptions.push(getAnOption(preferredType));
    suggestedTypes.forEach(config => {
        menuOptions.push(getAnOption(config));
    });

    return menuOptions;
}

function VizSelector(props) {
    const theme = useTheme();
    const mainDivRef = React.useRef();
    const dropdownRef = React.useRef();
    const labelRef = React.useRef();
    let [showDropdown, setShowDropdown] = useState(false);
    let [menuOptions, setMenuOptions] = useState(getMenuOptions(props.data));
    let drowdownLeftPos;

    const labelClickHandler = () => {
        setShowDropdown(!showDropdown);
    }

    const changeHandler = (change) => {
        const value = change?.selected?.value;
        if(value !== props.selected.type) {
            setShowDropdown(false);
            props.onChange(change.selected, props.selected.type);
        }
    };

    useEffect(() => {
        if(!menuOptions) {
            setMenuOptions(getMenuOptions(props.data));
        }

        let handleDocumentClick = (event) => {
            if(mainDivRef && mainDivRef.current && !mainDivRef.current.contains(event.target)) {
                if(showDropdown) {
                    setShowDropdown(false);
                }
            }
        }

        document.addEventListener('click', handleDocumentClick, false);
        // Specify how to clean up after this effect:
        return function cleanup() {
            document.removeEventListener('click', handleDocumentClick, false);
        };
    });

    // if(!props.dataTooLarge && !(menuOptions && (menuOptions.length > 1))) {
    //     return null;
    // }
    
    const LabelImage = props.selected && (getLabelIcon(props.selected.type, theme["icon_02"].light));
    const disableLabel = props.dataTooLarge || menuOptions?.length < 2;

    return (
        <StyledVizSelector ref={mainDivRef} disableLabel={disableLabel} customStyle={props.customStyle}>
            <StyledLabel onClick={!disableLabel ? labelClickHandler : null} ref={labelRef}>
                {LabelImage && 
                    <StyledVizLabel disableLabel={disableLabel}>
                        <StyledVizLabelIcon>{LabelImage}</StyledVizLabelIcon>
                        {!disableLabel ?
                            <StyledVizLabelArrow>
                                <SVGIcon Icon={showDropdown ? ChevronUpIcon : ChevronDownIcon} 
                                    width={20} 
                                    height={20} 
                                    fill={theme["icon_02"].light}
                                />
                            </StyledVizLabelArrow>
                            : null
                        }
                    </StyledVizLabel>
                }
                { props.dataTooLarge ?
                    <div className="ans-card__viz-disable-tooltip">
                        <Tooltip customStyle={`position: relative; max-width: 180px;`}
                            text={TOOLTIP_DISABLE_MSG}
                            onClick={()=>{}}
                        />
                    </div>
                    : null
                }
            </StyledLabel>
            {showDropdown ? 
                <StyledDropdown searchable={props.searchable} ref={dropdownRef} left={drowdownLeftPos}>
                    {/* <SingleSelectMenu theme={theme} 
                        options={props.searchable ? menuOptions.filter(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1) : menuOptions}
                        onChange={changeHandler} 
                        title="Visualization"
                        selected={props.selected && props.selected.type}
                    /> */}
                    <ActionMenu optionSections={[{
                        title: "Visualization",
                        options: menuOptions
                    }]}
                  clickHandler={changeHandler}
                />
                </StyledDropdown>
                : null
            }
        </StyledVizSelector>
    )
}

export default VizSelector;