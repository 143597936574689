import React, { useState, useEffect, useRef, MutableRefObject } from "react";
import { useTheme } from 'styled-components';
import BasicSection from "./basic-section/BasicSection";
import EntitiesSection from "./entities-section/EntitiesSection";
import { SVGIcon, TabSwitcher } from "@cuddle-dev/web-components";
import SQLSection from "./sql-section/SQLSection";
import PromptSection from "./prompts-section/PromptSection";
import TransformSection from "./transform-section/TransformSection";
import TableSection from "./table-section/TableSection";

// styled components
import {
    Container as StyledContainer,
    Header as StyledHeader,
    HeaderText as StyledHeaderText,
    Section as StyledSection,
    BackButton as StyledBackButton,
    BackIcon as StyledBackIcon,
    BackText as StyledBackText,
    CloseIcon as StyledCloseIcon,
    Tabs as StyledTabs
} from "./styled-components";

// images
import { ReactComponent as ChevronLeftIcon } from "../../../../images/chevron_left_20.svg";
import { ReactComponent as CloseIcon } from "../../../../images/close_20.svg";

const SECTION_TYPES = {
    basic: "BASIC",
    entities: "ENTITIES",
    transform: "TRANSFORM",
    table: "TABLE"
};
const TABS = {
    sql: {
        "value": "sql-query",
        "label": "SQL Query"
    },
    prompt: {
        "value": "prompt-details",
        "label": "Prompt Details"
    }
};

function Transparency(props) {
    const theme = useTheme();
    const sqlData = props.data.filter(datum => datum.type === "SQL")[0];
    const promptData = props.data.filter(datum => datum.type === "PROMPT")[0];
    const [headerHeight, setHeaderHeight] = useState(0);
    const [showSQL, setShowSQL] = useState(false);
    const [sqlDataCache, setSqlDataCache] = useState({});
    const [showPrompt, setShowPrompt] = useState(false);
    const [promptDataCache, setPromptDataCache] = useState({});
    const headerRef: MutableRefObject<HTMLDivElement> = useRef();

    useEffect(() => {
        const currentHeaderHeight = headerRef?.current?.getBoundingClientRect();

        if(headerHeight !== currentHeaderHeight.height) {
            setHeaderHeight(currentHeaderHeight.height);
        }
    });

    function getSection(sectionData) {
        switch(sectionData.type) {
            case SECTION_TYPES.basic:
                const isExplanation = sectionData.label?.toLowerCase().indexOf("explanation") > -1;
                return <BasicSection data={sectionData}
                    sqlData={isExplanation && sqlData}
                />
            case SECTION_TYPES.entities:
                return <EntitiesSection data={sectionData} 
                    sqlData={sqlData?.sqlIds ? sqlData : undefined} 
                    handleSQLDetailsClick={handleSQLDetailsClick} 
                    promptData={promptData}
                    handlePromptDetailsClick={handlePromptDetailsClick}
                />
            case SECTION_TYPES.transform:
                return <TransformSection data={sectionData}/>
            case SECTION_TYPES.table:
                return <TableSection data={sectionData}/>
            default:
                return null;
        }
    }

    function handleBackClick() {
        setShowSQL(false);
        setShowPrompt(false);
    }

    function handleSQLDetailsClick() {
        setShowSQL(true);
    }

    function handlePromptFetch(data, id) {
        const newPromptsCache = {...promptDataCache};
        newPromptsCache[id] = data;
        setPromptDataCache(newPromptsCache);
    }

    function handlePromptDetailsClick() {
        setShowPrompt(true);
    }

    function handleSQLFetch(data) {
        const newSQLCache = {...sqlDataCache};
        newSQLCache[data.id] = data;
        setSqlDataCache(newSQLCache);
    }

    function handleTabClick(data) {
        if(!showSQL && data.value === TABS.sql.value) {
            setShowSQL(true);
            setShowPrompt(false);
        } else if(!showPrompt && data.value === TABS.prompt.value) {
            setShowSQL(false);
            setShowPrompt(true);
        }
    }

    function getTabs() {
        const tabs = [];
        if(sqlData) {
            tabs.push(TABS.sql);
        }

        if(promptData) {
            tabs.push(TABS.prompt);
        }
        return tabs;
    }

    return <StyledContainer>
        <StyledHeader ref={headerRef}>
            {showSQL || showPrompt ? 
                <StyledBackButton onClick={handleBackClick}>
                    <StyledBackIcon>
                        <SVGIcon Icon={ChevronLeftIcon} 
                            width={20} 
                            height={20} 
                            fill={theme["icon_interactive"]}
                        />
                    </StyledBackIcon>
                    <StyledBackText>Back to Agent Details</StyledBackText>
                </StyledBackButton>
                : null
            }
            <StyledHeaderText>How did we arrive at this?</StyledHeaderText>
            <StyledCloseIcon onClick={props.closeModal}>
                <SVGIcon Icon={CloseIcon} 
                    width={24} 
                    height={24}
                    fill={theme["icon_02"].light}
                />
            </StyledCloseIcon>
            {showSQL || showPrompt ? 
                <StyledTabs>
                    <TabSwitcher selected={showSQL ? TABS.sql.value : TABS.prompt.value}
                        options={getTabs()}
                        handleClick={handleTabClick}
                    />
                </StyledTabs>
                : null
            }
        </StyledHeader>
        {!showSQL && !showPrompt && props.data.map((datum, index) => {
            if(datum.type === "SQL" || datum.type === "PROMPT") return null;
            return <StyledSection key={datum.type + index}>{getSection(datum)}</StyledSection>
        })}
        {showSQL ?
            <SQLSection sqlIds={sqlData.sqlIds} 
                sqlData={sqlDataCache} 
                headerHeight={headerHeight} 
                handleSQLFetch={handleSQLFetch}
            />
            : null
        }
        {showPrompt ?
            <PromptSection prompts={promptData.prompts}
                propmptsData={promptDataCache}
                headerHeight={headerHeight} 
                handlePromptFetch={handlePromptFetch}
            />
            : null
        }
    </StyledContainer>
}

export default Transparency;