import styled from "styled-components";
import { getStyleString } from "../../../../../utils/styleUtils";

interface promptProps {
    maxHeight?: number;
}

interface dotProps {
    selected?: boolean;
}

interface copyButtonProps {
    clickable?: boolean;
}

interface modelProps {
    maxWidth?: number;
}

export const Container = styled.div<promptProps>`
    overflow: auto;
    ${props => props.maxHeight ? `max-height: ${props.maxHeight}px;` : ""}
`;

export const Loader = styled.div`
    padding: 24px;

    & .loader-div:last-child {
        margin-top: 26px;
    }
`;

export const Details = styled.div`
    padding: 24px;
    border-bottom: 1px solid ${props => props.theme["border_02"]};
    display: flex;
`;

export const Prompts = styled.div`
    padding: 24px;
`;

export const Model = styled.div<modelProps>`
    padding-right: 24px;
    border-right: 2px solid ${props => props.theme["border_03"]};
    max-width: calc(100% - 138px - 24px); /*138 width of temperature, 24 margin-left*/
    box-sizing: border-box;
`;

export const Temperature = styled.div`
    margin-left: 24px;
`;

export const DetailLabel = styled.div`
    margin-bottom: 12px;
`;

export const DetailLabelText = styled.span`
    color: ${props => props.theme["text_03"].light};
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;
`;

export const DetailValue = styled.div`
    color: ${props => props.theme["text_03"].light};
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;



export const Title = styled.div`
    color: ${props => props.theme["text_02"].light};
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    
    .title-bold {
        font-weight: 600;
    }
`;
// ${props => getStyleString(props.theme["text_02_med"])};

export const CopyButton = styled.span<copyButtonProps>`
    position: absolute;
    top: 12px;
    right: 12px;
    padding: 8px;
    border-radius: 8px;
    background: ${props => props.theme["ui_05"]};
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.09);
    display: none;
    ${props => props.clickable ? "cursor: pointer;" : ""}
`;

export const Prompt = styled.div`
    position: relative;
    margin-top: 12px;
    margin-bottom: 20px;
    border-radius: 8px;
    padding: 16px;
    background: ${props => props.theme["ui_04"]};
    color: ${props => props.theme["text_01"].light};
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    word-break: break-all;
    box-sizing: border-box;
    white-space: pre-line;

    &:hover ${CopyButton} {
        display: block;
    }
`;

export const CopyIcon = styled.span<dotProps>`
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
`;

export const CopyText = styled.span<dotProps>`
    margin-left: 4px;
    color: ${(props) =>  props.theme["text_03"].light};
    ${(props) => getStyleString(props.theme["web_ui"])};
    display: inline-block;
    vertical-align: middle;
`;

export const InfoTooltip = styled.div`
    position: absolute;
    left: 50%;
    top: calc(100% + 4px);
    transform: translateX(-50%);
    display: none;
    width: max-content;
    z-index: 1;
`;

export const Info = styled.div`
    position: relative;
    height: 20px;
    margin-left: 8px;
    text-transform: none;
    display: inline-block;
    vertical-align: middle;

    &:hover ${InfoTooltip} {
        display: block;
    }
`;

export const Error = styled.div`
    padding: 24px;
`;