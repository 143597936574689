import React, { useState, useRef } from "react";
import { useAppSelector, useAppDispatch } from '../../../stores/redux-hooks';
import AnswerCard from "../../answer-card/AnswerCard";
import { Button, ButtonType, SVGIcon, ContextualMessageBar } from "@cuddle-dev/web-components";
import { useTheme } from "styled-components"
import {ANALYSIS_GROUPS} from "../../../constants";
import AnswerTitle from "../answer-title/AnswerTitle";
import ChangeViz from "./change-viz/ChangeViz";
import analyticsModule from "../../../utils/analytics";

import { renderMessages } from "../Chat";

// styled components
import {
    Container as StyledContainer,
    Title as StyledTitle,
    Carousel as StyledCarousel,
    AnswerSection as StyledAnswerSection,
    NavigationWrapper as StyledNavigationWrapper,
    Navigation as StyledNavigation,
    Arrow as StyledArrow,
    Dot as StyledDot,
    Numbers as StyledNumbers,
    Message as StyledMessage,
    MessageErrorCard as StyledMessageErrorCard
} from "./styled-components.js";

// actions
import { setFullScreenAnsVisibility } from "../../../reducers/App.reducer.js";

// images
import { ReactComponent as ChevronLeftIcon } from "../../../images/chevron_left_20.svg";
import { ReactComponent as ChevronRigthIcon } from "../../../images/chevron_right_20.svg";

const MAX_DOTS = 5;

function getSelectedDot(x, total) {
    if(x < (MAX_DOTS - 1)) {
        return x;
    };

    if(x === (total - 1)) {
        return (MAX_DOTS - 1)
    };

    return (MAX_DOTS - 2);

    //         (b-a)(x - min)
    // f(x) = --------------  + a   -> Scaling function; a=1 and b=3 for us as we want result to be 1|2|3
    //           max - min
    // const max = total - 1;
    // const min = 1;
    // return Math.round(((3 - 1) * (x - min)) / (max - min) + 1); 
}

function getAnalysisMap(cans) {
    const analysisMap = {};

    cans.forEach(can => {
        analysisMap[can.id] = can;
    });

    return analysisMap;
}

function AnswerCarousel(props) {
    const {data, metadata} = props;
    const isChange = data.cans[0].type?.toLowerCase() === "analysis" && 
            data.cans[0].additionalInfo?.group?.toLowerCase() === ANALYSIS_GROUPS.change;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [vizState, setVizState] = useState({});
    const userID = useAppSelector((state) => state.user.details.userID);
    const analysisMap = useRef(isChange && getAnalysisMap(data.cans));
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const showAvailableData = useAppSelector((state) => state.app.showAvailableData);

    function arrowClickHandler(event) {
        const direction = event.currentTarget.getAttribute("direction");

        if(direction === "left") {
            if(currentIndex > 0) {
                setCurrentIndex(currentIndex - 1);
            }
        } else if(direction === "right") {
            const carouselList = isChange ? data.layouts : data.cans;
            if(currentIndex < (carouselList.length - 1)) {
                setCurrentIndex(currentIndex + 1);
            }
        }
    }

    function vizChangeHandler(from, to) {
        const chatInfo = props.chatInfo;
        analyticsModule.trackEvent("Visualization Switched", {
            "Conversation ID": chatInfo.conversationId,
            "Chat ID": chatInfo.chatId,
            "Message ID": chatInfo.messageId,
            "From": from,
            "To": to
        });
    }

    // function userPreferenceChangeHandler(data) {
    //     const newVizState = {...vizState};
    //     newVizState[currentIndex] = data;
    //     setVizState(newVizState);
    //     props.onUserPreferenceUpdate(currentIndex, data);
    // }

    // function fullScreenViewChange(enabled) {
    //     dispatch(setFullScreenAnsVisibility(enabled));
    // }

    // console.log("[CuratedAnalysis - AnswerCarousel] userID: ", userID);
    // console.log(props, "AnswerCarousel");
    const cans = data.cans;
    const carouselList = isChange ? data.layouts : cans;
    const showCarousel = carouselList?.length > 1;
    const numOfDots = Math.min(carouselList.length, MAX_DOTS);
    const selectedDot = carouselList.length > MAX_DOTS ? getSelectedDot(currentIndex, carouselList.length) : currentIndex;
    const outerTitle = data?.additionalInfo?.title || (!showCarousel && cans?.length && cans[0]?.additionalInfo?.title);
    const subTitle = (!showCarousel && cans?.length && cans[0].answer?.periodDisplayTitle);
    const currentAnswer = isChange ? data.layouts[currentIndex] : cans[currentIndex];
    const isFirstAns = currentIndex === 0;
    const isLastAns = currentIndex === carouselList.length - 1;
    const carouselClasses = [];

    let content = <div></div>;
    let messages;

    if(isChange) {
        const changeData = carouselList[currentIndex].blocks[0].elements.map(element => analysisMap.current[element.id]);
        content = (
            <ChangeViz key={currentIndex} 
                data={changeData} 
                layout={carouselList[currentIndex]} 
                isChange={isChange} 
                hideTitle={!showCarousel}
                hideFooter={!showCarousel}
            />
        );
    } else if(currentAnswer?.answer) {
        const insightID = (currentAnswer && currentAnswer.query && currentAnswer.query.insightID) || "";
        const userVizPreference = currentAnswer && currentAnswer.userPreference && currentAnswer.userPreference.visualisation;
        const caMetadata = (currentAnswer.metadata && currentAnswer.metadata.Analysis) || {};
        const metadataUserPreference = caMetadata?.userPreferences;
        const isAnalysis = cans[0].type?.toLowerCase() === "analysis";
        const analysisGroup = isAnalysis && cans[0].additionalInfo?.group;
        const noViewOnly = analysisGroup && (analysisGroup.toLowerCase() === ANALYSIS_GROUPS.drivers ||
            analysisGroup.toLowerCase() === ANALYSIS_GROUPS.contribution ||
            analysisGroup.toLowerCase() === ANALYSIS_GROUPS.correlation);
            
        content = (
            <AnswerCard 
                key={(currentAnswer?.id || insightID)}
                showAvailableData={showAvailableData}
                result={currentAnswer}
                hideExplore={true}
                userID={userID}
                enableAddToBoards={false}
                enableFollow={false}
                currencyFormat={props.currencyFormat}
                showPeriodSubtitle={true}
                hidePeriodSelector={true}
                source="COPILOT"
                fromComponent={"COPILOT"}
                disableClickThrough={true}
                disableCuratedAnalysis={true}
                hideExport={currentAnswer && currentAnswer.answer && currentAnswer.answer.resultMetadata && currentAnswer.answer.resultMetadata.dataTooLarge ? true : false}
                // overflowMenuClickHandler={overflowMenuClickHandler.bind(this, chatInfo)}
                // viewDetailsClickHandler={viewDetailsClickHandler.bind(this, chatInfo)}
                // onUserPreferenceUpdate={userPreferenceUpdateHandler.bind(this, chatInfo, CANIndex)}
                enableFullScreenView={true}
                disableOnboarding={true}
                userPreferredViz={metadataUserPreference?.userPreferredViz || userVizPreference?.viz}
                vizState={metadataUserPreference?.vizState || userVizPreference?.vizState}
                // fullScreenHeaderTitle={sessions[chatInfo.chatId].chat_name}
                // fullScreenViewChange={fullScreenViewChange}
                metadata={props.metadata}
                isAnalysis={isAnalysis}
                viewOnly={isAnalysis ? !noViewOnly : undefined}
                hideTitle={!showCarousel}
                hideFooter={!showCarousel}
                vizChangeHandler={vizChangeHandler}
                title={caMetadata?.title}
            />
        );
    }
    
    if(currentAnswer.messages) {
        if(isChange || currentAnswer?.answer) {
            messages = <StyledMessage>{renderMessages(currentAnswer.messages)}</StyledMessage>
        } else {
            const messageType = Object.keys(currentAnswer.messages)[0];
            const message = currentAnswer.messages[messageType][0];
            
            messages = (
                <StyledMessageErrorCard>
                    <ContextualMessageBar 
                        type={messageType || "error"}
                        feedback={message.displayMessage || "There was an issue in processing the information."}
                        width="full"
                        card={true}
                        classes="carousel__error-card"
                    >
                    </ContextualMessageBar>
                </StyledMessageErrorCard>
            )
        }
    }

    if(!outerTitle && showCarousel) {
        carouselClasses.push("carousel-no-title");
    }
    
    return <StyledContainer>
        {outerTitle ?
            <StyledTitle>
                <AnswerTitle title={outerTitle.label} subTitle={subTitle || ""} />
            </StyledTitle> 
        : null}
        <StyledCarousel className={carouselClasses.join(" ")} showCarousel={showCarousel}>
            <StyledAnswerSection showCarousel={showCarousel}>
                {showCarousel ? <div direction="left" onClick={arrowClickHandler}>
                    <Button
                        startEnhancer={
                            <SVGIcon Icon={ChevronLeftIcon} 
                                width={24} 
                                height={24} 
                                fill={isFirstAns ? theme["icon_disabled"] : theme["icon_02"].light}
                            />
                        }
                        buttonType={ButtonType.buttonIcon}
                        disabled={isFirstAns}
                    />
                </div> : null}
                <div>
                    {messages}
                    {content}
                </div>
                {showCarousel ? <div direction="right" onClick={arrowClickHandler}>
                    <Button
                        startEnhancer={
                            <SVGIcon Icon={ChevronRigthIcon} 
                                width={24} 
                                height={24} 
                                fill={isLastAns ? theme["icon_disabled"] : theme["icon_02"].light}
                            />}
                        buttonType={ButtonType.buttonIcon}
                        disabled={isLastAns}
                    />
                </div> : null}
            </StyledAnswerSection>
            {showCarousel ? 
                <StyledNavigationWrapper>
                    <StyledNavigation>
                        {[...Array(numOfDots)].map((x, index) => <StyledDot selected={index === selectedDot}/>)}
                    </StyledNavigation>
                </StyledNavigationWrapper>
                : null
            }
        </StyledCarousel>
    </StyledContainer>
}

export default AnswerCarousel;