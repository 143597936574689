import React from "react";
import { connect } from "react-redux";

import {SingleSelectMenu, SVGIcon} from "@cuddle-dev/web-components";
import SessionService from "../../../../services/session.service";
import { CURRENT_PROJECT_SESSION_KEY } from "../../../../constants/";
import _ from "lodash";
import analyticsModule from "../../../../utils/analytics";

import { set_project as setCurrentProject } from "../../../../reducers/CurrProj.reducer.js";
import { set_message as setAppMessage } from "../../../../reducers/App.reducer";
import { setCookie } from "../../../../utils/utilityFunctions";

//images
import { ReactComponent as chevronDown } from "../../../../images/chevron-down-20.svg";
import { ReactComponent as chevronUp } from "../../../../images/chevron-up-20.svg";

//styled-components
import {
  SelectorLabel as StyledSelectorLabel,
  SelectorLabelText as StyledSelectorLabelText,
  SelectorLabelIcon as StyledSelectorLabelIcon,
  DropdownRoot as StyledDropdownRoot
} from "./styled-components.js";




class ProjectDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      projectsAvailForSelections: [],
      currProject: {},
    };
  }

  endEnhancer = () => {
    return  <SVGIcon Icon={this.state.selected ? chevronUp : chevronDown} width={20} height={20} />;
  };

  handleDropdownClick = (event) => {
    let isSelected = !this.state.selected;
    console.log("handleDropdownClick", isSelected);
    if (isSelected) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      analyticsModule.trackEvent("Project selection opened");
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    this.setState((state) => ({
      selected: !state.selected,
    }));

    event.preventDefault();
    event.stopPropagation();
  };

  handleOutsideClick = (e) => {
    this.handleDropdownClick(e);
  };

  onSwitchProject = (project) => {
    let currProject = this.props.currProject;
    setCookie({
      projectID: project.id,
    });

    if (currProject.projectId && currProject.projectId === project.projectId) {
      return;
    }

    analyticsModule.trackEvent("Project switched", {
      "New Project": project.id
    });

    SessionService.setItem(
      CURRENT_PROJECT_SESSION_KEY,
      JSON.stringify(project)
    );
    this.props.setCurrProject(project);
      
    this.props.setAppMessage({
      type: "success",
      message: "Project has been switched successfully."
    });
   
    setTimeout(() => {
      window.location.reload();
    }, 0);
  };

  convertDropdown = () => {
    // console.log("proj", this.state.projectsAvailForSelections)
    // let projects = this.state.projectsAvailForSelections
    let projects = this.props.userProjects;
    
    return projects.map((item) => {
      return {
        label: item.name,
        value: item.id,
        ...item,
      };
    });
  };

  render() {
    const { theme, darkBackground, rightAlignDropdown, bottomAlignDropdown } = this.props;
    console.log(this.props.theme, "prject this.props");
    const customStyle = `
      background: transparent;
      
      svg {
        margin-left: auto;
        padding-left: 8px;

        path {
          fill: #C5CCD6;
        }
      }

      pointer-events:all;
      
      justify-content: left;
      // min-width: 172px;
      @media screen and (max-width: 1023px) {
        // width: 172px;
      }        
    `;
    // console.log("themedropdown", theme);
    // console.log(
    //   "ProjectDropdown currentProject props",
    //   this.props,
    //   this.props.userProjects
    // );
    const currSelected = { ...this.props.currProject };

    if (currSelected) {
      currSelected.label = currSelected.name;
      currSelected.value = currSelected.id;
    }
    const dropdownOptions = this.convertDropdown();
    console.log(bottomAlignDropdown, "bottomAlignDropdown", dropdownOptions)

    return (
      <React.Fragment>
        <StyledSelectorLabel onClick={this.handleDropdownClick}>
          <StyledSelectorLabelText className="project-name" theme={theme} darkBackground={darkBackground} >
            {this.props.currProject.name}
          </StyledSelectorLabelText>
          <StyledSelectorLabelIcon theme={theme}>
            {this.endEnhancer()}
          </StyledSelectorLabelIcon>
        </StyledSelectorLabel>
        {this.state.selected && (
          <StyledDropdownRoot
            style={{
              top: "calc(100% - 10px)"
            }}
          >
          <SingleSelectMenu
            theme={theme}
            title="Project"
            options={dropdownOptions}
            selected={currSelected.id}
            changeHandler={(data) => {
              this.onSwitchProject(data);
              console.log("[SingleSelectMenu] data: ", data);
            }}
            type="radio"
          />
          </StyledDropdownRoot>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userProjects: state.userProjects.userProjects,
    currProject: state.currProject.currProject,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCurrProject: (payload) => dispatch(setCurrentProject(payload)),
    setAppMessage: (item) => dispatch(setAppMessage(item))
  };
  
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectDropdown);
