import styled from "styled-components";
import { getStyleString } from "../../../../utils/styleUtils";

interface ContainerProps {
  
}

export const Container = styled.div<ContainerProps>`
`;

export const Header = styled.div`
  padding: 16px 20px;
  border-bottom: 1px solid ${(props) =>  props.theme["border_03"]};
`;

export const HeaderTitle = styled.div`
  text-align: center;
  color: ${(props) =>  props.theme["text_02"].light};
  ${(props) => getStyleString(props.theme["text_02_med"])};
`;

export const Body = styled.div`
  padding: 24px 140px;
`;

export const Footer = styled.div`
  padding: 20px 0px;
`;

export const FooterActions = styled.div`
  text-align: center;
`;

export const FooterMessage = styled.div`
  margin: 20px 20px 0px 20px;
  text-align: center;
  color: ${(props) =>  props.theme["text_02"].light};
  ${(props) => getStyleString(props.theme["text_00_reg"])};
`;
