import React from "react";
import { useTheme } from 'styled-components';
import {SVGIcon, Tooltip} from '@cuddle-dev/web-components';

// images
import { ReactComponent as InfoIcon } from "../../../../../images/info_20.svg";

import {
    Container as StyledContainer,
    Text as StyledText,
    Info as StyledInfo,
    InfoTooltip as StyledInfoTooltip,
} from "./styled-components";

function SectionTitle(props) {
    const theme = useTheme();

    return <StyledContainer>
        <StyledText>{props.title}</StyledText>
        {props.info ?
            <StyledInfo>
                <SVGIcon Icon={InfoIcon} width={20} height={20} fill={theme["icon_03"].light}/>
                <StyledInfoTooltip>
                    <Tooltip style={{position: "relative"}} 
                        text={props.info}
                    />
                </StyledInfoTooltip>
            </StyledInfo>
            : null
        }
    </StyledContainer>
}

export default SectionTitle;