import React from "react";

// styled components
import {
    Option as StyledOption,
    Label as StyledLabel,
    SubLabel as StyledSubLabel
} from "./styled-components.js";

function SuggestionOption(props) {
    const data = props.data;

    function optionClickHandler(event) {
        if(props.onClick) {
            props.onClick(props.data);
        }
    }

    return <StyledOption highlight={props.highlight} onClick={optionClickHandler}>
        <StyledLabel highlight={props.highlight}>{data.label}</StyledLabel>
        <StyledSubLabel highlight={props.highlight}>
            {data.synonyms ?
                <span>
                    <span>{` aka `}</span>
                    <span>{data.synonyms[0]}</span>
                </span>
                : null
            }
            {data.subTitle ? 
                <span>
                    <span>{` - `}</span>
                    <span>{data.subTitle}</span>
                </span> 
                : null
            }
        </StyledSubLabel>
    </StyledOption>
}

export default SuggestionOption;