import styled from "styled-components";
import { getStyleString } from "../../../utils/styleUtils";

interface ChatProps {
    withLoader?: boolean;
}

export const Container = styled.div`
    // display: grid;
    // grid-template-rows: 60px auto;
    height: 100%;

    @media (min-width: 1024px) {
        display: grid;
        grid-template-rows: 60px calc(100% - 60px);
        grid-template-columns: 100%;
    }

    .edit-question-modal .modal-content {
        background-color: ${props => props.theme["ui_05"]};
        margin: 80px 124px;
        top: 0px;
        width: calc(100% - 248px);
        box-sizing: border-box;
        transform: none;
        left: 0px;
    }
`;

export const Header = styled.div`
    display: none;
    text-align: center;

    .session-item-container {
        background: none;
        height: 100%;
        box-sizing: border-box;
    }

    .session-item__title {
        line-height: 32px;
    }

    @media (min-width: 1024px) {
        display: block;
    }
`;

export const Content = styled.div`
    position: relative;
    height: 100%;
    background: #FFF;

    @media (min-width: 1024px) {
        margin: 0px 8px 8px 8px;
        border-radius: 12px;
        background: ${props => props.theme["ui_05"]};
        box-shadow: ${props => props.theme["boxShadow"].shadow_low};
        height: calc(100% - 8px);
        /* overflow-x: auto; */
    }

    #modal {
        position: absolute !important;
    }

    .modal-background {
        height: 100%;
    }

    .transparency-modal  .modal-content {
        width: calc(100% - 164px);
    }
`;

export const Chat = styled.div<ChatProps>`
    padding-bottom: 16px;
    height: calc(100% - ${props => props.withLoader ? 60 : 94}px + 16px);
    box-sizing: border-box;
    box-sizing: border-box;
    overflow-y: auto;
    background-color: ${props => props.theme["ui_04"]};
    / * Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const BottomOverlay = styled.div`
    position: absolute;
    width: 100%;
    bottom: 0px;
    border-radius: 16px 16px 12px 12px;
    background: ${props => props.theme["ui_05"]};
    box-shadow: ${props => props.theme["boxShadow"].shadow_high};
    z-index: 10;
`;

export const BottomLoader = styled.div`
    padding: 20px 24px;
    text-align: center;
    color: ${props => props.theme["text_02"].light};
    ${props => getStyleString(props.theme["text_02_med"])};
`;

export const FullOverlay = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: ${props => props.theme["ui_overlay"]};
`;

export const EditDiv = styled.div`
        display: flex;
        gap: 16px;
        margin: 20px 24px;
        .ask-bar {
            flex: 1;
            margin: 0px;
        }
`;

export const DialogBoxWrapper = styled.div`
    max-width: 420px;
`;

export const OverlayTopLoader = styled.div`
    position: absolute;
    top: 0px;
    left: 4px;
    width: calc(100% - 8px);
    height: 6px;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    animation: animateBackground 4s linear infinite;
    background-image: linear-gradient(90deg,rgba(197, 226, 254, 0.50),#6ab6ff,rgba(197, 226, 254, 0.50),rgba(197, 226, 254, 0.50),#6ab6ff);
    background-size: 400% 100%;
    height: 6px;

    @keyframes animateBackground {
        0% { background-position: 100% 0%; }
        100% { background-position: 0% 0%; }
    }
`;