import styled from "styled-components";
import { getStyleString } from "../../../../../utils/styleUtils";

export const Container = styled.div`
  margin-top: 28px;
  &:first-child {
    margin-top: 0px;
  }
`;

export const Period = styled.div`
  color: ${(props) =>  props.theme["text_03"][props.theme.backgroundMeta?.interface_background?.colorBrightness]};
  margin-left: 12px;
  ${(props) =>
    getStyleString(
      props.theme["eyebrow_text"]
    )
  };
`;

export const List = styled.div``;

export const ListItem = styled.div`
  margin-top: 8px;
  padding: 4px 0px;
`;
