import styled from "styled-components";
import { getStyleString } from "../../../utils/styleUtils";

export const Container = styled.div`
    display: flex;
    margin-top: 24px;
    align-items: center;
`;

export const Transparency = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
`;

export const Date = styled.div`
    color: ${props => props.theme["text_03"].light};
    ${props => getStyleString(props.theme["text_01_med"])};
`;

export const Separator = styled.div`
    color: ${props => props.theme["text_04"].light};
    ${props => getStyleString(props.theme["text_01_med"])};
    margin: 0px 8px;
`;

export const TransparencyText = styled.div`
    color: ${props => props.theme["text_03"].light};
    ${props => getStyleString(props.theme["text_01_med"])};
    margin-left: 8px;
`;

export const Remove = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
    cursor: pointer;
`;

export const RemoveIcon = styled.div`
    width: 24px;
    height: 24px;
`;

export const RemoveText = styled.span`
    margin-left: 8px;
    color: ${props => props.theme["text_interactive_error"]};
    ${props => getStyleString(props.theme["text_01_med"])};
`;