import OAuthService from "../services/oAuth.service";
import httpRequestService from "./httpRequest.js";

const authenticateUsingOAuth = (tokenURL) => { 
    return new Promise((resolve, reject) => {
        OAuthService.launchPopup(tokenURL, (err) => {
            reject(err);
        });
        OAuthService.listenToMessageEvent(resolve, reject);
    });
}

const signIn = (oauthDetails, onSuccess, onError) => {
    let tokenURL = oauthDetails.details.TOKEN_URL;
    
    authenticateUsingOAuth(tokenURL)
        .then(data => {
            console.log("[buttonClickHandler] data: ", data);
    
            // httpRequestService.getAuthToken(`login/token`, {
            //     grant_type: "password",
            //     authType: oauthDetails.details.AUTH_TYPE,
            //     id_token: data.id_token
            // }, (response) => {
            //     console.log("[cuddleLogin] response: ", response);
            //     onSuccess(response, oauthDetails.details.AUTH_TYPE);
            // }, (err) => {
            //     console.log("[cuddleLogin] Error with id_token: ", err);
            //     console.log("Using token");
                httpRequestService.getAuthToken(`login/token`, {
                    grant_type: "password",
                    authType: oauthDetails.details.AUTH_TYPE,
                    id_token: data.access_token
                }, (response) => {
                    console.log("[cuddleLogin] response: ", response);
                    onSuccess(response, oauthDetails.details.AUTH_TYPE);
                }, (err) => {
                    console.log("[cuddleLogin] Error with token: ", err);
                    onError(err);
                });
            // });  
        })
        .catch(error => {
            console.log("[buttonClickHandler] error: ", error);
            onError(error);
        });
}

const checkForIDToken = function(props) {
    // const code = (props.location.pathname.match(/code=([^&]+)/) || [])[1];
    // const state = (props.location.pathname.match(/state=([^&]+)/) || [])[1];
    console.log("[LoginCallback] props.location: ", props.location);

    const id_token = (props.location.hash.match(/id_token=([^&]+)/) || [])[1];
    const pathname_id_token = (props.location.pathname.match(/id_token=([^&]+)/) || [])[1];
    const search_id_token = (props.location.search.match(/id_token=([^&]+)/) || [])[1];
    console.log("[LoginCallback] id_token: ", id_token);
    console.log("[LoginCallback] pathname_id_token: ", pathname_id_token);
    console.log("[LoginCallback] search_id_token: ", search_id_token);

    const token = (props.location.hash.match(/token=([^&]+)/) || [])[1];
    const pathname_token = (props.location.pathname.match(/token=([^&]+)/) || [])[1];
    const search_token = (props.location.search.match(/token=([^&]+)/) || [])[1];
    console.log("[LoginCallback] token: ", token);
    console.log("[LoginCallback] pathname_token: ", pathname_token);
    console.log("[LoginCallback] search_token: ", search_token);

    const access_token = (props.location.hash.match(/access_token=([^&]+)/) || [])[1];
    const pathname_access_token = (props.location.pathname.match(/access_token=([^&]+)/) || [])[1];
    const search_access_token = (props.location.search.match(/access_token=([^&]+)/) || [])[1];
    console.log("[LoginCallback] access_token: ", access_token);
    console.log("[LoginCallback] pathname_access_token: ", pathname_access_token);
    console.log("[LoginCallback] search_access_token: ", search_access_token);
    
    const ID_TOKEN = id_token || pathname_id_token || search_id_token;
    const ACCESS_TOKEN = token || pathname_token || search_token || access_token || pathname_access_token || search_access_token;
    
    return {
        ID_TOKEN,
        ACCESS_TOKEN
    };
}

export default {
    signIn,
    checkForIDToken
};