import * as Msal from "msal";
import httpRequestService from "./httpRequest.js";


// Config object to be passed to Msal on creation
const msalConfig = {
    auth: {
        clientId: "5d59e115-2953-472c-a97a-1657728ee252",
        authority: "https://login.microsoftonline.com/organizations",
        redirectUri: window.location.origin
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        forceRefresh: true // Set this to "true" to skip a cached token and go to the server to get a new
    }
};  

const msalInstance = new Msal.UserAgentApplication(msalConfig);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
const loginRequest = {
    scopes: ["openid", "profile", "User.Read"],
};

const signIn = function(onSuccess, onError) {
    msalInstance.loginPopup(loginRequest)
        .then(loginResponse => {  
            console.log('id_token acquired at: ' + new Date().toString());
            if (msalInstance.getAccount()) {
                getTokenPopup(loginRequest)
                    .then(response => {
                        console.log("[Microsoft signIn] response: ", response);
                        
                        httpRequestService.getAuthToken(`/login/token`, {
                            grant_type: "password",
                            authType: "MICROSOFT",
                            id_token: response.accessToken
                        }, (response) => {
                            console.log("[cuddleLogin] response: ", response);
                            onSuccess(response, "MICROSOFT");
                        }, (err) => {
                            console.log("[cuddleLogin] Error: ", err);
                            onError(err);
                        });  
                    }).catch(error => {
                        console.log(error);
                        onError(error);
                    });
            }
        }).catch(function (error) {
            console.log(error);
            onError(error);
        });
}

function getTokenPopup(request) {
    return msalInstance.acquireTokenSilent(request)
        .catch(error => {
          console.log("silent token acquisition fails. acquiring token using popup");
            // fallback to interaction when silent call fails
            return msalInstance.acquireTokenPopup(request)
                .then(tokenResponse => {
                }).catch(error => {
                    console.log(error);
                });
        });
}

export default {
    signIn
};
