import styled from "styled-components";
import { getStyleString } from "../../../../../utils/styleUtils";

interface infoProps {
    leftMargin?: number;
}

interface tooltipProps {
    position?: string;
}

export const Container = styled.div`
`;

export const Description = styled.div`
    margin-top: 12px;
    color: ${props => props.theme["text_02"].light};
    ${props => getStyleString(props.theme["text_02_med"])};
`;

export const AgentBox = styled.div`
    margin-top: 20px;
    padding: 20px;
    border-radius: 16px;
    background: ${props => props.theme["ui_04"]};
`;

export const AgentTitle = styled.div`
    color: ${props => props.theme["text_01"].light};
    ${props => getStyleString(props.theme["agent_name"])};
`;

export const AgentDescription = styled.div`
    margin-top: 12px;
    color: ${props => props.theme["text_01"].light};
    ${props => getStyleString(props.theme["agent_description"])};
`;

export const EntitiesTable = styled.div`
    margin-top: 20px;
    border-radius: 12px;
    border: 1px solid ${props => props.theme["border_02"]};
`;

export const EntityName = styled.div`
    padding: 16px 20px;
    background: ${props => props.theme["ui_03"]};
    color: ${props => props.theme["text_03"].light};
    ${props => getStyleString(props.theme["eyebrow_text"])};
    display: grid;
    grid-template-columns: auto 20px;
`;

export const EntityValues = styled.div`
    background: ${props => props.theme["ui_05"]};
`;

export const EntityRow = styled.div`
    display: grid;
    grid-template-columns: 280px calc(100% - 280px);
    border-bottom: 1px solid ${props => props.theme["border_02"]};
    
    &:last-child {
        border-bottom: none;

        ${EntityName} {
            border-bottom-left-radius: 12px;
        }

        ${EntityValues} {
            border-bottom-right-radius: 12px;
        }
    }

    &:first-child {
        ${EntityName} {
            border-top-left-radius: 12px;
        }

        ${EntityValues} {
            border-top-right-radius: 12px;
        }
    }
`;

export const EntityValue = styled.div`
    padding: 16px 20px;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 8px;
    border-bottom: 1px solid ${props => props.theme["border_02"]};

    &:last-child {
        border-bottom: none;
    }
`;

export const ValueTitle = styled.div`
    display: flex;
    align-items: center;
`;

export const ValueName = styled.div`
    display: flex;
    align-items: center;
`;

export const ValueDescription = styled.div`
    color: ${props => props.theme["text_03"].light};
    ${props => getStyleString(props.theme["text_01_reg"])};
`;

export const EntityNameText = styled.div`
    text-transform: uppercase;
    color: ${props => props.theme["text_03"].light};
    ${props => getStyleString(props.theme["eyebrow_text"])};
`;

export const InfoTooltip = styled.div<tooltipProps>`
    position: absolute;
    display: none;
    width: max-content;
    z-index: 1;

    ${props => props.position === "bottom" ? `
        left: 50%;
        top: calc(100% + 4px);
        transform: translateX(-50%);
    ` : `
        left: calc(100% + 4px);
        top: 50%;
        transform: translateY(-50%);
    `}
`;

export const Info = styled.div<infoProps>`
    position: relative;
    height: 20px;
    ${props => props.leftMargin ? `margin-left: ${props.leftMargin}px;` : ""}

    &:hover ${InfoTooltip} {
        display: block;
    }
`;

export const EntityValueText = styled.div`
    color: ${props => props.theme["text_03"].light};
    ${props => getStyleString(props.theme["eyebrow_text"])};
    text-transform: uppercase;
`;

export const EntityValueNameText = styled.div`
    color: ${props => props.theme["text_02"].light};
    ${props => getStyleString(props.theme["text_02_med"])};
`;

export const EntityLink = styled.div`
    color: ${props => props.theme["text_interactive"]};
    ${props => getStyleString(props.theme["text_02_med"])};
    cursor: pointer;
`;