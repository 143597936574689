import React, { Component } from "react";
import { connect } from "react-redux";
import AppContext from "../../AppContext.js";
import {Snackbar} from "@cuddle-dev/web-components";

import "./StackItem.css";

// import { removeAppMessage } from "../../actions/App.actions";
import { remove_message as removeAppMessage } from "../../reducers/App.reducer.js";

class StackItem extends Component {
  state = {
    removeAnimation: false,
    // bottomCSSStyle: '-100'
  };

  componentDidMount() {
    // setTimeout(() => {
    //     this.calculateBottomVal();
    // }, 500);

    if (!this.props.item.doNotClose) {
      this.timeoutObj = setTimeout(() => {
        this.timeoutObj = undefined;
        this.props.onClose(this.props.item);
      }, 10000);
    }
  }

  componentWillReceiveProps(newProps) {
    this.props = newProps;
    // this.calculateBottomVal();
  }

  componentWillUnmount() {
    if (this.timeoutObj) {
      clearTimeout(this.timeoutObj);
    }
  }

  // calculateBottomVal = () => {
  //     let bottomCSSStyle = (this.props.totalItems - this.props.itemIndex) * 70;

  //     this.setState({
  //         bottomCSSStyle
  //     });
  // }

  onCloseCB = () => {
    this.setState(
      {
        removeAnimation: true,
      },
      () => {
        this.props.onClose(this.props.item);
      }
    );
  };

  render() {
    const item = this.props.item;
    // const bottomCSSStyle = this.state.bottomCSSStyle + 'px';
    const bottomCSSStyle = this.props.bottomValue + "px";
    const link = item.link
      ? {
          text: item.link.text,
          onClick: () => {
            this.onCloseCB();
            item.link.onClick();
          },
        }
      : undefined;
    let classes = ["stack-item"];

    if (this.state.removeAnimation) {
      classes.push("stack-item--remove");
    }

    return (
      <AppContext.Consumer>
        {(value) => (
          <div
            className={classes.join(" ")}
            key={item.validTill}
            style={{ bottom: bottomCSSStyle }}
          >
            <Snackbar
              type={item.type || "system"}
              feedback={item.message}
              link={link}
              onClose={this.onCloseCB}
              theme={value.theme}
              icon={item.icon}
            />
          </div>
        )}
      </AppContext.Consumer>
    );
  }
}

class SnackBarStack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      animation: {
        from: {
          bottom: "-10px",
        },
        to: {
          bottom: 0,
        },
      },
    };
    this.stackRef = React.createRef();
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      messages: newProps.messages,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    setTimeout(() => {
      if (this.state.messages !== prevState.messages && this.stackRef) {
        const items = this.stackRef.current.querySelectorAll(".stack-item");
        let currentBottom = 60;
        let bottomValues = [];

        for (let i = 0; i < items.length; i++) {
          const itemIndex = items.length - i - 1;
          bottomValues[itemIndex] = currentBottom;
          currentBottom += items[itemIndex].offsetHeight + 8;
        }

        this.setState({
          bottomValues,
        });
      }
    }, 0);
  }

  onButtonClick = () => {
    if (this.props.onButtonClick) {
      this.props.onButtonClick();
    }
  };

  onClose = (index) => {
    this.props.removeMessage(index);
  };

  renderSnackbarMessage = (item, itemIndex) => {
    return (
      <StackItem
        key={item.validTill}
        item={item}
        itemIndex={itemIndex}
        totalItems={this.state.messages.length}
        onClose={this.onClose}
        bottomValue={
          (this.state.bottomValues && this.state.bottomValues[itemIndex]) ||
          -100
        }
      />
    );
  };

  renderList = () => {
    let view = null;
    if (this.state.messages) {
      let revMessages = this.state.messages;
      // let revMessages = this.state.messages.sort((a, b) => {
      //     if(a.index < b.index) {
      //         return -1;
      //     } else if(a.index > b.index) {
      //         return 1;
      //     } else {
      //         return 0;
      //     }
      // });

      view = revMessages.map((item, index) =>
        this.renderSnackbarMessage(item, index)
      );
    }
    return view;
  };

  render() {
    let className = `app-snackbar`;

    return (
      <div className={className} ref={this.stackRef}>
        {this.renderList()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let newState = {};
  if (state.app && state.app.messages) {
    newState.messages = state.app.messages;
    newState.messagesLen = newState.messages.length;
  }
  return newState;
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeMessage: (message) => dispatch(removeAppMessage(message)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SnackBarStack);
