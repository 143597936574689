import styled from "styled-components";
import { getStyleString } from "../../../../../../utils/styleUtils";

interface SessionProps {
  isactive?: Boolean;
  isbackgroundlight?: Boolean;
  isSmall?: Boolean;
  notFullWidth?: Boolean;
}

interface TitleProps {
  isEditable?: Boolean;
  isDeletable?: Boolean;
}

interface InputProps {
  notFullWidth?: Boolean;
}

interface IconContainerProps {
  bigIcon?: Boolean;
}

interface IconProps {
  isbackgroundlight?: Boolean;
  bigIcon?: Boolean;
}

export const Session = styled.div<SessionProps>`
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
  background: ${(props) => props.isactive ? props.theme["left_pane_interactive_bg"][props.theme.backgroundMeta?.interface_background?.colorBrightness || "light"] : "" }; 
  color: ${(props) =>  props.theme["text_01"][props.theme.backgroundMeta?.interface_background?.colorBrightness || "light"] };
  display: grid;
  // grid-template-columns: auto ${props => props.isSmall ? 44 : 20}px;
  // grid-gap: 12px;
  // align-items: center;
  position: relative;

  &:hover {
    background: ${(props) => props.isbackgroundlight ? "rgba(0, 0, 0, 0.05)" : props.theme["appHeaderTabHover"] };
  }

  ${props => props.notFullWidth ? `
    display: inline-block;
    // padding: 18px 12px;
  ` : ""}
`;

export const Title = styled.div<TitleProps>`
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  // display: flex;
  height: 100%;
  // align-items: center;
  max-width: 432px;
  padding: ${(props) => (props.isEditable ? "0px" : "2px 32px 2px 0px")};
  box-sizing: border-box;
  ${(props) => getStyleString(props.theme["text_01_reg"])};
`;

export const Input = styled.input<InputProps>`
  border: 1px solid ${(props) => props.theme["ui_interactive"]};
  outline: none;
  background: none;
  color: ${(props) =>  props.theme["text_01"][props.theme.backgroundMeta?.interface_background?.colorBrightness || "light"] };
  ${(props) => getStyleString(props.theme["text_01_med"])};
  width: 100%;
  box-sizing: border-box;
  padding-right: 56px;

  ${props => props.notFullWidth ? `
    width: 320px;
  ` : ""}

  &:focus {
    border: 1px solid ${(props) => props.theme["ui_interactive"]};
    outline: none;
  }
`;

export const IconContainer = styled.div<IconContainerProps>`
  // margin-left: 12px;
  // display: inline-block;
  // vertical-align: middle;
  height: ${props => props.bigIcon ? 24 :20}px;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
`;

export const Icon = styled.div<IconProps>`
  width: ${props => props.bigIcon ? 24 :20}px;
  height: ${props => props.bigIcon ? 24 :20}px;
  display: inline-block;
  vertical-align: middle;

  svg path {
    fill: ${(props) => props.isbackgroundlight ? props.theme["icon02"] : props.theme["icon03"] };
  }

  &:nth-child(2) {
    margin-left: 4px;
  }
`;

export const MenuIcon = styled.div<IconProps>`
  position: relative;
`;

export const Dropdown = styled.div<IconProps>`
  position: absolute;
  top: calc(100% + 12px);
  right: -12px;
`;

export const DialogBoxWrapper = styled.div`
    max-width: 420px;
`;