import React, { useState, useEffect } from "react";
import {Button, SVGIcon} from "@cuddle-dev/web-components";
import { useTheme } from 'styled-components';
import ProfileDropdown from "../../main/NavBar/ProfileDropdown";
import ProjectDropdown from "../../main/NavBar/project-dropdown";

import {ProfileDropDownContainer as StyledProfileDropDownContainer} from "./styled-components";

function UtilityBar(props) {
    const [deviceType, setDeviceType] = useState(props.deviceType);
    const theme = useTheme();

    const isDesktop = props.deviceType === "desktop";

    return <React.Fragment>
        <img src={props.copilotIcon}></img>
        {
            !isDesktop ? <ProjectDropdown
            theme={theme}
            /> : null
        }
        <StyledProfileDropDownContainer className={isDesktop ? "display-bottom" : "display-right"}>
            <ProfileDropdown
                {...props.profileSection}
                theme={theme}
                deviceType={deviceType}
                bottomAlign={isDesktop}
                size={(!isDesktop) && "SMALL"}
            />
        </StyledProfileDropDownContainer>
    </React.Fragment>
}

export default UtilityBar;