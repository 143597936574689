import React from "react";

import "./ProfileDropdown.scss";
import {Button, SVGIcon, ButtonType} from "@cuddle-dev/web-components";
import{ ActionMenu } from "@cuddle-dev/web-components";
import { connect } from "react-redux";
import styled from "styled-components";
import ProfileIcon from "../../profile-icon";
import analyticsModule from "../../../utils/analytics";


//images
// import profile from "../../../images/hamburger-32.svg";
// import profile1 from "../../../images/profile/profile.svg";

const StyledProfileIcon = styled.div`
    ${(props) => `
        width: ${props.size === "SMALL" ? 32 : 36}px;
        height:  ${props.size === "SMALL" ? 32 : 36}px;
        cursor: pointer;
    `}
}`;

class ProfileDropdown extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props.profileSectionOptions, "profileSectionOptions")
    this.state = {
      selected: false,
      openPassModal: false,
      showToast: false,
    };
    
  }


  handleDropdownClick = (event) => {
    if (!this.state.selected) {
      document.addEventListener("click", this.handleOutsideClick, false);
      analyticsModule.trackEvent("User Menu Opened");
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    this.setState((state) => ({
      selected: !state.selected,
    }));

    event?.stopPropagation();
  };

  handleOutsideClick = (e) => {
    this.handleDropdownClick(e);
  };

  handleOptionClick = (data) => {
    this.props.handleProfileOptionClick(data);
    this.handleDropdownClick();
  };

  render() {
    const { theme, size } = this.props;
    // const customStyle = `
    //         background: ${
    //           this.state.selected
    //             ? theme["ui_interactive"]
    //             : theme["appHeader"]
    //         };
    //         color: inherit;
    //         path {
    //             fill: ${theme.textColor["appHeader"]};
    //         }
    //         &:hover{
    //             background: ${theme["appHeaderTabHover"]};
    //             color: ${theme["text05"]};
    //         }
    //         &:hover path {
    //             fill: ${theme["text05"]};
    //         };
    //         pointer-events: all;
    //         min-width: 160px;
    //         box-sizing: border-box;
    //     `;
    // console.log("themeprofile", theme, this.props.admin)

    return (
      <React.Fragment>
          <StyledProfileIcon onClick={this.handleDropdownClick} size={size}>
            <ProfileIcon theme={theme} size={size} />
          </StyledProfileIcon>
        {this.state.selected && (
          <div className={"profile-dropdown-container " + (this.props.bottomAlign ? "bottom-align" : "top-align")}>
            <ActionMenu
              theme={theme}
              header={{
                heading: `${this.props.firstName} ${this.props.lastName}`,
                subheading: this.props.email,
                meta: this.props.admin ? "Admin" : "",
              }}
              optionSections={this.props.profileSectionOptions}
              clickHandler={this.handleOptionClick}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userProjects: state.userProjects.userProjects,
    currProject: state.currProject.currProject,
  };
};

export default connect(mapStateToProps, null)(ProfileDropdown);
