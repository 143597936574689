import httpRequestService from "../utils/httpRequest";
import { generateQueryString } from "../utils/utilityFunctions";
import  newHttpRequestService from "../utils/newHTTPrequest";
import links from "../utils/links.js";

class ChatSessionService {
  constructor() {
    this.ORCHESTRATOR_URL = links.copilotOrchestratorURL;
    this.BASE_URL = `${this.ORCHESTRATOR_URL}/conversation`;
  }

  //Get conversations of user
  getConversations = (data, successCb, errorCb) => {
    let url = generateQueryString(
      `${this.BASE_URL}`,
      {
        user_id: data.userID
      }
    );
    
    httpRequestService.getRequest(url, successCb, errorCb);
  };
  
  //Create conversation
  createConversation = (data, successCb, errorCb) => {
    let url = this.BASE_URL;
    httpRequestService.postRequest(url, data, successCb, errorCb);
  };

  //Update conversation details
  updateConversation = (query, data, successCb, errorCb) => {
    let url = `${this.BASE_URL}/${query.conversation_id}`;
    httpRequestService.putRequest(url, data, successCb, errorCb);
  };

  //Delete a conversation
  deleteConversation = (query) => {
    let url = `${this.BASE_URL}/${query.conversation_id}?user_id=${query.user_id}`;
    return newHttpRequestService.delete(url, null);
  };

  //Delete chat and messages of this chat
  deleteChat = (query) => {
    let url = `${this.BASE_URL}/${query.conversation_id}/chat/${query.chat_id}?user_id=${query.user_id}`;
    return newHttpRequestService.delete(url, null);
  }

  deleteMessage = (query) => {
    let url = `${this.BASE_URL}/${query.conversation_id}/chat/${query.chat_id}/message/${query.message_id}?user_id=${query.user_id}`;
    return newHttpRequestService.delete(url, null);
  }

  getLastNConversations = (query, data, successCb, errorCb) => {
    let url = generateQueryString(
      `${this.BASE_URL}/${data.conversation_id}/chat_message/`,
      query
    );
    return httpRequestService.getRequestPromise(url, successCb, errorCb);
  };

  getSQL = (query, successCb, errorCb) => {
    let url = `${this.ORCHESTRATOR_URL}/sql/${query.sqlId}`;
    return httpRequestService.getRequestPromise(url);
  };

  getPrompt = (query, successCb, errorCb) => {
    let url = `${this.ORCHESTRATOR_URL}/prompt/${query.id}`;
    return httpRequestService.getRequestPromise(url);
  };

  executePrompt = (query, data) => {
    let url = `${this.ORCHESTRATOR_URL}/prompt/${query.id}/execute`;
    return httpRequestService.postRequestPromise(url, data);
  };
  
  // updateUserPreference = (data, successCb, errorCb) => {
  //   let url = this.BASE_URL;
  //   httpRequestService.putRequest(url, data, successCb, errorCb);
  // };

    // getFeedbackOptions = () => {
  //   let url = `${this.BASE_URL}/feedback/additional_info`;
  //   return httpRequestService.getRequestPromise(url);
  // };

  // submitFeedback = (query, data) => {
  //   let url = `${this.BASE_URL}/user/${query.userId}/chat_sessions/${query.sessionId}/conversations/${query.conversationId}/feedback`;
  //   return httpRequestService.postRequestPromise(url, data);
  // }

}

export default new ChatSessionService();
