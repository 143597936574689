import styled from "styled-components";
import { getStyleString } from "../../../utils/styleUtils";

export const Container = styled.div``;

export const Title = styled.div`
    // color: ${props => props.theme["text_01"].light};
    // ${props => getStyleString(props.theme["answer_title"])};
    margin-bottom: 20px;
`;

export const Carousel = styled.div`
    ${props => props.showCarousel ? `
        background: ${props.theme["ui_03"]};
        margin-left: -20px;
        margin-right: -20px;
        padding: 20px;
    ` : ""}
`;

export const AnswerSection = styled.div`
    ${props => props.showCarousel ? `
        display: grid;
        grid-template-columns: 36px calc(100% - 96px) 36px;
        align-items: center;
        gap: 12px;
    ` : ``
    }
}`;

export const NavigationWrapper = styled.div`
    padding: 16px 0px 12px 0px;
    border-radius: 0px 0px 12px 12px;
    background: ${props => props.theme["ui01"]};
}`;

export const Navigation = styled.div`
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}`;

export const Arrow = styled.div`
    width: 24px;
    height: 24px;
    cursor: pointer;
    ${props => props.direction === "left" ?
        "margin-right: 80px;"
        : "margin-left: 80px;"
    }

    svg {
        transform: scale(1.2);
        transform-origin: 0px 0px;
    }

    svg path {
        fill: ${props => props.theme["icon02"]};
    }
`;

export const Dot = styled.div`
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: ${props => props.theme[props.selected ? "ui_interactive" : "ui_01"]};
    margin-left: 10px;
    
    &:first-child {
        margin-left: 0px;
    }
    
`;

export const Numbers = styled.div`
    padding: 2px 4px;
    border-radius: 4px;
    background: #C5CCD6;
`;

export const Message = styled.div`
    margin-bottom: 12px;
`;

export const MessageErrorCard = styled.div`
    background: ${props => props.theme["ui_05"]};
    padding: 16px 12px;

    .carousel__error-card, .carousel__error-card div:first-child {
        border-radius: 0;
    }
`;