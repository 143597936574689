// import httpRequestService from '../utils/httpRequest';
// import {
//     getNodeServerURL
//  } from '../utils/utilityFunctions';



//  class ThemeService {
//     constructor() {
//         this.NODESERVER_URL = getNodeServerURL();
//         this.THEME_URL = `${this.NODESERVER_URL}theme`
//     }

//     getThemeData = (successCb, errorCb) => {
//         const url = `${this.THEME_URL}/default`;
//         httpRequestService.getRequestWithoutToken(url, successCb, errorCb)
//     }

//     getOrganizationTheme = (query) => {
//         const url = `${this.THEME_URL}/organization/${query.organizationId}`;
//         return httpRequestService.getRequestPromise(url)
//     }

//     getProjectTheme = (query) => {
//         const url = `${this.THEME_URL}/organization/${query.organizationId}/project/${query.projectId}`;
//         return httpRequestService.getRequestPromise(url)
//     }

//     getIfOrgIsWhitelabelled  = (query, successCb, errorCb) => {
//         const url = `${this.THEME_URL}/organization/${query.organizationId}/isWhitelabelled`;
//         httpRequestService.getRequest(url, successCb, errorCb)
//     }
//  }

//  export default new ThemeService;


import httpRequestService from '../utils/newHTTPrequest';
import {
    getNodeServerURL
 } from '../utils/utilityFunctions';



 class ThemeService {
    constructor() {
        this.NODESERVER_URL = getNodeServerURL();
        this.THEME_URL = `${this.NODESERVER_URL}theme`
    }

    getThemeData = (successCb, errorCb) => {
        const url = `${this.THEME_URL}/default`;
        return httpRequestService.getRequestWithoutToken(url, successCb, errorCb)
    }

    getOrganizationTheme = (query) => {
        const url = `${this.THEME_URL}/organization/${query.organizationId}?useParent=${query.useParent ? query.useParent : false}`;
        return httpRequestService.get(url)
    }

    getProjectTheme = (query) => {
        const url = `${this.THEME_URL}/organization/${query.organizationId}/project/${query.projectId}?useParent=${query.useParent ? query.useParent : false}`;
        return httpRequestService.get(url)
    }

    getIfOrgIsWhitelabelled  = (query, successCb, errorCb) => {
        const url = `${this.THEME_URL}/organization/${query.organizationId}/isWhitelabelled`;
        return httpRequestService.get(url, successCb, errorCb)
    }
 }

 export default new ThemeService;