import httpRequestService from '../utils/httpRequest';

 import {
    generateQueryString
 } from '../utils/utilityFunctions';

 class OrganizationService {
    constructor() {    
        this.BASE_URL = '/nodeserver/organization';
        this.BASE_USER_URL = `${this.BASE_URL}/user`;
    }

    // Organization CRUD
    addOrganizations = (organization, successCb, errorCb) => {
        httpRequestService.postRequest(this.BASE_URL, organization, successCb, errorCb);
    }
    
    getOrganizations = (query, successCb, errorCb) => {
        delete query.id;
        let url = this.BASE_URL;
        
        if(Object.keys(query).length) {
            url = generateQueryString(this.BASE_URL, query);
        }

        httpRequestService.getRequest(url, successCb, errorCb);
    }

    getOrganization = (query, successCb, errorCb) => {
        let url = `${ this.BASE_URL}/${query.id}`;   
        delete query.id;
        url = generateQueryString(url, query);
        httpRequestService.getRequest(url, successCb, errorCb);
    }

    updateOrganization = (organization, successCb, errorCb) => {
        let url = `${ this.BASE_URL}/${organization.id}`;
        httpRequestService.putRequest(url, organization, successCb, errorCb);
    }

    updateOrganization = (query, successCb, errorCb) => {
        let url = `${ this.BASE_URL}/${query.id}`
        httpRequestService.deleteRequest(url, successCb, errorCb);
    }

    // Organization-User CRUD
    
    getUsers = (query, successCb, errorCb) => {
        let url = `${this.BASE_URL}/${query.id}/users`;
        delete query.id;
        url = generateQueryString(url, query);
        httpRequestService.getRequest(url, successCb, errorCb);  
    }

    getUserForOrganization = (query, successCb, errorCb) => {
        let url = generateQueryString(this.BASE_USER_URL, query);
        httpRequestService.getRequest(url, successCb, errorCb);  
    }

    addUserToOrganization = (user, successCb, errorCb) => {
        httpRequestService.postRequest(this.BASE_USER_URL, user, successCb, errorCb);  
    }

    updateUserToOrganization = (user, successCb, errorCb) => {
        httpRequestService.putRequest(this.BASE_USER_URL, user, successCb, errorCb);  
    }

    deleteUserFromOrganization = (user, successCb, errorCb) => {
        let url = `${ this.BASE_USER_URL}/${user.id}`        
        httpRequestService.deleteRequest(url, user, successCb, errorCb);  
    }

    getEnvironmentInfo = () =>  {
        const url = `${this.BASE_URL}/env/info`;
        return httpRequestService.getRequestPromise(url);
    };
};

export default new OrganizationService;