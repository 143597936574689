

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
// import Icons, { IconWrapper } from "@cuddle-dev/test-web-ui-lib/dist/icons";
// import Loader from '@cuddle-dev/test-common-web-utils/dist/loader';
import AnswerCard from "../answer-card";
import Onboarding from "./onboarding/Onboarding";
import Document from "./document-content/Document";
// import Analyses from "./analyses";
// import InfiniteScroll from "react-infinite-scroll-component";
// import Lottie from "@cuddle-dev/test-web-ui-lib/dist/lottie";
// import Summary from "./summary/Summary.js";
// import analyticsModule from "@cuddle-dev/test-common-web-utils/dist/utils/analytics";
import { Button, ButtonType, SVGIcon, Tooltip } from "@cuddle-dev/web-components";

import { getChatDate, fetchIntentSubintent } from "../../utils/utilityFunctions";
// import Interpretation from "../interpretation";
import ChatError from "./chat-error/ChatError";
import ProfileIcon from "../profile-icon";
import { useTheme } from "styled-components";
// import CuratedAnalysis from "./curated-analysis";
// import { TYPE } from "@cuddle-dev/test-web-ui-lib/dist/buttonComponent/Constant";
// import ButtonComponent from "@cuddle-dev/test-web-ui-lib/dist/buttonComponent";
// import { createNewSession } from "../../reducers/chat-session-slice.js";
import AnswerFooter from "./answer-footer/AnswerFooter";
import AnswerCarousel from "./answer-carousel/AnswerCarousel";
// import Algorithm from "./analyses/algorithm/Algorithm";
import {ANALYSIS_GROUPS, RESPONSE_TYPES, RESPONSE_DISPLAY_TYPES} from "../../constants";
import AvailableDataAns from "./available-data-ans/AvailableDataAns";
import Loader from "../../components/loader/Loader";
import { useAppSelector } from '../../stores/redux-hooks';
import Summary from "../summary/Summary";
import {useOutsideClick} from "../../hooks";

//images
// import cruxIcon from "../../images/logo-on-white-bg.svg";
// import ThumpsUp from "../../images/thumbs-up_24.svg";
// import ThumpsDown from "../../images/thumbs-down_24.svg";
// import FilledThumpsUp from "../../images/thumbs-up-filled_24.svg";
// import FilledThumpsDown from "../../images/thumbs-down-filled_24.svg";
import { ReactComponent as PencilIcon } from "../../images/pencil_20.svg";
import { ReactComponent as TrashIcon } from "../../images/trash_20.svg";

// styled components
import {
  Conatiner as StyledConatiner,
  ProfileIcon as StyledProfileIcon,
  Chat as StyledChat,
  ChatContainer as StyledChatContainer,
  ResponseContent as StyledResponseContent,
  Time as StyledTime,
  Loader as StyledLoader,
  LoadingShimmer as StyledLoadingShimmer,
  InitialLoader as StyledInitialLoader,
  InitialLoaderAnimation as StyledInitialLoaderAnimation,
  Footer as StyledFooter,
  UserChatContainer as StyledUserChatContainer,
  InfoTooltip as StyledInfoTooltip,
  DocumentsContainer as StyledDocumentsContainer,
  ResponseSection as StyledResponseSection,
  OnboardingContainer as StyledOnboardingContainer,
  MessageWrapper as StyledMessageWrapper
} from "./styled-components";


import "./Chat.scss";

// actions
import { setFullScreenAnsVisibility } from "../../reducers/App.reducer.js";

// services
import ChatSessionService from "../../services/chat-session.service.js";

const MESSAGE_TYPE = {
  info: "system",
  error: "error"
}

function renderMessages(messages) {
  return Object.keys(messages).map((messageType) => {
    return messages[messageType].map((message, index) => {
      // console.log(MESSAGE_TYPE[messageType], messageType, "renderMessages type");
      return <ChatError key={ "message -" + index}
        text={message.displayMessage || "There was an issue in processing the information."}
        type={MESSAGE_TYPE[messageType] || messageType}
    ></ChatError>
    })
  })
}

function isAvailableDataIntent(response) {
  if(response && response.type === RESPONSE_TYPES.interpretation) {
    const intent = response.questionClassification && response.questionClassification.intent && response.questionClassification.intent.toLowerCase();
    const subIntent = response.questionClassification && response.questionClassification.subIntent && response.questionClassification.subIntent.toLowerCase();
    
    if(intent === "help" && subIntent === "available data") {
      return true;
    }
  }
  return false;
}

function shouldShowResponse(response) {
  if(isAvailableDataIntent(response)) {
    return true;
  }
  return !response.hide;
}

function Chat(props) {
  // Declare a new state variable, which we'll call "count"
  const [chatData, setChatData] = useState(props.chatData);
  const userID = props.userID;
  const dispatch = useDispatch();
  const editDivRef = useRef(null);
  const theme = useTheme();
  useOutsideClick(editDivRef, handleClickOutsideEditQuestion);
  const showAvailableData = useAppSelector((state) => state.app.showAvailableData);

  useEffect(() => {
    // Inside this callback function we perform our side effects.
    if (props.chatData !== chatData) {
      // console.log(props.chatData, "chatData changed dataaaa")
      setChatData(props.chatData);
    }
  }, [props.chatData]);

  // useEffect(() => {
  //   // Inside this callback function we perform our side effects.
  //   if (props.chatData !== chatData) {
  //     // console.log(props.chatData, "chatData changed dataaaa")
  //     setChatData(props.chatData);
  //   }
  // }, [props.onBoardingQuestion]);

  useEffect(() => {
    console.log("scrollToBottom");
    if (props.scrollToBottom) {
      props.scrollToBottom();
    }
  }, []);

  // useEffect(() => {
  //   if (props.scrollToBottom && !chatData[chatData.length - 1]?.isEditable) {
  //     props.scrollToBottom();
  //   }
  // }, [chatData]);

  function handleClickOutsideEditQuestion() {
    props.onCancelQuestion();
  }

  function storeUserPreference(index, chatInfo, data) {
    const requestData = {
      "chatId": chatInfo.chatId,
      "conversationId": chatInfo.conversationId,
      "responseId": chatInfo.responseId,
      "userId": userID,
      "userPreference": {
        "visualisation": data
      },
      "canPosition": index
    }
    console.log("[storeUserPreference] requestData: ", requestData);

    ChatSessionService.updateUserPreference(requestData,
      (data) => {
        console.log("[storeUserPreference] user preference updated. ", data);
      },
      (error) => {
        console.log("[storeUserPreference] error in user preference update: ", error);
      }
    )
  }

  function userPreferenceUpdateHandler(chatInfo, CANIndex, data) {
    storeUserPreference(CANIndex, chatInfo, data);
  }

  function fullScreenViewChange(enabled) {
    dispatch(setFullScreenAnsVisibility(enabled));
  }

  const getResponseUI = (responseType, response, notLast, chatInfo) => {
    const data = response?.data ? response?.data[responseType] : null;
    try {
      switch (responseType) {  
        case RESPONSE_TYPES.document: 
          const documentData = response.data[responseType];
          return <React.Fragment>
            <Summary data={documentData.cans[0].content.value}></Summary>
            <StyledResponseSection>
              <StyledDocumentsContainer>
                {
                  documentData.cans[0].references.map((chunk, index) => {
                    return <Document key={response.messageId + "--document--" + index} data={chunk.chunk}></Document>
                  })
                }
              </StyledDocumentsContainer>
              {/* {documentData?.additionalInfo?.transparency ?
                <AnswerFooter transparencyData={documentData?.additionalInfo?.transparency} onDelete={props.onMessageDelete.bind(this, response)} />
                : null
              } */}
            </StyledResponseSection>
          </React.Fragment>
        case RESPONSE_TYPES.summary:
          if(data?.cans[0]?.additionalInfo?.display_type === RESPONSE_DISPLAY_TYPES.availableData) {
            return <AvailableDataAns transactionData={props.transactionData} />
          }
          return (
            <Summary data={response.data[responseType].cans[0].content.value}
              promptId={response.data[responseType].cans[0].promptId}
            ></Summary>
          );
        case RESPONSE_TYPES.insights: 
        const metadata = data.metadata ? data.metadata.filter(metaDataItem => metaDataItem.code == 204)[0] : null;

        if(data && data.cans && data.cans.length) {
          // const isChange = data.cans[0].type?.toLowerCase() === "analysis" && 
          //   data.cans[0].additionalInfo?.group?.toLowerCase() === ANALYSIS_GROUPS.change;
              
          // if(isChange) {
          //   return <Algorithm data={data} isChange={isChange} />
          // }
          return <AnswerCarousel data={data} 
            metadata={metadata} 
            chatInfo={chatInfo}
          />
        } else {
          return null;
        }
      }
    } catch (error) {
        return <ChatError
          text={response.error && response.error.displayMessage ?  response.error.displayMessage : "There was an issue in processing the information."}
        ></ChatError>
    }
  };



  function getResponseFooter(response, hideRemoveAnswer, chatDate) {
    try {
      const hasDocument = response.responseTypes.includes(RESPONSE_TYPES.document);
      const hasSummary = response.responseTypes.includes(RESPONSE_TYPES.summary);
      let isSingleCANInsight = false;
      
      if(response.responseTypes.includes(RESPONSE_TYPES.insights)) {
        const isChange = response.data[RESPONSE_TYPES.insights]?.cans[0]?.type?.toLowerCase() === "analysis" && 
              response.data[RESPONSE_TYPES.insights]?.cans[0]?.additionalInfo?.group?.toLowerCase() === ANALYSIS_GROUPS.change;
  
        if(isChange) {
          if(response.data[RESPONSE_TYPES.insights]?.layouts?.length < 2) {
            isSingleCANInsight = true;
          }
        } else if(response.data[RESPONSE_TYPES.insights]?.cans?.length < 2) {
          isSingleCANInsight = true;
        }
      };
  
      if(hasDocument || hasSummary || isSingleCANInsight) {
        let transparencyData;
  
        if(isSingleCANInsight) {
          transparencyData = response.data[RESPONSE_TYPES.insights]?.cans[0]?.additionalInfo?.transparency;
        } else if(hasDocument) {
          transparencyData = response.data[RESPONSE_TYPES.document]?.additionalInfo?.transparency;
        } else if(hasSummary) {
          transparencyData = response.data[RESPONSE_TYPES.summary]?.cans[0]?.additionalInfo?.transparency;
        }
        
        if(transparencyData) {
          return <AnswerFooter transparencyData={transparencyData} 
            hideRemoveAnswer={hideRemoveAnswer} 
            onDelete={props.onMessageDelete.bind(this, response)}
            chatDate={chatDate}
          />
        }
      }
  
      if(!hideRemoveAnswer) {
        return <AnswerFooter className="response__answer-footer" 
          hideTransparency={true}
          hideRemoveAnswer={hideRemoveAnswer}
          onDelete={props.onMessageDelete.bind(this, response)}
          chatDate={chatDate}
        />
      }  
    } catch (error) {
      return <ChatError text={"There was an issue in processing the information."}></ChatError>;
    }
    
    return null;
  }

  // console.log(chatData, "props.chatData");
  const showOnboarding = chatData && !chatData.length;
  return (
    <StyledConatiner showOnboarding={showOnboarding}>
      {chatData && chatData.length ? 
        chatData.map((chat, index) => {
          const notLast = (index < (chatData.length - 1));
          const chatDate = !chat.isLoading && getChatDate(
            chat?.terminate?.[chat.terminate.length - 1].created
          );

            return (
              <div
                className={"conversation-container" + (notLast ? " not-last" : "")}
                key={"convsersation-" + index}
                id={chat.request.chatId}
                data-conversationId={chat.conversationId}
              >

                {chat.request ? (
                  <StyledChatContainer
                    className="user-chat-bubble user"
                   
                    showAvailableData={showAvailableData}
                    isEditable={chat.isEditable}
                    ref={chat.isEditable ? editDivRef : null}
                  >
                      <StyledUserChatContainer isEditable={chat.isEditable} >
                        <StyledProfileIcon className="profile-icon">
                          <ProfileIcon />
                        </StyledProfileIcon>
                          <React.Fragment>
                            <StyledChat className="user">
                              <span>{chat.request?.data?.CHAT?.question?.query}</span>
                              {chat.request?.userAction === "CHAT_EDIT" ? <span className="edited-status">(Edited)</span> : null}
                            </StyledChat>
                            <div>
                              <span className={"edit-btn" + (notLast ? " disabled" : "")}>
                                <Button
                                  startEnhancer={<SVGIcon Icon={PencilIcon} width={24} height={24} fill={theme["icon_02"].light}/>}
                                  buttonType={ButtonType.buttonIcon}
                                  theme={theme}
                                  onClick={props.onEditClick?.bind(this, chat)}
                                  disabled={notLast || chat.isLoading}
                                ></Button>
                                <StyledInfoTooltip className="chat__-info-tooltip">
                                  <Tooltip
                                      style={{ position: "relative" }}
                                      text={"You can edit only latest question."}
                                  />
                                </StyledInfoTooltip>
                              </span>
                              <Button
                                startEnhancer={<SVGIcon Icon={TrashIcon} width={24} height={24} fill={theme["icon_02"].light}/>}
                                buttonType={ButtonType.buttonIcon}
                                theme={theme}
                                disabled={chat.isLoading}
                                onClick={props.deleteChat?.bind(this, chat)}>
                              </Button>
                            </div>
                          </React.Fragment>
                        {/* } */}
                      </StyledUserChatContainer>
                  </StyledChatContainer>
                ) : null}

                <StyledChatContainer
                  className="user-chat-bubble crux"
                  showAvailableData={showAvailableData}
                >
                  <StyledChat className="crux">
                    { chat.responses ? (
                      <React.Fragment>
                        { chat.responses.map((response, index) => {
                          const hideRemoveAnswer = ((chat.responses.length - (chat.terminate ? chat.terminate.length : 0)) <= 1);
                          if(response.responseTypes?.includes(RESPONSE_TYPES.terminate) || 
                            response.type === "REQUEST" || 
                            (response.responseTypes?.includes("INTERPRETATION")) ||
                            (response.type === "RESPONSE" && !response.responseTypes)
                          ) {
                            if(response.messages && Object.keys(response.messages).length) {
                              return <StyledMessageWrapper>
                                {renderMessages(response.messages)}
                              </StyledMessageWrapper>
                            }

                            return null;
                          }

                          return <StyledResponseContent  key={
                            "chat--" +
                            response.conversationId +
                            "--" + response.messageId 
  
                          } id={response.messageId} className="response-content">

                          {
                            response.messages ? renderMessages(response.messages) : null
                          }
                          
                          {
                            response.responseTypes && response.data ? <>
                              { response.responseTypes.filter(responseType => response.data[responseType]).map((responseType, messageIndex) => {
                                  const chatInfo = {
                                    chatId: response.chatId,
                                    conversationId: response.conversationId,
                                    messageId: response.messageId,
                                    ...fetchIntentSubintent(chat)
                                  };
                                  return ( shouldShowResponse(response) ? 
                                  <StyledResponseSection
                                    className="response-section"
                                    key={
                                      "chat--" +
                                      response.conversationId +
                                      "--" + response.messageId +
                                      "--" +
                                      index + "--" + messageIndex
                                    }
                                  >
                                  {
                                    getResponseUI(responseType, response, notLast, chatInfo)
                                  }
                                  </StyledResponseSection> : null
                                )
                                })
                              }
                              {getResponseFooter(response, hideRemoveAnswer, chatDate)}
                            </> : null
                          }
                        </StyledResponseContent>
                        })}
                      </React.Fragment>
                    ) : (
                      <StyledInitialLoader>
                        <StyledInitialLoaderAnimation>
                          Loading...
                        </StyledInitialLoaderAnimation>
                      </StyledInitialLoader>
                    )}
              
                    {chat.isLoading ? (
                            // <StyledResponseContent className="response-content loader">
                            <StyledLoader>
                              <Loader type="shimmer"/>
                              {/* <StyledLoadingShimmer>
                                <div className="shimmerBG media"></div>
                              </StyledLoadingShimmer> */}
                            </StyledLoader>
                            // </StyledResponseContent>
                          ) :
                          null
                           
                    }
                  </StyledChat>
                </StyledChatContainer>
              </div>
            );
        })
        : (
          showOnboarding ? (
            <StyledOnboardingContainer>
              <Onboarding
                onAsk={props.onAsk}
                data={props.onBoardingQuestion}
                copilotIcon={props.copilotIcon}
                projectInfo={props.projectInfo}
              />
            </StyledOnboardingContainer>
        ): null)}
    </StyledConatiner>
  );
}

export default Chat;

export {
  renderMessages
}

