import styled from "styled-components";
import { getStyleString } from "../../../utils/styleUtils";

export const Container = styled.div`
    display: flex;
    width: 278.667px;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    background:  ${props => props.theme["ui_04"]};

    .modal-content {
        max-height: 100%;
    }
`;

export const Title = styled.div`
    color: ${props => props.theme["text_02"].light};
    ${props => getStyleString(props.theme["answer_document_title"])};
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    width: 100%;
    text-overflow: ellipsis;
`;

export const Content = styled.div`
    display: flex;
    padding: 8px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-radius: 8px;
    background:  ${props => props.theme["ui_03"]}; ;

`;


export const Page = styled.div`
    color: ${props => props.theme["text_04"].light};
    ${props => getStyleString(props.theme["eyebrow_text"])};
 
`;


export const PageContent = styled.div`
    color: ${props => props.theme["text_02"].light};
    ${props => getStyleString(props.theme["answer_summary"])};
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    width: 100%;
    text-overflow: ellipsis;

`;

export const PDFContainer = styled.div`
    height: 80vh;
    background:  ${props => props.theme["ui_04"]};
    box-sizing: border-box;
    border-radius: 12px;
`;

export const PDFContainerBody = styled.div`
    padding: 32px 72px;
    height: calc(100% - 84px);
    box-sizing: border-box;
    overflow: auto;
`;

export const PDFPage = styled.div`
    .react-pdf__Page__canvas {
        border-radius: 8px;
        box-sizing: border-box;
    }
`;


export const Header = styled.div`
    background: #FFF;
    display: flex;
    justify-content: space-between;
    padding: 16px 20px 8px 20px;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    box-sizing: border-box;
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
`;


export const Icons = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 8px;
    box-sizing: border-box;
    .icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        display: flex;
    }
`;

export const HeaderTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;

    .title {
        ${props => getStyleString(props.theme["answer_title"])};
        color: ${props => props.theme["text_01"].light};
    }

`