import httpRequestService from '../utils/httpRequest';
import { getNodeServerURL } from '../utils/utilityFunctions';

class DataDiscoveryService {
    constructor() {
        this.NODE_SERVER_URL = getNodeServerURL() ;
        this.AUTH_URL = `${this.NODE_SERVER_URL}auth-api/`;
    }

    // GET -> 'https://dev.cuddle.ai/nodeserver/auth-api/authorize/transactions?userID=:userID'
    fetchTransactionList = (query) => {
        let url = `${this.AUTH_URL}authorize/transactions?userID=${ query.userID }`;
        
        console.log("[fetchTransactionList] url: ", url);
        return httpRequestService.getRequestPromise(url);
    }

    // GET -> 'https://dev.cuddle.ai/nodeserver/auth-api/authorize/transaction/:transactionName/attributes?userID=:userID'
    fetchAttributesList = (query) => {
        let url = `${this.AUTH_URL}authorize/transaction/${query.transactionName}/attributes?userID=${ query.userID }`;
        
        console.log("[fetchAttributesList] url: ", url);
        return httpRequestService.getRequestPromise(url);
    }

    // GET -> 'https://dev.cuddle.ai/nodeserver/auth-api/authorize/transaction/:transactionName/measures?userID=:userID'
    fetchMeasuresList = (query) => {
        let url = `${this.AUTH_URL}authorize/transaction/${query.transactionName}/measures?userID=${ query.userID }`;
        
        console.log("[fetchMeasuresList] url: ", url);
        return httpRequestService.getRequestPromise(url);
    }

    searchKnowledge = (query) => {
        let url = `${this.AUTH_URL}authorize/knowledge/search?userID=${query.userID}&searchTerm=${query.searchTerm}`;
        console.log("[Search knowledge] url: ", url);
        return httpRequestService.getRequestPromise(url);
    }
}

export default new DataDiscoveryService;