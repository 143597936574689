import httpRequestService from '../utils/httpRequest';

class LoginService {
    constructor() {
        this.BASE_URL = "/login/";
    }

    // getAttributes = (query, successCb, errorCb) => {
    //     let url = this.BASE_URL + "attributes/";
    //     httpRequestService.postRequest(url, query, successCb, errorCb);
    // }

    getLoginOptions = (query, successCb, errorCb) => {
        let url = this.BASE_URL + "authWays";
        httpRequestService.getRequestWithoutToken(url, successCb, errorCb);
    }

    getOrgAuthDetails = (query, successCb, errorCb) => {
        let url = this.BASE_URL + "orgAuthDetails";
        httpRequestService.getRequestWithoutToken(url, successCb, errorCb);
    }

    // uploadPeriodTable = (query, data, successCb, errorCb) => {
    //     let url = this.BASE_URL + "period/uploadPeriod";
    //     httpRequestService.postRequest(url, data, successCb, errorCb);
    // }
    
}

export default new LoginService;