import React, {useState, useEffect} from "react";
import { Navigate } from "react-router-dom";
import {useTheme} from "styled-components";

import {
  setCookie,
  deleteAllCookies,
} from "../../utils/utilityFunctions";
import LoginOptions from "./login-options/LoginOptions";
import AnimatedText from "./animated-text";
import { useAppDispatch } from '../../stores/redux-hooks.ts';
import analyticsModule from "../../utils/analytics";

// services
import SessionService from "../../services/session.service";
import OrganizationUserService from "../../services/organisation.users.service";

//Redux actions
import { set_project_list as setProjectList } from "../../reducers/Project.reducer.js";
import { set_project as setCurrentProject } from "../../reducers/CurrProj.reducer.js";

//styled components
import { 
  MainContainer as StyledMainContainer,
  LoginContainer as StyledLoginContainer,
  LeftSection as StyledLeftSection,
  LoginHeader as StyledLoginHeader,
  HeaderLogo as StyleHeaderLogo,
  HeaderSeparator as StyledHeaderSeparator,
  HeaderText as StyledHeaderText,
  LoginContentText as StyledLoginContentText,
  LoginOptionsContainer as StyledLoginOptionsContainer,
  LoginHolder as StyledLoginHolder
} from "./styled-components";

// constants
import {
  CURRENT_PROJECT_SESSION_KEY,
  ALL_PROJECT_SESSION_KEY,
  ORGANIZATION_LINKS,
  APP_USER_CONFIG_SESSION_KEY,
  MULTITENANT_PROJECT_ALERT_MESSAGE_SESSION_KEY,
  USER_LOGIN_RESPONSE,
} from "../../constants/";
const LOGIN_STATE = {
  DEFAULT: 1,
  SUCCESS: 2,
  ERROR: 3,
};
const REDIRECT_URL = {
  ASK_PAGE: "/user/boards",
  ORGANIZATION_PAGE: ORGANIZATION_LINKS.PROJECTS_LIST,
};

function Login(props) {
  const theme = useTheme();
  const [currentState, setCurrentState] = useState(LOGIN_STATE.DEFAULT);
  const [redirectTo, setRedirectTo] = useState(REDIRECT_URL.ASK_PAGE);
  const [loginWarning, setLoginWarning] = useState(false);
  const [projectAlert, setProjectAlert] = useState("");
  const [hasProjectListAlert, setHasProjectListAlert] = useState(false);
  const [loginWarningProjectAlert, setLoginWarningProjectAlert] = useState(false);
  const [projects, setProjects] = useState(false);
  let userLoginResponse;
  const dispatch = useAppDispatch();
  // console.log("theme: ", theme);
  useEffect(() => {
    if (!projectAlert) {
      setTimeout(() => {
        checkForProjectMessage();
      }, 1000);
    }
  });

  useEffect(() => {
    if(loginWarning) {
      deleteAllCookies();
    }
  }, [loginWarning]);

  // componentWillUnmount() {
  //   SessionService.setItem(
  //     MULTITENANT_PROJECT_ALERT_MESSAGE_SESSION_KEY,
  //     ""
  //   );
  //   this.setState({
  //     loginWarning: false,
  //     redirectTo: REDIRECT_URL.ASK_PAGE,
  //     projectAlert: "",
  //     hasProjectListAlert: false,
  //     loginWarningProjectAlert: false,
  //   });
  // }

  function checkForProjectMessage() {
    let message = "";
    let sessionProjects;

    message = SessionService.getItem(
      MULTITENANT_PROJECT_ALERT_MESSAGE_SESSION_KEY
    );
    sessionProjects = SessionService.getItem(ALL_PROJECT_SESSION_KEY);

    // Getting data from session storage
    userLoginResponse = SessionService.getItem(USER_LOGIN_RESPONSE);

    // Parsing data to json
    userLoginResponse = userLoginResponse
      ? JSON.parse(userLoginResponse)
      : {};

    // Handling "null" case
    userLoginResponse = userLoginResponse || {};

    sessionProjects = sessionProjects ? JSON.parse(sessionProjects) : [];
    userLoginResponse.projects = sessionProjects;

    if (message || (message && message !== "undefined")) {
      if (sessionProjects.length > 0) {
        setCurrentState(LOGIN_STATE.SUCCESS);
        setProjects(sessionProjects);
        setProjectAlert(message);
        setHasProjectListAlert(true);
        // this.setState({
        //   currentState: LOGIN_STATE.SUCCESS,
        //   projects,
        //   projectAlert: message,
        //   hasProjectListAlert: true,
        // });
      } else {
        setLoginWarningProjectAlert(true);
        setProjectAlert(message);
        // this.setState({
        //   loginWarningProjectAlert: true,
        //   projectAlert: message,
        // });
      }
    }
  };

  function setAnalyticsUser(userInfo, loginMethod) {
    let date = new Date().toISOString().slice(0, 19);
    analyticsModule.setUserProps({
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      userID: userInfo.userID,
      email: userInfo.email,
    });
    analyticsModule.incrementPeopleProps({ "# of logins": 1 });
    analyticsModule.setPeoplePropsOnce({
      "First login": date,
    });
    analyticsModule.setPeopleProps({
      "Last login": date,
    });
    analyticsModule.trackEvent("Log In", {
      "Login method": loginMethod,
    });
  };

  function loginSuccessHandler(response, loginMethod) {
    console.log("[loginSuccessHandler] response: ", response);
    let projectsInResponse = response.projects;
    userLoginResponse = response;

    SessionService.setItem(
      USER_LOGIN_RESPONSE,
      JSON.stringify(userLoginResponse)
    );

    let cookieExpiry = new Date();
    cookieExpiry.setTime(cookieExpiry.getTime() + 20 * 24 * 60 * 60 * 1000);

    setAnalyticsUser(response, loginMethod);

    if (projectsInResponse && projectsInResponse.length >= 1) {
      let userConfig = {
        firstName: response.firstName,
        lastName: response.lastName,
        userID: response.userID,
        email: response.email,
        accesstoken: response.accesstoken,
        refreshtoken: response.refreshtoken,
        expires: cookieExpiry.toUTCString(),
        organisation: projectsInResponse[0].organizationId,
      };

      setCookie(userConfig);

      setCurrentState(LOGIN_STATE.SUCCESS);
      setProjects(projectsInResponse);
      // this.setState({
      //   currentState: LOGIN_STATE.SUCCESS,
      //   projects,
      // });

      SessionService.setItem(
        ALL_PROJECT_SESSION_KEY,
        JSON.stringify(projectsInResponse)
      );
      SessionService.setItem(
        CURRENT_PROJECT_SESSION_KEY,
        JSON.stringify(projectsInResponse[0])
      );
      
      dispatch(setProjectList(projectsInResponse));
      dispatch(setCurrentProject(projectsInResponse[0]))
      // props.setProjectList(projectsInResponse);
      // props.setCurrProject(projectsInResponse[0]);
      onProjectSelect(projectsInResponse[0]);
    } else if (!projectsInResponse || (projectsInResponse && projectsInResponse.length === 0)) {
        loginPostOperation(response, [], true);
        setLoginWarning(true);

        // this.setState(
        //   {
        //     loginWarning: true,
        //   },
        //   () => {
        //     deleteAllCookies();
        //   }
        // );
      // }
    }
  };

  function loginPostOperation(response, project, preventLogin) {
    console.log(response, "response");

    let cookieExpiry = new Date();
    cookieExpiry.setTime(cookieExpiry.getTime() + 20 * 24 * 60 * 60 * 1000);

    let projectID, organizationId;

    if (project && project.id) {
      projectID = project.id;
      organizationId = project.organizationId;
    } else if (response.projects && response.projects.length) {
      projectID = response.projects[0].id;
      organizationId = response.projects[0].organizationId;
    }

    let userConfig = {
      firstName: response.firstName,
      lastName: response.lastName,
      userID: response.userID,
      email: response.email,
      accesstoken: response.accesstoken,
      refreshtoken: response.refreshtoken,
      expires: cookieExpiry.toUTCString(),
      projectID,
      organisation: organizationId,
    };

    setCookie(userConfig);
    SessionService.setItem(
      APP_USER_CONFIG_SESSION_KEY,
      JSON.stringify(userConfig)
    );

    getUserRole(response.userID);

    if (preventLogin) {
      return;
    }

    props.login(
      response
    );
    // SessionService.setItem(CURRENT_ORGANIZATION_SESSION_KEY, JSON.stringify({
    //     "id": project.organizationId,
    //     "name": project.organizationName,
    // }));
  };

  function onProjectSelect(project, projectIndex) {
    setCurrentState(LOGIN_STATE.DEFAULT);
    // this.setState({
    //   currentState: LOGIN_STATE.DEFAULT,
    // });
    loginPostOperation(userLoginResponse, project);
    SessionService.setItem(
      CURRENT_PROJECT_SESSION_KEY,
      JSON.stringify(project)
    );
  };

  function getUserRole(id) {
    OrganizationUserService.getUser(
      { id },
      (resp) => {
        let userConfig = SessionService.getItem(
          APP_USER_CONFIG_SESSION_KEY
        );
        userConfig = userConfig ? JSON.parse(userConfig) : {};
        userConfig.role = resp.role;
        SessionService.setItem(
          APP_USER_CONFIG_SESSION_KEY,
          JSON.stringify(userConfig)
        );
      },
      (error) => console.error(error)
    );
  };

  function renderLoginView () {
    if (props.isLoggedIn) {
      return (
        // <Redirect to={{ pathname: redirectTo, state: { username: this.state.email } }} />
        <Navigate to={{ pathname: redirectTo}} />
      );
    }

    return (
      <StyledLoginHolder>
        <LoginOptions
          theme={theme}
          projectAlert={projectAlert}
          onLoginSuccess={loginSuccessHandler}
          loginWarning={loginWarning}
          loginWarningProjectAlert={loginWarningProjectAlert}
        />
      </StyledLoginHolder>
    );
  };

  

  const backgroundbrightness = theme.backgroundMeta?.interface_background?.colorBrightness || "light";
  
  return (
    <StyledMainContainer>
      <StyledLoginContainer>
        <StyledLeftSection>
          <StyledLoginHeader>
            <StyleHeaderLogo>
              {props.defaultBrandLogo ? <img src={props.defaultBrandLogo} alt="" /> : null}
            </StyleHeaderLogo>
            <StyledHeaderSeparator backgroundbrightness={backgroundbrightness} />
            <StyledHeaderText backgroundbrightness={backgroundbrightness}>
              Your AI analyst
            </StyledHeaderText>
          </StyledLoginHeader>
          <StyledLoginContentText>
            <AnimatedText theme={theme} envInfo={props.envInfo} backgroundbrightness={backgroundbrightness}/>
          </StyledLoginContentText>
        </StyledLeftSection>
        <StyledLoginOptionsContainer>
          {currentState !== LOGIN_STATE.SUCCESS &&
            renderLoginView()}
        </StyledLoginOptionsContainer>
      </StyledLoginContainer>
    </StyledMainContainer>
  );
}

export default Login;
