import styled from "styled-components";
import { getStyleString } from "../../../../utils/styleUtils";

export const Container = styled.div`
    border: 1px solid ${(props) => props.theme["border_03"]};
    border-radius: 12px;
    background: #FFF;

    &:first-child {
        margin-top: 0px;
    }

    .answer-footer {
      margin-top: 0px;
      padding: 16px 20px;
      border-top: 1px solid ${(props) => props.theme["border_03"]};
    }
`;

export const Header = styled.div`
  border-bottom: 1px solid ${(props) => props.theme["border_03"]};
  padding: 12px 20px 20px 20px;
  position: relative;
`;

export const Title = styled.div`
  margin-bottom: 12px;
  color: ${(props) => props.theme["text01"]};
  ${(props) => getStyleString(props.theme["cardHeader"])};
`;

export const VizSection = styled.div`
  ${props => props.isChange ? `
    display: grid;
    grid-template-columns: repeat(${props.gridColumns || 1}, 1fr);
    align-items: baseline;
    grid-column-gap: 20px;
    padding: 12px 8px;
  ` : ""}
`;

export const Viz = styled.div`
  &:first-child {
    margin-top: 0px;
  }
`;

export const VizTitle = styled.div`
  margin-bottom: 12px;
  color: ${(props) => props.theme["text01"]};
  ${(props) => getStyleString(props.theme["uiDescription"])};
`;

export const Error = styled.div`
  margin-bottom: 12px;
`;

export const SubHeader = styled.div`
  padding: 10px 14px;
  border-bottom: 1px solid ${(props) => props.theme["border_03"]};
`;