import React, {Component} from 'react';
import SuggestionOption from './suggestion-option/SuggestionOption.js';

//styled components
import {
    DropdownDiv as StyledDropdownDiv,
    // OptionSection as StyledOptionSection,
    // LinkDiv as StyledLinkDiv,
    // KeysHelp as StyledKeysHelp,
    Banner as StyledBanner
} from "./styled-components";

function SuggestionDropdown(props) {
    function optionClickHandler(option) {
        // if(props.onClick) {
        //     props.onClick(option);
        // }

        console.log("[optionClickHandler] option: ", option);
        if(props.onChange && !option.selected) {
            props.onChange(option);
        }
    }

    let optionDivs = props.options && props.options.length && props.options.map((option, index) => {
        // console.log(index, " => option: ", option);
        return (
            <SuggestionOption theme={props.theme}
                data={{
                    ...option,
                    hovered: props.highlightOption === index
                }}
                highlight={index === props.highlightedSuggestion}
                onClick={optionClickHandler}
                key={option.value || option.label}
            />
        );
    });

    if(!(optionDivs && optionDivs.length)) {
        return null;
    }
    
    return (
        <StyledDropdownDiv theme={props.theme} customStyling={props.customStyling}>
            {props.banner ?
                <StyledBanner theme={props.theme}>{props.banner.text}</StyledBanner>
                : null
            }
            {optionDivs}
        </StyledDropdownDiv>
    );

}

export default SuggestionDropdown;