import styled from "styled-components";
import { getStyleString } from "../../../../../utils/styleUtils";

interface containerTypes {
    $gridtemplate: string;
};

interface sectionLabelTypes {
    order: number;
};

export const Container = styled.div<containerTypes>`
    display: grid;
    grid-template-columns: ${props => props.$gridtemplate};
    grid-gap: 12px 16px;
`;

export const SectionLabel = styled.div<sectionLabelTypes>`
    color: ${props => props.theme["text_03"].light};
    ${props => getStyleString(props.theme["eyebrow_text"])};
    text-transform: uppercase;
    order: ${props => props.order};
`;

export const SectionText = styled.div<sectionLabelTypes>`
    border-radius: 8px;
    padding: 16px 20px;
    flex-grow: 1;
    background: ${props => props.theme["ui_04"]};
    color: ${props => props.theme["text_01"].light};
    ${props => getStyleString(props.theme["text_02_med"])};
    order: ${props => props.order};
`;

export const Arrow = styled.div<sectionLabelTypes>`
    display: flex;
    align-items: center;
    order: ${props => props.order};
`;

export const Filler = styled.div<sectionLabelTypes>`
    order: ${props => props.order};
`;