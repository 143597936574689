import React from "react";

// import "./LoginLoader.scss";

import Loader from "../../../../components/loader/Loader.js";

// styled components
import {
    Container as StyledContainer,
    Text as StyledText
} from "./styled-components.js";

function LoginLoader(props) {
    return (
        <StyledContainer className="login-loader">
            <Loader type="circular" />
            {props.showText ?
                <StyledText className="login-loader__text">Please wait</StyledText>
                : null
            }
        </StyledContainer>
    );
}

export default LoginLoader;