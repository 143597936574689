import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {SVGIcon} from '@cuddle-dev/web-components';
import { useTheme } from 'styled-components';

// styked components
import {
    Container as StyledContainer,
    Header as StyledHeader,
    Content as StyledContent,
    HeaderTitle as StyledHeaderTitle,
    Cross as StyledCross,
    HeaderSubTitle as StyledHeaderSubTitle,
    Section as StyledSection,
    SectionTitle as StyledSectionTitle,
    List as StyledList,
    ListItem as StyledListItem,
    Question as StyledQuestion,
    AddIcon as StyledAddIcon,
} from "./styled-components.js";

//images
import { ReactComponent as CloseIcon } from "../../../images/close_20.svg";
import { ReactComponent as AddIcon } from "../../../images/add_20.svg";

const isMac = window.navigator.userAgent.indexOf("Mac OS") !== -1;

function QuestionBank({onClose, onboardingSuggestedQuestions}) {
    const theme = useTheme();
    const [headerHeight, setHeaderHeight] = useState();
    const headerRef = useRef();

    useEffect(() => {
        const newHeaderHeight = (headerRef && headerRef.current && headerRef.current.offsetHeight) || 0;

        if(newHeaderHeight !== headerHeight) {
            setHeaderHeight(newHeaderHeight);
        }
    });

    function addClickHandler(data, event) {
        const customEventWithData = new CustomEvent('questionBankClick', {
            detail: { question: data.question }
        });
        document.dispatchEvent(customEventWithData);
    }

    return <StyledContainer>
        <StyledHeader ref={headerRef}>
            <StyledHeaderTitle>
                <span>Question Bank</span>
                <StyledCross onClick={onClose}>
                    <SVGIcon Icon={CloseIcon} width={20} height={20} fill={theme["icon_01"].light}/>
                </StyledCross>
            </StyledHeaderTitle>
            <StyledHeaderSubTitle>
                <span>{/*isMac ? "cmd" :*/ "ctrl"}</span>
                <span>Shift</span>
                <span>?</span>
                <span>to toggle this panel</span>
            </StyledHeaderSubTitle>
        </StyledHeader>
        <StyledContent headerHeight={headerHeight}>
            {onboardingSuggestedQuestions ?
                onboardingSuggestedQuestions.map(category => {
                    return <StyledSection key={category.id}>
                        <StyledSectionTitle>{category.name}</StyledSectionTitle>
                        <StyledList>
                            {category.onBoardingQuestions.map(question => {
                                return <StyledListItem key={question.id} onClick={addClickHandler.bind(this, question)}>
                                    <StyledQuestion>{question.question}</StyledQuestion>
                                    <StyledAddIcon>
                                        <SVGIcon Icon={AddIcon} width={24} height={24} fill={theme["icon_02"].light}/>
                                    </StyledAddIcon>
                                </StyledListItem>
                            })}
                        </StyledList>
                    </StyledSection>
                })
            : null
            }
        </StyledContent>
    </StyledContainer>
}

export default QuestionBank;