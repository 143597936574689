import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useAppSelector, useAppDispatch } from './stores/redux-hooks';

import "./App.css";
import {
  getCookie,
  setCookie,
  deleteAllCookies,
  fetchImage  
} from "./utils/utilityFunctions.js";
// import dataAPI from "./utils/dataAPI.js";
// import pouch from "./utils/pouch.js";
import AppContext from "./AppContext.js";
// import _ from "lodash";
import SnackBarStack from "./components/snackbar-stack/index.js";
import analyticsModule from "./utils/analytics";
import Main from "./pages/Main-old.js";
import Login from "./pages/login/Login.js";
import AppLoader from "./components/app-loader/AppLoader.component.js";
import httpRequestService from "./utils/httpRequest.js";
import { createTheme, ThemeProvider } from "@cuddle-dev/web-components";

// import devUtils from "@cuddle-dev/test-common-web-utils/dist/utils/devUtils";

// services
import SessionService from "./services/session.service.js";
import OrganizationService from "./services/organization.service.js";
import OrganizationUserService from "./services/organisation.users.service.js";
import ThemeService from "./services/theme.service.js";
import _ from "lodash";

// Redux actions
import {
  show as showLoaderCB,
  hide as hideLoaderCB,
} from "./reducers/loader-slice";
import { set_project_list as setProjectList } from "./reducers/Project.reducer.js";
import { set_project as setCurrentProject } from "./reducers/CurrProj.reducer.js";
import { add_details as addUserDetails, change_persona as changePersona } from "./reducers/user-slice.js";

// images
import logo from "./images/logo/full-logo-on-white.svg";


// constants
import {
  ALL_PROJECT_SESSION_KEY,
  CURRENT_PROJECT_SESSION_KEY,  
  PERSONAS,
  USER_LOGIN_RESPONSE,
  MULTITENANT_PROJECT_ALERT_MESSAGE_SESSION_KEY
} from "./constants/index";

interface AppProps extends React.HTMLAttributes<HTMLDivElement> {
}

// function App(props) {
const App: React.FC<AppProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [theme, setTheme] = useState(createTheme());
  const [themeData, setThemeData] = useState<any>();
  const [envTheme, setEnvTheme] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [defaultBrandLogo, setDefaultBrandLogo] = useState("");
  const [envInfo, setEnvInfo] = useState();
  const [brandLogo, setBrandLogo] = useState();
  const [copilotIcon, setCopilotIcon] = useState();
  const [isPreview, setIsPreview] = useState();
  const [projects, setProjects] = useState();
  const [couchAPIKey, setCouchAPIKey] = useState();
  const [showLoader, setShowLoader] = useState<boolean>();
  const [projectID, setProjectID] = useState();
  const [disableHeader, setDisableHeader] = useState();
  const [embed, setEmbed] = useState<boolean>();
  const [apiKey, setApiKey] = useState();
  const [organizationId, setOrganizationId] = useState();
  const [oauthToken, setOauthToken] = useState();
  const [authType, setAuthType] = useState();
  const [isAdmin, setIsAdmin] = useState<boolean>();
  const isLoading = useAppSelector((state) => state.loader.show);

  const dispatch = useAppDispatch();

  useEffect(() => {
    async function fetchData() {
      dispatch(showLoaderCB("Loading"));
      getDefaultEnvInfo();

      try {
        await getThemeData();
      } catch(error) {
        
      }

      processUserData();
      dispatch(hideLoaderCB());
    }
    fetchData();
  }, []);

  useEffect(() => {
    if(themeData?.logo ) {
      setLogo();
    }
  }, [themeData]);

  useEffect(() => {
    if(embed) {
      window.addEventListener("message", onMessage, false);;
    }

    return function() {
      window.removeEventListener("message", onMessage, false);
    }
  }, [embed]);

  function toggleLoadingState() {
    dispatch(showLoaderCB("Loading"));
    setTimeout(() => {
      dispatch(hideLoaderCB());
    }, 1000);
  };

  function updateUserAndAppDetails(data) {
    setIsLoggedIn(data.isLoggedIn);
    setProjectID(data.projectID);
    setDisableHeader(data.disableHeader);
    setEmbed(data.embed);
    setOrganizationId(data.organizationId);
    setApiKey(data.apiKey);
    setOauthToken(data.oauthToken);
    setAuthType(data.authType);
  }

  function processUserData() {
    let userID = getCookie("userID") as undefined | string;
    let accesstoken = getCookie("accesstoken") as undefined | string;
    let email = getCookie("email") as undefined | string;

    let embed = false;
    let query;
    let options = {} as any;

    if (location) {
      query = new URLSearchParams(location.search);
      embed =  query.get("embed") === "true";
     
      if(embed) {
        // window.addEventListener("message", this.onMessage, false);
        options = {
          disableHeader: query.get("disableHeader") === "true",
          embed,
          organizationId: query.get("organizationId"),
          apiKey: query.get("apiKey"),
          email: query.get("username"),
          oauthToken: query.get("oauthToken"),
          authType: query.get("authType")
        };

        if(options.email && (email !== options.email)) {
          deleteAllCookies();
          clearSessionData();
          userID = email = accesstoken = undefined;
        }
      }
    }

    if (accesstoken && userID && (userID !== "undefined") && email &&  (email !== "undefined") ) {
      let projects = JSON.parse(SessionService.getItem(ALL_PROJECT_SESSION_KEY) as string);
      let currentOrganisation = getCookie("organisation");
      let persona =  getCookie("persona");
      let currentProject = JSON.parse(
        SessionService.getItem(CURRENT_PROJECT_SESSION_KEY) as string
      );

      let currentProjectId = getCookie("projectID");
      // console.log(currentProjectId, "currentProjectId ", currentProject);
      
      let state = {
        isLoggedIn: true,
        firstName: getCookie("firstName") || getCookie("userName"),
        lastName: getCookie("lastName"),
        userID: userID,
        email: getCookie("email"),
        projectID: getCookie("projectID"),
        ...options
      };

      if(persona) {
        let personaObj = PERSONAS.filter(item => item.value === persona)[0];
        dispatch(changePersona(personaObj));
      }
      
      if (projects && currentProject && userID) {
        analyticsModule.setUserProps(state);
        // this.setState(state);
        updateUserAndAppDetails(state);
      } else if (currentOrganisation) {
        let query = {
          id: userID,
        };

        OrganizationUserService.getUser(
          query,
          (resp) => {
            projects = resp ? resp.projects : [];
            if (projects && projects.length) {
              projects.forEach((project) => {
                let id = project.id,
                  projectId = project.projectId;
                project.id = projectId;
                project.projectId = id;
                if (currentProjectId === projectId) {
                  currentProject = project;
                }
              });
              dispatch(setProjectList(projects));
              dispatch(setCurrentProject(currentProject || projects[0]));
              
              SessionService.setItem(
                CURRENT_PROJECT_SESSION_KEY,
                JSON.stringify(currentProject || projects[0])
              );
            }

            SessionService.setItem(
              ALL_PROJECT_SESSION_KEY,
              JSON.stringify(projects)
            );

            analyticsModule.setUserProps(state);
            updateUserAndAppDetails(state);
            // this.setState(state);
          },
          (err) => {
            console.error("Error on getting projects: ", err);
          }
        );
      }

      dispatch(addUserDetails({
        firstName: state.firstName,
        lastName: state.lastName,
        userID: userID,
        email: state.email,
      }));
    } else if(embed) {
      if((options.apiKey || options.oauthToken)) {
        options.projectID = query.get("projectID");

        setProjectID(options.projectID);
        setDisableHeader(options.disableHeader);
        setEmbed(true);
        setOrganizationId(options.organizationId);
        setApiKey(options.apiKey);
        setOauthToken(options.oauthToken);
        setAuthType(options.authType);
        // this.setState({
        //   embed: true,
        //   projectID: query.get("projectID"),
        //   ...options
        // });
        processEmbedData(options);
      }
    } else {
      console.log("logout.");
      deleteAllCookies();
      clearSessionData();
    }
  }

  function onMessage(event) {
    event.preventDefault();
    let embedData = event.data;
    if(embedData) {
      try {
        embedData = JSON.parse(embedData);
        embedData.email = embedData.username;
        let {theme, brandLogo, copilotIcon, isPreview} = embedData;
        if(theme?.theme) {
          const themeDoc = theme.theme.light;
          theme = createTheme({
            interactive: themeDoc.interactiveColor,
            uiInteractiveNegative: themeDoc.interactiveErrorColor,
            textInteractiveNegative: themeDoc.interactiveErrorColor,
            iconInteractiveNegative: themeDoc.interactiveErrorColor,
            notification: themeDoc.notificationColor,
            appHeader: themeDoc.appNavbarBgColor,
            chartColors: themeDoc.chartColorPalette,
          });
        }
        
        setTheme(theme);
        setBrandLogo(brandLogo);
        setCopilotIcon(copilotIcon);
        setIsPreview(isPreview);
        
        setTimeout(() => {
          processEmbedData(embedData);
        }, 0);
  
        console.log("onMessage: process Embed Data message", embedData);
      } catch (error) {
        console.log(error, "onMessage error");
      }
    }
  };

  function processEmbedData(embedData) {
    validateEmbedData(embedData)
  };

  function validateEmbedData(data) {
    if (data && (data.apiKey || data.oauthToken)) {
      let oauthData;

      if (data.apiKey) {
        oauthData = {
          authType: "API_KEY",
          organization_id: data.organizationId,
          grant_type: "password",
          username: data.email,
          apiKeyValue: data.apiKey,
        };
      } else if (data.oauthToken) {
        oauthData = {
          authType: data.authType,
          grant_type: "password",
          id_token: data.oauthToken,
        };
      }

      httpRequestService.getAuthToken(
        "/login/token",
        oauthData,
        (response) => {
          const projects = response.projects;

          let userDetails = {
            accesstoken: response.accesstoken,
            projectID: data.projectID || response.projects[0].id,
            email: response.email,
            firstName: response.firstName,
            lastName: response.lastName,
            userID: response.userID,
            organisation: data.organizationId,
            couchAPIKey: response.couchAPIKey,
            projects,
          };

          let validateProject = response.projects.filter(
            (project) => project.id === userDetails.projectID
          );
          if (!validateProject.length) {
            userDetails.projectID = response.projects[0].id;
          }
          analyticsModule.setUserProps(userDetails);
          setUserLoginDetails({
            ...userDetails, disableHeader: data.disableHeader
          });

          dispatch(addUserDetails({
            firstName: response.firstName,
            lastName: response.lastName,
            userID: response.userID,
            email: response.email,
          }));
        },
        (err) => {
          // this.setState({
          //   showLoader: false,
          // });
        }
      );
    } else {
      // this.setState({
      //   showLoader: false
      // });
    }
  };

  function setUserLoginDetails(data) {
    let cookieExpiry = new Date();
    cookieExpiry.setTime(cookieExpiry.getTime() + 20 * 24 * 60 * 60 * 1000);

    const projects = data.projects;
    const projectID = data.projectID || projects[0].id;
    const currentProjectJson = projects.filter(project => project.id === projectID)[0];
    let userConfig = {
      accesstoken: data.accesstoken,
      projectID: projectID,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      userID: data.userID,
      expires: cookieExpiry.toUTCString(),
      organisation: data.organisation || projects[0].organizationId,
    };

    setCookie(userConfig);
    dispatch(setProjectList(projects));
    dispatch(setCurrentProject(currentProjectJson));
    
    SessionService.setItem(
      CURRENT_PROJECT_SESSION_KEY,
      JSON.stringify(currentProjectJson)
    );
    SessionService.setItem(
      ALL_PROJECT_SESSION_KEY,
      JSON.stringify(projects)
    );

    setProjects(projects);
    // setUserConfig(userConfig);
    setCouchAPIKey(data.couchAPIKey);
    setIsLoggedIn(true);
    setShowLoader(false);
    setProjectID(projectID);
    setDisableHeader(data.disableHeader);
  };

  function processUserProjectsData(projectList = []) {
    // Get latest project details
    let projects = [];
    let projectIds;
    // Adding field projectId if not present
    if (projectList && projectList.length) {
      projects = projectList.map((project) => {
        return {...project, projectId: project.projectId || project.projectID};
      });
      projectIds = projects.map((item) => item.projectId);
    }

    // Get current project
    // let currentProject = SessionService.getItem(CURRENT_PROJECT_SESSION_KEY);
    let currentProject = null;
    // currentProject = currentProject ? JSON.parse(currentProject) : null;

    // Get all existing project
    // let allProjects = SessionService.getItem(ALL_PROJECT_SESSION_KEY);
    let allProjects = null;
    // allProjects = allProjects ? JSON.parse(allProjects) : null;

    if (currentProject) {
      try {
        // find all existing projectIDs
        let allProjectsIds = allProjects
          .filter((item) => item.projectId)
          .map((item) => item.projectId);

        // Find out difference between existing projects and latest projects
        let diffIds =
          allProjectsIds.length > projectIds.length
            ? _.difference(allProjectsIds, projectIds)
            : _.difference(projectIds, allProjectsIds);

        if (diffIds.length) {
          // Lastest project having more count<nav that existing projects
          // If: you have been added to project/s
          // Else: you have been removed from project/s
          if (projectIds.length > allProjectsIds.length) {
            // Get new project
            let newProject = projects.find(
              (item) => item.projectId === diffIds[0]
            );

            let alertMessage = "";

            // If: you dont have the access for current project
            // Else: you have been added to project
            if (diffIds.indexOf(currentProject.projectId) > -1) {
              // If: You have no access to any project
              // Else: you have not access to current project only
              if (projectIds.length === 0) {
                alertMessage = `You have been logged out as you no longer have access to project: ${newProject.name}.`;
              } else {
                alertMessage = `You no longer have access to project: ${newProject.name}. Please select a different project below.`;
              }

              loggoutHandler(alertMessage);
            } else {
              alertMessage = `You have been added to project: ${newProject.name}.`;
              //new // this.setAndClearAlertMessage(alertMessage);
            }
          } else if (projectIds.length < allProjectsIds.length) {
            // Get new project
            let newProject = allProjects.find(
              (item) => item.projectId === diffIds[0]
            );

            let alertMessage = "";

            // If: you dont have the access for current project
            // Else: you have been added to project
            if (diffIds.indexOf(currentProject.projectId) > -1) {
              // If: You have no access to any project
              // Else: you have not access to current project only
              if (projectIds.length === 0) {
                alertMessage = `You have been logged out as you no longer have access to project: ${newProject.name}.`;
              } else {
                alertMessage = `You no longer have access to project: ${newProject.name}. Please select a different project below.`;
              }

              loggoutHandler(alertMessage);
            } else {
              alertMessage = `You have been removed from project: ${newProject.name}.`;
              //new // this.setAndClearAlertMessage(alertMessage);
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    }

    // Update users projects
    // this.throttleFn(projects);
    // if (!currentProject) {
    //   if (allProjects && allProjects.length > 1) {
    //     dispatch(setProjectList(allProjects));
    //   } else if (projects && projects.length > 1) {
    //     dispatch(setProjectList(allProjects));
    //   } else if (allProjects && allProjects.length === 1) {
    //     onProjectSelectHandler(allProjects[0], allProjects);
    //   } else if (projects && projects.length === 1) {
    //     onProjectSelectHandler(projects[0], projects);
    //   }
    // }
  };

  function onProjectSelectHandler(project, projectsAvailForSelections) {
    // console.log("selected", project)
    // Set current project in session
    SessionService.setItem(
      CURRENT_PROJECT_SESSION_KEY,
      JSON.stringify(project)
    );
    this.props.setCurrentProject(project);

    // Set user projects in session
    SessionService.setItem(
      ALL_PROJECT_SESSION_KEY,
      JSON.stringify(projectsAvailForSelections)
    );

    dispatch(setProjectList(project));
  };


  function loggoutHandler(alertMessage) {
    let loginResponse = SessionService.getItem(USER_LOGIN_RESPONSE);
    this.logout();

    // Handling no project assign case with message
    setTimeout(() => {
      SessionService.setItem(
        MULTITENANT_PROJECT_ALERT_MESSAGE_SESSION_KEY,
        alertMessage
      );
      SessionService.setItem(USER_LOGIN_RESPONSE, loginResponse);
    }, 1000);
  };

  function login({firstName, lastName, userID, email, couchAPIKey, projects}) {
    console.log("login: ", firstName, lastName, userID, email, couchAPIKey);
    // clearPouchDB();
    let state = {
      isLoggedIn: true,
      firstName,
      lastName,
      userID,
      email,
      couchAPIKey,
    };

    dispatch(addUserDetails({
      firstName,
      lastName,
      userID,
      email,
    }));

    setCouchAPIKey(state.couchAPIKey);
    setIsLoggedIn(true);
    processUserProjectsData(projects);
    // this.setState(state);
  };

  function clearSessionData() {
    sessionStorage.clear();
  };

  function logout(type) {
    if (type) {
      analyticsModule.trackEvent("Logged Out", {
        From: type,
      });
    } else {
      analyticsModule.trackEvent("Logged Out");
    }
    
    analyticsModule.resetTracking();
    // clearPouchDB();
    setIsLoggedIn(false);
    setIsAdmin(false);

    toggleLoadingState();
    deleteAllCookies();
    clearSessionData();
    dispatch({type: 'USER_LOGOUT'});
    navigate("/");
    // window.location.reload();
  };

  // function clearPouchDB() {
  //   let userProjectsAPI = dataAPI();
  //   let organizationAPI = dataAPI();

  //   userProjectsAPI.destroyByName(pouch(), "user-projects", () => {});
  //   organizationAPI.destroyByName(pouch(), "organization", () => {});
  // };

  function getThemeData() {
    return new Promise((resolve, reject) => {
      ThemeService.getThemeData(
        (response) => {
          resolve(response);
          const themeObj = createThemeObj(response.theme);

          setThemeData(response.theme);
          setTheme(themeObj);
          setEnvTheme(themeObj);
          setLogo();
        },
        (error) => {
          setLogo();
          reject();
          console.log("error fetching theme data.");
        }
      );
    })
  };

  function setLogo() {
    let logoUrl = themeData?.logo;
    if(logoUrl) {
      fetchImage(
        logoUrl,
        (imgURL) => {
          setDefaultBrandLogo(imgURL);
          console.log(imgURL, "fetch defaultBrandLogo success");
        },
        (error) => {
          console.log(error, "fetch defaultBrandLogo error");
          setDefaultBrandLogo(logo);
        }
      );
    } else {
      setDefaultBrandLogo(logo);
    }
  }

  function  setThemeDataFunc(themeDoc){
    return new Promise((resolve, reject) => {
      let theme;
      if (themeDoc && themeDoc.light) {
        theme = createThemeObj(themeDoc);
        setTheme(theme);
        resolve(true);
      } 
    });
  };

  function createThemeObj(themeDoc) {
    let theme;
    if (themeDoc && themeDoc.light) {
      theme = createTheme({
        interactive: themeDoc.light.interactiveColor,
        uiInteractiveNegative: themeDoc.light.interactiveErrorColor,
        textInteractiveNegative: themeDoc.light.interactiveErrorColor,
        iconInteractiveNegative: themeDoc.light.interactiveErrorColor,
        notification: themeDoc.light.notificationColor,
        appHeader: themeDoc.light.appNavbarBgColor,
        chartColors: themeDoc.light.chartColorPalette,
      });
    }
    return theme;
  }

  async function getDefaultEnvInfo() {
    try {
      const response = await OrganizationService.getEnvironmentInfo().promise;
      console.log("getDefaultEnvInfo response: ", response);
      setEnvInfo(response.data.data);
    } catch(error) {
      console.log("[getDefaultEnvInfo] error: ", error);
    };
      // .then((response) => {
      //   setEnvInfo(response.data.data);
      // })
      // .catch(error => {

      // })
  }

  return (
   
      <AppContext.Provider value={{}}>
        {isLoading ?  <AppLoader /> : <div className="App">
            <Routes>
              <Route
                path="/"
                element={
                  isLoggedIn ? (
                    <ThemeProvider theme={theme}>
                      <Main 
                        setThemeData={setThemeDataFunc}
                        logout={logout}
                        brandLogo={brandLogo}
                        copilotIcon={copilotIcon}
                        defaultBrandLogo={defaultBrandLogo}
                      ></Main>
                      <SnackBarStack />
                    </ThemeProvider>
                  ) : (
                    <ThemeProvider theme={envTheme || theme}>
                        <Login
                          theme={envTheme || theme}
                          brandLogo={brandLogo}
                          copilotIcon={copilotIcon}
                          defaultBrandLogo={defaultBrandLogo}
                          isLoggedIn={isLoggedIn}
                          login={login}
                          envInfo={envInfo}
                        />
                    </ThemeProvider>
                  )
                }
              />
            </Routes>
        </div>}

      </AppContext.Provider>
   
  );
}

export default App;