import styled from 'styled-components';
import { getStyleString } from "../../../../../utils/styleUtils";

interface DropdownProps {
    customStyling: string;
}

export const DropdownDiv = styled.div<DropdownProps>`
    ${props => (`
        border-radius: 8px 8px 0px 0px;
        box-shadow: ${props.theme.boxShadow["shadow_low"]};
        background-color: ${props.theme["ui_05"]};
        padding: 12px 8px;
        // border: 1px solid ${props.theme["line01"]};
        // backdrop-filter: blur(27px);
        border-radius: 8px 8px 0px 0px;
        position: relative;
        min-width: 196px;
        width: 100%;
        // max-height: 244px;
        // overflow-y: auto;
        box-sizing: border-box;
    `)
    }

    ${props => props.customStyling || ""};

}`;

export const OptionSection = styled.div`
${props => (`
    &:not(:first-child) {
        padding-top: 8px;
        margin-left: 8px;
        margin-right: 8px;
    }
    &:not(:last-child) {
        // border-bottom: 1px solid ${props.theme['line02']};
        padding-bottom: 8px;
    }
    &:last-child {
        border-top: 1px solid ${props.theme['line02']};
    }
    &.no-border {
        border-bottom: none;
    }
    .title {
        ${getStyleString(props.theme['menuTitle'])};
        color: ${props.theme['text03']};
        padding: 4px 8px;
    }
`)
}`;

export const Banner = styled.div`
    padding: 14px 8px;
    border-top: 1px solid ${props => props.theme['line02']};
    border-bottom: 1px solid ${props => props.theme['line02']};
    ${props => getStyleString(props.theme['menuTitle'])};
    color: ${props => props.theme['text03']};
`;