import styled from "styled-components";
import { getStyleString } from "../../../utils/styleUtils";

export const Container = styled.div`
  height: calc(100% - 16px);
  border-radius: 12px;
  background: #FFF;
  margin: 8px 8px 8px 0px;
`;

export const Header = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${(props) => props.theme["border_03"]};
`;

export const Content = styled.div`
  padding: 20px 0px;
  height: calc(100% - ${props => props.headerHeight}px);
  overflow: auto;
  box-sizing: border-box;
`;

export const HeaderTitle = styled.div`
  ${props => getStyleString(props.theme["text_02_med"])};
  color: ${props => props.theme["text_02"].light};
`;

export const Cross = styled.span`
  width: 20px;
  height: 20px;
  float: right;
  cursor: pointer;
`;

export const HeaderSubTitle = styled.div`
  margin-top: 16px;
  ${props => getStyleString(props.theme["text_00_reg"])};
  color: ${props => props.theme["text_04"].light};

  span:nth-child(1), span:nth-child(2), span:nth-child(3) {
    ${props => getStyleString(props.theme["shortcut_text_tag"])};
    padding: 2px 4px;
    border-radius: 3px;
    border-bottom: 1px solid ${(props) => props.theme["border_02"]};
    background: ${props => props.theme["ui_03"]};
    color: ${props => props.theme["text_03"].light};
    margin-right: 4px;
  }
`;

export const SectionTitle = styled.div`
  margin-bottom: 8px;
  ${props => getStyleString(props.theme["eyebrow_text"])};
  text-transform: uppercase;
`;

export const Section = styled.div`
  padding: 0px 24px;
  border-bottom: 1px solid ${(props) => props.theme["border_02"]};
  margin-bottom: 20px;

  &:last-child {
    border-bottom: none;
    margin-bottom: none;
  }

  // Not using data03 as it is a very light colour(yellow)
  &:nth-child(9n + 1) ${SectionTitle} {
    color: ${(props) => props.theme["data01"]};
  }

  &:nth-child(9n + 2) ${SectionTitle} {
    color: ${(props) => props.theme["data02"]};
  }

  &:nth-child(9n + 3) ${SectionTitle} {
    color: ${(props) => props.theme["data04"]};
  }

  &:nth-child(9n + 4) ${SectionTitle} {
    color: ${(props) => props.theme["data05"]};
  }

  &:nth-child(9n + 5) ${SectionTitle} {
    color: ${(props) => props.theme["data06"]};
  }

  &:nth-child(9n + 6) ${SectionTitle} {
    color: ${(props) => props.theme["data07"]};
  }

  &:nth-child(9n + 7) ${SectionTitle} {
    color: ${(props) => props.theme["data08"]};
  }

  &:nth-child(9n + 8) ${SectionTitle} {
    color: ${(props) => props.theme["data09"]};
  }

  &:nth-child(9n) ${SectionTitle} {
    color: ${(props) => props.theme["data10"]};
  }
`;

export const List = styled.div`

`;

export const ListItem = styled.div`
  display: grid;
  display: grid;
  grid-template-columns: calc(100% - 44px) 24px;
  gap: 20px;
  padding: 12px 0px;
  border-bottom: 1px solid ${(props) => props.theme["border_02"]};
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
`;

export const Question = styled.div`
  ${props => getStyleString(props.theme["text_01_med"])};
  color: ${props => props.theme["text_03"].light}; 
`;

export const AddIcon = styled.div`
  width: 24px;
  height: 24px;
`;
