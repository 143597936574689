import styled from "styled-components";

interface ContainerProps {
}

interface NewConvoProps {
  disabled: boolean;
}

export const Container = styled.div<ContainerProps>`
    display: grid;
    grid-template-rows: 100%;
    height: 100%;

    @media (min-width: 1024px) {
      grid-template-rows: 60px calc(100% - 60px);
    }
`;

export const Header = styled.div`
    padding: 12px 20px;
    box-sizing: border-box;
    border-bottom: 1px solid ${props => props.theme["left_pane_border"].light};
    display: none;
    z-index: 13;
    position: relative;
    @media (min-width: 1024px) {
      display: flex;
      align-items: center;
    }

    .button-icon {
      margin-left: auto;
    } 
`;

export const Content = styled.div`
    // padding: 20px;
`;

export const NewConvo = styled.div<NewConvoProps>`
  padding: 20px 20px 16px 20px;

  .button-content {
    justify-content: left !important;
  }

  ${props => props.disabled ? `
    cursor: not-allowed;

    button {
      pointer-events: none;
    }
  ` : ""}
`;

export const SessionList = styled.div`
  height: calc(100% - 76px);
  overflow: auto;

  / * Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

`;

export const Loader = styled.div`
    padding: 20px;
`;

export const LoadingBar = styled.div`
  margin-bottom: 32px;

  .shimmer-bar {
    width: 54%;
    height: 12px;
    animation-duration: 2.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: linear-gradient(98deg, rgba(193, 196, 200, 0.85) 0.07%, rgba(233, 236, 242, 0.34) 98.82%);
    background-size: 1200px 100%;
  }

  &:nth-child(2), &:nth-child(4) {
    .shimmer-bar {
      width: 75%;
    }
  }

  &:nth-child(3) {
    .shimmer-bar {
      width: 87.5%;
    }
  }

  @-webkit-keyframes shimmer {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 100% 0;
    }
  }

  @keyframes shimmer {
      0% {
          background-position: -1200px 0;
      }
      100% {
          background-position: 1200px 0;
      }
  }
`;